import { UseReservations } from "../../context/ReservationsContext";

export function TypeReservationFilter() {
  const { setServiceType, serviceType } = UseReservations();

  const typesServices = [
    { value: "hotel", label: "Hotel" },
    { value: "tour", label: "Tour" },
    { value: "transport", label: "Transporte" },
  ];

  const handleTypeService = (value) => {

    setServiceType((services) => {
      if (services.some((service) => service === value)) {
        return services.filter((service) => service !== value);
      } else {
        return [...services, value];
      }
    });
  };

  return (
    <div className="flex flex-col gap-y-4 items-start h-full w-max">
      <p className="text-fs-14 m-b text-black">Servicios</p>

      <div className="flex flex-col gap-y-3">
        {typesServices.map((type, item) => (
          <button
            onClick={() => handleTypeService(type.value)}
            key={item}
            className={`select-none py-2 px-4 focus:outline-none rounded-full border ${
              serviceType.some((value) => value == type.value)
                ? "border-bl-100 bg-bl-100 text-white"
                : "border-gry-70"
            }`}
          >
            <p className="m-0 m-s-b text-fs-12 text-center">{type.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
