import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { FilterByOrder } from "./FilterByOrder";
import { FilterByRangeDate } from "./FilterByRangeDate";
import { TypeReservationFilter } from "./TypeReservationFilter";

export function FiltersReservation({ openFilter, setOpenFilter }) {
  return (
    <>
      <Transition show={openFilter} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[2]"
          onClose={() => setOpenFilter(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 " />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg p-7 relative w-max">
                <h3 className="text-fs-18 m-b mb-6 mt-2">
                  Filtros de Reservaciones
                </h3>
                <button
                  onClick={() => setOpenFilter(false)}
                  className="focus:outline-none absolute right-1 top-[15px] mr-3"
                >
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/close/close-70.svg`}
                    alt="close modal"
                    width={13}
                    height={13}
                  />
                </button>

                <div className={`w-max flex flex-col gap-x-6 gap-y-5 `}>
                  <div className="flex gap-6 justify-between">
                    <TypeReservationFilter />

                    <div className="w-[1px] h-[initial] bg-gry-70" />

                    <FilterByOrder />
                  </div>
                  <hr className="border-gry-70" />
                  {/* <div className="w-[1px] h-[initial] bg-gry-70" /> */}

                  <FilterByRangeDate />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
