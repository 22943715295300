/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../../others/BtnEdit";
import { SetConfigDropzone } from "../../others/setConfigDropZone";
import { MarketingContext } from "../../../context/MarketingContext";
import { useUser } from "../../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../../configs/Permissions/AlertPermissionsDeclined";

export function BannerHomeTransport() {
  const {
    totalFiles,
    routActual,
    responsiveView,
    languageSelected,
    jonImagesActualView,
  } = useContext(MarketingContext);

  const [bannerCar, setBannerCar] = useState(null);
  const [bannerBlue, setBannerBlue] = useState(null);
  const [bannerTransport, setBannerTransport] = useState(null);

  useEffect(() => {
    if (jonImagesActualView) {
      setBannerCar(
        jonImagesActualView[languageSelected].bannerCar[responsiveView.value]
      );
      setBannerBlue(
        jonImagesActualView[languageSelected].bannerBlue[responsiveView.value]
      );
      setBannerTransport(
        jonImagesActualView[languageSelected].bannerTransport[
          responsiveView.value
        ]
      );
    }
  }, [totalFiles, responsiveView, routActual, languageSelected]);

  return (
    <>
      <div className={`${responsiveView.value !== "laptop" && "hidden"}`}>
        <BannerHomeTransportD
          bannerCar={bannerCar}
          bannerBlue={bannerBlue}
          bannerTransport={bannerTransport}
        />
      </div>
      <div className={`${responsiveView.value == "laptop" && "hidden"}`}>
        <BannerHomeTransportSwiper
          bannerCar={bannerCar}
          bannerBlue={bannerBlue}
          bannerTransport={bannerTransport}
        />
      </div>
    </>
  );
}

export function BannerHomeTransportSwiper({
  bannerCar,
  bannerBlue,
  bannerTransport,
}) {
  const {
    responsiveView,
    setIsOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
  } = useContext(MarketingContext);

  const [selectedBanner, setSelectedBanner] = useState(null);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (jsonConfig) => {

    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {

      setIsOpenEdit(true);
  
      let configDropzone = SetConfigDropzone(routActual, jsonConfig);
  
      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  const isBannerCar =
    selectedBanner === "bannerCar" && currentBanner === "bannerCar";
  const isBannerBlue =
    selectedBanner === "bannerBlue" && currentBanner === "bannerBlue";
  const isBannerTransport =
    selectedBanner === "bannerTransport" && currentBanner === "bannerTransport";

  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={16}
      className=" !static h-[22.4rem] rounded-lg !z-0"
      breakpoints={{
        0: {
          slidesPerView: 0.5,
        },
        350: {
          slidesPerView: 1,
        },
      }}
    >
      {/* ONE CARD TRANSPORT */}

      <SwiperSlide className="!bg-white !rounded-lg">
        <div
          onClick={() => handleClick("bannerCar")}
          className={`bg-white items-center  relative w-full h-[94%] flex justify-center mb-5 hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer hover:brightness-75 ${
            isBannerCar && "border-4  border-or-100 rounded-xl brightness-75"
          }`}
        >
          <div className="flex h-full flex-col pl-[25px] gap-3 justify-center">
            <span className="m-b text-fs-28">Asegura tu trayecto</span>
            <bottom className="px-[24px] py-[16px] bg-yw-100 hover:bg-yw-110 m-s-b text-fs-14 w-fit rounded-full text-nowrap">
              ¡Reservar ahora!
            </bottom>
          </div>
          <div className="w-[60%] h-full !bg-white">
            <img
              src={bannerCar?.urlImage ? bannerCar?.urlImage : bannerCar}
              width={378}
              height={235}
              className="w-full h-full rounded-r-lg object-cover select-none "
              alt="Banner car"
            />
          </div>
          <BtnEdit />
        </div>
      </SwiperSlide>
      {/*END ONE CARD TRANSPORT */}

      {/*TWO CARD TRANSPORT */}
      <SwiperSlide className="!flex !justify-center !bg-white gap-4">
        <div
          onClick={() => handleClick("bannerBlue")}
          className={`relative w-full flex justify-center mb-5 hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
            isBannerBlue && "rounded-xl border-or-100 border-4"
          }`}
        >
          <img
            src={bannerBlue?.urlImage ? bannerBlue?.urlImage : bannerBlue}
            width={266}
            height={235}
            className={`w-full rounded-lg select-none group-hover:brightness-75 ${
              isBannerBlue && "brightness-75"
            }`}
            alt="banner car blue"
          />
          <BtnEdit />
        </div>

        <div
          onClick={() => handleClick("bannerTransport")}
          className={`relative w-full flex justify-center mb-5 hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
            responsiveView.value === "mobile" && "hidden"
          } ${isBannerTransport && "border-4  border-or-100 rounded-xl"}`}
        >
          <img
            src={
              bannerTransport?.urlImage
                ? bannerTransport?.urlImage
                : bannerTransport
            }
            width={266}
            height={235}
            className={`w-full rounded-lg select-none object-cover object-bottom group-hover:brightness-75 ${
              isBannerTransport && "brightness-75"
            }`}
            alt="Banner tour mes de feb"
          />
          <div className="absolute bottom-[30px] left-[33px] max-xl:top-[32px] max-xl:left-[33px] max-sm:left-[12%] max-sm:top-[40px]">
            <h2 className="m-b w-9/12 text-white text-fs-44 mb-5 text-left max-2xl:text-fs-22 max-xl:text-fs-18 max-lg:text-fsw-48 max-sm:text-fs-24">
              ¿Primera vez con nosotros?
            </h2>
            <div className="bg-red-100 rounded-full flex w-fit">
              <span className="m-s-b text-white pl-[10px] py-[10px]">
                Obten 10% menos
              </span>
              <span className="m-s-b text-black p-[10px] bg-white rounded-lg ml-[10px]">
                Saber más
              </span>
            </div>
          </div>
          <BtnEdit />
        </div>
      </SwiperSlide>
      {/*END TWO CARD TRANSPORT */}

      {/* THREE CARD TRANSPORT */}
      <SwiperSlide
        className={`${
          responsiveView.value === "tablet"
            ? " !hidden"
            : " flex justify-center !bg-white relative"
        }`}
      >
        <div
          onClick={() => handleClick("bannerTransport")}
          className={`relative w-full flex justify-center mb-5 hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
            isBannerTransport && "border-4 border-or-100 rounded-xl"
          }`}
        >
          <img
            src={
              bannerTransport?.urlImage
                ? bannerTransport?.urlImage
                : bannerTransport
            }
            width={378}
            height={235}
            className={`w-full h-[21rem] rounded-lg object-cover object-center select-none group-hover:brightness-75 ${
              isBannerTransport && "brightness-75"
            }`}
            alt="Banner tour mes de feb"
          />

          <div className="absolute bottom-[30px] left-[33px] max-xl:top-[32px] max-xl:left-[33px] max-sm:left-[12%] max-sm:top-[40px]">
            <h2 className="m-b w-9/12 text-white text-fs-44 mb-5 text-left max-2xl:text-fs-22 max-xl:text-fs-18 max-lg:text-fsw-48 max-sm:text-fs-24">
              ¿Primera vez con nosotros?
            </h2>
            <div className="bg-red-100 rounded-full flex w-fit">
              <span className="m-s-b text-white pl-[10px] py-[10px]">
                Obten 10% menos
              </span>
              <span className="m-s-b text-black p-[10px] bg-white rounded-lg ml-[10px]">
                Saber más
              </span>
            </div>
          </div>

          <BtnEdit />
        </div>
      </SwiperSlide>
      {/*END THREE CARD TRANSPORT*/}
      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </Swiper>
  );
}

export function BannerHomeTransportD({
  bannerCar,
  bannerBlue,
  bannerTransport,
}) {
  const {
    responsiveView,
    setIsOpenEdit,
    isOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
  } = useContext(MarketingContext);

  const [selectedBanner, setSelectedBanner] = useState(null);
  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (jsonConfig) => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {

      setIsOpenEdit(true);
  
      let configDropzone = SetConfigDropzone(routActual, jsonConfig);
  
      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  const isBannerCar =
    selectedBanner === "bannerCar" && currentBanner === "bannerCar";
  const isBannerBlue =
    selectedBanner === "bannerBlue" && currentBanner === "bannerBlue";
  const isBannerTransport =
    selectedBanner === "bannerTransport" && currentBanner === "bannerTransport";

  return (
    <>
      <div className={`flex gap-[16px] relative  ${isOpenEdit ? "h-[318px]" : "h-[350px]"}`}>
        <div
          onClick={() => handleClick("bannerCar")}
          className={`flex bg-white justify-center items-center shadow-3xl max-2xl:w-[33%] w-1/2 hover:border-4  hover:border-or-100 hover:rounded-xl hover:brightness-75 ${
            isBannerCar && "border-4 border-or-100 rounded-xl brightness-75"
          }`}
        >
          <div className="w-[50%] flex flex-col pl-[25px] gap-3 max-sm:!w-[58%]">
            <span
              className={`m-b ${
                responsiveView.value == "laptop" &&
                "text-fs-28 max-2xl:text-fs-20"
              }`}
            >
              Asegura tu trayecto
            </span>

            <div
              className={`px-[24px] py-[16px] bg-yw-100 m-s-b w-fit rounded-full text-center ${
                responsiveView.value == "laptop" &&
                "text-fs-14 max-2xl:text-fs-10"
              }`}
            >
              ¡Reservar ahora!
            </div>
          </div>

          <div className="relative w-[60%] h-full !bg-white">
            <img
              src={bannerCar?.urlImage ? bannerCar?.urlImage : bannerCar}
              width={378}
              height={235}
              className="w-full h-full rounded-r-lg object-cover object-center select-none "
              alt="Banner car desktop"
            />
          </div>
          <BtnEdit />
        </div>

        <div
          onClick={() => handleClick("bannerBlue")}
          className={`relative flex justify-center max-2xl:w-[33%] w-3/12 hover:border-4 hover:border-or-100 hover:rounded-xl group ${
            isBannerBlue && "border-4 border-or-100 rounded-xl"
          }`}
        >
          <img
            src={bannerBlue?.urlImage ? bannerBlue?.urlImage : bannerBlue}
            width={266}
            height={235}
            className={`w-full rounded-lg select-none object-cover group-hover:brightness-75 ${
              isBannerBlue && "brightness-75"
            }`}
            alt="Banner car blue"
          />
          <BtnEdit />
        </div>

        <div
          onClick={() => handleClick("bannerTransport")}
          className={`flex justify-center w-3/12 max-2xl:w-[33%] relative hover:border-4 hover:border-or-100 hover:rounded-xl hover:brightness-75 ${
            isBannerTransport &&
            "border-4 border-or-100 rounded-xl brightness-75"
          }`}
        >
          <img
            src={
              bannerTransport?.urlImage
                ? bannerTransport?.urlImage
                : bannerTransport
            }
            width={266}
            height={235}
            className="w-full rounded-lg select-none object-cover object-bottom"
            alt="Banner tour mes de feb"
          />

          <div
            className={`absolute ${
              responsiveView.value === "laptop" && "bottom-[30px] left-[22px]"
            } max-xl:top-[32px] max-xl:left-[2px] max-sm:left-[12%] max-sm:top-[40px]`}
          >
            <h2
              className={`m-b w-9/12 text-white mb-5 text-left ${
                responsiveView.value === "laptop" && "text-fs-28"
              }`}
            >
              ¿Primera vez con nosotros?
            </h2>

            <div className="bg-red-100 rounded-full flex w-fit">
              <span
                className={`m-s-b text-white pl-[10px] py-[10px] text-nowrap ${
                  responsiveView && "text-fs-12"
                }`}
              >
                Obten 10% menos
              </span>

              <span
                className={`m-s-b text-black p-[10px] bg-white rounded-lg ml-[10px] text-nowrap ${
                  responsiveView && "text-fs-12"
                }`}
              >
                Saber más
              </span>
            </div>
          </div>
          <BtnEdit />
        </div>
      </div>
      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
