import { useContext } from "react"
import { MarketingContext } from "../../sections/Marketing/context/MarketingContext"
import { NotificationContext } from "../../context/NotificationContext";

export function ContainerViewsHome({children}){
    const {responsiveView} = useContext(MarketingContext);
    const {isOpen} =useContext(NotificationContext);
    
    return(<div className={`${responsiveView.value === 'laptop' ? isOpen ? 'mx-[99px] max-2xl:mx-[50px]' : 'mx-[99px] max-2xl:mx-[70px]':''}`}>
        {children}
    </div>)
}