import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";
import { TotalStars } from "../../../../../components/stars/TotalStars";
// import { NotificationContext } from "../../../../context/NotificationContext";

export default function CardHotelHome(props) {
  const { hotel } = props;
  const { responsiveView } = useContext(MarketingContext);
  // const { isOpen } = useContext(NotificationContext);
  return (
    <div
      className={`shadow-3xl rounded-lg ${
        responsiveView.value === "laptop" ? "max-w-[330px]" : "w-[300px]"
      } ${responsiveView.value === "mobile" && " !w-full"}`}
    >
      <div className="w-full h-[216px] overflow-hidden rounded-t-lg">
        <img
          className={`w-full h-full rounded-t-lg object-cover select-none`}
          src={hotel.image}
          alt="card"
        />
      </div>

      <div className="w-full rounded-b-lg pb-3 pt-2 px-4 bg-white flex flex-col h-[145px]">
        <div className="m-s-b pt-1 text-fs-14 text-start truncate mb-[4px]">
          {hotel.name}
        </div>

        <div className="mb-[4px]">
          <TotalStars
            name="read-only"
            stars={5}
            width={"11px"}
            height={"11px"}
          />
        </div>

        <div className="flex gap-1 mb-[11px]">
          <img
            className="w-auto h-auto"
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/location/location-bl.svg`}
            alt="location icon"
            width={11}
            height={14}
          />
          <span className="text-bl-100 m-s-b text-fs-12 truncate">
            {hotel.address}
          </span>
        </div>

        <div className="flex justify-between border-t border-[#ebebeb] pt-[11px] items-center">
          <div className="flex flex-col">
            <span className="m-m text-gry-100 text-fs-12 text-start">
              Desde
            </span>
            <span className="m-b text-or-100 text-fs-12">
              MXN <span className="m-b text-fs-16">$5,000</span>
            </span>
          </div>

          <button
            className={`m-s-b text-fs-12 min-h-8 rounded-3xl px-4 py-2 text-nowrap bg-bl-100 text-white`}
          >
            Ver detalles
          </button>
        </div>
      </div>
    </div>
  );
}
