// import { ImageNotFoundType } from "./ImageNotFoundType";

export async function ImageModification(users) {
  users = await Promise.all(
    users.map(async (user) => {
      try {
        let hasImage = await IsImageURLValid(user.url_image);
        user = ValidImage(hasImage, user);
        return user;
      } catch (error) {
        console.log(error);
      }
    })
  );

  return users;
}

export function ValidImage(hasImage, user) {
  return (user = {
    ...user,
    url_image: hasImage
      ? user.url_image
      : `${import.meta.env.VITE_URL}icons/general/infotipo-staywuw.svg`,
  });
}

export function IsImageURLValid(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true); // Si la imagen se carga, es válida
    img.onerror = () => resolve(false); // Si hay un error al cargar, no es válida
    img.src = url;
  });
}
