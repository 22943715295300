import axiosWithInterceptor from "../../../configs/Instance/axiosConfig";

export const GetVacationsList = async (role, id) => {
  try {
    const apiUrl =
      role === "Recursos Humanos" || role === "Sistemas"
        ? `${import.meta.env.VITE_API_URL_STAYWUW}/vacations/users/search`
        : `${
            import.meta.env.VITE_API_URL_STAYWUW
          }/vacations/users/search?user_id=${id}`;

    const response = await axiosWithInterceptor.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching vacations list data:", error);
    throw error;
  }
};

export async function AutoCompleteUsers(input) {
  try {
    const response = await axiosWithInterceptor.get(
      `${
        import.meta.env.VITE_API_URL_STAYWUW
      }/vacations/users/search?searchTerm=${input}`
    );
    return response;
  } catch (error) {
    console.log("Auto complete user vacations", error);
    throw error;
  }
}

export const RequestVacations = async (idUsuario, password, dates,status,amount) => {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/vacations/request`,
      {
        dates: dates,
        idUsuario: idUsuario,
        password: password,
        status: status,
        amount: amount
      }
    );

    return response;
  } catch (error) {
    console.log("Solicitud de Vacaciones", error);
    throw error;
  }
};

export async function GetVacationUser(id) {
  try {
    const response = await axiosWithInterceptor.get(
      `${
        import.meta.env.VITE_API_URL_STAYWUW
      }/vacations/users/search?user_id=${id}`
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function ChangeStatusVacation(data) {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/vacations/confirm`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetApartmentList() {
  try {
    const response = await axiosWithInterceptor.get(
      `${
        import.meta.env.VITE_API_URL_STAYWUW
      }/DepartmentList`
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}


