import { ImageModification } from "../../../components/imageGet/ImageGet";
import { AutoCompleteUsers } from "../api/api";

export async function FilterInput(input) {
  try {
    const response = await AutoCompleteUsers(input);
    const data = await ImageModification(response.data.employees);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}


export function FilterApartment(apartment, users){
  return users.filter(user=>user?.department_name == apartment);
}
