import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function AlertsCrm({
  setConfirmation,
  isOpen,
  setIsOpen,
  twoOptions = false,
  title = "",
  text = "",
  typeAlert,
}) {
  const [bgColor, setBgColor] = useState("transparent");
  useEffect(() => {
    switch (typeAlert) {
      case "success":
        setBgColor("grn-100");
        break;
      case "error":
        setBgColor("red-100");
        break;
      case "warning":
        setBgColor("yw-100");
        break;
    }
  }, [typeAlert]);

  const openModal = () => {
    setIsOpen(true);
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={openModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[600px] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                <>
                  {/* LOGO AND HEADER BLUE */}
                  <div className={`w-full bg-${bgColor} p-7`}>
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/royal/principal-logo-blank.svg`}
                      alt="logo staywuw white"
                      className="w-[120px]"
                    />
                  </div>

                  <div className="px-7 mt-[55px] mb-[70px] text-center flex flex-col gap-12">
                    {title !== "" && (
                      //  TITLE ALERT
                      <h3 className="m-s-b text-fs-34">{title}</h3>
                    )}

                    {text !== "" && (
                      //TEXT ALERT
                      <span className="m-m text-fs-14">{text}</span>
                    )}

                    {/* BTN´S ALERT */}
                    <div className="flex justify-around">
                      {twoOptions && (
                        <button
                          onClick={handleModal}
                          className="px-6 py-4 rounded-lg bg-red-100 w-fit text-white m-s-b hover:bg-red-110"
                        >
                          Cerrar
                        </button>
                      )}

                      <button
                        onClick={() => setConfirmation(true)}
                        className="px-6 py-4 rounded-lg bg-bl-100 w-fit text-white m-s-b hover:bg-bl-110"
                      >
                        Aceptar
                      </button>
                    </div>
                  </div>

                  {/* LOGO AND FOOTER WHITE , COPY */}
                  {/* <div className="w-full shadow-3xl p-7 flex justify-between items-center">
                    <img
                      src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/royal/principal-logo.svg`}
                      alt="logo staywuw white"
                      className="w-[120px]"
                      width={120}
                      height={34}
                    />

                    <span className="text-gry-100 m-m text-fs-12">
                      Copyright © 2024 StayWuw. Todos los derechos reservados
                    </span>
                  </div> */}
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
