/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function BannerHomeKnowMore() {
  const beach = `${
    import.meta.env.VITE_URL_IMAGES
  }assets/banners/hotel/Feb2024/beach.jpg`;
  const city = `${
    import.meta.env.VITE_URL_IMAGES
  }assets/banners/hotel/Feb2024/city.jpg`;
  const selfie = `${
    import.meta.env.VITE_URL_IMAGES
  }assets/banners/hotel/Feb2024/selfie.png`;

  const [handleImage, setHandleImage] = useState(city);

  const { responsiveView } = useContext(MarketingContext);

  useEffect(() => {
    setTimeout(() => {
      if (handleImage === city) {
        setHandleImage(beach);
      } else {
        setHandleImage(city);
      }
    }, 3000);
  }, [handleImage]);

  return (
    <div
      className={`flex mb-10 mt-16 ${
        responsiveView.value === "laptop" && "mt-32 h-[408px]"
      } ${responsiveView.value !== "laptop" && "flex-col h-auto"} shadow-3xl`}
    >
      <div
        className={`flex flex-col justify-center w-[50%] py-[44px] ${
          responsiveView.value === "laptop" && "py-12 px-12 max-2xl:px-5"
        } bg-white rounded-tl-lg rounded-bl-lg ${
          responsiveView.value !== "laptop" &&
          "rounded-tr-lg rounded-bl-none w-full items-center"
        }`}
      >
        {/* FIX BOOSTRAP mb-5 - mb-[20px */}
        <h2 className="m-s-b text-fs-28 mb-[20px]">¿Aún no te decides?</h2>

        {/* FIX BOOSTRAP mb-5 - mb-[20px */}
        <div className="mb-[20px] w-[84%]">
          <p
            className={`m-0 text-fs-20 mb-[20px] m-m ${
              responsiveView.value !== "laptop" && "text-center"
            }`}
          >
            No te preocupes.
            <span className="m-m text-bl-100">
              {" "}
              Estamos aquí para simplificar{" "}
            </span>
            la planeación de tu viaje, haciéndola emocionante y sin
            complicaciones.
          </p>
        </div>

        <div className="bg-or-100 w-[164px] content-center py-3 text-center text-nowrap rounded-full text-white m-m text-fs-12 mb-[50px] flex justify-center items-center">
          Saber más
        </div>
      </div>

      <div
        className={` relative ${
          responsiveView.value == "laptop" &&
          "w-[50%] h-full rounded-b-lg rounded-t-none"
        } 
        ${responsiveView.value == "mobile" && "w-full"}`}
      >
        <div className="h-full">
          <div className="bg-transparent h-full">
            <img
              src={handleImage}
              width={364}
              height={259}
              className={`relative w-full  ${
                responsiveView.value === "laptop" && "h-full"
              } ${
                responsiveView.value === "mobile" ||
                responsiveView.value === "tablet"
                  ? "h-[259px]"
                  : ""
              } bottom-0 select-none`}
              alt="Img beach"
            />
          </div>
        </div>

        <img
          src={selfie}
          width={364}
          height={480}
          alt="selfie"
          className={`absolute right-0 bottom-[0] z-[1] w-full h-[20.7rem] ${
            responsiveView.value == "laptop" && "h-[426px]"
          } object-contain ${
            responsiveView.value == "mobile" && "w-[99%] h-[20rem]"
          } ${
            responsiveView.value == "tablet" && "w-[99%] h-[20rem]"
          } select-none`}
        />
      </div>
    </div>
  );
}
