import { useState } from "react";

import { InputEmail } from "./InputEmail";
import { ButtonLogOut } from "./ButtonLogOut";
import { ContainerConfig } from "../../config/ContainerConfig";
import AlertsCrm from "../../../../components/alerts/Alerts";
import { LogOutUserEmail } from "../../api/ConfigurationApi";

export function ContainerLogOut({ showNotification, isOpen }) {
  const [inputEmail, setInputEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [isValidGmail, setIsValidGmail] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleLogout = async (isConfirm) => {
    if (isConfirm) {
      setOpenAlert(false);
      setLoader(true);
      const logOutInfo = { session: 0, email: inputEmail };
      try {
        await LogOutUserEmail(logOutInfo);

        setLoader(false);
        showNotification(
          "success",
          `¡Log out!`,
          `El usuario cons el correo ${inputEmail} fue deslogueado correctamente`,
          5000
        );
        setInputEmail("");
      } catch (error) {
        console.log(error);

        setLoader(false);
        showNotification(
          "error",
          `Log out fallido`,
          `No se pudo hacer log out al usuario con el correo ${inputEmail}`,
          5000
        );
        throw error;
      }
    }
  };

  return (
    <>
      <ContainerConfig isOpen={isOpen}>
        {isOpen && (
          <div className="flex flex-col gap-y-2.5">
            <InputEmail
              inputEmail={inputEmail}
              setInputEmail={setInputEmail}
              setIsValidGmail={setIsValidGmail}
            />

            <ButtonLogOut
              handleLogout={() => setOpenAlert(true)}
              inputEmail={inputEmail}
              loader={loader}
              isValidGmail={isValidGmail}
            />
          </div>
        )}
      </ContainerConfig>

      <AlertsCrm
        setConfirmation={handleLogout}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title="¡Actualización de proveedores de pago!"
        text="¿Deseas actualizar los proveedores de pago?"
        typeAlert={"warning"}
      />
    </>
  );
}
