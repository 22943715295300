/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { ConfirmCancellation } from "./ModalDetails/ConfirmCancellation";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";
import { useUser } from "../../../configs/login/UserContext";
import AlertPermissionsDeclined from "../../../configs/Permissions/AlertPermissionsDeclined";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../components/alerts/notifications/useNotification";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative inline-block">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>

      {showTooltip && (
        <div className="bg-black text-white text-xs rounded py-1 px-2 right-[-20px] absolute z-10">
          {text}
        </div>
      )}
    </div>
  );
};

export function RemoveReservation({ reservationInfo }) {
  const [openModal, setOpenModal] = useState(false);
  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const { notification, showNotification, hideNotification } =
    useNotification();

  const handlePermissions = () => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "cancel_booking"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setOpenModal(!openModal);
    }
  };

  return (
    <>
      <Tooltip text={<React.Fragment>{"Cancelacion"}</React.Fragment>}>
        <button onClick={handlePermissions} className="focus:outline-none">
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/delete/clipboard-list.svg`}
            width={20}
            height={20}
            alt="see details"
          />
        </button>
      </Tooltip>

      <ConfirmCancellation
        isOpen={openModal}
        setIsOpen={setOpenModal}
        reservation={reservationInfo}
        showNotification={showNotification}
      />

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
