import { useContext, useEffect, useState } from "react";

import { homeRecommendedTour } from "../../../configs/Json";
import { MarketingContext } from "../../../context/MarketingContext";

export function CartTourOptions() {
  const { responsiveView, isOpenEdit } = useContext(MarketingContext);
  const [maxCard, setMaxCard] = useState(0);

  useEffect(() => {
    switch (responsiveView.value) {
      case "laptop":
        setMaxCard(isOpenEdit ? 8 : 6);
        break;
      case "tablet":
        setMaxCard(4);
        break;
      case "mobile":
        setMaxCard(2);
        break;
    }
  }, [isOpenEdit, responsiveView]);

  return (
    <>
      <div className="mb-10 mt-[128px] max-sm:mt-10">
        <h2 className="m-b text-center text-fs-24 mb-2">
          ¿Cómo disfrutar al máximo tu viaje?
        </h2>
        <div className="flex text-center justify-center max-sm:text-fs-12 w-11/12">
          <div className="m-m text-gry-100">
            Desde explorar <b className="text-black">+100 sitios históricos,</b>{" "}
            tenemos todo lo que necesitas para crear recuerdos inolvidables.
            ¡Planea tu itinerario con nosotros y haz que cada momento cuente en
            tu viaje!
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-x-5 mb-[45px]">
        {homeRecommendedTour.slice(0, maxCard).map((tour, index) => (
          <div
            className={`rounded-md w-[179px] !h-[265px] select-none ${`max-2xl:${
              maxCard - 1 == index && "hidden"
            }`}`}
            key={index}
          >
            <div className="h-full bg-white shadow-3xl border border-gry-30 rounded-md px-4 py-4 flex flex-col justify-between items-center min-w-[179px]">
              <div className="w-[132px] h-[132px]">
                <img
                  className="w-full h-full rounded-full object-cover select-none"
                  src={tour.image}
                  alt="staywow card"
                />
              </div>

              <div className="max-w-xs overflow-hidden text-center">
                <span className="text-fs-14 w-full">
                  <b className="pt-3 !box-orient-vertical !line-clamp-2 !overflow-hidden !text-ellipsis">
                    {tour.es.name}
                  </b>
                </span>
              </div>

              <div className="flex justify-center gap-1 mt-[8px]">
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/location/location-b.svg`}
                  width={9.5}
                  height={12.1}
                  alt="icon-location-b"
                />
                <span className="m-m text-gry-100 text-fs-12 truncate ...">
                  {tour.es.destination.length > 21
                    ? `${tour.es.destination.substring(0, 21)} ..`
                    : tour.es.destination}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
