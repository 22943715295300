export default function ToolTipDays({ user }) {
  return (
    <div className="absolute top-[3rem] md:right-0 z-[1000]">
      <div className="relative bg-bl-100 rounded-md p-4 w-max">
        <img
          className="absolute top-[-9px] max-md:left-0 max-md:right-0 md:right-[3.5rem] max-md:mx-auto z-[10]"
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/ellipse-bl-100.svg`}
          alt="ellipse blue"
          width={22}
          height={22}
        />
        <ul className="list-none text-white text-fs-12">
          {" "}
          <li>Disponibles: {user.vacations.available_days}</li>
          <li>
            Utilizados:{" "}
            {user.vacations.dates.approved.length +
              user.vacations.dates.pay.length}
          </li>
          <li>
            Total:{" "}
            {user.vacations.available_days +
              user.vacations.dates.approved.length +
              user.vacations.dates.pay.length}
          </li>
        </ul>
      </div>
    </div>
    //     <div className="absolute top-[-2px] left-0 m-b rounded-sm px-1 text-fs-12 bg-yw-50">
    //     <ul className="list-none">
    //       <li>Disponibles: {user.vacations.available_days}</li>
    //       <li>Utilizados: {user.vacations.dates.approved.length + user.vacations.dates.pay.length}</li>
    //       <li>Total: {user.vacations.available_days + user.vacations.dates.approved.length + user.vacations.dates.pay.length}</li>
    //     </ul>
    //   </div>
  );
}
