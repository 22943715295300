import { useContext } from "react";

import { popularState } from "../../../configs/Json";
import { MarketingContext } from "../../../context/MarketingContext";

export function PopularState() {
  const { responsiveView } = useContext(MarketingContext);

  const parsePrice = (price) => (
    <>
      {Math.floor(price)
        .toLocaleString("es-MX", { currency: "MXN" })
        .replace(".00", "")}
      .<sup>{(price % 1).toFixed(2).slice(2)}</sup>
    </>
  );
  return (
    <div
      className={`my-16 {responsiveView.value !== 'mobile' && 'md:my-32'} relative`}
    >
      <h1 className="m-b text-fs-24 mb-9">Estados más populares</h1>

      <div className="h-[500px] relative">
        <div
          className={`flex flex-col ${
            responsiveView.value !== "mobile" &&
            "md:flex-row md:gap-y-0 md:gap-x-3"
          } gap-x-0 gap-y-3 h-full `}
        >
          <div
            className={`flex gap-x-3 w-full ${
              responsiveView.value !== "mobile" && "md:h-full"
            } h-2/4`}
          >
            <div
              className={`relative rounded-lg ${
                responsiveView.value !== "mobile" && "md:w-[23%]"
              } w-full  overflow-hidden`}
            >
              <img
                src={popularState[0].imageUrl}
                alt={`${popularState[0].name}`}
                className="brightness-[.7] absolute inset-0 w-full h-full object-cover select-none"
              />
              <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                <span className="text-fs-14 m-b">{popularState[0].name}</span>
                <span className="m-m text-fs-10">
                  Hoteles desde{" "}
                  <span className="m-s-b text-fs-12">
                    MXN ${parsePrice(popularState[0].price)}
                  </span>
                </span>
              </div>
            </div>

            <div
              className={`relative rounded-lg ${
                responsiveView.value !== "mobile" && "md:w-[77%]"
              } w-full  overflow-hidden`}
            >
              <img
                src={popularState[1].imageUrl}
                alt={`${popularState[1].name}`}
                className="h-full rounded-lg object-cover brightness-[.7] select-none w-full"
                width="100%"
                height="100%"
              />

              <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                <span
                  className={`text-fs-14 ${
                    responsiveView.value !== "mobile" && "md:text-fs-20"
                  } m-b`}
                >
                  {popularState[1].name}
                </span>
                <span
                  className={`m-m text-fs-10 ${
                    responsiveView.value !== "mobile" && "md:text-fs-12"
                  }`}
                >
                  Hoteles desde{" "}
                  <span
                    className={`m-s-b text-fs-12 ${
                      responsiveView.value !== "mobile" && "md:text-fs-16"
                    }`}
                  >
                    {" "}
                    MXN ${parsePrice(popularState[1].price)}{" "}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div
            className={`flex ${
              responsiveView.value !== "mobile" &&
              "md:flex-col md:gap-x-0 md:w-[268px] md:h-full"
            } gap-y-3 gap-x-3 h-[45%]`}
          >
            {popularState.slice(2, 4).map((destination, index) => (
              <div
                key={index}
                className={`relative rounded-lg ${
                  responsiveView.value !== "mobile" && "md:h-[246px]"
                } w-full  overflow-hidden`}
              >
                <img
                  src={destination.imageUrl}
                  alt={`${destination.name}`}
                  className="brightness-[.7] absolute inset-0 w-full h-full object-cover select-none"
                />

                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="m-b text-fs-14">{destination.name}</span>
                  <span className="text-fs-10 m-m">
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12">
                      MXN ${parsePrice(destination.price)}
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Swiper
        slidesPerView={1}
        spaceBetween={0}
        className="h-[500px] absolute w-full"
        navigation
        modules={[Navigation]}
        id="swiper-popular-estates"
      >
        <SwiperSlide className="bg-transparent">
          <div className="flex flex-col md:flex-row gap-x-0 gap-y-3 md:gap-y-0 h-full md:gap-x-3">
            <div className="flex gap-x-3 w-full md:h-full h-2/4">

              <div className="relative rounded-lg md:w-[23%] w-full cursor-pointer overflow-hidden">
                <img
                  src={popularState[0].imageUrl}
                  alt={`${popularState[0].name}`}
                  className="brightness-[.7] absolute inset-0 w-full h-full object-cover transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5] select-none"
                />
                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="text-fs-14 m-b">{popularState[0].name}</span>
                  <span className="m-m text-fs-10">
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12">
                      MXN ${parsePrice(popularState[0].price)}
                    </span>
                  </span>
                </div>
              </div>

              <div className="relative rounded-lg md:w-[77%] w-full cursor-pointer overflow-hidden">
                <img
                  src={popularState[1].imageUrl}
                  alt={`${popularState[1].name}`}
                  className="h-full rounded-lg object-cover brightness-[.7] select-none w-full transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5]"
                  width="100%"
                  height="100%"
                />

                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="text-fs-14 md:text-fs-20 m-b">
                    {popularState[1].name}
                  </span>
                  <span className="m-m text-fs-10 md:text-fs-12">
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12 md:text-fs-16">
                      {" "}
                      MXN ${parsePrice(popularState[1].price)}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="flex md:flex-col gap-y-3 gap-x-3 md:gap-x-0 md:w-[268px] md:h-full h-[45%]">
              {popularState.slice(2, 4).map((destination, index) => (
                <div
                  key={index}
                  className="relative rounded-lg md:h-[246px] w-full cursor-pointer overflow-hidden"
                >
                  <img
                    src={destination.imageUrl}
                    alt={`${destination.name}`}
                    className="brightness-[.7] absolute inset-0 w-full h-full object-cover transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5] select-none"
                  />

                  <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                    <span className="m-b text-fs-14">{destination.name}</span>
                    <span className="text-fs-10 m-m">
                      Hoteles desde{" "}
                      <span className="m-s-b text-fs-12">
                        MXN ${parsePrice(destination.price)}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </SwiperSlide>


        <SwiperSlide className="bg-transparent">
          <div className="flex flex-col md:flex-row gap-x-0 gap-y-3 md:gap-y-0 h-full md:gap-x-3">
            <div className="flex gap-x-3 w-full md:h-full h-2/4">
              <div className="relative rounded-lg md:w-[23%] w-full cursor-pointer overflow-hidden">
                <img
                  src={popularState[4].imageUrl}
                  alt={`${popularState[4].name}`}
                  className="brightness-[.7] absolute inset-0 w-full h-full object-cover transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5] select-none"
                  width="100%"
                  height="100%"
                />

                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="text-fs-14 m-b">{popularState[4].name}</span>
                  <span className="m-m text-fs-10">
                    {" "}
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12">
                      {" "}
                      MXN ${parsePrice(popularState[4].price)}{" "}
                    </span>
                  </span>
                </div>
              </div>

              <div className="relative rounded-lg md:w-[77%] w-full cursor-pointer overflow-hidden">
                <img
                  src={popularState[5].imageUrl}
                  alt={`${popularState[5].name}`}
                  className="h-full rounded-lg object-cover brightness-[.7]  transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5] select-none"
                  width="100%"
                  height="100%"
                />

                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="text-fs-14 md:text-fs-20 m-b">
                    {popularState[5].name}
                  </span>
                  <span className="m-m text-fs-10 md:text-fs-12">
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12 md:text-fs-16">
                      {" "}
                      MXN ${parsePrice(popularState[5].price)}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="flex md:flex-col gap-y-3 gap-x-3 md:gap-x-0 md:w-[268px] md:h-full h-[45%]">
              <div className="relative rounded-lg md:h-[246px] w-full cursor-pointer overflow-hidden">
                <img
                  src={popularState[6].imageUrl}
                  alt={`${popularState[6].name}`}
                  className="h-full rounded-lg object-cover brightness-[.7] select-none transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5]"
                  width="100%"
                  height="100%"
                />

                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="m-b text-fs-14">{popularState[6].name}</span>
                  <span className="text-fs-10 m-m">
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12">
                      {" "}
                      MXN ${parsePrice(popularState[6].price)}{" "}
                    </span>
                  </span>
                </div>
              </div>

              <div className="relative rounded-lg md:h-[246px] w-full cursor-pointer overflow-hidden">
                <img
                  src={popularState[0].imageUrl}
                  alt={`${popularState[0].name}`}
                  className="h-full rounded-lg object-cover brightness-[.7] select-none  transition-transform duration-500 transform scale-100 hover:scale-105 hover:brightness-[.5]"
                  width="100%"
                  height="100%"
                />

                <div className="absolute flex flex-col bottom-0 text-white p-4 text-left">
                  <span className="m-b text-fs-14">{popularState[0].name}</span>
                  <span className="text-fs-10 m-m">
                    Hoteles desde{" "}
                    <span className="m-s-b text-fs-12">
                      {" "}
                      MXN ${parsePrice(popularState[0].price)}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper> */}
    </div>
  );
}
