export default function Unauthorized() {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen bg-white">
        <div className="animate-pulse mb-4">
          <img
            src={`${import.meta.env.VITE_URL_IMAGES}assets/img/crm/404.png`}
            alt="Company Logo"
          />
        </div>
        <div className="text-lg font-semibold text-gry-100 m-b text-center">
          Acceso restringido. <br /> Ponte en contacto con el administrador si
          necesitas acceso.
        </div>
      </div>
    </div>
  );
}
