export const formatCurrency = (value) => {
  if (!value) return "";
  if (typeof value === "number") value = value.toFixed(2).toString();
  const cleanedValue = value.replace(/[^0-9]/g, "");
  const numberValue = (cleanedValue / 100).toFixed(2);
  return parseFloat(numberValue).toLocaleString("es-MX", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const parserSalary = (total) => {
  if (typeof total === "string") {
    total = total.replace(/,/g, "");
  }
  return parseFloat(total);
};
