/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback} from "react";
import { useDropzone } from "react-dropzone";

import { useUser } from "../../../../configs/login/UserContext";
import { base64Data } from "../../../../components/dropzone/ChangeFormatImage";

export function DropzoneUser() {
  const {setImageUser} = useUser();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.slice(0, 1).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          base64Data(URL.createObjectURL(file)).then((imageData) => {
            setImageUser(imageData);
          });
          return;
        };
        image.src = event.target.result;
      };

      if (file.type.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else {
        alert("Solo se puede subir archivos de tipo imagen");
        return;
      }
    });
  });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div
      className="w-full h-full flex items-center backdrop-brightness-50 rounded-lg"
      {...getRootProps()}
    >
      <input type="text" {...getInputProps()} placeholder="Hola" />
      <p className="mx-auto my-auto m-s-b text-white text-fs-12">
        Cambiar imagen
      </p>
    </div>
  );
}
