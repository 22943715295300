export function DatesSkeleton({totalDates}) {
  return (
    <div className="w-[85%] pl-1">
      <div className="flex gap-x-1 w-full mt-1">
        {[...Array(totalDates)].map((_, index) => (
          <div
            key={index}
            className="h-[50px] w-full rounded-[7px] border-0 animate-[skeletonLoading_1s_linear_infinite_alternate]"
          />
        ))}
      </div>
    </div>
  );
}
