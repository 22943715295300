
import { Publish } from "./Publish";
import { ButtonGooBack } from "./ButtonGooBack";

import { ResponsiveViews } from "../ResponsiveViews/ResponsiveViews";

export function NavButtons({ view }) {

 

  return (
    <div className="flex justify-between items-center sticky top-0 z-[2] bg-white pb-4 rounded-lg">
      <div className="flex items-center gap-x-6">
        <ButtonGooBack />
        <Publish />
      </div>

      <ResponsiveViews view={view} />
    </div>
  );
}
