import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { GetImagesByView } from "../../api/MarketingApi";
import { MarketingContext } from "../../context/MarketingContext";

export function PrincipalView() {
  const { responsiveView, languageSelected } = useContext(MarketingContext);
  const navigate = useNavigate();

  const [allImages, setAllImages] = useState(null);

  const handleRoute = (param) => {
    navigate(`/mkt/${responsiveView.value}/${param}/${languageSelected}`);
  };

  useEffect(() => {
    GetImagesByView("all", languageSelected)
      .then((response) => {
        // console.log(response);
        setAllImages(response);
      })
      .catch((error) => {
        console.log(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const viewMark = [
    {
      name: "Home",
      hover: "HOME",
      banner: allImages?.home.bannerHome.laptop[0],
      icon: `${
        import.meta.env.VITE_URL_IMAGES
      }assets/icons/general/home-nav.svg`,
      date: "10/07/2024, 5:59 PM",
      altIcon: "icon home",
      handleRoute: "home",
    },
    {
      name: "Hotel",
      hover: "HOTEL",
      banner: allImages?.hotel.bannerHotel.laptop[0],
      icon: `${
        import.meta.env.VITE_URL_IMAGES
      }assets/icons/hotel/domain-nav.svg`,
      date: "10/07/2024, 5:59 PM",
      altIcon: "icon hotel",
      handleRoute: "hotel",
    },
    {
      name: "Tour",
      hover: "TOUR",
      banner: allImages?.tour.bannerTour.laptop[0],
      icon: `${
        import.meta.env.VITE_URL_IMAGES
      }assets/icons/transport/transport-nav.svg`,
      date: "10/07/2024, 5:59 PM",
      altIcon: "icon tour car",
      handleRoute: "tour",
    },
    {
      name: "Transporte",
      hover: "TRANSPORTE",
      banner: `${allImages?.transporte.bannerTransportHome.laptop[0]}`,
      icon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/tour/map-nav.svg`,
      date: "10/07/2024, 5:59 PM",
      altIcon: "icon transport map",
      handleRoute: "transport",
    },
  ];

  return (
    allImages && (
      <div className="w-full flex items-center justify-center bg-gry-20 rounded-2xl h-[91vh]">
        <div className="w-full h-full grid grid-cols-2 gap-4">
          {/* DISPLAYS HOME MARKETING  */}
          {viewMark.map((viewM, index) => (
            <div
              key={index}
              onClick={() => handleRoute(viewM.handleRoute)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              className="relative bg-gry-50 w-full h-full overflow-hidden cursor-pointer hover:brightness-75 flex flex-col shadow-3xl hover:border-2 hover:border-or-100"
            >
              <div className="w-full flex justify-between h-10 bg-gry-50 px-5 ">
                <div className="flex items-center gap-3 h-full">
                  <img src={viewM.icon} alt={viewM.alt} />
                  <span className="m-0 m-b text-fs-12 text-gry-100 text-center">
                    {viewM.name}
                  </span>
                </div>

                <p className=" m-m flex text-gry-70 items-center gap-3 text-fs-12">
                  {" "}
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/crm/navbarCRM/history-100.svg`}
                    width={14}
                    height={14}
                    alt="time update"
                  />{" "}
                  10/07/2024, 5:59 PM
                </p>
              </div>
              <div className="relative h-[250px] w-full mb-4">
                <img
                  src="https://apicrm.staywuw.com/assets/img/crm/navbar.jpg"
                  alt="nav"
                />
                <img
                  src={viewM.banner}
                  className="w-full h-full object-cover"
                />

                <img
                  src="https://apicrm.staywuw.com/assets/img/crm/search.png"
                  alt="img search"
                  className="absolute left-0 right-0 bottom-[-150px] mx-auto w-[80%]"
                />
              </div>
              {hoveredIndex === index && (
                <div className="w-full h-full flex justify-center items-center absolute text-white text-fs-40 m-b">
                  {/* <img src={viewM.icon} alt={viewM.alt} /> */}
                  {viewM.hover}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  );
}
