
import { useState } from "react";

import { SearchReviews } from "./SearchReviews";
import { UseReservations } from "../context/ReservationsContext";
import { FiltersReservation } from "./FiltersReservation/FiltersReservation";

export function ReservationListing() {
  const { handleReservations } = UseReservations();

  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div
      className={`flex flex-col gap-y-4 pb-12`}
    >
      <div className="flex justify-between max-lg:gap-y-5 items-center max-lg:flex-col">
        <div className="flex items-center gap-x-4">
        {/* SEARCH COMPONENT */}
        <SearchReviews />

        <button
            className="focus:outline-none w-[20px] h-[20px]"
            onClick={handleReservations}
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/crm/refresh.svg`}
              alt="refresh users"
            />
            </button>
        </div>

        <div className="flex items-center gap-x-4">
          {/* FILTER TABLE */}
          <button
            className="focus:outline-none px-4 flex items-center gap-x-2"
            onClick={() => setOpenFilter(!openFilter)}
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/general/filter-black.svg`}
              alt="filter table"
              width={13}
              height={13}
            />
            <p className="m-0 text-fs-12 m-m text-gry-100">Filtar</p>
          </button>
        </div>
      </div>

      <FiltersReservation
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
    </div>
  );
}
