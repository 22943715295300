import {
  homeConfig,
  hotelConfig,
  tourConfig,
  transportConfig,
} from "../../configs/ConfigViews";

export function SetConfigDropzone(routActual, bannerConfig) {
  let jsonConfig = "";

  switch (routActual) {
    case "home":
      jsonConfig = homeConfig;
      break;
    case "hotel":
      jsonConfig = hotelConfig;
      break;
    case "tour":
      jsonConfig = tourConfig;
      break;
    case "transport":
      jsonConfig = transportConfig;
      break;
  }
  let configDropzone = jsonConfig[bannerConfig];

  return configDropzone;
}
