import { Markup } from "../components/Markup/Markup";
import { LogOutByEmail } from "../components/LogOutByEmail/LogOutByEmail";
import { ProviderStayWuw } from "../components/ProviderStayWuw/ProviderStayWuw";
import { Notifications } from "../../../components/Notifications/Notification";
import { ProviderPayment } from "../components/ProviderPayment/ProviderPayment";

export function LayoutConfiguration() {
  return (
    <>
      {/* NOTIFICATION */}
      <div className="flex justify-between mb-[44px] w-full">
        <h1 className="text-fs-24 m-b text-black">Configuración</h1>

        <Notifications />
      </div>

      <div className="grid grid-cols-2 gap-y-6 gap-x-6">
        <ProviderStayWuw />
        <ProviderPayment />
        <LogOutByEmail />
        <Markup />
      </div>
    </>
  );
}
