import moment from "moment";
import { useEffect } from "react";
import { UseVacations } from "../context/VacationsContext";
// import { getHolidays } from "../config/HolidayJson";

export default function DaysOfMonth() {
  const {
    currentMonth,
    setCurrentMonth,
    daysPerMonth,
    setDaysPerMonth,
    currentYear,
    setCurrentYear,
    
  } = UseVacations();
  // HOLIDAYS
  // const holidays = getHolidays(currentYear);

  useEffect(() => {
    const obtainDaysMonth = () => {
      let year = currentYear;
      let month = currentMonth;

      if (currentMonth == 13) {
        setCurrentYear(currentYear + 1);
        setCurrentMonth(1);
      }
      if (currentMonth == 0) {
        setCurrentYear(currentYear - 1);
        setCurrentMonth(12);
      }

      const daysInMonth = new Date(year, month, 0).getDate();
      const days = [];

      for (let day = 1; day <= daysInMonth; day++) {
        const fecha = new Date(year, month - 1, day);
        const nameDay = fecha.toLocaleString("es-ES", { weekday: "long" });
        const numberMonth = fecha.getMonth() + 1;
        const date = moment({ year: year, month: month - 1, day: day }).format(
          "YYYY-MM-DD"
        );
        days.push({
          year,
          day,
          nameDay,
          numberMonth,
          date,
        });
      }

      setDaysPerMonth(days);
    };

    obtainDaysMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth]);

  const currentDay = moment().format('YYYY-MM-DD');
  return (
    <>
      <ul className="pl-1 flex gap-1 pr-[7px]">
        {daysPerMonth.map((month, index) => (
          <li
            key={index}
            className={`px-1 py-2 rounded-lg flex flex-col justify-center items-center w-full ${
              month.nameDay === "domingo" 
              // || 
              // holidays.some(holiday => 
              //   holiday.day === month.day && 
              //   holiday.month === month.numberMonth && 
              //   holiday.year === month.year
              // ) 
                // ? "bg-[#FF000020]"  
                ? "bg-[#e0dcdc]"  
                : month.date === currentDay ? "bg-bl-100" : "bg-[#F4F9FD]"
            }`}
          >
            <div className={`text-fs-13 m-b max-2xl:text-fs-10 max-lg:text-fs-8 ${ month.date === currentDay ? "text-white" : "text-[#7D8594]"}`}>{month.day}</div>
            <div className={`text-fs-11 max-2xl:text-fs-8 m-m truncate ${ month.date === currentDay ? "text-white" : "text-[#7D8594]"}`}>
            <span className="max-xl:hidden inline">{month.nameDay.slice(0, 3)}</span>
            <span className="xl:hidden uppercase">{month.nameDay.slice(0, 1)}</span>

            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
