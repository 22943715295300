import "moment/locale/es";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { RequestVacations } from "../api/api";
import { UseVacations } from "../context/VacationsContext";
import { useUser } from "../../../configs/login/UserContext";
import { useNotification } from "../../../components/alerts/notifications/useNotification";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import PaidVacation from "./PaidVacation";
import { parserSalary } from "../../RH/configs/SalaryConfig";
export default function ConfirmVacations({
  isOpen,
  close,
  name,
  vacations,
  id,
  img,
  notificationResponse,
}) {
  const { refreshUserList } = UseVacations();
  const { user } = useUser();
  const { notification, showNotification, hideNotification } =
    useNotification();

  moment.updateLocale("es", {
    months:
      "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split(
        "_"
      ),
    weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
  });

  const isRole =
    (user.department_name === "Recursos Humanos" &&
      user.role_name === "Admin") ||
    user.department_name === "Recursos Humanos" ||
    user.department_name === "Sistemas";

  const formatDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD").format(
      "dddd D [de] MMMM [del] YYYY"
    );
  };

  const selectedVacations =
    vacations?.dates?.selected?.length > 0 ? vacations.dates.selected : [];

  const [password, setPassword] = useState("");
  const handleAccept = async (status) => {
    if (password.length === 0) {
      setIsPassword(false);
      return;
    }
    if (!password.trim()) {
      return showNotification(
        "warning",
        "Password",
        "La contraseña es obligatoria"
      );
    }
    if (isRole && vacationType == null) {
      return showNotification(
        "warning",
        "Tipo de vacaciones",
        "Seleccione si las vacaciones son pagadas o normales"
      );
    }

    if (isRole && status == 3 && amount == 0.0) {
      return showNotification(
        "warning",
        "Agregar el monto",
        "Agrega el monto de las vacaciones trabajadas"
      );
    }
    try {
      if (selectedVacations.length > 0) {
        await RequestVacations(id, password, selectedVacations, status, amount);
        notificationResponse(name);
        refreshUserList();
      } 
      // else {
      //   console.log("No hay vacaciones con fechas en estado 'pending'.");
      // }
    } catch (error) {
      showNotification("error", "Error", `${error.response.data.error}`);
      console.error(error.response);
    }
    //
  };

  const [isTextType, setIsTextType] = useState(false);

  const [isPassword, setIsPassword] = useState(true);

  useEffect(() => {
    if (password.length > 3) {
      setIsPassword(true);
    }
  }, [password]);

  // TYPE VACATIONS AND PAID VACATIONS
  const [vacationType, setVacationType] = useState(null);
  const [amount, setAmount] = useState("");
  const handleVacationChange = (value) => {
    setVacationType(value);
  };
  const handleAmountChange = (value) => {
    setAmount(parserSalary(value));
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[486px] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                  <div className="px-6 pb-6">
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-100.svg`}
                      width={16}
                      height={16}
                      alt="icon-close"
                      className="mr-2 cursor-pointer absolute right-4 top-5"
                      onClick={close}
                    />

                    <div className="flex flex-col gap-3 mt-3">
                      <div className="flex gap-3 pt-5">
                        <div className="w-[120px] h-[120px] bg-gry-50 rounded-lg">
                          {/* IMG PROFILE */}
                          <img
                            src={img}
                            className="rounded-lg object-cover w-full h-full"
                            alt="profile"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          {isRole ? (
                            <div className="w-full m-b text-fs-20 text-gry-100">
                              Modal Rh
                            </div>
                          ) : (
                            <div className="w-full m-b text-fs-20 text-gry-100">
                              Vacaciones Seleccionadas
                            </div>
                          )}
                          <h3 className="text-fs-20 text-black m-b">{name}</h3>
                          <div className="bg-grn-10 text-grn-100 px-2 py-1 w-fit text-fs-12 m-s-b rounded">
                            Activo*
                          </div>
                        </div>
                      </div>
                      <hr className="border-[1.5px] my-2" />

                      {/* INPUT START DATE */}
                      <div className="relative w-full text-fs-12 m-m text-gry-100">
                        <label className="m-b text-fs-14 text-gry-100">
                          Fechas Seleccionadas
                          <span>
                            {" "}
                            ({" "}
                            <span className="text-[#EB741E]">
                              {vacations.dates.selected.length}
                            </span>{" "}
                            / {vacations.available_days} )
                          </span>
                        </label>
                        <div className="flex px-[16px] py-[12.8px] border border-[#ebebeb] items-center gap-2 relative mt-2">
                          <div className="flex flex-col">
                            {vacations?.dates?.selected?.length > 0 ? (
                              vacations.dates.selected.map((date, i) => (
                                <div
                                  className="flex gap-2 items-center"
                                  key={i}
                                >
                                  <div className="rounded-full bg-[#C775391E] border border-[#EB741E] w-[10px] h-[10px]" />
                                  {formatDate(date)}
                                </div>
                              ))
                            ) : (
                              <div className="m-s-b text-fs-12 text-red-100">
                                No hay fechas Selecionadas
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <hr className="border-[1.5px] my-2" />
                      {isRole ? (
                        <>
                          <PaidVacation
                            onChange={handleVacationChange}
                            onAmountChange={handleAmountChange}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {/* PASSWORD */}
                      <div className="flex flex-col">
                        <span className="m-b text-fs-14 text-gry-100 mb-0">
                          Contraseña
                        </span>
                        <span className="text-fs-10 m-m text-gry-100">
                          *Para autorizar vacaciones se debera confirmar la
                          contraseña*
                        </span>

                        <div className="flex relative">
                          <form autoComplete="off" className="w-full">
                            <input
                              type={isTextType ? "text" : "password"}
                              placeholder="Contraseña"
                              className={`appearance-none outline-none border-2  rounded h-[45px] pl-5 w-full pr-8 mt-5 ${
                                isPassword ? "border-gry-30" : "border-red-100"
                              }`}
                              value={password}
                              // onFocus={() => setPassword("")}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete="new-password"
                              name={`password-${Math.random()
                                .toString(36)
                                .substr(2, 9)}`}
                            />
                          </form>

                          <button
                            onClick={() => setIsTextType(!isTextType)}
                            className="absolute right-2 bottom-3 rounded-full"
                          >
                            <img
                              src={`${
                                import.meta.env.VITE_URL_IMAGES
                              }assets/icons/general/${
                                isTextType ? "eye.svg" : "eye-close.svg"
                              }`}
                              alt="eye password"
                            />
                          </button>
                        </div>
                        {!isPassword && (
                          <p className="text-red-100 text-fs-12 m-m">
                            La contraseña es requerida
                          </p>
                        )}
                      </div>
                    </div>

                    {/* BTN´S ALERT */}
                    <div className="flex gap-3 mt-7">
                      {isRole ? (
                        <>
                          <button
                            className="flex gap-1 px-3 py-3 rounded-lg bg-red-100 w-fit text-fs-12 text-white m-s-b hover:bg-red-110"
                            onClick={close}
                            // onClick={() => handleStatusVacation(2)}
                          >
                            Cancelar
                          </button>
                          <button
                            onClick={() => handleAccept(vacationType)}
                            className="flex gap-1 px-3 py-3 rounded-lg bg-bl-100 w-fit text-fs-12 text-white m-s-b hover:bg-bl-110"
                          >
                            Confirmar vacaciones
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="flex gap-1 px-3 py-3 rounded-lg bg-red-100 w-fit text-fs-12 text-white m-s-b hover:bg-red-110"
                            onClick={close}
                          >
                            Cambiar fechas
                          </button>
                          <button
                            onClick={() => handleAccept(0)}
                            className="flex gap-1 px-3 py-3 rounded-lg bg-bl-100 w-fit text-fs-12 text-white m-s-b hover:bg-bl-110"
                          >
                            Aceptar
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
