import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
// import './index.css'
import "./globals.css";
import MyErrorBoundary from "./ErrorBoundary.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MyErrorBoundary>
      <App />
    </MyErrorBoundary>
  </React.StrictMode>
);
