// src/components/ErrorBoundary.jsx
import { Fragment, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Dialog, Transition } from "@headlessui/react";

function ErrorFallback({ error, resetErrorBoundary }) {
    const [isOpen, setIsOpen] = useState(true);
  
    const closeModal = () => {
      setIsOpen(false);
      resetErrorBoundary();
    };
  
    const errorStack = error.stack
      ? error.stack.split("\n")[1].trim()
      : "No stack trace available";

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div
            className="fixed inset-0 bg-gray-900 bg-opacity-10"
            aria-hidden="true"
          />
  
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-transparent p-8 text-left align-middle shadow-xl transition-all">
                  <div className="bg-white bg-opacity-90 p-8 rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900"
                    >
                      ¡Ups! algo salió mal
                    </Dialog.Title>
  
                    <div className="mt-4">
                      <p className="text-red-100 font-bold mb-2">
                        Mensaje de error:
                      </p>
                      <pre className="text-sm bg-red-50 p-3 rounded text-red-100 whitespace-pre-wrap">
                        {error.message}
                      </pre>
                      <p className="text-gray-500 mt-4">Ubicación del error:</p>
                      <div className="text-sm bg-gray-100 p-3 rounded whitespace-pre-wrap">
                        {errorStack}
                      </div>
                    </div>
  
                    <div className="mt-6">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Recargar
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
  
  

export default function MyErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Lógica de reset si necesitas reiniciar algún estado
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
