import { useContext } from "react";

import { MarketingContext } from "../../context/MarketingContext";

export default function BtnEdit() {
  const { setIsOpenEdit } = useContext(MarketingContext);

  const handleClick = () => {
    setIsOpenEdit(false);
  };

  return (
    <>
      {/* BTN EDIT */}
      <button
        onClick={handleClick}
        className="bg-or-100 rounded-lg py-3 px-5 absolute bottom-[-20px] hover:bg-or-110"
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/crm/pencil-w.svg`}
          width={16}
          height={16}
        />
      </button>
    </>
  );
}
