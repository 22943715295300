import moment from "moment";

import { FirstDateFilter } from "./FirstDateFilter";
import { UseReservations } from "../../context/ReservationsContext";

export function FilterByRangeDate() {
  const { departureDate, setDepartureDate } = UseReservations();

  const handleFistDate = (e) => {
    // console.log(e);

    const dateSelected = moment(e[0]).format("YYYY-MM-DD");
    setDepartureDate((dates) => ({ ...dates, firstDay: dateSelected }));
  };

  const handleLastDate = (e) => {
    const dateSelected = moment(e[0]).format("YYYY-MM-DD");
    setDepartureDate((dates) => ({ ...dates, lastDate: dateSelected }));
  };

  const handleSetDates = () => {
    setDepartureDate({ firstDay: null, lastDate: null });
  };

  // console.log(departureDate);
  

  return (
    <div className="flex flex-col gap-y-2 items-center">
      <div className="flex h-max gap-x-2 items-center">
        <div className="w-[10rem] flex flex-col gap-y-4 ">
          <span className="text-fs-14 m-b text-black">Fecha inicial</span>
          <div className="flex px-[16px] py-[12.8px] border border-[#ebebeb] items-center gap-2 relative rounded-sm">
            <img
              className="w-[14px] h-[16px]"
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/calendar/calendar-b.svg`}
              alt={` icon calendar`}
            />

            <FirstDateFilter
              starDate={departureDate.firstDay}
              handelDate={handleFistDate}
            />
          </div>
        </div>

        <div className="w-[10rem] flex flex-col gap-y-4 ">
          <span className="text-fs-14 m-b text-black">Fecha final</span>
          <div className="flex px-[16px] py-[12.8px] border border-[#ebebeb] items-center gap-2 relative rounded-sm">
            <img
              className="w-[14px] h-[16px]"
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/calendar/calendar-b.svg`}
              alt={` icon calendar`}
            />

            <FirstDateFilter
              starDate={departureDate.lastDate}
              handelDate={handleLastDate}
              isFirstDate={false}
            />
          </div>
        </div>
      </div>

      <button
        onClick={handleSetDates}
        className="focus:outline-none py-3 px-4 rounded-lg bg-bl-100 text-center m-m text-white text-fs-12"
      >
        Borrar fechas
      </button>
    </div>
  );
}
