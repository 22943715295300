import React, { useState } from "react";

import { UseReservations } from "../context/ReservationsContext";
import { DetailsReservation } from "./ModalDetails/DetailsReservation";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";
import { useUser } from "../../../configs/login/UserContext";
import AlertPermissionsDeclined from "../../../configs/Permissions/AlertPermissionsDeclined";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative inline-block">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {showTooltip && (
        <div className="bg-black text-white text-xs rounded py-1 px-2 right-[-20px] absolute z-10">
          {text}
        </div>
      )}
    </div>
  );
};

export function SeeDetails({ referenceNumber }) {
  const [isOpen, setIsOpen] = useState(false);
  const { handleModalReservation } = UseReservations();
  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const openModal = () => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "view_booking_details"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpen(!isOpen);
      handleModalReservation(referenceNumber);
    }
  };

  return (
    <>
      <Tooltip text={<React.Fragment>{"Detalles"}</React.Fragment>}>
        <button className="focus:outline-none" onClick={openModal}>
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/general/eye.svg`}
            width={20}
            height={20}
            alt="edit details"
          />
        </button>
      </Tooltip>

      <DetailsReservation isOpen={isOpen} openModal={openModal} />

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
