/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { TableReservation } from "./TableReservation";
import { cardReservations } from "../config/filesJson";
import { UseReservations } from "../context/ReservationsContext";
import Pagination from "../../../components/pagination/Pagination";

const tabs = [
  { label: "Todas", value: "Todas" },
  { label: "Confirmado", value: "Confirmado" },
  { label: "Pendiente", value: "Pendiente" },
  { label: "Cancelado", value: "Cancelado" },
  { label: "Proceso de cancelación", value: "Proceso de cancelación" },
];

export function TabTableReservation() {
  const { tabSelected, setTabSelected, dataReservationFilter, setCardFilter } =
    UseReservations();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentTab, setCurrentTab] = useState([]);

  const blogPerPage = 6;

  const indexOfLastTab = currentPage * blogPerPage;
  const indexOfFirstTab = indexOfLastTab - blogPerPage;

  // Set initial tab and card filter
  useEffect(() => {
    setTabSelected(tabs[0].value);
  }, []);

  // Update card filter based on selected tab
  useEffect(() => {
    if (cardReservations[0]?.options) {
      setCardFilter(cardReservations[0].options[0]);
    }
  }, []);

  // Update pagination data whenever the filtered data changes
  useEffect(() => {
    if (dataReservationFilter) {
      setCurrentTab(
        dataReservationFilter.slice(indexOfFirstTab, indexOfLastTab)
      );
      const calculatedPages = Math.ceil(
        dataReservationFilter.length / blogPerPage
      );
      setTotalPages(Math.max(calculatedPages, 1));
    }
  }, [dataReservationFilter, currentPage]);

  // Reset page to 1 if the current tab has no item
  useEffect(() => {
    if (currentTab.length === 0 && currentPage > 1) {
      setCurrentPage(1);
    }
  }, [currentTab]);

  const { totalConfirmed, totalPending, totalDeclined, pendingDeclined } =
    UseReservations();

  const validateStatus = (status) => {
    // console.log(status);

    let totalStatus = 0;
    switch (status) {
      case "Confirmado":
        totalStatus = totalConfirmed;
        break;
      case "Pendiente":
        totalStatus = totalPending;
        break;
      case "Cancelado":
        totalStatus = totalDeclined;
        break;
      case "Proceso de cancelación":
        totalStatus = pendingDeclined;
        break;
      case "Todas":
        totalStatus =
          totalConfirmed + totalPending + totalDeclined + pendingDeclined;
        break;
      default:
        totalStatus = 0;
        break;
    }
    return totalStatus;
  };

  const handleStatus = (value) => {
    let status = value;
    if (value === "Cancelaciones pendientes") {
      status = "Cancelado Pendiente";
    }
    setTabSelected(status);
  };

  return (
    <>
      <div className="flex gap-x-2 text-fs-12 m-s-b overflow-x-auto">
        {tabs.map((tab, index) => (
          <button
            className="focus:outline-none focus:ring-transparent p-0"
            key={index}
            onClick={() => handleStatus(tab.value)}
          >
            <div
              className={`flex gap-x-1 items-center py-2 px-4 rounded-t-[4px] ${
                tabSelected === tab.value ? "bg-gry-30" : "bg-gry-50"
              }`}
            >
              <h3
                className={`${
                  tabSelected === tab.value
                    ? "text-bl-100 m-b"
                    : "text-gry-70 m-m"
                } text-fs-12`}
              >
                {tab.label} ({validateStatus(tab.label)})
              </h3>
            </div>
          </button>
        ))}
      </div>

      <div className="w-full flex justify-center">
        <TableReservation dataReservationFilter={currentTab} />
      </div>

      <div className="w-full flex justify-end mt-2">
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            pageChange={currentPage}
            onChange={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}
