import moment from "moment";
import { UseVacations } from "../context/VacationsContext";

export default function SelectMonth() {
  const { currentMonth, setCurrentMonth, currentYear } = UseVacations();

  const mountSelect = [
    { name: "Enero", value: 1 },
    { name: "Febrero", value: 2 },
    { name: "Marzo", value: 3 },
    { name: "Abril", value: 4 },
    { name: "Mayo", value: 5 },
    { name: "Junio", value: 6 },
    { name: "Julio", value: 7 },
    { name: "Agosto", value: 8 },
    { name: "Septiembre", value: 9 },
    { name: "Octubre", value: 10 },
    { name: "Noviembre", value: 11 },
    { name: "Diciembre", value: 12 },
  ];

  // CHANGE ARROWS
  const IncreaseMonth = () => {
    if (!(currentMonth === 12 && currentYear === moment().year() + 1)) {
      if (currentMonth < 13) {
        setCurrentMonth((prevCountMonth) => prevCountMonth + 1);
      }
    }
  };

  const ReductionMonth = () => {
    if (!(currentMonth === 1 && currentYear === moment().year())) {
      if (currentMonth > 0) {
        setCurrentMonth((prevCountMonth) => prevCountMonth - 1);
      }
    }
  };

  // CHANGE SELECT
  const handleMonthChange = (newCurrentMonth) => {
    if (newCurrentMonth === 13) {
      setCurrentMonth(1);
    } else {
      setCurrentMonth(newCurrentMonth);
    }
  };
  const currentDateMount = moment().month() + 1;
  const currentDateYear = moment().year();

  return (
    <div className="flex">
      {/* SELECT MONTH */}
      <div className="w-full justify-center flex gap-1">
        <div className="relative cursor-pointer">
          <select
            id="monthSelect"
            value={currentMonth}
            onChange={(e) => handleMonthChange(parseInt(e.target.value))}
            className="m-b text-fs-14 appearance-none focus:outline-none pl-4 pr-6 cursor-pointer"
          >
            {mountSelect.map((month, index) => (
              <option key={index} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/arrows/arrow-drop-down.svg`}
            width={10}
            height={10}
            alt="icon"
            className="absolute top-2.5 right-2"
          />
        </div>
        <div className="m-b text-fs-14">{currentYear}</div>
      </div>

      {/* ARROWS */}
      <div className="flex gap-2 pr-10">
      { currentMonth > currentDateMount &&  currentYear == currentDateYear &&(
          <button
            onClick={ReductionMonth}
            // disabled={
            //  currentYear >= currentDateYear
            // }
          >
            <img    
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/arrows/arrow-calendar-${
                currentMonth == 1 && currentYear == moment().year()
                  ? "b-l"
                  : "bl-l"
              }.svg`}
              width={18}
              height={18}
              alt="icon arrow"
              className={`${
                currentMonth == 1 &&
                currentYear == moment().year() &&
                "cursor-not-allowed"
              }`}
            />
          </button>
        )}
        {currentYear > currentDateYear &&(
          <button
            onClick={ReductionMonth}
            // disabled={
            //  currentYear >= currentDateYear
            // }
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/arrows/arrow-calendar-${
                currentMonth == 1 && currentYear == moment().year()
                  ? "b-l"
                  : "bl-l"
              }.svg`}
              width={18}
              height={18}
              alt="icon arrow"
              className={`${
                currentMonth == 1 &&
                currentYear == moment().year() &&
                "cursor-not-allowed"
              }`}
            />
          </button>
        )}

        <button onClick={IncreaseMonth}>
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/arrows/arrow-calendar-${
              currentMonth == 12 && currentYear == moment().year() + 1
                ? "b-r"
                : "bl-r"
            }.svg`}
            width={18}
            height={18}
            alt="icon arrow"
            className={`${
              currentMonth == 12 &&
              currentYear == moment().year() + 1 &&
              "cursor-not-allowed"
            }`}
          />
        </button>
      </div>
    </div>
  );
}
