import { Fragment } from "react";

import { FilterByRol } from "./FilterByRol";
import { FilterByStatus } from "./FilterByStatus";
import { Dialog, Transition } from "@headlessui/react";
import { useUser } from "../../../../configs/login/UserContext";

export function FilterComponent({ isOpenFilter, setIsOpenFilter }) {
  const { user } = useUser();


  return (
    <>
      <Transition show={isOpenFilter} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[2]"
          onClose={() => setIsOpenFilter(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 " />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg p-5 relative w-max">
                <h3 className="text-fs-18 m-b mb-6 mt-2">
                  Filtros de Usuarios
                </h3>
                <button
                  onClick={() => setIsOpenFilter(false)}
                  className="focus:outline-none absolute right-1 top-[15px] mr-3"
                >
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/close/close-70.svg`}
                    alt="close modal"
                    width={13}
                    height={13}
                  />
                </button>

                <div className={`w-max flex gap-x-6 flex-col gap-y-4`}>
                  {user.department_name == "Recursos Humanos" && (
                    <>
                      <FilterByRol />
                      <hr />
                    </>
                  )}
                  {/* <div className="w-[1px] h-[initial] bg-gry-70" /> */}
                  <FilterByStatus />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
