import moment from "moment";
import { useState } from "react";

import { DropzoneFiles } from "./DropzoneFiles";
import { UseRHContext } from "../../context/RHContext";
import {
  isBase64,
  isPdfBase64,
} from "../../../../components/dropzone/ChangeFormatImage";

export default function DocDetails() {
  const styleT = "text-fs-14 m-b text-gry-100";
  const styleS = "text-fs-14 m-m text-gry-100";

  const { rhDocs, setRhDocs, louderFinDocs } = UseRHContext();

  const [isHover, setIsHover] = useState({ hover: false, index: null });

  const handleMouseEnter = (index) => {
    setIsHover({ hover: true, index: index });
  };

  const handleMouseLeave = () => {
    setIsHover({ hover: false, index: null });
  };

  const handlePreviewFile = (file) => {
    window.open(file);
  };

  return (
    <div className="my-6 gap-16 flex">
      {/* VIEW DOCUMENTS */}
      <div className="grid grid-rows-3 grid-flow-col gap-y-4 gap-x-12 w-full">
        {rhDocs.map((docs, index) => (
          <div key={index} className="mt-4 w-max">
            <div className="flex gap-x-3 justify-between">
              <h3 className={`${styleT}`}>{docs.name}</h3>
              <button
                className="focus:outline-none"
                disabled={!docs.document}
                onClick={() => handlePreviewFile(docs.url)}
              >
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/general/eye-b.svg`}
                  width={15}
                  height={15}
                  alt="icon eye"
                />
              </button>
            </div>

            <div
              className={`${!docs?.updated_at && "h-[18px] w-[2px]"} ${styleS}`}
            >
              {docs?.updated_at &&
                `${moment(docs?.updated_at).format("DD/MM/YYYY")} | ${moment(
                  docs?.updated_at
                ).get("hours")}`}{" "}
            </div>
            <div className="relative w-[234px] h-[100px] bg-gry-50 rounded-2xl flex flex-col justify-center items-center mt-5">
              {louderFinDocs ? (
                <div className="animate-[skeletonLoading_1s_linear_infinite_alternate] rounded-2xl w-full h-full" />
              ) : (
                <>
                  <img
                    src={imageUrl(docs.document).image}
                    alt="icon close gry"
                    className={`object-cover ${imageUrl(docs.document).style}`}
                    width={18}
                    height={18}
                  />

                  {!docs.document && (
                    <span className="text-fs-10 m-m text-gry-100">
                      {docs.name}
                    </span>
                  )}

                  <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave()}
                    className={`cursor-pointer absolute w-full h-full inset-y-0 inset-x-0 `}
                  >
                    {isHover.hover && isHover.index === index && (
                      <>
                        <DropzoneFiles docs={docs} setRhDocs={setRhDocs} />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const imageUrl = (url) => {
  if (url) {
    if (isBase64(url)) {
      if (isPdfBase64(url)) {
        return {
          image: `${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/typeFile/pdf.svg`,
          style: "w-[40px] h-[40px]",
        };
      } else {
        return { image: url, style: "w-full h-full rounded-2xl" };
      }
    } else {
      return { image: url, style: "w-full h-full rounded-2xl" };
    }
  } else {
    return {
      image: `${
        import.meta.env.VITE_URL_IMAGES
      }assets/icons/general/add-image.svg`,
      style: "w-[18px] h-[18px]",
    };
  }
};
