export function DivisionActivities(services) {
  let typesActivities = {
    hotel: [],
    tour: [],
    transport: [],
  };

  services.map((service) => {
    switch (service.type) {
      case "hotel":
        typesActivities.hotel = [...typesActivities.hotel, service];
        break;
      case "Activity":
        typesActivities.tour = [...typesActivities.tour, service];
        break;
      case "transport":
        typesActivities.transport = [...typesActivities.transport, service];
        break;
    }
  });

  return typesActivities;
}
