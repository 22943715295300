import { useContext, useState, useEffect } from "react";
import { ChangeStatusUser } from "../api/api";

import { UsersContext } from "../context/UsersContext";
import AlertsCrm from "../../../components/alerts/Alerts";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";
import { useUser } from "../../../configs/login/UserContext";
import AlertPermissionsDeclined from "../../../configs/Permissions/AlertPermissionsDeclined";
import { SelectStatus, StatusView } from "./Status";

const typeStatus = [
  { label: "Activo", value: "ACTIVO" },
  { label: "Inactivo", value: "INACTIVO" },
];

export default function SelectStatusUser({ status, id, name }) {
  const { user } = useUser();
  const { refreshUserList } = useContext(UsersContext);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(true);
  const [alertConfig, setAlertConfig] = useState({
    title: "",
    text: "",
    typeAlert: "",
  });

  const [currentId, setCurrentId] = useState("");
  const [currentEventValue, setCurrentEventValue] = useState("");

  useEffect(() => {
    const executeChangeStatus = async () => {
      if (isConfirmOpen) {
        try {
          let updateStatusUser = await ChangeStatusUser(
            currentId,
            currentEventValue
          );

          if (updateStatusUser.data.code === "ok") {
            refreshUserList();
          }
        } catch (error) {
          console.log(error);
          throw error;
        } finally {
          setIsConfirmOpen(false);
        }
      }
    };

    executeChangeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmOpen]);

  const handleStatus = (id, event) => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "change_user_status"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setOpenAlert(true);
      setTypeAlert("cfv");
      setAlertConfig({
        title: "¡Cambiar Estatus!",
        text: `¿Desea cambiar el estatus de ${name}?`,
        typeAlert: "warning",
      });
      setCurrentId(id);
      setCurrentEventValue(event.target.value);
    }
  };

  const ConfirmAlert = () => {
    if (typeAlert == "cfv") {
      setOpenAlert(false);
      setIsConfirmOpen(true);
    }
  };
  const hasPermission = HasPermissionsUser(
    user.permissions,
    "change_user_status"
  );

  return (
    <>
      <div className="flex justify-center">
        {hasPermission ? (
          <SelectStatus
            handleStatus={handleStatus}
            typeStatus={typeStatus}
            idUser={id}
            status={status}
          />
        ) : (
          <StatusView status={status} />
        )}

        <AlertsCrm
          setConfirmation={ConfirmAlert}
          isOpen={openAlert}
          setIsOpen={setOpenAlert}
          twoOptions={true}
          title={alertConfig.title}
          text={alertConfig.text}
          typeAlert={alertConfig.typeAlert}
        />
      </div>

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
