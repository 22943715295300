import axiosWithInterceptor from "../../../configs/Instance/axiosConfig";

export async function SendImages(jsonImage) {
  try {
    let response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/uploadImages`,
      jsonImage
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetImagesByView(view, languageSelected) {
  try {
    let response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/getImages/${languageSelected}/${view}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
