import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ImageModification } from "../../../../components/imageGet/ImageGet";

export function CommentsReservation({ comments }) {
  const scrollRef = useRef(null);
const [commentsImg,SetCommentsImg] = useState([]);
  const scrollToBottom = () => {
    const scrollableDiv = scrollRef.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ImageModification(comments);
      SetCommentsImg(response)
    };
  
    fetchData();
    scrollToBottom();
  }, [comments]);

  return (
    <div className="flex flex-col gap-y-2 h-[40%] w-full">
      <h3 className="text-gry-100 m-b text-fs-14 h-[10%]">Comentarios</h3>

      <div
        ref={scrollRef}
        className="overflow-y-auto h-[90%] scroll-page-gry pr-3"
      >
        {commentsImg.length > 0 ? (
          commentsImg.map((comment, index) => (
            <CommentCard
              key={index}
              comment={comment}
              totalComments={comments.length}
              index={index}
            />
          ))
        ) : (
          <div className="m-m text-fs-12 h-full flex items-center justify-center">
            Comentarios vacios
          </div>
        )}
      </div>
    </div>
  );
}

export function CommentCard({ comment }) {
  return (
    <div
      className={
        "relative border border-[#ECECEC] mb-2 pb-2 py-1 pl-3 rounded z-0"
      }
    >
      {/* BTN CLOSE */}
      {/* <button className="absolute right-2 top-2">
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/close/close-r.svg`}
            alt="icon close"
            width={10}
            height={10}
          />
        </button> */}

      <div className="flex gap-2 p-0 m-0">
        <div className=" w-6 h-6">
          <img
            className="rounded-full w-full h-full"
            width={133}
            height={117}
            alt="profile comments"
            src={comment.url_image}
          />
        </div>
        <p className="text-fs-12 text-bl-100 m-b">{comment.user_name}</p>
      </div>

      <div className="pl-7">
        <p className="m-0 text-fs-10 m-m text-gry-70">
          {moment(comment.created_at).format("DD/MM/YYYY")}
        </p>
        <p className="m-0 text-fs-14 m-m text-gry-100">{comment.comment}</p>
      </div>
    </div>
  );
}
