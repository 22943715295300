import DaysOfMonth from "../components/DaysOfMonth";
import SelectMonth from "../components/SelectMonth";
import ListEmployees from "../components/ListEmployees";
import SearchCalendar from "../components/SearchCalendar";
// import CalendarVacations from "../components/CalendarVacations";
import { VacationsProviderContext } from "../context/VacationsContext";
import { Notifications } from "../../../components/Notifications/Notification";

export default function VacationsLayout() {
  return (
    <VacationsProviderContext>
      <div className="flex justify-between mb-[44px] w-full">
        <h1 className="text-fs-24 m-b text-black">Vacaciones</h1>
        <Notifications />
      </div>

      <div className="flex border-b">
        <div className="w-[15%] border-r pb-3">
          <SearchCalendar />
        </div>

        <div className="w-[85%] flex flex-col pb-3 justify-between overflow-x-hidden">
          <SelectMonth />
          <DaysOfMonth />
          {/* <CalendarVacations /> */}
        </div>
      </div>
      
      <ListEmployees />
    </VacationsProviderContext>
  );
}
