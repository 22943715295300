export function AgeCalculation(startContract) {
  const actualDate = new Date();
  const startDate = new Date(startContract);
  let year = actualDate.getFullYear() - startDate.getFullYear();
  let month = actualDate.getMonth() - startDate.getMonth();
  let dates = actualDate.getDate() - startDate.getDate();

  if (month < 0) {
    year--;
    month += 12;
  }

  if (dates < 0) {
    month--;
    let lastDates = new Date(
      actualDate.getFullYear(),
      actualDate.getMonth(),
      0
    ).getDate();
    dates += lastDates;
  }
  return { year, month, dates };
}
