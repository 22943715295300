/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import NotificationType from "../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../components/alerts/notifications/useNotification";

export default function AlertPermissionsDeclined({
  hasPermissions,
  cleanedAlert,
}) {
  const { notification, showNotification, hideNotification } =
    useNotification();

  useEffect(() => {
    if (!hasPermissions) {
      showNotification(
        "warning",
        "Permisos Denegados",
        "No cuentas con los permisos",
        5000
      );
    }
  }, [hasPermissions]);

  useEffect(() => {
    if (notification && !notification.visible) {
      cleanedAlert(true);
    }
  }, [notification]);

  return (
    !hasPermissions &&
    notification &&
    notification.visible && (
      <NotificationType
        type={notification.type}
        title={notification.title}
        message={notification.message}
        duration={notification.duration}
        onClose={hideNotification}
      />
    )
  );
}
