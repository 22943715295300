/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";

import {
  FilterByActivity,
  FilterByOrder,
  FilterByService,
  FilterByStatus,
  FilterRangeDate,
  FilterTabStatus,
  SearchReviewFilter,
} from "../config/FiltersReservation";
import { GetDetailsReservation, GetReservation } from "../api/Reservations";
import { useUser } from "../../../configs/login/UserContext";
// import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";

const ReservationsContext = createContext();

export const UseReservations = () => useContext(ReservationsContext);

const ReservationProvider = ({ children }) => {
  const [cardFilter, setCardFilter] = useState(null);
  const [searchTable, setSearchTable] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [totalPending, setTotalPending] = useState(0);
  const [loaderReservation, setLoaderReservation] = useState(false);
  const [totalDeclined, setTotalDeclined] = useState(0);
  const [filterByOrder, setFilterByOrder] = useState(1);
  const [totalConfirmed, setTotalConfirmed] = useState(0);
  const [pendingDeclined, setPendingDeclined] = useState(0);
  const [dataReservation, setDataReservation] = useState(null);
  const [filterByRangeDate, setFilterByRangeDate] = useState(null);
  const [detailsReservation, setDetailsReservations] = useState(null);
  const [dataReservationFilter, setDataReservationFilter] = useState(null);
  const [departureDate, setDepartureDate] = useState({
    firstDay: null,
    lastDate: null,
  });

  const { token } = useUser();
  const [tabSelected, setTabSelected] = useState(null);

  const handleReservations = () => {
    setLoaderReservation(true);
    const userID = "001";
    if (token) {
      GetReservation(userID)
        .then((result) => {
          if (result?.data) {
            setDataReservation(result.data.Bookings);
            setLoaderReservation(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoaderReservation(false);
          throw error;
        });
    }
  };

  const handleModalReservation = (referenceNumber) => {
    GetDetailsReservation(referenceNumber).then((response) => {
      setDetailsReservations(response.data.Bookings);
    });
  };

  // useEffect(() => {
  //   const hasPermission = HasPermissionsUser(
  //     user.permissions,
  //     "view_booking_list"
  //   );
  //   if (hasPermission) {
  //     handleReservations();
  //   }
  
  // }, [user, token]);

  useEffect(() => {
    if (dataReservation) {
      let filter = [...dataReservation];

      if (filterByOrder) {
        filter = FilterByOrder(filter, filterByOrder);
      }

      if (searchTable !== "") {
        filter = SearchReviewFilter(filter, searchTable);
      }

      if (tabSelected !== "Todas" && tabSelected) {
        filter = FilterTabStatus(filter, tabSelected);
      }

      if (serviceType.length > 0) {
        filter = FilterByService(filter, serviceType);
      }

      if (departureDate.firstDay && departureDate.lastDate) {
        filter = FilterRangeDate(filter, departureDate);
      }

      FilterByStatus(
        dataReservation,
        setTotalConfirmed,
        setTotalPending,
        setTotalDeclined,
        setPendingDeclined
      );

      setDataReservationFilter(filter);
    }
  }, [
    dataReservation,
    searchTable,
    cardFilter,
    tabSelected,
    serviceType,
    departureDate,
    filterByOrder,
  ]);

  useEffect(() => {
    if (dataReservation && cardFilter) {
      let filter = [...dataReservation];

      if (cardFilter?.value !== "all") {
        filter = FilterByActivity(filter, cardFilter.value);
      }

      FilterByStatus(
        filter,
        setTotalConfirmed,
        setTotalPending,
        setTotalDeclined,
        setPendingDeclined
      );

      setDataReservationFilter(filter);
    }
  }, [dataReservation, cardFilter]);

  return (
    <ReservationsContext.Provider
      value={{
        cardFilter,
        searchTable,
        serviceType,
        tabSelected,
        totalPending,
        setCardFilter,
        totalDeclined,
        departureDate,
        filterByOrder,
        setSearchTable,
        setTabSelected,
        setServiceType,
        totalConfirmed,
        dataReservation,
        pendingDeclined,
        setFilterByOrder,
        setDepartureDate,
        filterByRangeDate,
        setPendingDeclined,
        setDataReservation,
        detailsReservation,
        setFilterByRangeDate,
        dataReservationFilter,
        setDetailsReservations,
        setDataReservationFilter,
        handleReservations,
        handleModalReservation,
        loaderReservation,
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
};

export { ReservationsContext, ReservationProvider };
