export function ClientInfo({ holder, payment, status }) {
  const getStatusStyles = (status) => {
    switch (status) {
      case "Cancelado":
        return "bg-red-20 text-red-100";
      case "Confirmado":
        return "bg-grn-10 text-grn-100";
      case "Pendiente":
        return "bg-or-20 text-or-100";
      default:
        return "";
    }
  };

  return (
    <div className="w-[30%] flex flex-col gap-y-4 pr-5 border-r border-gry-70 overflow-y-auto scroll-page-gry">
      {/* NAME */}
      <div className="m-b text-fs-14 text-gry-100 mb-2 uppercase flex gap-3 items-center">
        {holder.name} {holder.surname}{" "}
        <span
          className={`p-1 rounded-md m-b text-fs-12 w-max flex ${getStatusStyles(
            status
          )}`}
        >
          {status}{" "}
        </span>
      </div>
      <div className="w-full rounded-lg bg-grn-30 py-2 px-4">
        {/* TITLE RESERV NUMBER */}
        <h2 className="text-fs-16 m-m text-bl-100 flex items-center gap-x-2">
          No. de confirmación:{" "}
          <p className="m-0 text-fs-14 m-b">#{holder.booking_id}</p>
        </h2>
      </div>

      <div className="flex flex-col gap-y-7">
        {/* EMAIL */}
        <div className="blok">
          <h2 className="m-b text-fs-14 text-gry-100 mb-1 ">
            Correo electrónico
          </h2>

          <p className="m-0 m-m text-fs-14 text-gry-100 flex gap-1">
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/mail/mail-b.svg`}
              alt="icon close"
              width={20}
              height={20}
            />
            {holder.email}
          </p>
        </div>

        {/* NAME */}
        <div className="blok">
          <h2 className="m-b text-fs-14 text-gry-100 mb-1">Nombre(s)</h2>
          <p className="m-0 m-m text-fs-14 text-gry-100 flex gap-1">
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/adult/adult-b.svg`}
              alt="icon close"
              width={18}
              height={18}
            />
            {holder.name}{" "}
          </p>
        </div>

        {/* LAST NAME */}
        <div className="blok">
          <h2 className="m-b text-fs-14 text-gry-100 mb-1">Apellido(s)</h2>
          <p className="m-0 m-m text-fs-14 text-gry-100 flex gap-1">
            {" "}
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/adult/adult-b.svg`}
              alt="icon close"
              width={18}
              height={18}
            />
            {holder.surname}
          </p>
        </div>

        {/* PHONE */}
        <div className="blok">
          <h2 className="m-b text-fs-14 text-gry-100 mb-1">Teléfono</h2>
          <p className="m-0 m-m text-fs-14 text-gry-100 flex gap-1">
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/tel/tel-b.svg`}
              alt="icon close"
              width={18}
              height={18}
            />
            {holder.phone_number}
          </p>
        </div>
        {/* PENDING */}
        {/* TOTAL PERSON  */}
        {/* <div className="blok">
          <h2 className="m-b text-fs-14 text-gry-100 mb-1">Personas</h2>
          <p className="m-0 m-m text-fs-14 text-gry-100 flex gap-1">
            {" "}
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/family/family-b.svg`}
              alt="icon close"
              width={18}
              height={18}
            />
            1*
          </p>
        </div> */}
      </div>

      {/* CREDIT CARD */}
      {payment.card_digits && (
        <div className="blok">
          <h2 className="m-b text-fs-14 text-gry-100 mb-1">Pago</h2>
          <p className="m-0 m-m text-fs-14  mb-2">
            Tarjeta: XXXX XXXX {payment.card_digits}
          </p>
          <p className="m-0 m-m text-fs-14 flex gap-x-1 ">
            Monto total:{" "}
            <p className="m-o m-b">
              $
              {Math.floor(payment.amount)
                .toLocaleString("es-MX", { currency: "MXN" })
                .replace(".00", "")}
              .<sup>{(payment.amount % 1).toFixed(2).slice(2)}</sup> MXN
            </p>
          </p>
        </div>
      )}

      {/* MAIL FORWARDING */}
      {/* <div className="py-2 px-5 bg-gry-50 rounded-md flex items-center gap-x-3">
        <img
          src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/mail/outgoing-mail.svg`}
          width={20}
          height={20}
          alt="send notification"
        />

        <div className="flex flex-col">
          <h2 className="m-b text-fs-14 text-gry-100">Reenviar notificación</h2>
          <p className="m-0 m-m text-fs-14">
            Reenviar itinerario por correo electrónico.
          </p>
        </div>
      </div> */}

      {/* <div className="flex items-center gap-x-2">
        <img
          src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/calendar/calendar-cancel.svg`}
          width={16}
          height={16}
          alt="send notification"
        />
        <p className="m-0 text-fs-14 m-m">Cancelar reserva</p>
      </div> */}
    </div>
  );
}
