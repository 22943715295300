export function Skeleton() {
  return [...Array(3)].map((_, index) => (
    <div key={index} className="flex flex-col gap-y-2 pr-3 mr-3">
      <div className="rounded-lg animate-[skeletonLoading_1s_linear_infinite_alternate] h-[22px] w-[70px]" />

      <div className="flex flex-wrap gap-x-4 items-center py-[7px]">
        <Inputs max={index} />
      </div>
    </div>
  ));
}

export function Inputs({max}) {
    
  const handleArray = (max) => {
    switch (max) {
      case 0:
        return 3;
      case 1:
      case 2:
        return 1;
    }
  };
  return [...Array(handleArray(max))].map((_, index) => (
    <div
      className="rounded-sm animate-[skeletonLoading_1s_linear_infinite_alternate] h-[18px] w-[90px]"
      key={index}
    />
  ));
}
