/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { CheckBox } from "./CheckBox";
import { Skeleton } from "./Skeleton";
import { ButtonSave } from "./ButtonSave";
import AlertsCrm from "../../../../components/alerts/Alerts";
import { ContainerConfig } from "../../config/ContainerConfig";
import {
  GetProvidersStayWuw,
  SaveProvidersStayWuw,
} from "../../api/ConfigurationApi";

export function ContainerProviderStayWuw({ isOpen, showNotification }) {
  const [providersSelected, setProviderSelected] = useState({
    hotel: [],
    activity: [],
    transport: [],
  });

  const [servicesUpdates, setServicesUpdates] = useState({});

  const [loader, setLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [providersStay, setProvidersStay] = useState(null);

  const getProviders = async () => {
    try {
      const response = await GetProvidersStayWuw();
      setProvidersStay(response);
      // handleProvidersActive(response);
      setProviderSelected(response);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // GET PROVIDER STAYWUW
  useEffect(() => {
    if (isOpen) {
      getProviders();
    }
  }, [isOpen]);

  // HANDLE RESPONSE
  useEffect(() => {
    validIsChange();
  }, [providersSelected]);

  const updateArray = (services, newValue, checked) => {
    services = services.map((service) => {
      if (service.name == newValue.name) {
        return { ...service, status: checked ? "active" : "inactive" };
      }
      return service;
    });
    return services;
  };

  // SET PROVIDERS SELECTED BY CHECKBOX
  const handleProvider = (service, prov, checked) => {
    setProviderSelected((services) => {
      services = {
        ...services,
        [service]: updateArray(services[service], prov, checked),
      };
      return services;
    });
  };

  // VALID IF PROVIDER STAYWUW IS CHANGE
  const validIsChange = () => {
    let result = {};

    if (providersStay) {
      Object.keys(providersSelected).forEach((key) => {
        if (
          JSON.stringify(providersStay[key]) !==
          JSON.stringify(providersSelected[key])
        ) {
          providersSelected[key].map((h) => {
            if (h.status == "active") {
              result[key] = providersSelected[key]
                .filter((provider) => provider.status == "active")
                .map((prov) => prov.name);
            } else if (
              h.status !== "active" &&
              providersSelected[key].filter(
                (provider) => provider.status == "active"
              ).length == 0
            ) {
              result[key] = [];
            }
          });
        } else {
          result[key] = null;
        }
      });

      const newServices = Object.fromEntries(
        Object.entries(result).filter(([key, value]) => value !== null)
      );

      setServicesUpdates(newServices);
    }
  };

  // SAVE PROVIDERS
  const saveProvider = async (isSave) => {
    if (isSave) {
      setOpenAlert(false);
      setLoader(true);
      try {
        const response = await SaveProvidersStayWuw(servicesUpdates);
        if (response.code == "ok") {
          setLoader(false);
          showNotification(
            "success",
            "Proveedores actualizados con exito",
            "",
            5000
          );
          setServicesUpdates({});
          getProviders();
        }
      } catch (error) {
        console.log(error);
        setLoader(false);
        showNotification("error", "La actualización falló", "", 5000);
        throw error;
      }
    }
  };

  // SET ICON BY SERVICE
  const handleIcon = (provider) => {
    switch (provider) {
      case "hotel":
        return "assets/icons/hotel/domain-nav.svg";
      case "activity":
        return "assets/icons/tour/map-nav.svg";
      case "transport":
        return "assets/icons/transport/transport-nav.svg";
    }
  };

  const capitalizeTitle = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <ContainerConfig isOpen={isOpen}>
        {isOpen && (
          <div className="flex flex-col gap-y-2.5">
            <div className="flex justify-between">
              {providersStay ? (
                Object.keys(providersStay).map((provider, index) => (
                  <div
                    key={index}
                    className={`flex flex-col gap-y-2 pr-3 mr-3`}
                  >
                    <div className="flex items-center gap-x-3">
                      <img
                        src={`${import.meta.env.VITE_URL_IMAGES}${handleIcon(
                          provider
                        )}`}
                        alt="provider"
                        width={16}
                        height={16}
                      />

                      <p className="m-s-b text-fs-16">
                        {capitalizeTitle(provider)}
                      </p>
                    </div>

                    {/* CHECKBOX */}
                    <CheckBox
                      provider={provider}
                      handleProvider={handleProvider}
                      providersSelected={providersSelected}
                    />
                  </div>
                ))
              ) : (
                <Skeleton />
              )}
            </div>

            {/* SAVE BUTTON */}
            <ButtonSave
              saveProvider={() => setOpenAlert(true)}
              loader={loader}
              servicesUpdates={servicesUpdates}
            />
          </div>
        )}
      </ContainerConfig>

      <AlertsCrm
        setConfirmation={saveProvider}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title="¡Actualización de proveedores!"
        text="¿Deseas actualizar los proveedores de StayWuw?"
        typeAlert={"warning"}
      />
    </>
  );
}
