import InfoUser from "./components/InfoUser";
import { useUser } from "../../configs/login/UserContext";
import BarGraphReservation from "./components/BarGraphReservation";
import { Notifications } from "../../components/Notifications/Notification";
import { ReservationProvider } from "../Reservations/context/ReservationsContext";

export function LayoutPresentation() {
  const { user } = useUser();

  // const hasPermission = HasPermissionsUser(
  //   user.permissions,
  //   "view_booking_list"
  // );

  return (
    <ReservationProvider>
      <div className="h-full">
        <div className="flex justify-between items-center mb-[7rem]">
          <div className="flex flex-col">
            <h1 className="w-max h-max m-m text-fs-24">
              ¡Bienvenido de nuevo!{" "}
            </h1>
            <span className="m-b text-bl-100 text-fs-24">{user.name}</span>
          </div>

          <Notifications />
        </div>

        {/* {hasPermission && <TotalReservationsCards />} */}

        <div className="flex gap-36">
          {(user.role_name == "AdminSuper" ||
            user.department_name == "Reservaciones") && <BarGraphReservation />}
          <InfoUser users={user} />
        </div>
      </div>
    </ReservationProvider>
  );
}
