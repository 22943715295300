export function ButtonSave({ isChange, setOpenAlert, loader }) {
  return (
    <button
      onClick={() => setOpenAlert(true)}
      className={` w-max focus:outline-none px-[18px] py-[7px] rounded-lg border-0 flex items-center gap-x-[10px] ${
        !isChange ? "bg-grn-50" : "bg-grn-70"
      }`}
      disabled={!isChange}
    >
      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/general/save-w.svg`}
        alt="save"
        width={18}
        height={18}
      />
      <p className="text-white text-fs-16 m-0 text-center">
        {loader ? "Guardando..." : "Guardar"}
      </p>
    </button>
  );
}
