import { MarketingViews } from "./MarketingViews";
import { ProviderMarketingContext } from "../context/MarketingContext";
import { Notifications } from "../../../components/Notifications/Notification";

export default function MarketingLayout() {
  return (
    <ProviderMarketingContext>
      <div className="h-full">
        <div className="flex justify-between mb-[30px] w-full">
          <h1 className="text-fs-24 m-b text-black">Marketing</h1>

          <Notifications />
        </div>

        <MarketingViews />
      </div>
    </ProviderMarketingContext>
  );
}
