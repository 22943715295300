/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";

import { UseRHContext } from "../context/RHContext";
import { GetVacationUser } from "../../Vacations/api/api";
import ViewInformationRH from "./ModalInfo/ViewInformationRH";
import { RhConfirmVacation } from "./ModalInfo/RhConfirmVacation";
import { EmptyTable } from "../../../components/table/EmptyTable";
import Pagination from "../../../components/pagination/Pagination";
import { BodySkeleton } from "../../../components/skeletons/BodySkeleton";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../components/alerts/notifications/useNotification";
import { formatCurrency } from "../configs/SalaryConfig";

export default function TableRH() {
  const {
    resetDocs,
    addNewUser,
    dataRHFilter,
    setAddNewUser,
    isLoaderTable,
    setUserSelected,
    refreshUserList,
    findUserSelected,
    refreshDocumentUser,
  } = UseRHContext();

  const { notification, showNotification, hideNotification } =
    useNotification();
  const [openVacation, setOpenVacation] = useState(false);

  const [employeesInfo, setEmployeesInfo] = useState(null);

  useEffect(() => {
    if (addNewUser.isUser) {
      showNotification("success", addNewUser.title, addNewUser.message, 5000);

      const cleanIsUser = setTimeout(() => {
        setAddNewUser({ isUser: false, title: null, message: null });
      }, 5000);

      return () => clearTimeout(cleanIsUser);
    }
  }, [addNewUser]);

  const styleTd = "m-0 text-fs-12 m-m flex justify-center text-center";
  const styleTh = "text-fs-14 m-b text-gry-100 ";

  const [isInformationOpen, setIsInformationOpen] = useState(false);

  const openInfoDialog = (user) => {
    findUserSelected(user.id);
    refreshDocumentUser(user.id);
    setIsInformationOpen(true);
  };

  const closeInfoDialog = () => {
    setIsInformationOpen(false);
    resetDocs(true);
    setUserSelected(null);
  };

  // PAGINATION
  const blogPerPage = 7;
  const [totalPages, setTotalPages] = useState(1);
  const [currentTab, setCurrentTab] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastTab = currentPage * blogPerPage;
  const indexOfFirstTab = indexOfLastTab - blogPerPage;

  useEffect(() => {
    if (dataRHFilter && dataRHFilter.length > 0) {
      const sortedUsers = [...dataRHFilter].sort((a, b) => b.id - a.id);

      setCurrentTab(sortedUsers.slice(indexOfFirstTab, indexOfLastTab));

      const calculatedPages = Math.ceil(sortedUsers.length / blogPerPage);
      setTotalPages(Math.max(calculatedPages, 1));
    } else {
      setCurrentTab([]);
      setTotalPages(1);
    }
  }, [dataRHFilter, currentPage, indexOfFirstTab, indexOfLastTab]);

  useEffect(() => {
    if (currentTab.length === 0 && currentPage > 1) {
      setCurrentPage(1);
    }
  }, [currentTab]);

  const getVacationUser = async (id) => {
    try {
      const response = await GetVacationUser(id);
      if (response.code === "ok") {
        setEmployeesInfo(response.employees[0]);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleVacations = (user) => {
    getVacationUser(user.id);
    setOpenVacation(true);
  };

  const notificationResponse = (name, status) => {
    refreshUserList();
    showNotification(
      "success",
      `¡Vacaciones${status == 1 ? " confirmadas" : "canceladas"}!`,
      `Las vacaciones de ${name} fueron ${
        status == 1 ? "confirmadas" : "canceladas"
      }`,
      5000
    );
  };

  // COPY TEXT EMAIL

  const [copy, setCopy] = useState(false);
  const [copyIndex, setCopyIndex] = useState(null);

  const handleCopy = (index) => {
    const emailElement = document.querySelector(`p[data-index="${index}"]`);

    const textToCopy = emailElement.textContent;
    navigator.clipboard.writeText(textToCopy);

    setCopy(true);

    setCopyIndex(index);
    setTimeout(() => {
      setCopy(false);
    }, 500);
  };

  // TOOLTIP
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = (index) => {
    setCopyIndex(index);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div>
      <div className="bg-gry-30 rounded-b-2xl rounded-tr-2xl p-5 w-full ">
        <table className="w-full mt-6">
          <thead className="mb-4">
            <tr>
              <th className={`${styleTh} w-[152px]`}>No.Folio interno</th>
              <th className={`${styleTh}`}>Nombre</th>
              <th className={`${styleTh}`}>Correo</th>
              <th className={`${styleTh}`}>Departamento</th>
              <th className={`${styleTh}`}>Estatus</th>
              <th className={`${styleTh}`}>Fecha de contratación</th>
              <th className={`${styleTh}`}>Tipo de contrato</th>
              <th className={`${styleTh}`}>Salario</th>
              {/* <th className={`${styleTh}`}>NSS</th> */}
              <th className={`${styleTh}`}>Acciones</th>
            </tr>
          </thead>

          {!isLoaderTable && dataRHFilter && dataRHFilter.length > 0 && (
            <tbody>
              <tr className="h-[25px] bg-gry-30"></tr>
              {/* {dataRHFilter.map((rh, index) => ( */}
              <>
                {currentTab.map((rh, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr className="bg-white rounded-lg h-[56px]">
                        {/* NO.FOLIO INTERNO */}
                        <td className="rounded-l-xl">
                          <div className="flex items-center gap-x-2 justify-center">
                            <p className={styleTd}>{rh.user_name}</p>
                          </div>
                        </td>

                        {/* NAME */}
                        <td className="w-[250px]">
                          <p className={`${styleTd} !m-s-b`}>{rh.name}</p>
                        </td>

                        {/* EMAIl */}
                        <td className="w-[250px]">
                          <div className="flex items-center gap-3 relative">
                            <button
                              className="focus:outline-none"
                              onClick={() => handleCopy(index)}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/crm/copy.svg`}
                                width={12}
                                height={12}
                                className="cursor-pointer"
                              />
                            </button>
                            {showTooltip && copyIndex === index && (
                              <div
                                className={`bg-black text-white text-xs bottom-5 rounded py-1 px-2 left-[-35px] absolute z-[20]`}
                              >
                                Copiar Correo
                              </div>
                            )}
                            <p
                              className={`${styleTd} !m-s-b`}
                              data-index={index}
                            >
                              {rh.email}
                            </p>

                            {copy && copyIndex === index ? (
                              <div className="absolute text-bl-100 m-b text-fs-10 bottom-[-1rem] left-10">
                                Copiado
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>

                        {/* DEPARTMENT */}
                        <td className="w-[8rem]">
                          <p className={`${styleTd}`}>
                            {rh.user_department_description}
                          </p>
                        </td>

                        {/* STATUS */}
                        <td className="w-[4rem]">
                          <p
                            className={`flex justify-center items-center rounded px-2 py-1 ${styleTd} `}
                          >
                            <span
                              className={`px-2 py-1 rounded m-s-b ${
                                rh.status === "ACTIVO" || rh.status === "ALTA"
                                  ? "bg-grn-10 text-grn-100"
                                  : "bg-red-20 text-red-100"
                              }`}
                            >
                              {rh.status}
                            </span>
                          </p>
                        </td>

                        {/* ENTRY DATE */}
                        <td className="w-[8rem]">
                          <div className="flex gap-x-2 items-center justify-center">
                            <p
                              className={`!m-s-b ${
                                !rh?.start_contract && "text-red-100"
                              } ${styleTd}`}
                            >
                              {rh.start_contract
                                ? moment(rh.start_contract).format("DD/MM/YYYY")
                                : "-"}
                            </p>
                          </div>
                        </td>

                        {/* NSS */}
                        {/* <td className="w-[8rem]">
                          <p
                            className={`${styleTd} ${
                              !rh?.nss && "text-red-100"
                            }`}
                          >
                            {rh.nss == null ? "SIN NSS" : rh.nss}
                          </p>
                        </td> */}

                        {/* TYPE CONTRACT */}
                        <td className="w-[10rem]">
                          <div className="flex items-center justify-center">
                            <div
                              className={` text-fs-12 m-s-b ${
                                rh.contract_type === "INDEFINIDO"
                                  ? "text-grn-100 "
                                  : rh.contract_type === "TEMPORAL"
                                  ? "text-yw-100"
                                  : "text-red-100"
                              }`}
                            >
                              {rh.contract_type == null
                                ? "SIN CONTRATO"
                                : rh.contract_type}
                            </div>
                          </div>
                        </td>

                        {/* SALARY */}
                        <td className="w-[150px]">
                          <div className="flex items-center gap-x-2 justify-center">
                            <p className={styleTd}>
                              ${" "}
                              {rh?.salary ? formatCurrency(rh.salary) : "00.00"}
                            </p>
                          </div>
                        </td>

                        {/* ACCIONES */}
                        <td className="rounded-r-xl w-[6rem]">
                          <div className="grid grid-cols-2 justify-items-center">
                            {/* BTN EYE */}
                            <button
                              className="w-max focus:outline-none"
                              onClick={() => openInfoDialog(rh)}
                            >
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/general/eye-b.svg`}
                                width={18}
                                height={18}
                                alt="icon eye"
                              />
                            </button>

                            {/* BTN EDIT */}
                            {rh?.vacation && (
                              <button
                                className="focus:outline-none"
                                onClick={() => handleVacations(rh)}
                              >
                                <img
                                  src={`${
                                    import.meta.env.VITE_URL_IMAGES
                                  }assets/icons/crm/navbarCRM/vacations-bl.svg`}
                                  width={15}
                                  height={15}
                                  alt="icon edit"
                                />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>

                      {rh.length - 1 !== index && (
                        <tr className="h-[12px] bg-transparent" />
                      )}
                    </>
                  </React.Fragment>
                ))}
              </>
              <ViewInformationRH
                isOpen={isInformationOpen}
                closeInfo={closeInfoDialog}
              />
            </tbody>
          )}
        </table>
        {isLoaderTable && <BodySkeleton />}

        {(!dataRHFilter || dataRHFilter.length == 0) && !isLoaderTable && (
          <EmptyTable />
        )}
      </div>

      <div className="w-full flex justify-end mt-2">
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            pageChange={currentPage}
            onChange={setCurrentPage}
          />
        )}
      </div>

      <RhConfirmVacation
        isOpen={openVacation}
        userVacation={employeesInfo}
        open={handleVacations}
        close={() => setOpenVacation(false)}
        notificationResponse={notificationResponse}
      />

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </div>
  );
}
