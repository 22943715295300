import { useState } from "react";
import { formatCurrency, parserSalary } from "../../RH/configs/SalaryConfig";

export default function PaidVacation({ onChange ,onAmountChange}) {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (value) => {
    const newValue = selectedValue === value ? null : value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const handleKeyDown = (event) => {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleRefundChange = (e) => {
    const value = e.target.value;
    let salary = parserSalary(formatCurrency(value));
    if (salary <= 99000) {
      setPaid(formatCurrency(value));
      onAmountChange(formatCurrency(value))
    }
  };

  const [paid, setPaid] = useState("0.00");

  return (
    <>
      <div className="flex justify-around">
        <div className="flex gap-2">
          <input
            type="radio"
            id="paid"
            value={3}
            checked={selectedValue === 3}
            onChange={() => handleChange(3)}
          />
          <label htmlFor="paid" className="m-s-b text-gry-100 text-fs-12">
            Vacaciones <span className="text-[#66CC9B]">Pagadas</span>
          </label>
        </div>

        <div className="flex gap-2">
          <input
            type="radio"
            id="normal"
            value={1}
            checked={selectedValue === 1}
            onChange={() => handleChange(1)}
          />
          <label htmlFor="normal" className="m-s-b text-gry-100 text-fs-12">
            Vacaciones <span className="text-[#15C0E6]">Normales</span>
          </label>
        </div>
      </div>

      {selectedValue == 3 && (
        <>
          <label className="text-fs-12 m-m text-gry-100">Monto</label>
          <div className="relative w-full h-[43px]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-[18px]">
              <span className="text-gry-100 text-fs-14">$</span>
            </div>

            <input
              type="text"
              onKeyDown={handleKeyDown}
              value={paid}
              onChange={handleRefundChange}
              maxLength={99000}
              className={`border-2 border-gry-30 rounded pl-[15px] pr-[15px] text-gry-100 text-fs-12 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 text-right`}
            />
          </div>
        </>
      )}
    </>
  );
}
