import moment from "moment";
import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { UseReservations } from "../../Reservations/context/ReservationsContext";

// BAR GRAPH
const BarChart = () => {
  const { dataReservationFilter } = UseReservations();

  // SELECT
  const monthsSelect = [
    { mount: "Enero", value: 1 },
    { mount: "Febrero", value: 2 },
    { mount: "Marzo", value: 3 },
    { mount: "Abril", value: 4 },
    { mount: "Mayo", value: 5 },
    { mount: "Junio", value: 6 },
    { mount: "Julio", value: 7 },
    { mount: "Agosto", value: 8 },
    { mount: "Septiembre", value: 9 },
    { mount: "Octubre", value: 10 },
    { mount: "Noviembre", value: 11 },
    { mount: "Diciembre", value: 12 },
  ];

  // VALUES FOR EACH MONTH
  const [months, setMonths] = useState([
    { month: "Jan", value: 1, total: 0 },
    { month: "Feb", value: 2, total: 0 },
    { month: "Mar", value: 3, total: 0 },
    { month: "Apr", value: 4, total: 0 },
    { month: "May", value: 5, total: 0 },
    { month: "Jun", value: 6, total: 0 },
    { month: "Jul", value: 7, total: 0 },
    { month: "Aug", value: 8, total: 0 },
    { month: "Sep", value: 9, total: 0 },
    { month: "Oct", value: 10, total: 0 },
    { month: "Nov", value: 11, total: 0 },
    { month: "Dec", value: 12, total: 0 },
  ]);

  useEffect(() => {
    if (dataReservationFilter) {
      setMonths((monthsValues) => {
        let newValue = monthsValues.map((month) => {
          let totalValue = dataReservationFilter.filter(
            (reservation) =>
              moment(reservation.reservationDate).month() + 1 === month.value
          ).length;
          return { ...month, total: totalValue };
        });

        return newValue;
      });
    }
  }, [dataReservationFilter]);

  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).month() + 1
  );

  const borderTop = [8, 8, 8, 8];

  // FUNCTION THAT HANDLES THE CHANGE OF THE SELECT
  const handleSelectChange = (event) => {
    let numberMonth = parseInt(event.target.value);
    setSelectedMonth(numberMonth);
  };

  // FUNCTION TO CONDITIONALLY GENERATE THE LABEL
  const getLabel = (month) =>
    selectedMonth === month
      ? {
          show: true,
          backgroundColor: "#000",
          position: "top",
          padding: [3, 7],
          borderRadius: 10,
          formatter: (params) => `{icon|} ${params.value}`,
          rich: {
            icon: {
              height: 20,
              width: 20,
              backgroundColor: {
                image: `${
                  import.meta.env.VITE_URL
                }icons/arrows/arrow-graph-g.svg`,
              },
            },
          },
          textStyle: {
            color: "#fff",
          },
        }
      : null;

  // FUNTION TO GENERATE THE itemStyle
  const getItemStyle = (month) => ({
    color: selectedMonth === month ? "#2B4998" : "#E0E9FF",
    borderRadius: borderTop,
  });

  // CHART SETTINGS
  const option = {
    xAxis: {
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      type: "category",
      data: months.map((m) => m.month), // EXTRACT THE NAMES OF THE MONTHS
    },
    yAxis: {
      type: "value",
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: true },
      splitLine: { show: false },
    },
    series: [
      {
        data: months.map((m) => ({
          value: m.total,
          itemStyle: getItemStyle(m.value),
          label: getLabel(m.value),
        })),
        type: "bar",
      },
    ],
  };

  return (
    <div className="w-1/2 shadow-3xl rounded-2xl px-10 py-6">
      <div className="flex justify-between">
        <div>
          <h2 className="text-fs-22 m-s-b">Estadistica</h2>
          <span className="text-fs-14 m-m text-gry-70">Reservaciones</span>
        </div>
        {/* SELECT */}
        <div>
          <select
            value={selectedMonth}
            onChange={handleSelectChange}
            className="focus:outline-none px-3 py-2 rounded-xl bg-[#F9FBFF] text-gry-100 m-m scroll-page-gry"
          >
            {monthsSelect.map((mount, index) => (
              <option value={mount.value} key={index}>
                {mount.mount}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* ReactECharts COMPONENT TO RENDER THE CHART */}
      <ReactECharts option={option} style={{ height: 300, width: "100%" }} />
    </div>
  );
};

export default BarChart;
