/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { UseReservations } from "../context/ReservationsContext";
import { ReservationListing } from "./ReservationListing";
import { TabTableReservation } from "./TabTableReservation";

export function ReservationFilterTable() {
  const { handleReservations } = UseReservations();

  useEffect(() => {
    handleReservations();
  }, []);

  return (
    <>
      <ReservationListing />

      <TabTableReservation />
    </>
  );
}
