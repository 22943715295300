// HOME
export const homeConfig = {
  bannerHome: {
    laptop: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    tablet: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    mobile: {
      width: 720,
      height: 928,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 918,
      maxHeight: 938,
    },
    maxImage: 5,
    nameRoute: "Banner Principal",
    nameComponent: "Banner Principal Home",
    nameJson: "bannerHome",
    apiReference: "",
  },

  offertsCarrousel: {
    laptop: {
      width: 1546,
      height: 800,
      minWidth: 1536,
      maxWidth: 1556,
      minHeight: 790,
      maxHeight: 810,
    },
    tablet: {
      width: 1546,
      height: 800,
      minWidth: 1536,
      maxWidth: 1556,
      minHeight: 790,
      maxHeight: 810,
    },
    mobile: {
      width: 720,
      height: 720,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 710,
      maxHeight: 730,
    },
    maxImage: 5,
    nameRoute: "Carrusel de ofertas",
    nameComponent: "Carrusel de ofertas Home",
    nameJson: "offertsCarrousel",
    apiReference: "",
  },

  offerBaggage: {
    laptop: {
      width: 756,
      height: 800,
      minWidth: 746,
      maxWidth: 766,
      minHeight: 790,
      maxHeight: 810,
    },
    tablet: {
      width: 756,
      height: 800,
      minWidth: 746,
      maxWidth: 766,
      minHeight: 790,
      maxHeight: 810,
    },
    mobile: {
      width: 720,
      height: 720,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 710,
      maxHeight: 730,
    },
    maxImage: 1,
    nameRoute: "Ofertas de viaje",
    nameJson: "offerBaggage",
    nameComponent: "Banner ofertas de viaje Home",
    apiReference: "",
  },

  offerHotel: {
    laptop: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    tablet: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    mobile: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    maxImage: 1,
    nameRoute: "Ofertas de hoteles",
    nameJson: "offerHotel",
    nameComponent: "Banner ofertas de hoteles",
    apiReference: "",
  },

  offerTour: {
    laptop: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    tablet: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    mobile: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    maxImage: 1,
    nameRoute: "Ofertas de tours",
    nameJson: "offerTour",
    nameComponent: "Banner ofertas de tours",
    apiReference: "",
  },

  bannerBlue: {
    laptop: {
      width: 1148,
      height: 500,
      minWidth: 1138,
      maxWidth: 1158,
      minHeight: 490,
      maxHeight: 510,
    },
    tablet: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    mobile: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    maxImage: 1,
    nameRoute: "Ofertas de ahora banner 1",
    nameJson: "bannerBlue",
    nameComponent: "Banner ofertas de ahora banner 1",
    apiReference: "",
  },

  bannerYellow: {
    laptop: {
      width: 1148,
      height: 500,
      minWidth: 1138,
      maxWidth: 1158,
      minHeight: 490,
      maxHeight: 510,
    },
    tablet: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    mobile: {
      width: 560,
      height: 560,
      minWidth: 550,
      maxWidth: 570,
      minHeight: 550,
      maxHeight: 570,
    },
    maxImage: 1,
    nameRoute: "Ofertas de ahora banner 2",
    nameJson: "bannerYellow",
    nameComponent: "Banner ofertas de ahora banner 2",
    apiReference: "",
  },

  hotelChains: {
    laptop: {
      width: 150,
      height: 100,
      minWidth: 130,
      maxWidth: 190,
      minHeight: 50,
      maxHeight: 160,
    },
    tablet: {
      width: 150,
      height: 100,
      minWidth: 130,
      maxWidth: 190,
      minHeight: 50,
      maxHeight: 160,
    },
    mobile: {
      width: 150,
      height: 100,
      minWidth: 130,
      maxWidth: 190,
      minHeight: 50,
      maxHeight: 160,
    },
    maxImage: 10,
    nameRoute: "Los mejores hoteles",
    nameJson: "hotelChains",
    nameComponent: "Carrusel de los mejores hoteles",
    apiReference: "",
  },
};
// HOME END

// HOTEL
export const hotelConfig = {
  bannerHotel: {
    laptop: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    tablet: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    mobile: {
      width: 720,
      height: 928,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 918,
      maxHeight: 938,
    },
    maxImage: 5,
    nameRoute: "Banner Principal",
    nameJson: "bannerHotel",
    nameComponent: "Banner Principal Hotel",
    apiReference: "",
  },

  bannerDestination: {
    laptop: {
      width: 1094,
      height: 470,
      minWidth: 1084,
      maxWidth: 1104,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 1094,
      height: 470,
      minWidth: 1084,
      maxWidth: 1104,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    maxImage: 1,
    nameRoute: "Banner Destino",
    nameComponent: "Banner Destino Hotel",
    nameJson: "bannerDestination",
    apiReference: "",
  },

  bannerBlue: {
    laptop: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    maxImage: 1,
    nameRoute: "Banner Azul",
    nameComponent: "Banner Azul Hotel",
    nameJson: "bannerBlue",
    apiReference: "",
  },

  bannerTour: {
    laptop: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    maxImage: 1,
    nameRoute: "Banner Tour",
    nameComponent: "Banner Tour Hotel",
    nameJson: "bannerTour",
    apiReference: "",
  },
};
// HOTEL END

// TOUR
export const tourConfig = {
  bannerTour: {
    laptop: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    tablet: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    mobile: {
      width: 720,
      height: 928,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 918,
      maxHeight: 938,
    },
    maxImage: 1,
    nameRoute: "Banner Principal",
    nameComponent: "Banner Principal Tour",
    nameJson: "bannerTour",
    apiReference: "",
  },

  bannerDestination: {
    laptop: {
      width: 1094,
      height: 470,
      minWidth: 1084,
      maxWidth: 1104,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 1094,
      height: 470,
      minWidth: 1084,
      maxWidth: 1104,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    maxImage: 1,
    nameRoute: "Banner Destino",
    nameComponent: "Banner Destino Tour",
    nameJson: "bannerDestination",
    apiReference: "",
  },

  bannerBlue: {
    laptop: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },

    maxImage: 1,
    nameRoute: "Banner Azul",
    nameComponent: "Banner Azul Tour",
    nameJson: "bannerBlue",
    apiReference: "",
  },

  bannerText: {
    laptop: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },

    maxImage: 1,
    nameRoute: "Banner Tour",
    nameComponent: "Banner Tour Tour",
    nameJson: "bannerText",
    apiReference: "",
  },

  bannerLocation: {
    laptop: {
      width: 2220,
      height: 816,
      minWidth: 2210,
      maxWidth: 2230,
      minHeight: 806,
      maxHeight: 826,
    },
    tablet: {
      width: 2220,
      height: 816,
      minWidth: 2210,
      maxWidth: 2230,
      minHeight: 806,
      maxHeight: 826,
    },
    mobile: {
      width: 720,
      height: 720,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 710,
      maxHeight: 730,
    },

    maxImage: 1,
    nameRoute: "Banner Bottom",
    nameComponent: "Banner Bottom Tour",
    nameJson: "bannerLocation",
    apiReference: "",
  },
};
// TOUR END

// TRANSPORT
export const transportConfig = {
  bannerTransportHome: {
    laptop: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    tablet: {
      width: 2732,
      height: 960,
      minWidth: 2722,
      maxWidth: 2742,
      minHeight: 950,
      maxHeight: 970,
    },

    mobile: {
      width: 720,
      height: 928,
      minWidth: 710,
      maxWidth: 730,
      minHeight: 918,
      maxHeight: 938,
    },
    maxImage: 1,
    nameRoute: "Banner Principal",
    nameComponent: "Banner Principal Transporte",
    nameJson: "bannerTransportHome",
    apiReference: "",
  },

  bannerCar: {
    laptop: {
      width: 547,
      height: 470,
      minWidth: 537,
      maxWidth: 557,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 547,
      height: 470,
      minWidth: 537,
      maxWidth: 557,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    maxImage: 1,
    nameRoute: "Banner Car",
    nameComponent: "Banner Car Transporte",
    nameJson: "bannerCar",
    apiReference: "",
  },

  bannerBlue: {
    laptop: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },

    maxImage: 1,
    nameRoute: "Banner Azul",
    nameComponent: "Banner Azul Transporte",
    nameJson: "bannerBlue",
    apiReference: "",
  },

  bannerTransport: {
    laptop: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    tablet: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },
    mobile: {
      width: 530,
      height: 470,
      minWidth: 520,
      maxWidth: 540,
      minHeight: 460,
      maxHeight: 480,
    },

    maxImage: 1,
    nameRoute: "Banner Transporte",
    nameComponent: "Banner Transporte",
    nameJson: "bannerTransport",
    apiReference: "",
  },
};
// TRANSPORT END
