import { useEffect, useState } from "react";

export default function Pagination({ count, pageChange, onChange }) {
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  useEffect(() => {
    const generatePages = () => {
      const totalButtons = 5;
      let pages = [];

      if (count <= totalButtons) {
        pages = Array.from({ length: count }, (_, i) => i + 1);
      } else {
        const startPage = Math.max(1, pageChange - 2);
        const endPage = Math.min(count, pageChange + 2);

        if (startPage > 2) pages.push(1, "prev-dots");
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
        if (endPage < count - 1) pages.push("next-dots", count);
      }

      return pages;
    };

    setArrOfCurrButtons(generatePages());
  }, [pageChange, count]);

  // DOTS FUNCIONALITY
  const handleDotsClick = (direction) => {
    if (direction === "prev-dots") {
      onChange(Math.max(1, pageChange - 5));
    } else if (direction === "next-dots") {
      onChange(Math.min(count, pageChange + 5));
    }
  };

  return (
    <div className="flex items-center justify-between bg-white w-fit">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <nav
          className="isolate inline-flex -space-x-px rounded-md gap-[8px]"
          aria-label="Pagination"
        >
          {/* Botón anterior */}
          <button
            disabled={pageChange === 1}
            onClick={() => onChange(pageChange - 1)}
            className={`${
              pageChange === 1
                ? "!bg-gry-50 !text-gry-70 cursor-not-allowed"
                : "bg-gry-50 text-gry-100 cursor-pointer"
            } inline-flex items-center rounded-full h-[28px] w-[28px] justify-center`}
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/arrows/left-100.svg`}
              width={7}
              height={12}
            />
          </button>

          {arrOfCurrButtons.map((page, index) => (
            <button
              key={index}
              onClick={() =>
                typeof page === "number"
                  ? onChange(page)
                  : handleDotsClick(page)
              }
              className={`${
                pageChange === page ? "!bg-or-100 !text-white" : ""
              } inline-flex items-center rounded-full h-[28px] w-[28px] justify-center text-sm font-semibold text-gry-100 ${
                typeof page === "string" ? "cursor-pointer" : "cursor-pointer"
              }`}
            >
              {page === "prev-dots" || page === "next-dots" ? "..." : page}
            </button>
          ))}

          {/* Botón siguiente */}
          <button
            disabled={pageChange === count}
            onClick={() => onChange(pageChange + 1)}
            className={`${
              pageChange === count
                ? "!bg-gry-30 !text-gry-70 cursor-not-allowed"
                : "bg-gry-50 text-gry-100 cursor-pointer"
            } inline-flex items-center rounded-full h-[28px] w-[28px] justify-center`}
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/arrows/right-100.svg`}
              width={7}
              height={12}
            />
          </button>
        </nav>
      </div>
    </div>
  );
}
