import { useContext } from "react";

import { StepCard } from "./StepCard";
import CarrouselSteps from "./CarrouselSteps";
import { MarketingContext } from "../../../context/MarketingContext";

const stepsDataItinerary = [
  {
    number: 1,
    stepType: "stepOne",
    title: "Arma tu paquete turístico",
    description: "Elige tu transporte, alojamiento y actividad favorita.",
  },
  {
    number: 2,
    stepType: "stepTwo",
    title: "Agrega los detalles de reservación",
    description: "Proporciona tus datos de contacto, fechas y preferencias.",
  },
  {
    number: 3,
    stepType: "stepThree",
    title: "Realiza el pago",
    description: "Elige tu método de pago y confirma tu reservación.",
  },
  {
    number: 4,
    stepType: "stepFour",
    title: "Descarga tu itinerario",
    description:
      "Al finalizar tu compra, recibirás un recibo de itinerario descargable en tu correo electrónico.",
  },
];

export function StepsCards() {
  const { responsiveView } = useContext(MarketingContext);
  const activeStep = 1;
  return (
    <>
      <div
        className={`flex items-stretch mt-2  ${
          responsiveView.value === "laptop" ? "flex-col" : "hidden"
        }`}
      >
        <div className="flex flex-wrap gap-x-1 m-b text-fs-28 justify-start lg:justify-start md:justify-center lg:w-[73%] xl:w-[63%] 2xl:w-[50%]">
          <p className="m-0 text-wrap">Diseña el</p>
          <p className="m-0 text-bl-100 text-nowrap">itinerario perfecto</p>
          <p className="m-0 text-wrap">para tu viaje</p>
        </div>

        <div className="grid grid-cols-2 mt-6 gap-x-[31px] gap-y-[40px] w-max ">
          {stepsDataItinerary.map((step, index) => (
            <div key={index} className="w-[200px] h-[200px]">
              <StepCard
                activeStep={activeStep}
                number={step.number}
                title={step.title}
                description={step.description}
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        <CarrouselSteps activeStep={activeStep} stepData={stepsDataItinerary} />
      </div>
    </>
  );
}
