import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendForgotPasswordLink } from "../../api/api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);

    // console.log(email);
    // return;
    try {
      const success = await sendForgotPasswordLink(email);
      if (success) {
        setIsLinkSent(true);
      } else {
        setError(
          "There was an issue sending the reset link. Please try again."
        );
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="h-screen flex justify-center items-center"
        style={{
          backgroundImage:
            "url('https://image.cdn2.seaart.ai/2023-07-18/46388951261253/a4fe07580489c6351a2f089609beb657ebafa944_high.webp')",
        }}
      >
        <div className="w-[400px] flex flex-col items-center gap-4 border-4 border-gray-100 p-8 rounded-[42px] shadow-3xl bg-white">
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/royal/principal-logo.svg`}
            alt="logo stay-wuw"
            className="w-[200px] h-[93px] mb-[10px]"
          />

          {isLinkSent ? (
            <div className="text-center">
              <p className=" text-fs-16 m-s-b mb-6">
                El enlace para restablecer la contraseña ha sido enviado a su
                correo electrónico.
              </p>
              <button
                className="text-white m-b text-fs-12 bg-bl-100 w-full rounded-lg py-3 hover:bg-bl-110"
                onClick={() => navigate("/login")}
              >
                Volver a iniciar sesión
              </button>
            </div>
          ) : (
            <>
              <span className="m-s-b text-fs-20 text-center">
                Ingresa tu Correo de recuperación
              </span>

              <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <label className="m-b text-fs-12">Email:</label>
                  <input
                    placeholder="ejemplo@staywuw.com"
                    className="appearance-none outline-none border-2 border-gry-30 rounded-lg h-[45px] pl-5 w-full pr-8"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isLoading}
                  />
                  {error && (
                    <span className="text-red-500 text-fs-12">{error}</span>
                  )}
                </div>
                <button
                  className="text-white m-b text-fs-12 bg-bl-100 w-full rounded-lg py-3 hover:bg-bl-110"
                  type="submit"
                  disabled={isLoading} // Deshabilitar el botón mientras se envía la petición
                >
                  {isLoading ? "Enviando..." : "Enviar"}{" "}
                  {/* Cambiar el texto del botón */}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
