/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";

import { MarketingContext } from "../../context/MarketingContext";
import { DropzoneFile } from "../../../../components/dropzone/Dropzone";

export function ImagesBanners() {
  const { totalFiles, configDropzone, responsiveView } =
    useContext(MarketingContext);

  return (
    <>
      <h4 className="m-b text-fs-16 mt-2">{configDropzone.nameComponent}</h4>

      <div className="flex flex-col gap-3">
        <span className="text-fs-12 m-b text-gry-100">
          Imágenes ({totalFiles}/{configDropzone.maxImage})
        </span>

        {/* DROPZONE */}
        <DropzoneFile />
      </div>

      {/* IMAGE SIZE */}
      <span className="m-m text-fs-12 text-gry-100">{`Tamaño: ${
        configDropzone[responsiveView.value].width
      }, ${configDropzone[responsiveView.value].height} px`}</span>
    </>
  );
}
