/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

import { BannerHome } from "../BannerHome";
import { PopularState } from "../others/PopularState";
import { ShuffleHotel } from "../others/ShuffleHotel";
import { GetImagesByView } from "../../../api/MarketingApi";
import { TransportBanner } from "../others/TransportBanner";
import { CartTourOptions } from "../others/CartTourOptions";
// import { JsonImagesHotel } from "../../../configs/JsonImages";
import { BannerHomeKnowMore } from "../others/BannerHomeKnowMore";
import { MarketingContext } from "../../../context/MarketingContext";
import BannerSearch from "../../../../../components/bannerHomes/BannerSearch";
import { ContainerViewsHome } from "../../../../../components/containers/ContainerViewsHome";

export function HotelLayout() {
  const { responsiveView, jonImagesActualView, setJonImagesActualView, routActual, languageSelected } =
    useContext(MarketingContext);

  useEffect(() => {
    if (routActual) {
      // VALID IF LENGTH ARY IS EMPTY FROM GET IMAGE
      if (jonImagesActualView[languageSelected] == null ) {
        GetImagesByView(routActual, languageSelected).then((result) => {
          if (result) {
            setJonImagesActualView((images) => {
              return { ...images, [languageSelected]: result };
            });
          }
        });
      }
    }
  }, [routActual, languageSelected]);

  return (
    jonImagesActualView[languageSelected]  && (
      <div
        className={`mx-auto ${
          responsiveView.width !== "all" && responsiveView.width
        }`}
      >
        <BannerSearch />

        <ContainerViewsHome>
          <BannerHome />
          <PopularState />
          <ShuffleHotel />
          <TransportBanner />
          <CartTourOptions />
          <BannerHomeKnowMore />
        </ContainerViewsHome>
      </div>
    )
  );
}
