import { useContext, useState } from "react";
import { NotificationContext } from "../../context/NotificationContext";

export const Tooltip = ({ text, children }) => {
  const { isOpen } = useContext(NotificationContext);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={`relative inline-block cursor-pointer w-full`}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>

      {showTooltip && (
        <div
          className={`bg-black text-white text-xs rounded py-1 px-2 left-[-10px] absolute z-[20] ${
            isOpen && "hidden"
          }`}
        >
          {text}
        </div>
      )}
    </div>
  );
};
