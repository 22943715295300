/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "../../configs/login/UserContext";

export const NavigationURL = [
  "login",
  "users",
  "dashboard",
  "reservations",
  "rh",
  "vacations",
  "marketing",
  "revenue",
  "history",
  "settings",
  "support",
  "configurations",
];

export default function NavigationMenu() {
  const [activeRouter, setActiveRouter] = useState(null);
  const { token } = useUser();

  let actualRote = useLocation();

  useEffect(() => {
    const path = window.location.pathname;

    let actualRouter = null;
    let pathRouter = path.split(/[/-]/);
    
    NavigationURL.map((url) => {
      if (pathRouter.includes(url)) {
        actualRouter = pathRouter.filter((value) => value === url);
      }
    });
    

    if (path === "/") {
      if (token) {
        setActiveRouter(null);
      } else {
        setActiveRouter("login");
      }
    }
    

    if (path === `/reservations`) {
      setActiveRouter(`reservations`);
    }

    if (actualRouter != null) {
      setActiveRouter(actualRouter[0]);
    }
  }, [actualRote]);

  return activeRouter;
}
