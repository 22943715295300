/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../../others/BtnEdit";
import { SetConfigDropzone } from "../../others/setConfigDropZone";
import { MarketingContext } from "../../../context/MarketingContext";
import { useUser } from "../../../../../configs/login/UserContext";
import AlertPermissionsDeclined from "../../../../../configs/Permissions/AlertPermissionsDeclined";
import { HasPermissionsUser } from "../../../../../configs/Permissions/PermissionsUser";

export default function BannerExclusiveDiscounts() {
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerDiscounts, setBannerDiscounts] = useState(null);
  const [bannerDiscountsSecond, setBannerDiscountsSecond] = useState(null);
  const [isOfferHotel, setIsOfferHotel] = useState(false);
  const [isOfferTour, setIsOfferTour] = useState(false);

  const {
    responsiveView,
    setIsOpenEdit,
    isOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
    totalFiles,
    jonImagesActualView,
    languageSelected,
  } = useContext(MarketingContext);

  useEffect(() => {
    if (jonImagesActualView[languageSelected]) {
      setBannerDiscounts(
        jonImagesActualView[languageSelected].offerHotel[responsiveView.value]
      );
      setBannerDiscountsSecond(
        jonImagesActualView[languageSelected].offerTour[responsiveView.value]
      );
    }
  }, [totalFiles, responsiveView, routActual, languageSelected]);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (jsonConfig) => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpenEdit(true);

      let configDropzone = SetConfigDropzone(routActual, jsonConfig);

      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  useEffect(() => {
    if (selectedBanner === "offerHotel" && currentBanner === "offerHotel") {
      setIsOfferHotel(true);
    } else {
      setIsOfferHotel(false);
    }

    if (selectedBanner === "offerTour" && currentBanner === "offerTour") {
      setIsOfferTour(true);
    } else {
      setIsOfferTour(false);
    }
  }, [selectedBanner, currentBanner]);

  return (
    <>
      <div
        className={`flex w-full 2xl:gap-[1vw] gap-[10px] my-[32px] justify-between ${
          responsiveView.value === "tablet" && "!h-[252px]"
        } ${
          responsiveView.value === "mobile"
            ? "flex-col h-auto"
            : isOpenEdit
            ? "h-[280px]"
            : "h-[330px]"
        }`}
      >
        {/* BANNERS DESKTOP */}
        <div
          className={`relative 2xl:w-fit 2xl:gap-[2vw] w-[570px] flex gap-[10px] ${
            responsiveView.value === "tablet" && "hidden"
          } ${responsiveView.value === "mobile" && " hidden"}`}
        >
          <div
            onClick={() => handleClick("offerHotel")}
            className={`shadow-3xl rounded-lg flex justify-center hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
              isOpenEdit ? "w-[280px]" : "w-[350px]"
            } ${isOfferHotel && "border-4  border-or-100 rounded-xl"}`}
          >
            <img
              src={
                bannerDiscounts?.urlImage
                  ? bannerDiscounts?.urlImage
                  : bannerDiscounts
              }
              className={`object-cover w-full h-full rounded-lg select-none aspect-square group-hover:brightness-75 ${
                isOfferHotel && "brightness-75"
              }`}
              alt="banner ofertas de hoteles"
            />
            <BtnEdit />
          </div>

          <div
            onClick={() => handleClick("offerTour")}
            className={`shadow-3xl rounded-lg flex justify-center hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
              isOpenEdit ? "w-[280px]" : "w-[350px]"
            } ${isOfferTour && " border-4 border-or-100 rounded-xl"}`}
          >
            <img
              src={
                bannerDiscountsSecond?.urlImage
                  ? bannerDiscountsSecond?.urlImage
                  : bannerDiscountsSecond
              }
              className={`object-cover w-full h-full rounded-lg select-none aspect-square group-hover:brightness-75 ${
                isOfferTour && "brightness-75"
              }`}
              alt="banner ofertas de tour"
            />
            <BtnEdit />
          </div>
        </div>

        {/* SWIPER MOBILE */}
        <div
          className={`relative w-[35%] hidden max-sm:block justify-center ${
            responsiveView.value === "tablet" && "!flex"
          } ${responsiveView.value === "mobile" && " !flex !w-full h-[410px]"}`}
        >
          <Swiper
            slidesPerView={1}
            className="h-full rounded-lg !z-0 !static"
            initialSlide={0}
          >
            <SwiperSlide className="!flex !justify-center !bg-white">
              <div
                onClick={() => handleClick("offerHotel")}
                className={`relative w-full flex justify-center mb-5 hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                  isOfferHotel && "border-4 border-or-100 rounded-xl"
                }`}
              >
                <img
                  src={
                    bannerDiscounts?.urlImage
                      ? bannerDiscounts?.urlImage
                      : bannerDiscounts
                  }
                  className={`w-full h-full rounded-lg object-cover select-none group-hover:brightness-75 ${
                    isOfferHotel && "brightness-75"
                  }`}
                  alt="banner offers hotel"
                />
                <BtnEdit />
              </div>
            </SwiperSlide>

            <SwiperSlide className="!flex !justify-center !bg-white">
              <div
                onClick={() => handleClick("offerTour")}
                className={`relative w-full flex justify-center mb-5 hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                  isOfferTour && "border-4 border-or-100 rounded-xl"
                }`}
              >
                <img
                  src={
                    bannerDiscountsSecond?.urlImage
                      ? bannerDiscountsSecond?.urlImage
                      : bannerDiscountsSecond
                  }
                  className={`w-full h-full rounded-lg select-none object-cover group-hover:brightness-75 ${
                    isOfferTour && "brightness-75"
                  }`}
                  alt="banner offer tour"
                />
                <BtnEdit />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* END SWIPER MOBILE */}

        <div
          className={`shadow-3xl rounded-lg ${
            responsiveView.value === "tablet" && " h-[93%] !w-[63.6%]"
          }${
            responsiveView.value === "mobile"
              ? " !w-full h-[280px]"
              : " w-[54.6%]"
          }`}
        >
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/img/crm/Nick-home-offers.jpg`}
            alt="banner-hotel-offers"
            className="w-full h-full rounded-lg cursor-not-allowed select-none"
          />
        </div>
      </div>

      <div className="m-m text-fs-12 text-gry-100 flex justify-center gap-1 mb-[80px] cursor-default">
        <span>
          **Precios y descuentos válidos en servicios seleccionados y sujetos a
          disponibilidad al momento de la compra. Consulta términos y
          condiciones haciendo clic{" "}
          <p className="text- m-s-b text-black underline decoration-solid m-0 cursor-default">
            Aquí
          </p>
        </span>
      </div>
      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
