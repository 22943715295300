/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageUser, setImageUser] = useState(null);
  const [samePassword, setSamePassword] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isValidationPassword, setIsValidationPassword] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const storedUser = localStorage.getItem("user");
      const storedToken = localStorage.getItem("token");
      if (storedUser && storedToken) {
        setUser(JSON.parse(storedUser));
        setToken(storedToken);
      }
      setLoading(false);
    }, 800);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (progress > 70) {
      setIsValidationPassword(true);
    } else {
      setIsValidationPassword(false);
    }
  }, [progress]);

  const updateUser = (user) => {
    setUser(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  };

  const updateToken = (token) => {
    setToken(token);
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  };

  // Function to update user role and type dynamically
  const updatePermissions = (newRole, newUserType) => {
    if (user) {
      const updatedUser = { ...user, role: newRole, userType: newUserType };
      updateUser(updatedUser);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: updateUser,
        token,
        setToken: updateToken,
        loading,
        updatePermissions,
        imageUser, setImageUser,
        samePassword, setSamePassword,
        isValidationPassword, setIsValidationPassword,
        progress, setProgress
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
