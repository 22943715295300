import { createContext, useState, useEffect } from "react";

import { FetchUserList, GetRoleList, GetUserPermissions } from "../api/api";
import { ImageModification } from "../../../components/imageGet/ImageGet";
import {
  FilterActiveStatus,
  FiltersUsersInput,
  FilterUsersByRole,
} from "../config/FiltersUsers";

const UsersContext = createContext();

const UsersProviderContext = ({ children }) => {
  const [dataUsers, setDataUsers] = useState([]);
  const [inputFilter, setInputFilter] = useState("");
  const [rolesSelected, setRolesSelected] = useState([]);
  const [dataUserFilter, setDataUserFilter] = useState([]);
  const [filterStatus, setFilterStatus] = useState("ACTIVO");
  const [isFocusedNewPassword, setIsFocusedNewPassword] = useState(false);
  const [rolesLists, setRolesLists] = useState([]);
  const [loaderUsers, setLoaderUsers] = useState(true);

  const getRoleLists = async () => {
    try {
      const roles = await GetRoleList();
      setRolesLists(roles.roleList);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const refreshUserList = async () => {
    setLoaderUsers(true);
    try {
      const data = await FetchUserList();
      const response = await ImageModification(data.userInfo);
      setDataUsers(response);
      setLoaderUsers(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoaderUsers(false);
      throw error;
    }
  };

  const handleFocus = () => {
    setIsFocusedNewPassword(true);
  };

  const handleBlur = () => {
    setIsFocusedNewPassword(false);
  };

  useEffect(() => {
    refreshUserList();
    // getRoleLists();
  }, []);

  useEffect(() => {
    if (dataUsers) {
      let dataFilter = [...dataUsers];

      if (inputFilter !== "") {
        dataFilter = FiltersUsersInput(dataFilter, inputFilter);
      } else {
        if (filterStatus) {
          dataFilter = FilterActiveStatus(dataFilter, filterStatus);
        } else {
          dataFilter = FilterActiveStatus(dataFilter, "ACTIVO");
        }
      }

      if (rolesSelected.length > 0) {
        dataFilter = FilterUsersByRole(dataFilter, rolesSelected);
      }

      setDataUserFilter(dataFilter);
    }
  }, [inputFilter, dataUsers, filterStatus, rolesSelected]);

  const getAllPermissions = async (id) => {
    try {
      const allPermission = await GetUserPermissions(id);
      return allPermission
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <UsersContext.Provider
      value={{
        handleBlur,
        rolesLists,
        handleFocus,
        inputFilter,
        loaderUsers,
        setDataUsers,
        filterStatus,
        rolesSelected,
        setInputFilter,
        dataUserFilter,
        setFilterStatus,
        refreshUserList,
        setRolesSelected,
        setDataUserFilter,
        isFocusedNewPassword,
        setIsFocusedNewPassword,
        getAllPermissions,
        getRoleLists
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { UsersContext, UsersProviderContext };
