import { useContext } from "react";
import { StepsBannerStyle } from "../../../configs/StepsBannerStyle";
import { MarketingContext } from "../../../context/MarketingContext";

const originDashedOne = [
  { key: 0, value: false },
  { key: 1, value: false },
  { key: 2, value: false },
  { key: 3, value: false },
  { key: 4, value: false },
  { key: 5, value: false },
  { key: 6, value: false },
  { key: 7, value: false },
];

const originDashedTwo = [
  { key: 0, value: false },
  { key: 1, value: false },
  { key: 2, value: false },
  { key: 3, value: false },
  { key: 4, value: false },
  { key: 5, value: false },
  { key: 6, value: false },
  { key: 7, value: false },
];

export default function TipsBooking() {
  const { responsiveView } = useContext(MarketingContext);

  return (
    <div className="flex flex-col items-center my-32">
      <h3 className="m-b text-fs-24 max-sm:text-center">
        Reserva tu traslado en 3 pasos
      </h3>

      <div
        className={`flex gap-[16px] overflow-x-clip scroll-page-gry max-[991px]:gap-0 items-center ${
          responsiveView.value === "mobile" && "flex-col"
        }`}
      >
        {/* LOCATION */}
        <div className="flex flex-col p-[20px] items-center  w-[165px]">
          <div className="p-[4px] bg-white shadow-3xl mb-[16px]">
            <div
              className={`bg-gry-50 w-[80px] h-[80px] flex items-center justify-center border-2 border-white rounded-lg`}
            >
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/location/location-on-bl.svg`}
                alt="icon-location"
                width={24}
                height={30}
                className="w-[24px] h-[30px]"
              />
            </div>
          </div>

          <span className="mb-[8px] text-fs-14px m-s-b text-nowrap">
            Elige la ubicación
          </span>
          <span className="text-gry-100 text-fs-12 text-center">
            Tu estancia perfecta comienza con la ubicación ideal.
          </span>
        </div>

        {/* LINE TOP DASHED */}
        <div
          className={`flex w-[116px] relative justify-around ${
            responsiveView.value !== "mobile" && "mb-[75px]"
          } ${
            responsiveView.value == "mobile" &&
            "h-[8rem] items-center rotate-[90deg]"
          }`}
        >
          {originDashedOne.map((dashed, index) => (
            <div
              key={index}
              className={`${
                dashed.key === 0 || dashed.key === 7 ? "w-[7px]" : "w-[10px]"
              } h-[3px] relative ${
                dashed.value ? "bg-or-100" : "bg-gry-50"
              } ${StepsBannerStyle(dashed.key, 1)}`}
            />
          ))}
        </div>

        {/* CALENDAR */}
        <div className="flex flex-col p-[20px] items-center w-[165px]">
          <div className="p-[4px] bg-white mb-[16px] shadow-3xl">
            <div
              className={`bg-or-100 w-[80px] h-[80px] flex items-center justify-center border-2 border-white rounded-lg`}
            >
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/calendar/calendar-done-w.svg`}
                alt="icon-location"
                width={27}
                height={30}
                className="w-[27px] h-[30px]"
              />
            </div>
          </div>

          <span className="mb-[8px] text-fs-14px m-s-b text-nowrap">
            Fecha de recolección
          </span>
          <span className="text-gry-100 text-fs-12 text-center">
            Selecciona el día que más te convenga.
          </span>
        </div>

        {/* LINE BOTTOM DASHED */}
        <div
          className={`flex w-[116px] relative justify-around ${
            responsiveView.value !== "mobile" && "mb-[75px]"
          } ${
            responsiveView.value == "mobile" &&
            "h-[8rem] items-center rotate-[90deg]"
          }`}
        >
          {originDashedTwo.map((dashed, index) => (
            <div
              key={index}
              className={`!w-[10px] ${
                dashed.key === 0 || dashed.key === 7 ? "w-[7px]" : "w-[10px]"
              } h-[3px] relative ${
                dashed.value === true ? "bg-or-100" : "bg-gry-50"
              } ${StepsBannerStyle(dashed.key, 2)}`}
            />
          ))}
        </div>

        {/* AIRPORT */}
        <div className="flex flex-col p-[20px] items-center w-[165px]">
          <div className="p-[4px] bg-white mb-[16px] shadow-3xl">
            <div
              className={`bg-gry-50 w-[80px] h-[80px] flex items-center justify-center border-2 border-white rounded-lg`}
            >
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/general/airport-bl.svg`}
                alt="icon-location"
                width={33}
                height={21}
                className={`w-[33px] h-[21px]`}
              />
            </div>
          </div>

          <span className="mb-[8px] text-fs-14px m-s-b text-nowrap">
            Reserva tu coche
          </span>
          <span className="text-gry-100 text-fs-12 text-center">
            Elige y reserva el auto perfecto para ti
          </span>
        </div>
      </div>
    </div>
  );
}
