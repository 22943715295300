export const activityDetailsTest = {
  hotel: [
    {
      name: "Oasis Smart cancún",
      reservationNO: "123456789",
      provider: "HotelBeds",
      address: "Boulevard Kukulkan Km 16.5",
      checkIn: "Lunes 22/07/2024 | 1:27pm",
      checkOut: "Viernes 26/07/2024 | 1:27pm",
      amenity: "All",
      rooms: 2,
      titular: "Julio",
      other: "2.1 personas",
      totalPrice: 1800.12,
    },
    {
      name: "Oasis Smart cancún",
      reservationNO: "123456789",
      provider: "HotelBeds",
      address: "Boulevard Kukulkan Km 16.5",
      checkIn: "Lunes 22/07/2024 | 1:27pm",
      checkOut: "Viernes 26/07/2024 | 1:27pm",
      amenity: "All",
      rooms: 2,
      titular: "Julio",
      other: "2.1 personas",
      totalPrice: 1800.12,
    },
  ],
  tour: [],
  transport: [],
};

export const cardReservations = [
  {
    name: "Total de reservas",
    isSelected: true,
    options: [
      { label: "Total de reservas", value: "all" },
      { label: "Hotel", value: "hotel" },
      { label: "Tour", value: "tour" },
      { label: "Transporte", value: "transport" },
    ],
    icon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/general/contract.svg`,
    number: 2,
    bgColor: "bg-bl-20",
  },
  {
    name: "Confirmadas",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/calendar/calendar-conf-grn.svg`,
    number: 2,
    bgColor: "bg-grn-20",
  },
  {
    name: "Pendientes",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/calendar/calendar-prog-yll.svg`,
    number: 2,
    bgColor: "bg-or-20",
  },
  {
    name: "Canceladas",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/calendar/calendar-canc-red.svg`,
    number: 2,
    bgColor: "bg-red-20",
  },
];
