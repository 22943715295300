export const SetFilesJson = (
  configDropzone,
  view,
  fileData,
  jonImagesActualView,
  languageSelected
) => {
  let imageJson = jonImagesActualView;
  if (
    Array.isArray(imageJson[languageSelected][configDropzone.nameJson][view])
  ) {
    imageJson[languageSelected][configDropzone.nameJson][view] = [
      ...imageJson[languageSelected][configDropzone.nameJson][view],
      fileData,
    ];
  } else {
    imageJson[languageSelected][configDropzone.nameJson][view] = fileData;
  }
  return imageJson[languageSelected][configDropzone.nameJson][view];
};

export const RemoveFileOnJson = (
  configDropzone,
  view,
  file,
  jonImagesActualView,
  languageSelected
) => {
  let imageJson = jonImagesActualView;

  if (
    Array.isArray(imageJson[languageSelected][configDropzone.nameJson][view])
  ) {
    imageJson[languageSelected][configDropzone.nameJson][view] = imageJson[
      languageSelected
    ][configDropzone.nameJson][view].filter((fileUrl) => fileUrl !== file);
  } else {
    imageJson[languageSelected][configDropzone.nameJson][view] = null;
  }
  return imageJson[languageSelected][configDropzone.nameJson][view];
};

export const ValidationJson = (
  imageJson,
  configDropzone,
  view,
  languageSelected
) => {
  let jsonImages = null;
  if (imageJson[languageSelected][configDropzone.nameJson] === undefined) {
    jsonImages =
      imageJson[languageSelected].OfferNow[configDropzone.nameJson][view];
  } else {
    jsonImages = imageJson[languageSelected][configDropzone.nameJson][view];
  }
  return jsonImages;
};
