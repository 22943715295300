export function FiltersUsersInput(users, inputValue) {
  const normalizedInputValue = inputValue.toLowerCase();

  let filterData = users.filter(
    (userInfo) =>
      (userInfo.name &&
        userInfo.name.toLowerCase().includes(normalizedInputValue)) ||
      (userInfo.status &&
        userInfo.status.toLowerCase().includes(normalizedInputValue)) ||
      (userInfo.user_role_description &&
        userInfo.user_role_description
          .toLowerCase()
          .includes(normalizedInputValue)) ||
      (userInfo?.user_name &&
        userInfo?.user_name.toLowerCase().includes(normalizedInputValue)) ||
      (userInfo.ext &&
        userInfo.ext.toLowerCase().includes(normalizedInputValue)) ||
      (userInfo.email &&
        userInfo.email.toLowerCase().includes(normalizedInputValue))
  );

  return filterData;
}

export function FilterActiveStatus(users, status) {
  return users.filter((user) => user.status === status);
}

export function FilterRolesStatus(users, status) {
  if (status === "OTROS") {
    status = null;
  }
  return users.filter((user) => user?.contract_type === status);
}

export function FilterUsersVacations(users) {
  return users.filter((user) => user?.vacation === true);
}

export function FilterUsersByRole(users, status) {
  let filterUsers = users.filter((user) => status.includes(user.role_id));
  return filterUsers;
}

export function FiltersTotals(
  users,
  setAllTotal,
  setTemporaryTotal,
  setVacationsTotal,
  setIndefiniteTotal,
  setWithoutContract
) {
  setAllTotal(users.length);
  setTemporaryTotal(FilterRolesStatus(users, "TEMPORAL").length);
  setVacationsTotal(FilterUsersVacations(users, "VACATIONS").length);
  setIndefiniteTotal(FilterRolesStatus(users, "INDEFINIDO").length);
  setWithoutContract(FilterRolesStatus(users, "OTROS").length);
}
