
import axiosWithInterceptor from "../../../configs/Instance/axiosConfig";

export const FetchRHList = async () => {
  try {
    const response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/userList`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export async function FindUser(id) {
  try {
    const response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/findUser/${id}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function ValidationUser(id){
  try {
    const response = await axiosWithInterceptor(`${import.meta.env.VITE_API_URL_STAYWUW}/findUser/${id}?isActive=true`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
} 

export async function FindUserDocs(id) {
  try {
    const response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/UserDocument/${id}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function SaveDocument(documents) {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/saveUserDocument`,
      documents
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function AutoCompleteInput(input) {
  try {
    const response = await axiosWithInterceptor.get(
      `${
        import.meta.env.VITE_API_URL_STAYWUW
      }/autocomplete/banks?query=${input}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
