/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

import ChainsHome from "../ChainsHome/ChainsHome";
import EnjoyStayHome from "../others/EnjoyStayHome";
import { GetImagesByView } from "../../../api/MarketingApi";
import ExploreActivitiesHome from "../others/ExploreActivitiesHome";
import BannerHomeOffers from "../BannerHomeOffers/BannerHomeOffers";
import { BannerOffersNow } from "../BannerOffersNow/BannerOffersNow";
import { MarketingContext } from "../../../context/MarketingContext";
import { BannerStepsItinerary } from "../others/BannerStepsItinerary";
import PopularDestinationsHome from "../others/PopularDestinationsHome";
import { TransportBanner } from "../../HotelView/others/TransportBanner";
import BannerSearch from "../../../../../components/bannerHomes/BannerSearch";
// import { UserExperienceBanner } from "../others/UserExperience/UserExperienceBanner";
import BannerExclusiveDiscounts from "../BannerExclusiveDiscounts/BannerExclusiveDiscounts";
import { ContainerViewsHome } from "../../../../../components/containers/ContainerViewsHome";
import { BannerDiscoverPossibilities } from "../../TransportView/others/BannerDiscoverPossibilities";

export function HomeLayout() {
  
  const {
    routActual,
    jonImagesActualView,
    responsiveView,
    setJonImagesActualView,
    languageSelected,
  } = useContext(MarketingContext);
  
  useEffect(() => {
    
    if (routActual) {
      // VALID IF LENGTH ARY IS EMPTY FROM GET IMAGE
      if (jonImagesActualView[languageSelected] == null ) {
        GetImagesByView(routActual, languageSelected).then((result) => {
          if (result) {
            setJonImagesActualView((images) => {
              return { ...images, [languageSelected]: result };
            });
          }
        });
      }
    }
  }, [routActual, languageSelected]);
  
  // console.log(languageSelected, jonImagesActualView[languageSelected]);

  return (
    jonImagesActualView[languageSelected] && (
      <div
        className={`mx-auto ${
          responsiveView.width !== "all" && responsiveView.width
        }`}
      >
        <BannerSearch />

        <ContainerViewsHome>
          <BannerHomeOffers />
          <BannerExclusiveDiscounts />
          <BannerOffersNow />
          {/* <UserExperienceBanner /> */}
          <ExploreActivitiesHome />
          <BannerStepsItinerary />
          <PopularDestinationsHome />
          <ChainsHome />
          <EnjoyStayHome />
          <TransportBanner />
          <BannerDiscoverPossibilities />
        </ContainerViewsHome>
      </div>
    )
  );
}
