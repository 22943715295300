import { UseRHContext } from "../../context/RHContext";

export function FilterStatus() {
  const { statusSelected, setStatusSelected } = UseRHContext();

  const statusValues = [
    { label: "Activo", value: "ACTIVO" },
    { label: "Inactivo", value: "INACTIVO" },
  ];
  return (
    <div className="flex flex-col items-start gap-y-3 max-w-[12rem]">
      <h3 className="text-fs-14 m-b">Estatus</h3>
      <div className="flex gap-3">
        {statusValues.map((status, index) => (
          <button
            onClick={() =>
              setStatusSelected((value) =>
                value === status.value ? "" : status.value
              )
            }
            key={index}
            className={`flex gap-x-2 items-center select-none border py-2 px-4 rounded-full ${
              statusSelected === status.value
                ? "border-bl-100 bg-bl-100 text-white"
                : "border-gry-70"
            }`}
          >
            <p className="m-0 text-fs-12 m-s-b select-none">{status.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
