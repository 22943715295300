export default function Profile({ user, isOpen, setOpenEditUser }) {
  return (
    <div
      onClick={() => setOpenEditUser(true)}
      className={`w-full flex items-center gap-2 px-6 py-3 text-sm font-semibold leading-6 cursor-pointer hover:bg-gry-50 ${
        isOpen ? "" : "justify-center"
      }`}
    >
      <div className="h-11 w-11 rounded-lg bg-gry-50">
        <img
          src={user.url_image}
          alt="profile image"
          className="w-full h-full object-cover rounded-lg"
        />
      </div>

      <div className={`flex flex-col ${isOpen ? "w-[78%]" : "hidden"}`}>
        <span
          aria-hidden="true"
          className={`${isOpen ? "text-fs-14 m-b" : "hidden"}`}
        >
          {user?.name}
        </span>
        <span className={`truncate ${isOpen ? "m-m text-fs-10" : "hidden"}`}>
          {user?.email}
        </span>
      </div>
    </div>
  );
}
