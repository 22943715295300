import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { ChangeStatusVacation } from "../../../Vacations/api/api";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { UseVacations } from "../../../Vacations/context/VacationsContext";
// import PaidVacation from "../../../Vacations/components/PaidVacation";

export function RhConfirmVacation({
  isOpen,
  userVacation,
  close,
  notificationResponse,
}) {
  const { notification, showNotification, hideNotification } =
    useNotification();

  const { refreshUserList } = UseVacations();
  const [password, setPassword] = useState("");
  const [isPassword, setIsPassword] = useState(true);

  const [isTextType, setIsTextType] = useState(false);
  const formatDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD").format(
      "dddd D [de] MMMM [del] YYYY"
    );
  };

  useEffect(() => {
    if (password.length > 3) {
      setIsPassword(true);
    }
  }, [password]);

  const handleStatusVacation = async (status) => {
    if (password.length === 0) {
      setIsPassword(false);
      return;
    }

    const body = { userId: userVacation.id, password, status };

    try {
      const response = await ChangeStatusVacation(body);
      if (response.code == "ok") {
        refreshUserList();
        notificationResponse(userVacation.name, status);
        close();
      }
    } catch (error) {
      console.log(error);
      showNotification(
        "error",
        "Ocurrió un problema",
        `No se pudieron ${
          status == 1 ? "actualizar" : "cancelar"
        } las vacaciones del usuario`,
        5000
      );
      throw error;
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[486px] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-100.svg`}
                      width={16}
                      height={16}
                      alt="icon-close"
                      className="mr-2 cursor-pointer absolute right-4"
                      onClick={close}
                    />

                    <div className="flex flex-col gap-3 mt-5">
                      <div className="flex gap-3">
                        <div className="w-[120px] h-[120px] bg-gry-50 rounded-lg">
                          {/* IMG PROFILE */}
                          {userVacation && userVacation.url_image && (
                            <img
                              src={userVacation.url_image}
                              className="rounded-lg object-cover"
                              alt="profile"
                            />
                          )}
                        </div>

                        <div className="flex flex-col gap-1">
                          <h3 className="text-fs-20 text-gry-100 m-b">
                            {userVacation?.name}
                          </h3>

                          <div className="bg-grn-10 text-grn-100 px-2 py-1 w-fit text-fs-12 m-s-b rounded">
                            Activo
                          </div>
                        </div>
                      </div>

                      <hr className="border-[1.5px] my-2" />

                      {/* INPUT START DATE */}
                      <div className="relative w-full text-fs-12 m-m text-gry-100">
                        <label className="m-b text-fs-14 text-gry-100">
                          Fechas Pendientes
                          <span>
                            {" "}
                            ({" "}
                            <span className="text-[#15C0E6]">
                              {userVacation?.vacations.dates.pending.length}
                            </span>{" "}
                            / {userVacation?.vacations.available_days} )
                          </span>
                        </label>
                        <div className="flex px-[16px] py-[12.8px] border border-[#ebebeb] items-center gap-2 relative mt-2">
                          <div className="flex flex-col">
                            {userVacation?.vacations.dates.pending ? (
                              userVacation?.vacations.dates.pending.map(
                                (date, index) => (
                                  <div
                                    className="flex gap-2 items-center"
                                    key={`${index}-${index}`}
                                  >
                                    <div className="rounded-full bg-[#15C0E61E] border border-[#15C0E6] w-[10px] h-[10px]" />
                                    {formatDate(date)}
                                  </div>
                                )
                              )
                            ) : (
                              <div className="m-s-b text-fs-12 text-red-100">
                                No hay fechas Selecionadas
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <hr className="border-[1.5px] my-2" />

                      {/* TYPE VACATIONS */}
                      {/* <PaidVacation onChange={handleVacationChange} onAmountChange={handleAmountChange}/> */}

                      {/* PASSWORD */}
                      <div className="flex flex-col">
                        <span className="m-b text-fs-14 text-gry-100 mb-0">
                          Contraseña
                        </span>

                        <span className="text-fs-10 m-m text-gry-100">
                          *Para autorizar vacaciones se debera confirmar la
                          contraseña*
                        </span>

                        <div className="flex relative">
                          <form autoComplete="off" className="w-full">
                            <input
                              type={isTextType ? "text" : "password"}
                              placeholder="Contraseña"
                              className="appearance-none outline-none border-2 border-gry-30 rounded h-[45px] pl-5 w-full pr-8 mt-5"
                              value={password}
                              // onFocus={() => setPassword("")}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete="new-password"
                              name={`password-${Math.random()
                                .toString(36)
                                .substr(2, 9)}`}
                            />
                          </form>

                          <button
                            onClick={() => setIsTextType(!isTextType)}
                            className="absolute right-2 bottom-3 rounded-full"
                          >
                            <img
                              src={`${
                                import.meta.env.VITE_URL_IMAGES
                              }assets/icons/general/${
                                isTextType ? "eye.svg" : "eye-close.svg"
                              }`}
                              alt="eye password"
                            />
                          </button>
                        </div>

                        {!isPassword && (
                          <p className="text-red-100 text-fs-12 m-m">
                            La contraseña es requerida
                          </p>
                        )}
                      </div>
                    </div>

                    {/* BTN´S ALERT */}
                    <div className="flex gap-3 mt-7">
                      <button
                        className="flex gap-1 px-3 py-3 rounded-lg bg-red-100 w-fit text-fs-12 text-white m-s-b hover:bg-red-110"
                        onClick={() => handleStatusVacation(2)}
                      >
                        Cancelar vacaciones
                      </button>
                      <button
                        onClick={() => handleStatusVacation(1)}
                        className="flex gap-1 px-3 py-3 rounded-lg bg-bl-100 w-fit text-fs-12 text-white m-s-b hover:bg-bl-110"
                      >
                        Confirmar vacaciones
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
