import { useState } from "react";
import EditUser from "../../Users/components/EditUser/EditUser";

export default function InfoUser(props) {
  const users = props.users;
  //   console.log(users);

  const [openEditUser, setOpenEditUser] = useState(false);

  return (
    <>
      <>
        <div className=" rounded-[20px] bg-bl-100 flex justify-center items-center px-[50px] py-[35px]">
          <div className="flex flex-col gap-2 m-m items-center">
            <div className={`w-[100px] h-[100px] rounded-full p-2 bg-white`}>
              <img
                src={users.url_image}
                alt="img perfil"
                className="w-full h-full object-cover rounded-full"
              />
            </div>

            <span className="text-white text-fs-22 m-s-b">{users.name}</span>
            <span className="text-white text-fs-12 m-m">{users.email}</span>
            
            {/* BTN EDIT */}
            <button
              onClick={() => setOpenEditUser(true)}
              className="text-white w-fit flex gap-2 items-center border border-white rounded-lg px-3 py-2 text-fs-16 hover:bg-bl-110"
            >
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/crm/pencil-edit-w.svg`}
                alt="icon pencil"
              />
              Editar Perfil
            </button>
          </div>
        </div>

        <EditUser
          users={users}
          isOpen={openEditUser}
          setIsOpen={setOpenEditUser}
        />
      </>
    </>
  );
}
