import "swiper/css";
import "swiper/css/pagination";
import "../../../assets/styles/Swiper.css";

// import { Pagination } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";

export default function SwipperLogin() {
  return (
    <>
      <img
        src="https://s1.1zoom.me/big0/362/Turtles_Underwater_world_554511_1280x720.jpg"
        className="w-full h-full object-cover rounded-r-[40px]"
      />
      {/* <Swiper
          pagination={{
            clickable: true,
          }}
          id="swiper-login-crm"
          modules={[Pagination]}
          className="mySwiper h-full rounded-r-[40px] max-md:rounded-r-none"
        >
          {[...Array(3)].map((_, index) => (
            <SwiperSlide key={index}>
              <img
                alt="Gallery login"
                className="w-full h-full object-cover"
                src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/banners/home/Pool_view_4.tmedium.jpg`}
              />
            </SwiperSlide>
          ))}
        </Swiper> */}
    </>
  );
}
