import moment from "moment";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { CancelReservation } from "../../api/Reservations";
import { UseReservations } from "../../context/ReservationsContext";

export function ConfirmCancellation({
  isOpen,
  setIsOpen,
  reservation,
  showNotification,
}) {
  const { handleReservations } = UseReservations();
  const [password, setPassword] = useState("");
  const [fullRefund, seFullRefund] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isTextType, setIsTextType] = useState(false);

  const handleKeyDown = (event) => {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const cleanedValue = value.replace(/[^0-9]/g, "");
    const numberValue = (cleanedValue / 100).toFixed(2);
    return parseFloat(numberValue).toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseCurrency = (value) => parseFloat(value.replace(/[^0-9.-]+/g, ""));

  const handleRefundChange = (e) => {
    const value = e.target.value;
    seFullRefund(formatCurrency(value));
  };

  const renderRow = (label, value) => (
    <div className="flex items-center gap-x-5 py-[5px]">
      <p className="m-b m-0 w-[179px] text-fs-12 text-gry-100">{label}</p>
      <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
        {value}
      </p>
    </div>
  );

  const handleConfirmRefund = async () => {
    if (!password.trim()) {
      return showNotification(
        "error",
        "Error",
        "La contraseña es obligatoria",
        6000
      );
    }

    try {
      setIsLoading(true);
      const parsedRefund = parseCurrency(fullRefund);

      let response = await CancelReservation(
        reservation.bookingReference,
        password,
        parsedRefund
      );

      if (response?.data?.error) {
        // console.log("entra if", response?.data?.error);
        showNotification(
          "warning",
          "Intente de nuevo",
          `Hubo un problema al intentar cancelar la reserva #${reservation.bookingReference}, inténtalo más tarde.`,
          6000
        );
      }

      if(!response?.data?.error){
        showNotification(
          "success",
          "Cancelación confirmada",
          `La cancelación con el número de reserva #${reservation.bookingReference} se canceló con éxito.`,
          6000
        );
      }
      

      handleReservations();
      setIsOpen(false);
      setIsLoading(false);
    } catch (error) {
      if (error.response?.data.code === "MissingPerms") {
        showNotification(
          "error",
          "Acción No Permitida",
          `No cuenta con los permisos para realizar esta acción.`,
          6000
        );
      } else {
        showNotification(
          "error",
          "Ocurrió un error",
          `Hubo un problema al intentar cancelar la reserva #${reservation.bookingReference}, inténtalo más tarde.`,
          6000
        );
      }
      setIsLoading(false);
      console.error(error.response.data);
    }
  };

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[2]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg p-5 relative w-[500px]">
                {/* CLOSE MODAL */}
                <button
                  onClick={() => setIsOpen(false)}
                  className="focus:outline-none absolute right-0 top-[12px] mr-3"
                >
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/close/close-70.svg`}
                    alt="close modal"
                    width={13}
                    height={13}
                  />
                </button>

                <h3 className="m-s-b text-fs-16 pb-3 text-red-100">
                  Solicitud de Cancelación de Reserva
                </h3>

                <hr className="my-4 border-[1.5px]" />

                {/* INFO CANCELLATION */}
                <div className="h-max overflow-auto scroll-page-gry">
                  {renderRow(
                    "Folio de la reservación",
                    reservation.bookingReference
                  )}
                  {renderRow("Nombre del cliente", reservation.userName)}
                  {renderRow(
                    "Servicios a cancelar",
                    reservation.services.map((service, index) => (
                      <span key={index}>
                        {service.type}
                        {reservation.services.length - 1 !== index && ","}
                      </span>
                    ))
                  )}
                  {renderRow(
                    "Día de la reservación",
                    moment(reservation.reservationDate).format("DD/MM/YYYY")
                  )}
                </div>

                {/* TOTAL PRICE */}
                <div className="flex items-center gap-x-5 py-[5px] relative">
                  <p className="m-b m-0 w-[179px] text-fs-12 text-gry-100">
                    Costo total
                  </p>
                  <p className="m-0 text-fs-14 m-m items-center text-gry-100">
                    $
                    {Math.floor(reservation.totalAmount)
                      .toLocaleString("es-MX", { currency: "MXN" })
                      .replace(".00", "")}
                    .
                    <sup>
                      {(reservation.totalAmount % 1).toFixed(2).slice(2)}
                    </sup>{" "}
                    (impuestos incluidos)
                  </p>
                </div>

                <hr className="my-4 border-[1.5px]" />

                {/* PASSWORD */}
                <div className="flex flex-col gap-1 mb-3">
                  <p className="m-b m-0 w-[179px] p-0 text-fs-14 text-gry-100">
                    Contraseña
                  </p>
                  <span className="m-m m-0 p-0 text-fs-10 text-gry-100">
                    *Para autorizar la cancelación se deberá confirmar la
                    contraseña*
                  </span>
                  <div className="relative w-full rounded border border-gry-50 h-[43px] mt-4 mb-5">
                    <input
                      type={isTextType ? "text" : "password"}
                      placeholder="Contraseña"
                      className="border py-2 my-auto px-[10px] focus:outline-none w-full h-full bg-transparent placeholder:m-m placeholder:text-fs-12"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img
                      onClick={() => setIsTextType(!isTextType)}
                      className="absolute inset-y-0 right-[10px] my-auto cursor-pointer"
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/general/${
                        isTextType ? "eye.svg" : "eye-close.svg"
                      }`}
                      alt="toggle password visibility"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>

                {/* INPUT REFUND */}
                <div className="flex flex-col gap-1 mb-3">
                  <p className="m-b m-0 w-[179px] p-0 text-fs-14 text-gry-100">
                    Reembolso total
                  </p>
                  <span className="m-m m-0 p-0 text-fs-10 text-gry-100">
                    *Coloca el monto a rembolsar*
                  </span>
                  <div className="relative w-full mt-4 mb-5">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gry-100">$</span>
                    </div>
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      value={fullRefund}
                      onChange={handleRefundChange}
                      placeholder="Total a rembolsar"
                      className="pl-6 pr-4 text-right border border-gry-50 py-2 my-auto px-[10px] focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                    />
                  </div>
                </div>

                {/* BTN SAVE AND CANCELATION */}
                <div className="flex justify-center gap-6">
                  <button
                    onClick={() => setIsOpen(false)}
                    className="focus:outline-none text-red-100 text-fs-12 m-b cursor-pointer p-4 rounded-lg hover:text-white hover:bg-red-100"
                  >
                    Cancelar
                  </button>

                  <button
                    type="submit"
                    className={`gap-2 focus:outline-none rounded-lg flex items-center p-4 text-white text-fs-12 m-b ${
                      !isLoading ? "hover:bg-bl-110 bg-bl-100" : "bg-bl-70"
                    }`}
                    onClick={handleConfirmRefund}
                    disabled={isLoading}
                  >
                    {isLoading ? "Enviando..." : "Enviar"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )} */}
    </>
  );
}
