import { FilesCards } from "./FilesCards";
import { AddComment } from "./AddComment";
import { CommentsReservation } from "./Comments";
import { AddDocument } from "./AddDocument";

export function DocumentDetails({ documents, comments, isCanceled }) {
  return (
    <div className="flex flex-col pl-5 border-l gap-y-5 border-gry-70 h-full w-[30%]">
      <div className="flex flex-col gap-y-5 h-[50%]">
        <h3 className="text-fs-14 text-gry-100 m-b">
          {documents.length == 0 ? (
            <span>Sin documentos</span>
          ) : (
            <span>Documentos {documents.length}</span>
          )}
        </h3>

        <div className="flex flex-col gap-y-2 h-[80%] overflow-y-auto scroll-page-gry">
          {documents.length > 0 ? (
            documents.map((document, index) => (
              <FilesCards key={index} file={document} />
            ))
          ) : (
            <div className="m-m text-fs-12 h-full flex items-center justify-center">
              No hay documentos
            </div>
          )}
        </div>
      </div>

      {/* <div className="w-full flex justify-center"> */}
      {/* </div> */}

      <AddDocument isCanceled={isCanceled} />
      <CommentsReservation comments={comments} />

      <div className="w-full flex justify-center">
        <AddComment isCanceled={isCanceled} />
      </div>
    </div>
  );
}
