/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

import TipsBooking from "../others/TipsBooking";
import { GetImagesByView } from "../../../api/MarketingApi";
import { BannerHomeTransport } from "../others/BannerHomeTransportD";
import { MarketingContext } from "../../../context/MarketingContext";
import { RecommendedTransport } from "../others/RecommendedTransport";
import { BannerSafelyTransport } from "../others/BannerSafelyTransport";
import { CartTourOptions } from "../../HotelView/others/CartTourOptions";
import BannerSearch from "../../../../../components/bannerHomes/BannerSearch";
import { BannerDiscoverPossibilities } from "../others/BannerDiscoverPossibilities";
import { ContainerViewsHome } from "../../../../../components/containers/ContainerViewsHome";

export function TransportLayout() {
  const {
    routActual,
    responsiveView,
    languageSelected,
    jonImagesActualView,
    setJonImagesActualView,
  } = useContext(MarketingContext);

  useEffect(() => {
    if (routActual) {
      // VALID IF LENGTH ARY IS EMPTY FROM GET IMAGE
      if (jonImagesActualView[languageSelected] == null ) {
        GetImagesByView("transporte", languageSelected).then((result) => {
          if (result) {
            setJonImagesActualView((images) => {
              return { ...images, [languageSelected]: result };
            });
          }
        });
      }
    }
  }, [routActual, languageSelected]);

  return (
    jonImagesActualView[languageSelected] && (
      <div
        className={`mx-auto ${
          responsiveView.width !== "all" && responsiveView.width
        }`}
      >
        <BannerSearch />
        <ContainerViewsHome>
          <BannerHomeTransport />

          <TipsBooking />

          {/* <ChainsHome/> */}

          <BannerSafelyTransport />

          <BannerDiscoverPossibilities />

          <RecommendedTransport />

          <CartTourOptions />
        </ContainerViewsHome>
      </div>
    )
  );
}
