// modules
// _________________
// 1	 | rh
// 2	 | resevas
// 3	 | marketing
// 4	 | finanzas
// 5	 | vacaciones
// 6   | usuarios

export const navigationMenu = [
  {
    name: "Dashboard",
    href: "dashboard" || "",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/dashboard-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/dashboard-bl.svg`,
    current: false,
    alt: "icon users",
    roles: [5],
  },

  {
    name: "Usuarios",
    href: "users",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/users-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/users-bl.svg`,
    current: false,
    alt: "icon users",
    roles: [6],
  },

  {
    name: "Reservaciones",
    href: "reservations",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/book-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/book-bl.svg`,
    current: false,
    alt: "icon book",
    roles: [2],
  },

  {
    name: "RH",
    href: "rh",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/rh-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/rh-bl.svg`,
    current: false,
    alt: "icon rh",
    roles: [1],
  },

  {
    name: "Vacaciones",
    href: "vacations",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/vacations-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/vacations-bl.svg`,
    current: false,
    alt: "icon vacations",
    roles: [5],
  },

  {
    name: "Marketing",
    href: "marketing",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/marketing-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/marketing-bl.svg`,
    current: false,
    alt: "icon gallery",
    roles: [3],
  },
  
  {
    name: "Ingresos",
    href: "#",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/revenue-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/revenue-100.svg`,
    current: false,
    alt: "icon revenue",
    roles: [4],
  },
];

export const settingsMenu = [
  // {
  //   id: 1,
  //   name: "Historial",
  //   href: "#",
  //   icon: `${
  //     import.meta.env.VITE_URL_IMAGES
  //   }assets/icons/crm/navbarCRM/history-100.svg`,
  //   iconA: `${
  //     import.meta.env.VITE_URL_IMAGES
  //   }assets/icons/crm/navbarCRM/history-bl.svg`,
  //   current: false,
  //   alt: "icon history",
  //   roles: [1, 2, 3, 4, 5],
  // },
  {
    id: 2,
    name: "Configuración",
    href: "configurations",
    icon: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/settings-100.svg`,
    iconA: `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/crm/navbarCRM/settings-bl.svg`,
    current: false,
    alt: "icon settings",
    roles: [7],
  },
  // {
  //   id: 3,
  //   name: "Soporte",
  //   href: "#",
  //   icon: `${
  //     import.meta.env.VITE_URL_IMAGES
  //   }assets/icons/crm/navbarCRM/help-100.svg`,
  //   iconA: `${
  //     import.meta.env.VITE_URL_IMAGES
  //   }assets/icons/crm/navbarCRM/help-100.svg`,
  //   current: false,
  //   alt: "icon support",
  //   roles: [1, 2, 3, 4, 5, 6, 7],
  // },
];
