import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function BannerDiscoverPossibilities() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div
      className={`relative w-full flex  my-32 ${
        responsiveView.value !== "laptop" ? "items-start" : "items-center"
      }`}
    >
      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/banners/transport/banner-baggage.jpg`}
        alt="banner baggage"
        className={`w-full ${
          responsiveView.value !== "laptop" && "hidden"
        } select-none`}
      />
      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/banners/transport/banner-baggage-mobile.jpg`}
        alt="banner baggage mobile"
        className={`${
          responsiveView.value === "laptop" && "hidden"
        } w-full h-[822px] select-none`}
      />

      <div
        className={`absolute flex flex-col ${
          responsiveView.value !== "laptop"
            ? "mx-[40px] mt-[90px]"
            : "ml-[60px] mt-[40px]"
        } `}
      >
        <div className="flex flex-col w-full max-md:items-center ">
          <h2
            className={`m-b text-fs-34 ${
              responsiveView.value !== "laptop" && "text-center"
            }`}
          >
            Descubre tus posibilidades aquí
          </h2>

          <span
            className={`m-s-b text-fs-16 w-[70%] mt-[10px] ${
              responsiveView.value !== "laptop" && "text-center w-full"
            }`}
          >
            Nos encantaría conocerte mejor. Compártenos tus datos y permítenos
            brindarte una atención personalizada.
          </span>
        </div>

        <div
          className={`flex gap-2 mt-[35px] ${
            responsiveView.value !== "laptop" && "flex-col"
          }`}
        >
          <input
            placeholder="Escribe tu nombre*"
            className={`h-[44px] bg-white rounded-[4px] pl-[10px] m-s-b text-fs-12 ${
              responsiveView.value !== "laptop" ? "w-full" : " w-[257px]"
            } focus:outline-none italic`}
            disabled={true}
          />

          <input
            placeholder="Número de teléfono *"
            className={`h-[44px] bg-white pl-[10px] rounded-[4px] m-s-b text-fs-12 ${
              responsiveView.value !== "laptop" ? "w-full" : " w-[257px]"
            } focus:outline-none italic`}
            disabled={true}
          />
          <div
            className={`text-white px-[40px] py-[10px] rounded-full flex items-center justify-center max-lg:w-full bg-bl-100`}
          >
            Enviar
          </div>
        </div>
      </div>
    </div>
  );
}
