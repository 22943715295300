/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export function NewComments({isLoader, comments, setComments, isEmptyComment, setIsEmptyComment }) {
  useEffect(() => {
    if (comments.split("").length > 0 && comments.trim() === "") {
      setIsEmptyComment(true);
    } else {
      setIsEmptyComment(false);
    }
  }, [comments]);

  return (
    <div className="relative">
      <textarea
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        rows="4"
        placeholder="Escriba su comentario...."
        className={`h-[80px] focus:outline-none border rounded-lg p-4 w-full placeholder:text-gray-400 mb-4 ${
          isEmptyComment ? "border-red-100" : "border-gry-50"
        }`}
        name="comments"
        disabled={isLoader}
      />
      
      {isEmptyComment && (
        <span className="text-red-100 absolute left-0 bottom-[5px] text-fs-12">
          El comentario es obligatorio
        </span>
      )}
    </div>
  );
}
