import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import AlertsCrm from "../../../../components/alerts/Alerts";

import { useUser } from "../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";


export function ButtonGooBack() {
  const navigation = useNavigate();
  const { view } = useParams();

  const [openAlert, setOpenAlert] = useState(false);

  const isConfirmed = () => {
    navigation(`/marketing`);
  };

  const handleGooBack = () => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    if (hasPermission) {
      setOpenAlert(true);
    }else{
      isConfirmed();

    }
  };

  const { user } = useUser();

  return (
    <>
      <button
        onClick={handleGooBack}
        className="focus:outline-none bg-bl-100 rounded-full p-4 flex items-center justify-center w-10 h-10"
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/arrows/left-w.svg`}
          alt="goo back"
          width={12}
          height={12}
        />
      </button>

      <AlertsCrm
        setConfirmation={isConfirmed}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title={`¿Deseas dejar de editar la sección de ${view}?`}
        text={`¡Si abandonas esta vista sin guardar, los cambios realizados se perderán!`}
        typeAlert={"warning"}
      />
    </>
  );
}
