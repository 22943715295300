import axiosWithInterceptor from "../../../configs/Instance/axiosConfig";

export async function GetReservation(useId) {
  // console.log(token);
  try {
    let respose = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/bookingList/3`
    );
    return respose;
  } catch (error) {
    console.log(error);
  }
}

export async function GetDetailsReservation(reference) {
  try {
    let response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/bookingDetails/${reference}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function SaveNewComment(bookingId, userId, comment) {
  try {
    let response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/addComment`,
      {
        booking_id: bookingId,
        user_id: userId,
        comment: comment,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function SaveFilesReservationDetails(token, booking_id, file) {
  try {
    let data = JSON.stringify({ booking_id: booking_id, documents: file });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${import.meta.env.VITE_API_URL_STAYWUW}/saveDocuments`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    let response = await axiosWithInterceptor(config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function RemoveFiles(token, document_id) {
  try {
    const config = {
      method: "put",
      url: `${
        import.meta.env.VITE_API_URL_STAYWUW
      }/deleteDocuments/${document_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axiosWithInterceptor(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const CancelBookingRequest = async (bookingId, password) => {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/cancel-booking-request`,
      {
        bookingId,
        password,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error al cancelar la reserva:", error);
    throw error;
  }
};

export const CancelReservation = async (bookingId, password, amount) => {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/cancel-booking`,
      {
        bookingId: bookingId,
        password: password,
        totalRefund: amount,
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateBookingStatusApi = async (referenceNumber) => {
  
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/booking-reconfirmation`,
      { bookingId: referenceNumber }
    );
    return response.data.status;
  } catch (error) {
    throw new Error("Error en la actualización. Contacta al equipo.");
  }
};
