import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function BannerCallHotelT() {
  const { responsiveView } = useContext(MarketingContext);

  return (
    <div className="relative w-full">
      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/banners/desktop/call-d.webp`}
        alt="Banner-Call-Center"
        width={1272}
        height={246}
        className="select-none w-full"
      />

      <div
        className={`absolute w-[62%] top-0 left-0 bottom-0 h-max my-auto
          ${responsiveView.value === "laptop" && "ml-[40px]"} 
          ${responsiveView.value === "tablet" && "ml-[60px]"}
          ${responsiveView.value === "mobile" && "ml-[20px]"} `}
      >
        <h2
          className={`m-b ${
            responsiveView.value === "mobile" && "text-fs-10 mb-[5px]"
          } text-bl-100 text-left ${
            responsiveView.value === "tablet" && "text-fs-15 mb-[4px]"
          } ${
            responsiveView.value === "laptop" &&
            "text-fs-24 max-2xl:text-fs-18 mb-[8px]"
          }`}
        >
          ¿Dudas con tu reservación? Llama ahora, nosotros te ayudamos a
          resolver tus dudas
        </h2>

        <h4
          className={`m-s-b ${
            responsiveView.value === "mobile" && "text-fs-8"
          } ${responsiveView.value === "tablet" && "text-fs-12"} ${
            responsiveView.value === "laptop" && "text-fs-20 max-2xl:text-fs-16"
          } text-gry-100 m-0 `}
        >
          Necesitas algo más y no lo encuentras, llámanos al{" "}
          <span
            className={`m-b ${
              responsiveView.value === "mobile" && "text-fs-8"
            } ${responsiveView.value === "tablet" && "text-fs-12"} ${
              responsiveView.value === "laptop" &&
              "text-fs-20 max-2xl:text-fs-16"
            } m-0 `}
          >
            800 953 0342
          </span>
          , uno de nuestros asesores te puede ayudar{" "}
        </h4>
      </div>
    </div>
  );
}
