export function ContainerConfig({ isOpen, children }) {
  return (
    <div
      className={`w-full rounded-md px-[43px] border-0 transition-all ease-in-out ${
        isOpen ? "min-h-[8rem] py-5 shadow-3xl" : "h-0"
      }`}
    >
      {children}
    </div>
  );
}
