// src/api.js

import axios from "axios";

// import axiosWithInterceptor from '../configs/Instance/axiosConfig';

export const loginApi = async (identifier, password) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/login`,
      { identifier, password }
    );
    return response.data;
  } catch (error) { 
    throw new Error("Login failed");
  }
};

export const logoutApi = async (token) => {
  try {
    await axios.get(`${import.meta.env.VITE_API_URL_STAYWUW}/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
  } catch (error) {
    console.error("Error al cerrar sesión:", error);
    throw error;
  }
};

export const sendForgotPasswordLink = async (email) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/forgot-password`,
      {
        email: email,
        url: `${window.location.origin}/reset-password`,
      }
    );

    return response.status === 200;
  } catch (error) {
    throw new Error(
      "There was an issue sending the reset link. Please try again."
    );
  }
};
