/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import { SendImages } from "../../api/MarketingApi";
import { MarketingContext } from "../../context/MarketingContext";
import AlertsCrm from "../../../../components/alerts/Alerts";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { useUser } from "../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";

export function Publish() {
  const { routActual, jonImagesActualView } = useContext(MarketingContext);
  const { notification, showNotification, hideNotification } =
    useNotification();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [configAlert, setConfigAlert] = useState({
    typeAlert: "",
    title: "",
    text: "",
  });

  const handleClick = () => {
    setOpenAlert(true);
    let validJsonEn = validationIsChangeJson(jonImagesActualView.en);
    let validJsonEs = validationIsChangeJson(jonImagesActualView.es);

    if (!validJsonEn || !validJsonEs) {
      setConfigAlert({
        typeAlert: "error",
        title: "ALERTA",
        text: `Este botón permite la publicación de forma individual y no está vinculado a un flujo automatizado para las vistas WEB, MOBILE y TABLET. Si seleccionas "Publicar", ten en cuenta que el contenido solo se subirá de manera independiente.`,
      });
    } else {
      setConfigAlert({
        typeAlert: "success",
        title: "Éxito",
        text: `Este botón publicará el contenido en todas las vistas (WEB, MOBILE y TABLET) de forma simultánea como parte del flujo automatizado. Al seleccionar "Publicar", las imágenes se subirán correctamente a todas las plataformas`,
      });
    }
  };

  useEffect(() => {
    if (isConfirmed) {
      const response = async () => {
        try {
          let sendJson = {
            type: routActual === "transport" ? "transporte" : routActual,
          };
          if (jonImagesActualView.en) {
            sendJson = {
              ...sendJson,
              lang: "en",
              ...jonImagesActualView.en,
            };

            await setImagesApi(sendJson);
          }

          if (jonImagesActualView.es) {
            sendJson = {
              ...sendJson,
              lang: "es",
              ...jonImagesActualView.es,
            };

            await setImagesApi(sendJson);
          }

          showNotification(
            "success",
            "La subida de imágenes fue exitosa.",
            "La subida de imágenes fue exitosa, revise los cambios en StayWuw.com",
            5000
          );
        } catch (error) {
          console.log(error);
          showNotification(
            "error",
            "Algo falló.",
            "Ocurrió un problema en la subida de imágenes, por favor inténtelo más tarde.",
            5000
          );
          throw error;
        }
      };

      setIsConfirmed(false);
      setOpenAlert(false);
      response();

      // let nameView = routActual;

      // let sendJson = {
      //   type: nameView,
      // };

      // // IS EN LANGUAGE
      // if (jonImagesActualView.en) {
      //   sendJson = {
      //     ...sendJson,
      //     lang: "en",
      //     ...jonImagesActualView.en,
      //   };
      //   setImagesApi(sendJson).then((response) => {
      //     console.log(response);
      //   });
      // }

      // // IS ES LANGUAGE
      // if (jonImagesActualView.es) {
      //   sendJson = {
      //     ...sendJson,
      //     lang: "es",
      //     ...jonImagesActualView.es,
      //   };

      //   setImagesApi(sendJson).then((response) => {
      //     console.log(response);
      //   });
      // }
    }
  }, [isConfirmed]);

  const { user } = useUser();

  const hasPermission = HasPermissionsUser(
    user.permissions,
    "upload_images"
  );

  return (
    <>
      {hasPermission && (
        <button
          onClick={handleClick}
          className="focus:outline-none bg-bl-100 flex items-center p-4 gap-x-2 rounded-lg text-fs-12 m-b text-white"
        >
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/crm/publish-w.svg`}
            alt="publish"
            width={13}
            height={18}
          />
          Publicar
        </button>
      )}

      <AlertsCrm
        setConfirmation={setIsConfirmed}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title={configAlert.title}
        text={configAlert.text}
        typeAlert={configAlert.typeAlert}
      />

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}

const setImagesApi = async (json) => {
  // console.log(json);
  try {
    let response = await SendImages(json);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const isObject = (value) => {
  return value !== null && typeof value === "object" && !Array.isArray(value);
};

const arrayHasObject = (value) => {
  return value.some((item) => isObject(item));
};

const validationIsChangeJson = (json) => {
  let allValid = true;

  if (json) {
    Object.keys(json).forEach((key) => {
      const laptop = json[key]["laptop"];
      const tablet = json[key]["tablet"];
      const mobile = json[key]["mobile"];

      if (laptop === null || mobile === null || tablet === null) {
        allValid = false;
        return; // Salir de esta iteración si hay null
      }

      // VALIDATION WHEN IS NOT ARRAY
      const isLaptopObject = isObject(laptop);
      const isTabletObject = isObject(tablet);
      const isMobileObject = isObject(mobile);

      const isLaptopArray = Array.isArray(laptop);
      const isTabletArray = Array.isArray(mobile);
      const isMobileArray = Array.isArray(tablet);

      if (isLaptopObject || isTabletObject || isMobileObject) {
        if (!(isLaptopObject && isTabletObject && isMobileObject)) {
          allValid = false;
        }
      }

      if (isLaptopArray || isTabletArray || isMobileArray) {
        // Verificar que el arreglo no esté vacío
        if (isLaptopArray && laptop.length === 0) {
          allValid = false;
        }
        if (isMobileArray && mobile.length === 0) {
          allValid = false;
        }
        if (isTabletArray && tablet.length === 0) {
          allValid = false;
        }
        const laptopHasObject = isLaptopArray ? arrayHasObject(laptop) : false;
        const tabletHasObject = isTabletArray ? arrayHasObject(tablet) : false;
        const mobileHasObject = isMobileArray ? arrayHasObject(mobile) : false;

        if (laptopHasObject && (!mobileHasObject || !tabletHasObject)) {
          allValid = false;
        }

        if (mobileHasObject && (!laptopHasObject || !tabletHasObject)) {
          allValid = false;
        }

        if (tabletHasObject && (!laptopHasObject || !mobileHasObject)) {
          allValid = false;
        }
      }
    });
  }

  return allValid;
};
