import React, { useState } from "react";
import ReconfirmationDialog from "./ReconfirmationDialog";
import { UseReservations } from "../../context/ReservationsContext";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative inline-block">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {showTooltip && (
        <div className="bg-black text-white text-nowrap text-xs rounded py-1 px-2 right-[-20px] absolute z-10">
          {text}
        </div>
      )}
    </div>
  );
};

export function ReconfirmationBooking({ referenceNumber }) {
  const [isOpen, setIsOpen] = useState(false);
  const { handleReservations, setTabSelected } = UseReservations();
  const [isUpdate, setIsUpdate] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);

    if (isUpdate) {
      {
        if (isOpen === true) {
          handleReservations();
          setTabSelected("Todas");
        }
      }
    }
  };

  return (
    <>
      <Tooltip text={<React.Fragment>{"Actualizar estatus"}</React.Fragment>}>
        <button className="focus:outline-none" onClick={openModal}>
          <img
            className="animate-pulse"
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }/assets/icons/crm/refresh.svg`}
            width={20}
            height={20}
            alt="edit details"
          />
        </button>
      </Tooltip>

      <ReconfirmationDialog
        isOpen={isOpen}
        onClose={openModal}
        referenceNumber={referenceNumber}
        isUpdate={() => setIsUpdate(true)}
      />
    </>
  );
}

export function ReconfirmationBookingBtn({ referenceNumber, status }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { handleReservations, setTabSelected } = UseReservations();

  const openModal = () => {
    setIsOpen(!isOpen);
    if (isUpdate) {
      {
        if (isOpen === true) {
          handleReservations();
          setTabSelected("Todas");
        }
      }
    }
  };

  return (
    <>
      <button
        className="p-1 rounded-md m-b text-fs-12 w-max flex justify-center bg-or-20 text-or-100"
        onClick={openModal}
      >
        {status}
      </button>

      <ReconfirmationDialog
        isOpen={isOpen}
        onClose={openModal}
        referenceNumber={referenceNumber}
        isUpdate={() => setIsUpdate(true)}
      />
    </>
  );
}
