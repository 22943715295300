export function Notifications() {
  return (
    <div className="bg-white p-2 rounded-lg relative flex items-center justify-center cursor-pointer shadow-3xl h-max ">
      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/notification/notification-black.svg`}
        width={14}
        height={16}
        alt="notification"
      />
    </div>
  );
}
