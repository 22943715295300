import moment from "moment";
import { PYCModal } from "./PYC";
import { useState } from "react";

export function TransportDetails({ transports }) {
  const [openPYC, setOpenPYC] = useState(false);
  const leftStyle = (isBold) =>
    `${isBold ? "m-b" : "m-m"} m-0 w-[199px] text-fs-14 text-gry-100`;

  const providerTypes = (provider) => {
    let nameProvider = "";

    switch (provider) {
      case "ct":
        nameProvider = "Civitatis";
    }
    return nameProvider;
  };

  return (
    <div className="flex flex-col gap-y-3 mt-2">
      {transports.map((transport, index) => (
        <div
          key={index}
          className="bg-gry-30 flex flex-col gap-y-2 p-4 rounded-lg"
        >
          <h3 className="m-b text-fs-14 text-black">
            {transport.vehicle_name}
          </h3>

          <h3 className="m-b text-fs-14 text-gry-100">Datos generales</h3>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(false)}`}>No. de reserva</p>
            <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              <span className="m-b text-bl-100">StayWuw:</span>
              {transport.provider_booking_id}
            </p>
          </div>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(false)}`}>Proveedor</p>
            <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              <span className="m-b text-bl-100">
                {providerTypes(transport.provider)}
              </span>
            </p>
          </div>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(false)}`}>Pick up</p>
            <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              {moment(transport.departure).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(false)}`}>Tipo de transporte</p>
            <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              {transport.type_transport === "PRIVATE" ? (
                <span>Privado</span>
              ) : (
                <span>Compartido</span>
              )}
            </p>
          </div>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(false)}`}>Tipo de viaje</p>
            <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              {transport.round ? (
                <span>Redondo</span>
              ) : (
                <span>Sencillo</span>
              )}
            </p>
          </div>

          <div className="flex items-center gap-x-5 py-[5px] relative">
              <p className={`${leftStyle(true)}`}>Políticas de cancelación</p>
              <p
                className={`m-0 text-fs-14 m-m flex gap-x-2 items-center text-bl-100 underline decoration-bl-100 cursor-pointer`}
                onClick={() => setOpenPYC(true)}
              >
                Rembolsable
              </p>

              <PYCModal
                text={
                 `Cancela antes de ${transport.cancellation_policies} horas`
                }
                open={openPYC}
                handleClose={() => setOpenPYC(false)}
              />
            </div>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(true)}`}>Total de personas</p>
            <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              {transport.totalPax}
            </p>
          </div>

          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(true)}`}>Total</p>

            <p className="m-0 text-fs-14 m-m items-center text-gry-100">
              $
              {Math.floor(transport.amount)
                .toLocaleString("es-MX", { currency: "MXN" })
                .replace(".00", "")}
              .
              <sup className="sup-price-cart">
                {(transport.amount % 1).toFixed(2).slice(2)}{" "}
              </sup>{" "}
              MXN
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
