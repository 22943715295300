export const homeViews = [
  {
    name: "Home",
    routeName: "home",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/general/`,
    icon: `home-nav.svg`,
    iconW: `home-w-nav.svg`,
  },
  {
    name: "Hotel",
    routeName: "hotel",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/hotel/`,
    icon: `domain-nav.svg`,
    iconW: `domain-nav-w.svg`,
  },
  {
    name: "Tour",
    routeName: "tour",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/transport/`,
    icon: `transport-nav.svg`,
    iconW: `transport-nav-w.svg`,
  },
  {
    name: "Transporte",
    routeName: "transport",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/tour/`,
    icon: `map-nav.svg`,
    iconW: `map-nav-w.svg`,
  },
];

export const responsiveViews = [
  {
    value: "laptop",
    width: "all",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/crm/`,
    icon: "computer-gry.svg",
    iconW: "computer-w.svg",
    w: 18,
    h: 15,
  },
  {
    value: "tablet",
    width: "w-[768px]",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/crm/`,
    icon: "tablet-gry.svg",
    iconW: "tablet-w.svg",
    w: 15,
    h: 18,
  },
  {
    value: "mobile",
    width: "w-[412px]",
    urlIcon: `${import.meta.env.VITE_URL_IMAGES}assets/icons/crm/`,
    icon: "phone-gry.svg",
    iconW: "phone-w.svg",
    w: 11,
    h: 18,
  },
];

export const popularState = [
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/48/mazatlan-royal-vacations-mexico.webp",
    name: "Mazatlán",
    price: 6394.25,
  },
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/18/cancun-royal-vacations-mexico.webp",
    name: "Cancun",
    price: 3891.09,
  },
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/7/los-cabos-royal-vacations-mexico.webp",
    name: "Los Cabos",
    price: 619.21,
  },
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/67/puerto-vallarta-royal-vacations-mexico.webp",
    name: "Puerto Vallarta",
    price: 2117.62,
  },
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/49/cdmx-royal-vacations-mexico.webp",
    name: "Ciudad de Mexico",
    price: 6210.19,
  },
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/1/acapulco-royal-vacations-mexico.webp",
    name: "Acapulco",
    price: 2065.55,
  },
  {
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/18/cancun-royal-vacations-mexico.webp",
    name: "Cancun",
    price: 3891.09,
  },
];

export const listOptions = [
  {
    id: 1,
    icon: `beach/beach-w.svg`,
    iconActive: `beach/beach-b.svg`,
    name: "Playa",
  },

  {
    id: 2,
    icon: `family/family-w.svg`,
    iconActive: `family/family-b.svg`,
    name: "Familia",
  },

  {
    id: 3,
    icon: `romantic/romantic-w.svg`,
    iconActive: `romantic/romantic-b.svg`,
    name: "Romántico",
  },

  {
    id: 4,
    icon: `business/business-w.svg`,
    iconActive: `business/business-b.svg`,
    name: "Negocios",
  },

  {
    id: 5,
    icon: `adults/adults-w.svg`,
    iconActive: `adults/adults-b.svg`,
    name: "Adultos",
  },

  {
    id: 6,
    icon: `pet/pet-w.svg`,
    iconActive: `pet/pet-b.svg`,
    name: "Pet Friendly",
  },
];

export const shuffleHotelTypes = [
  {
    code: 42883,
    name: "Park Royal Beach Cancun",
    codeName: "park-royal-beach-cancun",
    image:
      "https://cdn.worldota.net/t/x500/content/85/6c/856ce692b51b8c305884d9c23dbc682796affde3.JPEG",
    destinationCodeName: "cancun",
    price: 2341.8,
    stars: 4,
    address: "Blvd Kukulkan Km. 12.5, Zona Hotelera",
    es: {
      destination: "Cancun",
      country: "mexico",
    },
    en: {
      destination: "Cancun",
      country: "mexico",
    },
  },
  {
    code: 42886,
    name: "Sandos Caracol Eco Resort All Inclusive",
    codeName: "sandos-caracol-eco-resort-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/ec/ce/eccefa29e25b07b207bf740170970bd559f2d5a8.jpeg",
    destinationCodeName: "riviera-maya-playa-del-carmen",
    price: 2255.6,
    stars: 4,
    address: "Carretera Cancun, Chetumal, Km. 295",
    es: {
      destination: "Playa del Carmen",
      country: "mexico",
    },
    en: {
      destination: "Playa del Carmen",
      country: "mexico",
    },
  },
  {
    code: 42887,
    name: "Oleo Cancun Playa",
    codeName: "oleo-cancun-playa",
    image:
      "https://cdn.worldota.net/t/x500/content/d4/ff/d4ff720f13b426428f117258062231cacb348aad.jpeg",
    destinationCodeName: "cancun",
    price: 5303.4,
    stars: 5,
    address: "Blvd. Kukulcan Km. 19.5 Zona Hotelera",
    es: {
      destination: "Cancun",
      country: "mexico",
    },
    en: {
      destination: "Cancun",
      country: "mexico",
    },
  },
  {
    code: 42889,
    name: "Occidental Tucancun",
    codeName: "occidental-tucancun",
    image:
      "https://cdn.worldota.net/t/x500/content/1f/e4/1fe421d807f28abf6cc89b8db9c2f2d9b96df5ec.jpeg",
    destinationCodeName: "cancun",
    price: 2242.4,
    stars: 3,
    address: "Boulevard Kukulcan Km 14. Lote 24",
    es: {
      destination: "Cancun",
      country: "mexico",
    },
    en: {
      destination: "Cancun",
      country: "mexico",
    },
  },
  {
    code: 42893,
    name: "Fiesta Americana Condesa Cancun- All Inclusive",
    codeName: "fiesta-americana-condesa-cancun-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/27/a1/27a18b1c1f725829ba62328509857860c0f0616e.jpeg",
    destinationCodeName: "cancun",
    price: 5753.8,
    stars: 4,
    address: "Boulevard Kukulkan Km 16.5, Zona Hotelera",
    es: {
      destination: "Cancun",
      country: "mexico",
    },
    en: {
      destination: "Cancun",
      country: "mexico",
    },
  },
  {
    code: 42894,
    name: "Fiesta Americana Cozumel All Inclusive",
    codeName: "fiesta-americana-cozumel-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/33/9c/339cd021f94f5956dd58441668872669b9f97211.jpeg",
    destinationCodeName: "cozumel",
    price: 3421.2,
    stars: 4,
    address: "Carr. A Chankanaab Km. 7.5 Zona Hotelera Sur",
    es: {
      destination: "Cozumel",
      country: "mexico",
    },
    en: {
      destination: "Cozumel",
      country: "mexico",
    },
  },
  {
    code: 42906,
    name: "Plaza Pelicanos Grand Beach Resort",
    codeName: "plaza-pelicanos-grand-beach-resort",
    image:
      "https://cdn.worldota.net/t/x500/content/53/f5/53f5d66e1534c9726262daba24a97d39c9c35ad4.JPEG",
    destinationCodeName: "puerto-vallarta",
    price: 5506.6,
    stars: 1,
    address: "Jose Clemente Orozco #131",
    es: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
    en: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
  },
  {
    code: 42909,
    name: "Marival Emotions Resort & Suites All Inclusive",
    codeName: "marival-emotions-resort-suites-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/8b/75/8b756bbfd770cc957a24e5de29fb0a9bfeb7d003.jpeg",
    destinationCodeName: "puerto-vallarta",
    price: 5317.8,
    stars: 4,
    address: "Blvd. Nuevo Vallarta y Paseo Cocoteros",
    es: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
    en: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
  },
  {
    code: 42910,
    name: "Melia Puerto Vallarta All Inclusive",
    codeName: "melia-puerto-vallarta-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/db/71/db71e2a9c1090c6b28d51b0905006f0ec5613367.JPEG",
    destinationCodeName: "puerto-vallarta",
    price: 4048.4,
    stars: 3,
    address: "Paseo De La Marina Sur Lot H-7",
    es: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
    en: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
  },
  {
    code: 42911,
    name: "Tropicana",
    codeName: "tropicana",
    image:
      "https://cdn.worldota.net/t/x500/content/03/0c/030c9ca42f3a270823e6bbebc69c4925d1c8f219.JPEG",
    destinationCodeName: "puerto-vallarta",
    price: 2284.6,
    stars: 3,
    address: "Amapas #214 Col.",
    es: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
    en: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
  },
  {
    code: 42912,
    name: "Vamar Vallarta All Inclusive Marina & Beach Resort",
    codeName: "vamar-vallarta-all-inclusive-marina-beach-resort",
    image:
      "https://cdn.worldota.net/t/x500/content/ab/a3/aba38ab0efad56f534d05531eb4811cb0ef380af.jpeg",
    destinationCodeName: "puerto-vallarta",
    price: 2359,
    stars: 2,
    address: "Avenida Paseo de la Marina Sur S/N",
    es: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
    en: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
  },
  {
    code: 42915,
    name: "Fiesta Americana Puerto Vallarta All Inclusive",
    codeName: "fiesta-americana-puerto-vallarta-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/4e/20/4e2003a8b6683dff2a816e296fa394fff58c8cf3.jpeg",
    destinationCodeName: "puerto-vallarta",
    price: 4522.6,
    stars: 4,
    address: "Boulevard Francisco Medina Ascencio Km 2.5, Ascenc",
    es: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
    en: {
      destination: "Puerto Vallarta",
      country: "mexico",
    },
  },
  {
    code: 42926,
    name: "Viva Maya by Wyndham, Trademark All-Inclusive",
    codeName: "viva-maya-by-wyndham-trademark-all-inclusive",
    image:
      "https://cdn.worldota.net/t/x500/content/9b/2a/9b2a259892bd66bd942b5c9cf04d6bb921632558.jpeg",
    destinationCodeName: "riviera-maya-playa-del-carmen",
    price: 2242.4,
    stars: 4,
    address: "Ave. Xaman-ha, Manzana 5 Lote 1",
    es: {
      destination: "Playa del Carmen",
      country: "mexico",
    },
    en: {
      destination: "Playa del Carmen",
      country: "mexico",
    },
  },
];

export const homeRecommendedTour = [
  {
    id: 1,
    codeName: "paddle-surf-en-el-islote-el-morro",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/paddle-surf-islote-morro.jpg",
    category: 0,
    duration: "1h 30m",
    es: {
      name: "Paddle surf en el islote El Morro",
      destination: "Acapulco",
      country: "mexico",
      price: 255.72,
    },
    en: {
      name: "Isla El Morro Paddleboarding",
      destination: "Acapulco",
      country: "mexico",
      price: 255.72,
    },
  },
  {
    id: 2,
    codeName: "paseo-en-barco-por-acapulco",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/paseo-barco-acapulco.jpg",
    category: 4.5,
    duration: "2h 30m",
    es: {
      name: "Paseo en barco por Acapulco",
      destination: "Acapulco",
      country: "mexico",
      price: 434.72,
    },
    en: {
      name: "Acapulco Bay Cruise",
      destination: "Acapulco",
      country: "mexico",
      price: 434.72,
    },
  },
  {
    id: 5,
    codeName: "snorkel-en-la-bahia-de-acapulco",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/snorkel-bahia-acapulco.jpg",
    category: 0,
    duration: "1h 30m",
    es: {
      name: "Snorkel en la bahía de Acapulco",
      destination: "Acapulco",
      country: "mexico",
      price: 358,
    },
    en: {
      name: "Acapulco Bay Snorkelling",
      destination: "Acapulco",
      country: "mexico",
      price: 358,
    },
  },
  {
    id: 6,
    codeName: "cena-y-espectaculo-de-clavados-en-acapulco",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/cena-espectaculo-clavados-acapulco.jpg",
    category: 3.5,
    duration: "3h",
    es: {
      name: "Cena y espectáculo de clavados en Acapulco",
      destination: "Acapulco",
      country: "mexico",
      price: 1704.76,
    },
    en: {
      name: "Acapulco Cliff Dive Show & Dinner",
      destination: "Acapulco",
      country: "mexico",
      price: 1704.76,
    },
  },
  {
    id: 12,
    codeName: "excursion-a-la-isla-de-la-roqueta",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/excursion-isla-roqueta.jpg",
    category: 2,
    duration: "4h 30m",
    es: {
      name: "Excursión a la isla de La Roqueta",
      destination: "Acapulco",
      country: "mexico",
      price: 1263.23,
    },
    en: {
      name: "La Roqueta Island Day Trip",
      destination: "Acapulco",
      country: "mexico",
      price: 1263.23,
    },
  },
  {
    id: 14,
    codeName: "tour-por-el-fuerte-de-san-diego-playas-caleta-y-caletilla",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/fuerte-san-diego-caleta-caletilla.jpg",
    category: 0,
    duration: "6h",
    es: {
      name: "Tour por el Fuerte de San Diego + Playas Caleta y Caletilla",
      destination: "Acapulco",
      country: "mexico",
      price: 834.35,
    },
    en: {
      name: "San Diego Fort Tour + Caleta and Caletilla Beaches",
      destination: "Acapulco",
      country: "mexico",
      price: 834.35,
    },
  },
  {
    id: 121,
    codeName: "paseo-en-catamaran-por-el-mar-de-cortes",
    destinationCodeName: "los-cabos",
    image:
      "https://cdn2.civitatis.com/mexico/los-cabos/paseo-catamaran-mar-cortes.jpg",
    category: 4.5,
    duration: "3h 30m",
    es: {
      name: "Paseo en catamarán por el Mar de Cortés",
      destination: "Los Cabos",
      country: "mexico",
      price: 1443.97,
    },
    en: {
      name: "Los Cabos Boat Ride",
      destination: "Los Cabos",
      country: "mexico",
      price: 1443.97,
    },
  },
  {
    id: 365,
    codeName: "paseo-a-caballo-por-tequila-visita-a-una-destileria-con-cata",
    destinationCodeName: "guadalajara",
    image:
      "https://cdn2.civitatis.com/mexico/guadalajara/paseo-caballo-tequila-visita-destileria-cata.jpg",
    category: 5,
    duration: "6h",
    es: {
      name: "Paseo a caballo por Tequila + Visita a una destilería con cata",
      destination: "Guadalajara",
      country: "mexico",
      price: 2945.84,
    },
    en: {
      name: "Horseback Riding in Tequila & Distillery Visit with Tasting",
      destination: "Guadalajara",
      country: "mexico",
      price: 2945.84,
    },
  },
  {
    id: 500,
    codeName: "senderismo-por-la-barranca-tararecua",
    destinationCodeName: "creel",
    image:
      "https://cdn2.civitatis.com/mexico/creel/senderismo-barranca-tararecua.jpg",
    category: 1,
    duration: "8h",
    es: {
      name: "Senderismo por la Barranca Tararecua",
      destination: "Creel",
      country: "mexico",
      price: 2365.37,
    },
    en: {
      name: "Hiking Through the Tararecua Canyon",
      destination: "Creel",
      country: "mexico",
      price: 2365.37,
    },
  },
  {
    id: 700,
    codeName: "free-tour-por-merida",
    destinationCodeName: "merida-yucatan",
    image: "https://cdn2.civitatis.com/mexico/merida/free-tour-merida.jpg",
    category: 4.5,
    duration: "2h",
    es: {
      name: "Free tour por Mérida",
      destination: "Merida - Yucatan",
      country: "mexico",
      price: 0,
    },
    en: {
      name: "Mérida Free Tour",
      destination: "Mérida",
      country: "mexico",
      price: 0,
    },
  },
  {
    id: 721,
    codeName: "excursion-a-yaxuna-cenote-lol-ha",
    destinationCodeName: "merida-yucatan",
    image:
      "https://cdn2.civitatis.com/mexico/merida/excursion-yaxuna-cenote-lol-ha.jpg",
    category: 0,
    duration: "11h 30m",
    es: {
      name: "Excursión a Yaxuná + Cenote Lol Ha",
      destination: "Merida - Yucatan",
      country: "mexico",
      price: 4858.59,
    },
  },
  {
    id: 730,
    codeName: "visita-a-un-viedo-de-san-miguel-de-allende",
    destinationCodeName: "san-miguel-de-allende",
    image:
      "https://cdn2.civitatis.com/mexico/guanajuato/visita-vinedo-san-miguel-allende.jpg",
    category: 0,
    duration: "4h",
    es: {
      name: "Visita a un viñedo de San Miguel de Allende",
      destination: "San Miguel de Allende",
      country: "mexico",
      price: 1761.47,
    },
    en: {
      name: "Visit to a Vineyard in San Miguel de Allende",
      destination: "San Miguel de Allende",
      country: "mexico",
      price: 1761.47,
    },
  },
  {
    id: 837,
    codeName: "paseo-en-submarino-por-cozumel",
    destinationCodeName: "cozumel",
    image:
      "https://cdn2.civitatis.com/mexico/cozumel/paseo-submarino-cozumel.jpg",
    category: 5,
    duration: "2h",
    es: {
      name: "Paseo en submarino por Cozumel",
      destination: "Cozumel",
      country: "mexico",
      price: 2148.01,
    },
    en: {
      name: "Submarine Trip in Cozumel",
      destination: "Cozumel",
      country: "mexico",
      price: 2148.01,
    },
  },
  {
    id: 886,
    codeName: "free-tour-por-playa-del-carmen",
    destinationCodeName: "riviera-maya-playa-del-carmen",
    image:
      "https://cdn2.civitatis.com/mexico/playa-del-carmen/free-tour-playa-carmen.jpg",
    category: 5,
    duration: "1h 30m",
    es: {
      name: "Free tour por Playa del Carmen",
      destination: "Riviera Maya / Playa del Carmen",
      country: "mexico",
      price: 0,
    },
  },
  {
    id: 1100,
    codeName: "tour-de-tacos-por-holbox",
    destinationCodeName: "isla-holbox",
    image: "https://cdn2.civitatis.com/mexico/holbox/tour-tacos-holbox.jpg",
    category: 1,
    duration: "3h",
    es: {
      name: "Tour de tacos por Holbox",
      destination: "Isla Holbox",
      country: "mexico",
      price: 1636.58,
    },
    en: {
      name: "Holbox Taco Tour",
      destination: "Holbox",
      country: "mexico",
      price: 1636.58,
    },
  },
  {
    id: 1111,
    codeName: "entrada-al-parador-ecoturistico-yokdzonot",
    destinationCodeName: "chichen-itza",
    image:
      "https://cdn2.civitatis.com/mexico/chichen-itza/entrada-parador-ecoturistico-yokdzonot.jpg",
    category: 5,
    duration: "",
    es: {
      name: "Entrada al Parador Ecoturístico Yokdzonot",
      destination: "Chichén Itzá",
      country: "mexico",
      price: 239.35,
    },
    en: {
      name: "Cenote Yokdzonot Ticket",
      destination: "Chichen Itza",
      country: "mexico",
      price: 239.35,
    },
  },
];

export const transportData = [
  {
    id: 1,
    name: "Vehículo de 6 plazas",
    type: "private",
    img: "https://cdn2.civitatis.com/imagenes/traslados/700x327/van.jpg",
    capacity: 6,
    polices: {
      advance: 72,
      cancellation: 24,
    },
    facilities: {
      largeSuitcase: 5,
      handSuitcase: 0,
    },
  },
  {
    id: 2,
    name: "Traslado compartido",
    type: "shared",
    img: "https://cdn2.civitatis.com/imagenes/traslados/700x327/transporte-compartido.jpg",
    capacity: 59,
    polices: {
      advance: 48,
      cancellation: 48,
    },
    facilities: {
      largeSuitcase: 59,
      handSuitcase: 59,
    },
  },
  {
    id: 3,
    name: "Minibús de 10 plazas",
    type: "private",
    img: "https://cdn2.civitatis.com/imagenes/traslados/700x327/bus.jpg",
    capacity: 10,
    polices: {
      advance: 72,
      cancellation: 24,
    },
    facilities: {
      largeSuitcase: 10,
      handSuitcase: 10,
    },
  },
  {
    id: 4,
    name: "Turismo de 3 plazas",
    type: "private",
    img: "https://cdn2.civitatis.com/imagenes/traslados/700x327/sedan-turismo.jpg",
    capacity: 3,
    polices: {
      advance: 72,
      cancellation: 24,
    },
    facilities: {
      largeSuitcase: 3,
      handSuitcase: 3,
    },
  },
  {
    id: 5,
    name: "Minibús de 18 plazas",
    type: "private",
    img: "https://cdn2.civitatis.com/imagenes/traslados/700x327/bus.jpg",
    capacity: 18,
    polices: {
      advance: 72,
      cancellation: 24,
    },
    facilities: {
      largeSuitcase: 18,
      handSuitcase: 18,
    },
  },
  {
    id: 6,
    name: "Minibús de 10 plazas",
    type: "private",
    img: "https://cdn2.civitatis.com/imagenes/traslados/700x327/bus.jpg",
    capacity: 10,
    polices: {
      advance: 24,
      cancellation: 24,
    },
    facilities: {
      largeSuitcase: 7,
      handSuitcase: 10,
    },
  },
];
