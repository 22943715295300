import BtnAddRH from "./BtnAddRH";
import SearchRH from "./SearchRH";
import FiltersRH from "./Filters/FiltersRH";
import { FiltersContainerRh } from "./Filters/FiltersContainerRh";
import { UseRHContext } from "../context/RHContext";

export default function RHListing() {
  
  const { refreshUserList} = UseRHContext();


  return (
    <div
      className={`flex flex-col gap-y-4 transition-all duration-500 justify-center pb-12 `}
    >
      <div className="flex justify-between ">
        <div className="flex items-center gap-x-4">
          <SearchRH />

          <button
            className="focus:outline-none w-[20px] h-[20px]"
            onClick={refreshUserList}
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/crm/refresh.svg`}
              alt="refresh users"
            />
          </button>
        </div>

        <div className="flex gap-5">
          <FiltersRH />
          <BtnAddRH />
        </div>
      </div>

      <FiltersContainerRh/>
    </div>
  );
}
