import RHLayout from "../sections/RH/Layout/RHLayout";
import UsersLayout from "../sections/Users/Layout/UsersLayout";
import { LoginLayout } from "../sections/Login/Layout/LoginLayout";
import MarketingLayout from "../sections/Marketing/Layout/MarketingLayout";
import VacationsLayout from "../sections/Vacations/Layout/VacationsLayout";
import { LayoutPresentation } from "../sections/Presentation/LayoutPresentation";
import { LayoutViewSelected } from "../sections/Marketing/Layout/LayoutViewSelected";
import { ReservationsLayout } from "../sections/Reservations/Layout/ReservationsLayout";
import { LayoutConfiguration } from "../sections/Configuration/layout/LayoutConfiguration";
import Unauthorized from "../components/unauthorized/Unauthorized";

// modules
// _________________
// 1	 | rh
// 2	 | resevas
// 3	 | marketing
// 4	 | finanzas
// 5	 | vacaciones
// 6   | usuarios

export const PhatRoutings = () => {
  const establishedRoutes = [
    { path: "*", component: Unauthorized, allowedModules: [5] },
    { path: "/dashboard", component: LayoutPresentation, allowedModules: [5] },
    { path: "/login", component: LoginLayout, allowedModules: ["guest"] },
    { path: "/users", component: UsersLayout, allowedModules: [6] },
    { path: "/reservations", component: ReservationsLayout, allowedModules: [2] },
    { path: "/rh", component: RHLayout, allowedModules: [1] },
    { path: "/vacations", component: VacationsLayout, allowedModules: [5] },
    { path: "/marketing", component: MarketingLayout, allowedModules: [3] },
    { path: "/mkt/:responsive/:view/:lang", component: LayoutViewSelected, allowedModules: [3] },
    { path: "/configurations", component: LayoutConfiguration, allowedModules: [7] },
  ];

  return establishedRoutes;
};

