/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { UserImage } from "./UserImage";
import { UpdateUser } from "../../api/api";
import { logoutApi } from "../../../../api/api";
import { UpdatePassword } from "./UpdatePassword";
import AlertsCrm from "../../../../components/alerts/Alerts";
import { useUser } from "../../../../configs/login/UserContext";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";

export default function EditUser({ users, isOpen, setIsOpen }) {
  const {
    imageUser,
    setImageUser,
    samePassword,
    isValidationPassword,
    setSamePassword,
  } = useUser();

  const { notification, showNotification, hideNotification } =
    useNotification();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, token, setToken, user } = useUser();
  const [isLoader, setIsLoader] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [changePassword, setChangePassword] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");

  useEffect(() => {
    if (confirmedPassword.length >= 0) {
      if (password === confirmedPassword) {
        setSamePassword(true);
      } else {
        setSamePassword(false);
      }
    } else {
      setSamePassword(true);
    }
  }, [confirmedPassword, password]);

  useEffect(() => {
    if (isPhoneEdit) {
      setPhone(users.phone);
    }
  }, [isPhoneEdit]);

  useEffect(() => {
    validationNumber(phone);
  }, [phone]);

  function openModal() {
    setIsOpen(true);
  }

  function handleModal() {
    setPassword("");
    setConfirmedPassword("");
    setImageUser(null);
    setIsOpen(false);
  }

  const handleLogout = async () => {
    try {
      await logoutApi(token);

      setUser(null);
      setToken(null);

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const validationNumber = (value) => {
    if (value) {
      value = value.trim();
      if (value.length < 10) {
        setIsValidPhone(false);
        return false;
      } else {
        setIsValidPhone(true);
        return true;
      }
    } else {
      setIsValidPhone(false);
      return false;
    }
  };

  const handleUpdateUser = async () => {
    let infoToUpdate = {};

    if (imageUser) {
      infoToUpdate = { ...infoToUpdate, image: imageUser };
    }
    let validNumber = validationNumber(phone);
    if (!validNumber && isPhoneEdit) {
      return;
    }

    if (validNumber && isPhoneEdit) {
      infoToUpdate = { ...infoToUpdate, phone };
    }

    if (changePassword && (password === "" || confirmedPassword === "")) {
      setSamePassword(false);
      return;
    }

    if (changePassword && samePassword) {
      if (isValidationPassword) {
        setHasPassword(true);
        infoToUpdate = {
          ...infoToUpdate,
          password: password,
          password_confirmation: confirmedPassword,
        };
      }
    }

    if (Object.keys(infoToUpdate).length > 0) {
      setOpenAlert(true);
      setDataToEdit(infoToUpdate);
    } else {
      setOpenAlert(false);
    }
  };

  const confirmAlert = async () => {
    setOpenAlert(false);
    setIsLoader(true);
    try {
      const responseUserUpdated = await UpdateUser(dataToEdit, users.id);

      if (responseUserUpdated.code === "ok") {
        if (!hasPassword) {
          let newUser = {
            ...user,
            url_image: responseUserUpdated.user.url_image,
            phone: responseUserUpdated.user.phone,
          };

          setUser(newUser);

          setIsLoader(false);
          // window.location.reload();
        } else {
          handleLogout();
        }
      }
    } catch (error) {
      setIsLoader(false);
      showNotification(
        "error",
        "Ocurrió un error en la actualización",
        "Por favor inténtelo más tarde",
        4000
      );
      console.log("error", error);
      throw error;
    }
  };

  const handleNumbers = (numbers) => {
    return numbers.replace(/\D/g, "");
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[500px] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-100.svg`}
                      width={16}
                      height={16}
                      alt="icon-close"
                      className="mr-2 cursor-pointer absolute right-4"
                      onClick={handleModal}
                    />

                    {/* PROFILE */}
                    <div className="flex gap-4">
                      {/* IMG PROFILE */}
                      <div className="relative w-[120px] h-[120px] bg-gry-50 rounded-lg p-2">
                        <UserImage />
                      </div>

                      <div className="flex flex-col items-start gap-4">
                        {/* AVAILABLE */}
                        <div className="flex items-center gap-1">
                          <div
                            className={`rounded-full w-3 h-3 ${
                              users.status.toLowerCase() === "activo"
                                ? " bg-grn-100"
                                : " bg-gry-100"
                            }`}
                          />
                          <span className="m-m text-fs-12 text-[#5F5F5F]">
                            {users.status}
                          </span>
                        </div>

                        {/* NAME */}
                        <span className="text-fs-18 text-[#5F5F5F] m-b">
                          {users.name}
                        </span>
                      </div>
                    </div>

                    {/* GMAIL AND TEL */}
                    <div className="flex flex-col gap-3 mt-4 pt-4 border-t-2 border-gry-50">
                      {/*CHANGE GMAIL */}
                      <div className="flex flex-col gap-2">
                        <span className="m-b text-fs-14 text-[#5F5F5F]">
                          Correo electrónico
                        </span>

                        <span className="m-m text-fs-12 text-[#5F5F5F]">
                          {users.email}
                        </span>
                      </div>

                      {/*CHANGE TEL */}
                      <div className="flex flex-col gap-2 ">
                        <div className="flex gap-x-2 items-center">
                          <span className="m-b text-fs-14 text-[#5F5F5F]">
                            Teléfono
                          </span>

                          <button
                            onClick={() => setIsPhoneEdit(!isPhoneEdit)}
                            className="relative w-[12px] h-[12px]"
                          >
                            <img
                              src={`${
                                import.meta.env.VITE_URL_IMAGES
                              }assets/icons/crm/pencil-edit.svg`}
                              width={16}
                              height={16}
                              alt="icon-close"
                            />
                            {isPhoneEdit && (
                              <hr className="absolute bg-black w-[2px] inset-x-0 inset-y-0 rotate-[160deg] mx-auto my-auto h-full" />
                            )}
                          </button>
                        </div>

                        {isPhoneEdit ? (
                          <div className="relative w-full border border-gry-50 h-[43px]">
                            <img
                              src={`${
                                import.meta.env.VITE_URL_IMAGES
                              }assets/icons/tel/tel-b.svg`}
                              width={15}
                              height={15}
                              alt="icon-tel-crm"
                              className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                            />

                            <input
                              type="tel"
                              className={`border py-2 my-auto pl-[40px] focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 ${
                                !isValidPhone
                                  ? "border-red-100"
                                  : "border-gry-50"
                              } `}
                              placeholder="Teléfono"
                              value={phone}
                              maxLength={10}
                              onChange={(e) =>
                                setPhone(handleNumbers(e.target.value))
                              }
                            />

                            {!isValidPhone && (
                              <p className="text-red-500 text-xs">
                                El teléfono es requerido
                              </p>
                            )}
                          </div>
                        ) : (
                          <span className="m-m text-fs-12 text-[#5F5F5F]">
                            {users.phone}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* ADMIN , NOTIFICATION AND PASS */}
                    <div
                      className={`flex flex-col gap-3 border-t border-gry-50 pt-4 ${
                        isPhoneEdit && !isValidPhone ? "mt-8 " : "mt-4"
                      }`}
                    >
                      {/* PASSWORD */}
                      <div className="flex flex-col gap-2">
                        <span className="m-b text-fs-14 text-[#5F5F5F]">
                          Contraseña
                        </span>

                        {changePassword ? (
                          <UpdatePassword
                            cancelUpdate={setChangePassword}
                            password={password}
                            setPassword={setPassword}
                            confirmedPassword={confirmedPassword}
                            setConfirmedPassword={setConfirmedPassword}
                          />
                        ) : (
                          <>
                            <span
                              className="text-bl-100 m-m text-fs-10 cursor-pointer w-max"
                              onClick={() => setChangePassword(true)}
                            >
                              Cambiar contraseña
                            </span>
                          </>
                        )}
                      </div>

                      {/* BTN SAVE */}
                      <button
                        onClick={handleUpdateUser}
                        className={`flex gap-1 px-3 py-3 rounded-lg bg-bl-100 w-fit text-fs-12 text-white m-s-b ${
                          !isLoader && "hover:bg-bl-110 mt-3"
                        }`}
                        disabled={isLoader}
                      >
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/save-w.svg`}
                          alt="icon save white"
                          width={18}
                          height={18}
                        />

                        {isLoader ? "Guardando..." : "Guardar"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <AlertsCrm
        setConfirmation={confirmAlert}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title="¿Deseas actualizar tu perfil?"
        text="¡Si actualizas tu contraseña se te sacará de la sección!"
        typeAlert={"warning"}
      />

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
