import React from "react";
import { Tooltip } from "../Tooltip/Tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Settings({ settings, isOpen, handleRoute }) {
  return (
    <ul role="list" className="-mx-2 mt-2 space-y-1">
      {settings.map((sett) => (
        <li
          key={sett.name}
          className={`h-[52px] flex ${!isOpen && "justify-center"}`}
        >
          <Tooltip text={<React.Fragment>{sett.name}</React.Fragment>}>
            <div
              onClick={() => handleRoute(sett.href)}
              className={`${classNames(
                sett.current
                  ? "relative bg-gry-50 text-bl-100 after:border-[1.8px] after:border-bl-100 after:absolute after:left-[-1px] after:h-[30px] after:rounded-lg"
                  : "text-gry-100 hover:bg-gry-50 hover:text-bl-100",
                "group flex gap-x-3 rounded-md p-2 m-s-b text-fs-14 leading-6 h-full items-center"
              )} ${isOpen ? "" : "justify-center"}`}
            >
              <img
                src={`${classNames(sett.current ? sett.iconA : sett.icon)}`}
                alt={sett.alt}
              />
              <span className={`${isOpen ? "truncate" : "hidden"}`}>
                {sett.name}
              </span>
            </div>
          </Tooltip>
        </li>
      ))}
    </ul>
  );
}
