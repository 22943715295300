import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return re.test(password);
  };

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      token: params.get("token"),
      email: params.get("email"),
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!validatePassword(password)) {
      setError(
        "La contraseña debe tener al menos 8 caracteres, incluyendo un número y un carácter especial."
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden.");
      return;
    }

    setIsLoading(true);

    const { token, email } = getQueryParams();

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL_STAYWUW}/password-reset`,
        {
          email: email,
          password: password,
          password_confirmation: confirmPassword,
          token: token,
        }
      );

      if (response?.data?.code == "ok") {
        navigate("/login");
      } else {
        setError(
          "Hubo un problema al restablecer la contraseña. Por favor, inténtelo de nuevo."
        );
      }
    } catch (error) {
      setError(
        "Hubo un problema al restablecer la contraseña. Por favor, inténtelo de nuevo."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="h-screen flex justify-center items-center"
      style={{
        backgroundImage:
          "url('https://image.cdn2.seaart.ai/2023-07-18/46388951261253/a4fe07580489c6351a2f089609beb657ebafa944_high.webp')",
      }}
    >
      <div className="w-[400px] flex flex-col items-center gap-4 border-4 border-gray-100 p-8 rounded-[42px] shadow-3xl bg-white">
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/royal/principal-logo.svg`}
          alt="logo stay-wuw"
          className="w-[200px] h-[93px] mb-[10px]"
        />

        <span className="m-s-b text-fs-20 text-center">
          Restablecer contraseña
        </span>

        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <label className="m-b text-fs-12">Nueva contraseña:</label>
            <input
              placeholder="Nueva contraseña"
              className="appearance-none outline-none border-2 border-gry-30 rounded-lg h-[45px] pl-5 w-full pr-8"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="m-b text-fs-12">Confirmar contraseña:</label>
            <input
              placeholder="Confirmar contraseña"
              className="appearance-none outline-none border-2 border-gry-30 rounded-lg h-[45px] pl-5 w-full pr-8"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
            />
          </div>
          {error && <span className="text-red-500 text-fs-12">{error}</span>}
          <button
            className="text-white m-b text-fs-12 bg-bl-100 w-full rounded-lg py-3 hover:bg-bl-110"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Restableciendo..." : "Restablecer contraseña"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
