import moment from "moment";

export const getHolidays = (currentYear) => [
  {
    name: "Año nuevo",
    day: 1,
    month: 1,
    year: currentYear,
  },
  {
    name: "Día de la Constitución",
    day: getFirstMondayOfFebruary(currentYear),
    month: 2,
    year: currentYear,
  },
  {
    name: "Natalicio de Benito Juárez",
    day: getThirdMondayOfMonth(currentYear,3),
    month: 3,
    year: currentYear,
  },
  {
    name: "Día del Trabajo",
    day: 1,
    month: 5,
    year: currentYear,
  },
  {
    name: "Transmisión del Poder Ejecutivo",
    day: 1,
    month: 10,
    year: 2024,
  },
  {
    name: "Día de la Independencia",
    day: 16,
    month: 9,
    year: currentYear,
  },
  {
    name: "Revolución Mexicana",
    day: getThirdMondayOfMonth(currentYear,11),
    month: 11,
    year: currentYear,
  },
  {
    name: "Navidad 🎅",
    day: 25,
    month: 12,
    year: currentYear,
  },
];

function getFirstMondayOfFebruary(year) {
  const firstDayOfFebruary = moment(`${year}-02-01`);
  const dayOfWeek = firstDayOfFebruary.day();

  // IF 1 FIRST DAY IS NOT MONDAY ADVANCE ONE
  if (dayOfWeek !== 1) {
    firstDayOfFebruary.add((8 - dayOfWeek) % 7, 'days'); // ADD DAYS REQUIRED
  }
  
  return firstDayOfFebruary.date(); // RETURN DAY NUMBER
}


function getThirdMondayOfMonth(year, month) {
  const firstDayOfMonth = moment(`${year}-${month}-01`);
  const dayOfWeek = firstDayOfMonth.day();

  // IF FIRST DAY IS NOT MONDAY, ADVANCE TO FIRST MONDAY
  const firstMonday = dayOfWeek === 1 
    ? firstDayOfMonth 
    : firstDayOfMonth.add((8 - dayOfWeek) % 7, 'days');

  // ADD 14 DAYS TO GET THE THIRD MONDAY
  const thirdMonday = firstMonday.add(14, 'days');
  
  return thirdMonday.date(); // RETURN DAY NUMBER
}
