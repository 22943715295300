import moment from "moment";
import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { CancelBookingRequest } from "../../api/Reservations";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { UseReservations } from "../../context/ReservationsContext";

export default function ModalCancellation({ isOpen, setIsOpen, hotel }) {
  const leftStyle = `m-b m-0 w-[179px] text-fs-12 text-gry-100`;
  const [password, setPassword] = useState("");
  const { handleReservations } = UseReservations();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRoom, setIsOpenRoom] = useState(false);
  const [isOpenTypeR, setIsOpenTypeR] = useState(false);
  const [isTextType, setIsTextType] = useState(false);
  const { notification, showNotification, hideNotification } =
    useNotification();

  useEffect(() => {
    if (isOpen) {
      setPassword("");
    }
  }, [isOpen]);

  const handleCancelBooking = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await CancelBookingRequest(hotel.booking_id, password);
        handleReservations();
        showNotification(
          "success",
          "Enviado",
          "Tu solicitud de cancelación de reserva ha sido enviada con éxito.",
          5000
        );
        setIsOpen(false);
      } catch (error) {
        showNotification(
          "error",
          "Error",
          "Revise si la contraseña es correcta",
          5000
        );
      } finally {
        setIsLoading(false);
      }
    }
  };


  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[2]"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 " />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg p-5 relative w-[500px]">
                {/* CLOSE MODAL */}
                <button
                  onClick={() => setIsOpen(false)}
                  className="focus:outline-none absolute right-0 top-[12px] mr-3"
                >
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/close/close-70.svg`}
                    alt="close modal"
                    width={13}
                    height={13}
                  />
                </button>

                <h3 className="m-s-b text-fs-16 pb-3 text-red-100">
                  Solicitud de Cancelación de Reserva
                </h3>
                <div className="flex gap-4 w-full">
                  {/* IMG HOTEL */}
                  <div className="bg-gry-50 w-[25%] h-[120px] rounded-lg"></div>
                  {/* NAME HOTEL */}
                  <div className="flex flex-col gap-2 w-[75%]">
                    <h3 className="m-b text-fs-20 ">{hotel.hotel_name}</h3>

                    <div className="m-b text-fs-12 text-bl-100 flex gap-2">
                      Hotel Beds
                      <span className="m-m text-gry-100">
                        {" "}
                        {hotel.provider_booking_id}
                      </span>
                    </div>

                    <div className="m-b text-fs-12 text-bl-100 flex gap-2">
                      StayWuw
                      <span className="m-m text-gry-100">
                        {" "}
                        {hotel.booking_id}
                      </span>
                    </div>
                  </div>
                </div>
                {/* POLICY */}
                <div className="flex gap-1 mt-2">
                  <div
                    className={`px-2 py-1 bg-grn-10 text-grn-100 rounded m-b text-fs-12 w-fit`}
                  >
                    Se puede cancelar antes del{" "}
                    {hotel.rooms[0].cancellation_policies?.policy?.from ||
                      "17/05/2024"}{" "}
                    con un cargo del{" "}
                    {hotel.rooms[0].cancellation_policies?.policy?.percentage ||
                      "100"}
                    %*
                  </div>
                </div>

                <hr className="my-4 border-[1.5px]" />

                {/* INFO CANCELLATION */}
                <div className="h-[17rem] overflow-auto scroll-page-gry">
                  {/* CHECK IN AND CHECK OUT */}
                  <div className="flex items-center gap-x-5 py-[5px]">
                    <p className={`${leftStyle}`}>Check In</p>
                    <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                      {moment(hotel.check_in).format("DD/MM/YYYY")}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-5 py-[5px]">
                    <p className={`${leftStyle}`}>Check Out</p>
                    <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                      {moment(hotel.checkOut).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  {/* ROOMS */}
                  <div className="flex items-center gap-x-5 py-[5px]">
                    <p className={`${leftStyle}`}>Habitaciones</p>
                    <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                      {hotel.rooms.length}
                    </p>
                  </div>

                  {/* BTN SEE MORE */}
                  <button
                    className="flex gap-1 items-center ml-[190px]"
                    onClick={() => setIsOpenRoom(!isOpenRoom)}
                  >
                    <span className="m-s-b text-bl-100 text-fs-14">
                      {isOpenRoom ? "Ver Menos" : "Ver más"}
                    </span>
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/arrows/arrow-drop-down-bl.svg`}
                      alt="icon arrow down"
                      width={10}
                      height={10}
                      // className="rotate-180 transform"
                      className={`${isOpenRoom ? "rotate-180 transform" : ""} `}
                    />
                  </button>

                  {/* SEE MORE ROOMS */}
                  {isOpenRoom &&
                    hotel.rooms.map((room, item) => (
                      <div className="" key={item}>
                        <p className={`${leftStyle}`}>Habitación {item + 1}</p>

                        <div className="ml-5 flex items-center gap-x-5 py-[5px]">
                          <p className={`${leftStyle}`}>Titular</p>
                          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                            {room.holder}
                          </p>
                        </div>

                        <div className="ml-5 flex items-center gap-x-5 py-[5px]">
                          <p className={`${leftStyle}`}>{room.room_name}</p>
                          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                            {room.paxes}
                          </p>
                        </div>
                      </div>
                    ))}

                  {/* ROOM TYPE */}
                  <div className="flex items-center gap-x-5 py-[5px]">
                    <p className={`${leftStyle}`}>Tipo de habitación</p>
                    <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                      {hotel.rooms[0].room_name}
                    </p>

                    <button onClick={() => setIsOpenTypeR(!isOpenTypeR)}>
                      <img
                        src={`${
                          import.meta.env.VITE_URL_IMAGES
                        }assets/icons/arrows/arrow-drop-down-bl.svg`}
                        alt="icon arrow down"
                        width={10}
                        height={10}
                        className={`${
                          isOpenTypeR ? "rotate-180 transform" : ""
                        } `}
                      />
                    </button>
                  </div>
                  {isOpenTypeR &&
                    hotel.rooms
                      .filter((_, index) => index !== 0)
                      .map((type, index) => (
                        <p
                          key={index}
                          className="ml-[199px] m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100"
                        >
                          {type.room_name}
                        </p>
                      ))}

                  {/* GUEST */}
                  <div className="flex gap-x-5 py-[5px]">
                    <p className={`m-m m-0 w-[179px] text-fs-12 text-gry-100`}>
                      Huéspedes
                    </p>
                    <div className="flex flex-col gap-2">
                      {hotel.rooms.map((guest, index) => (
                        <p
                          key={index}
                          className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100"
                        >
                          {guest.holder}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>

                {/* TOTAL PRICE */}
                <div className="flex items-center gap-x-5 py-[5px]">
                  <p className={`${leftStyle}`}>Costo total</p>
                  <p className="m-0 text-fs-14 m-m items-center text-gry-100">
                    $
                    {Math.floor(hotel.totalAmount)
                      .toLocaleString("es-MX", { currency: "MXN" })
                      .replace(".00", "")}
                    .
                    <sup className="sup-price-cart">
                      {(hotel.totalAmount % 1).toFixed(2).slice(2)}{" "}
                    </sup>{" "}
                    (impuestos incluidos)
                  </p>
                </div>

                <hr className="my-4 border-[1.5px]" />

                {/* PASSWORD */}
                <div className="flex flex-col gap-1 mb-3">
                  <p
                    className={`m-b m-0 w-[179px] p-0 text-fs-14 text-gry-100`}
                  >
                    Contraseña
                  </p>
                  <span className="m-m m-0 p-0  text-fs-10 text-gry-100">
                    *Para autorizar la cancelacion se debera confirmar la
                    contraseña*
                  </span>

                  <div className="relative w-full rounded border border-gry-50 h-[43px] mt-4 mb-5">
                    <input
                      type={isTextType ? "text" : "password"}
                      placeholder="Contraseña"
                      className="border border-gry-50 py-2 my-auto pl-[10px] focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                      value={password}
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <img
                      onClick={() => setIsTextType(!isTextType)}
                      className="absolute inset-y-0 right-[10px] my-auto cursor-pointer"
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/general/${
                        isTextType ? "eye.svg" : "eye-close.svg"
                      }`}
                      alt="toggle password visibility"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>

                {/* BTN SAVE AND CANCELATION */}
                <div className="flex justify-center gap-6">
                  <a
                    onClick={() => setIsOpen(false)}
                    className="focus:outline-none text-red-100 text-fs-12 m-b cursor-pointer p-4 rounded-lg hover:text-white hover:bg-red-100"
                  >
                    Cancelar
                  </a>

                  <button
                    type="submit"
                    className="gap-2 focus:outline-none rounded-lg bg-bl-100 hover:bg-bl-110 flex items-center p-4 text-white text-fs-12 m-b"
                    onClick={handleCancelBooking}
                    disabled={isLoading}
                  >
                    {isLoading ? "Enviando..." : "Enviar"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
