import { UseReservations } from "../context/ReservationsContext";

export function SearchReviews() {
  const { setSearchTable, searchTable } = UseReservations();

  const handleLetters = (input) =>
    input.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, "");

  const handleSearch = (event) => {
    const newValue = handleLetters(event.target.value);

    if (newValue.includes("@")) {
      return;
    }
    setSearchTable(event.target.value);
  };

  return (
    <div className="relative bg-gry-30 rounded-lg h-[43px] min-w-full lg:min-w-[456px]">
      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/search/search-black-crm.svg`}
        width={15}
        height={15}
        alt="search-black-crm"
        className="absolute top-0 bottom-0 ml-[18px] my-auto"
      />
      <form autoComplete="off" className="w-full">
        <input
          type="text"
          className="my-auto pl-[40px] absolute left-0 top-0 right-0 bottom-0  focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
          placeholder="Buscar"
          value={searchTable}
          onChange={handleSearch}
        />
      </form>
    </div>
  );
}
