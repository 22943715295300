import TableRH from "./TableRH";
import { UseRHContext } from "../context/RHContext";

const tabs = [
  { label: "Todos", value: "ALL" },
  { label: "Indefinido", value: "INDEFINIDO" },
  { label: "Temporal", value: "TEMPORAL" },
  { label: "Sin contrato", value: "OTROS" },
  { label: "Vacaciones", value: "VACATIONS" },
];

export default function TabTablaRH() {
  const {
    setFilterStatus,
    filterStatus,
    allTotal,
    temporaryTotal,
    vacationsTotal,
    indefiniteTotal,
    withoutContract,
  } = UseRHContext();

  const handleValue = (value) => {
    let statusSelected = value.toUpperCase();
    setFilterStatus(statusSelected);
  };

  const validateContract = (value) => {
    let total = 0;

    switch (value) {
      case "ALL":
        total = allTotal;
        break;
      case "INDEFINIDO":
        total = indefiniteTotal;
        break;
      case "TEMPORAL":
        total = temporaryTotal;
        break;
      case "OTROS":
        total = withoutContract;
        break;
      case "VACATIONS":
        total = vacationsTotal;
        break;
    }
    return total;
  };

  return (
    <>
      <div className="flex gap-x-2 text-fs-12 m-s-b overflow-x-auto">
        {tabs.map((tab, index) => (
          <button
            className="focus:outline-none focus:ring-transparent p-0"
            key={index}
            onClick={() => handleValue(tab.value)}
          >
            <div
              className={`flex gap-x-1 items-center py-2 px-4 rounded-t-[4px] ${
                filterStatus === tab.value ? "bg-gry-30" : "bg-gry-50"
              }`}
            >
              {tab.value === "ALL" && (
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/general/list-${
                    filterStatus === "ALL" ? "bl.svg" : "70.svg"
                  }`}
                  width={15}
                  height={15}
                  alt="icon eye"
                />
              )}
              <h3
                className={`${
                  filterStatus === tab.value
                    ? "text-bl-100 m-b"
                    : "text-gry-70 m-m"
                } text-fs-12`}
              >
                {tab.label} ({validateContract(tab.value)})
              </h3>
            </div>
          </button>
        ))}
      </div>

      <div className="w-full">
        <TableRH />
      </div>
    </>
  );
}
