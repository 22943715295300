/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";

import { SelectContract } from "./SelectContract";
import { UseRHContext } from "../context/RHContext";
import { CreateUser, UpdateUser } from "../../Users/api/api";
import { AutoCompleteBank } from "./ModalEdit/AutoCompleteBank";
import SelectRolUser from "../../Users/components/SelectRolUser";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../components/alerts/notifications/useNotification";
import { formatCurrency, parserSalary } from "../configs/SalaryConfig";
import { SelectorApartment } from "../../Users/components/SelectorApartment";
import { useUser } from "../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";

export default function AddNewEmployee({ isOpen, closeNewUser }) {
  const {
    userSelected,
    rolesLists,
    setAddNewUser,
    apartmentsLists,
    refreshUserList,
    findUserSelected,
    getApartmentLists
  } = UseRHContext();
  const { notification, showNotification, hideNotification } =
    useNotification();

  const [phone, setPhone] = useState(null);
  const [userName, setUserName] = useState("");
  const [nssNumber, setNssNumber] = useState("");
  const [startContract, setStartContract] = useState(null);
  const [endContract, setEndContract] = useState(null);
  const [observerUser, setObserverUser] = useState("");
  const [bankName, setBankName] = useState("");
  const [numberBak, setNumberBank] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [contractType, setContractType] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [password, setPassword] = useState(`Royal${moment().year()}*`);
  const [isPassword, setIsPassword] = useState(true);
  const [domain, setDomain] = useState("@staywuw.com");
  const [salary, setSalary] = useState("0.00");


  const { user } = useUser();
  const hasPermission = HasPermissionsUser(user.permissions, "list_departments");

  useEffect(() => {
    if (hasPermission) {
      getApartmentLists();
    }
  }, []);
  

  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidSalary, setIsValidSalary] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [isValidRol, setIsValidRol] = useState(true);
  const [isValidApartment, setIsValidApartment] = useState(true);

  useEffect(() => {
    if (userSelected) {
      setPhone(userSelected?.phone);
      setSalary(
        userSelected?.salary ? formatCurrency(userSelected?.salary) : "0.00"
      );
      setUserName(userSelected?.name);
      setNssNumber(userSelected?.nss);
      setEndContract(userSelected?.end_contract);
      setStartContract(userSelected?.start_contract);
      setObserverUser(userSelected?.observations);
      setSelected(userSelected?.role_id);
      setBankName(userSelected?.bank_name);
      setNumberBank(userSelected?.clabe);
      setCardNumber(userSelected?.card_number);
    } else {
      setPhone(null);
      setUserName("");
      setNssNumber("");
      setEndContract(null);
      setStartContract(null);
      setObserverUser("");
      setNumberBank("");
      setCardNumber("");
      setContractType("");
      setSelected(null);
      setBankName("");
      setSalary("0.00");
      setPassword(`Royal${moment().year()}*`);
      setIsValidName(true);
      setIsValidPhone(true);
      setIsValidSalary(true);
    }
  }, [userSelected, isOpen]);

  const handleNumbers = (numbers) => {
    return numbers.replace(/\D/g, "");
  };

  const handleLetters = (input) => {
    return input.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, "");
  };

  const handleKeyDown = (event) => {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleRefundChange = (e) => {
    const value = e.target.value;
    let salary = parserSalary(formatCurrency(value));
    if (salary <= 99000) {
      setSalary(formatCurrency(value));
    }
  };

  const handleSubmit = async () => {
    let validSalary = validationSalary(salary);
    let validName = validationName(userName);
    let validNumber = validationNumber(phone);
    let validRol = validationRol(selected);
    let validApartment = validationApartment(selectedApartment);
    if (!validSalary || !validName || !validNumber || !validRol || !validApartment) {
      return;
    }

    setIsLoader(true);
    let data = {
      name: userName,
      phone: phone,
      role_id: Number(selected),
      contract_type: contractType?.value,
      start_contract: startContract,
      nss: nssNumber,
      bank_name: bankName,
      clabe: numberBak,
      card_number: cardNumber,
      salary: parserSalary(salary),
      observaciones: observerUser,
      department_id : selectedApartment,
    };

    if (contractType?.value == "TEMPORAL") {
      data = { ...data, end_contract: endContract };
    }

    if (!userSelected) {
      data = {
        ...data,
        password: password,
        password_confirmation: password,
        domain: domain,
        status: "ACTIVO",
      };
    }

    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === "") {
        delete data[key];
      }
    }
    try {
      let response;
      let configAlert = {
        title: "",
        message: "",
      };
      if (userSelected) {
        response = await UpdateUser(data, userSelected.id);
        configAlert = {
          title: `Actualización exitosa`,
          message: `El usuario ${userSelected.name} se actualizó correctamente.`,
        };
      } else {
        response = await CreateUser(data);
        configAlert = {
          title: `Creación exitosa`,
          message: `La creación del usuario fue exitosa`,
        };
      }
      if (response.code === "ok") {
        showNotification(
          "success",
          configAlert.title,
          configAlert.message,
          5000
        );
        setIsLoader(false);
        closeNewUser();
        setAddNewUser({
          isUser: true,
          title: configAlert.title,
          message: configAlert.message,
        });
        refreshUserList();
        if (userSelected) {
          findUserSelected(userSelected.id);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      showNotification(
        "error",
        "Problemas en la actualización",
        "Tuvimos un problema al intentar actualizar al usuario, por favor inténtalo más tarde.",
        5000
      );
      throw error;
    }
  };

  useEffect(() => {
    validationNumber(phone);
  }, [phone]);
  useEffect(() => {
    validationName(userName);
  }, [userName]);
  useEffect(() => {
    validationSalary(salary);
  }, [salary]);

  useEffect(() => {
    if (selected && selected !== "") {
      setIsValidRol(true);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedApartment && selectedApartment !== "") {
      setIsValidApartment(true);
    }
  }, [selected]);

  const validationName = (value) => {
    value = value.trim();

    if (value.length === 0) {
      setIsValidName(false);
      return false;
    } else {
      setIsValidName(true);
      return true;
    }
  };

  const validationNumber = (value) => {
    if (value) {
      value = value.trim();
      if (value.length < 10) {
        setIsValidPhone(false);
        return false;
      } else {
        setIsValidPhone(true);
        return true;
      }
    } else {
      setIsValidPhone(false);
      return false;
    }
  };

  const validationSalary = (value) => {
    if (value) {
      let total = parserSalary(value);
      if (total === 0) {
        setIsValidSalary(false);
        return false;
      } else {
        setIsValidSalary(true);
        return true;
      }
    }
  };

  const validationRol = (rol) => {
    if (rol && rol !== "") {
      setIsValidRol(true);
      return true;
    } else {
      setIsValidRol(false);
      return false;
    }
  };
  const validationApartment = (apartment) => {
    if (apartment && apartment !== "") {
      setIsValidApartment(true);
      return true;
    } else {
      setIsValidApartment(false);
      return false;
    }
  };

  const inputRefStart = useRef(null);
  const inputRefEnd = useRef(null);

  const [placeholder, setPlaceholder] = useState("Fecha inicial");
  const [placeholderEnd, setPlaceholderEnd] = useState("Fecha final");

  const handleFocusStart = (e) => {
    e.target.type = "date";
    if (inputRefStart.current) {
      inputRefStart.current.showPicker();
    }

    setPlaceholder("");
  };

  const handleBlurStart = (e) => {
    if (!e.target.value) {
      e.target.type = "text";
      setPlaceholder("Fecha inicial");
    }
  };

  const handleFocusEnd = (e) => {
    e.target.type = "date";
    if (inputRefEnd.current) {
      inputRefEnd.current.showPicker();
    }
    setPlaceholderEnd("");
  };

  const handleBlurEnd = (e) => {
    if (!e.target.value) {
      e.target.type = "text";
      setPlaceholderEnd("Fecha final");
    }
  };

  const handleClick = (click) => {
    if (click === 1) {
      inputRefStart.current.showPicker();
    } else {
      inputRefEnd.current.showPicker();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPlaceholder("Fecha inicial");
      setPlaceholderEnd("Fecha final");
    }
  }, [isOpen]);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative !z-[10]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[60%] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-100.svg`}
                      width={16}
                      height={16}
                      alt="icon-close"
                      className="mr-2 cursor-pointer absolute right-4"
                      onClick={closeNewUser}
                    />

                    <h2 className="m-b text-fs-20 text-[#5F5F5F] text-center">
                      {userSelected
                        ? "Editar empleado"
                        : "Crear nuevo empleado"}
                    </h2>

                    <div className="flex flex-col gap-3 mt-5">
                      <span className="m-b text-fs-14 text-[#5F5F5F]">
                        Datos empresariales
                      </span>

                      {/* FORM */}
                      <div className="flex gap-4 w-full">
                        {/* SECCION 1 */}
                        <div className="w-1/2 flex flex-col gap-2">
                          {/* INPUT USER */}
                          {userSelected && (
                            <div className="w-full">
                              <label className="text-fs-12 m-m text-gry-100">
                                Nombre de usuario
                              </label>

                              <div className="relative border border-gry-50 h-[43px]">
                                <img
                                  src={`${
                                    import.meta.env.VITE_URL_IMAGES
                                  }assets/icons/adult/adult-b.svg`}
                                  width={15}
                                  height={15}
                                  alt="icon-tel-crm"
                                  className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                                />

                                <input
                                  type="text"
                                  placeholder="Nombre de usuario"
                                  className="border pl-[40px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                                  value={userSelected?.user_name}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          )}

                          {/* INPUT NAME */}
                          <div>
                            <label className="text-fs-12 m-m text-gry-100">
                              Nombre completo{" "}
                              <span className="text-red-100">*</span>
                            </label>
                            <div className="relative w-full h-max">
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/adult/adult-b.svg`}
                                width={15}
                                height={15}
                                alt="icon-tel-crm"
                                className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                              />

                              <input
                                type="text"
                                placeholder="Nombre completo"
                                className={`border-2 pl-[40px] text-gry-100 text-fs-12 h-[43px] py-2 my-auto focus:outline-none w-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 ${
                                  isValidName
                                    ? "border-gry-50"
                                    : "border-red-100"
                                }`}
                                value={userName}
                                onChange={(e) =>
                                  setUserName(handleLetters(e.target.value))
                                }
                              />
                            </div>
                            {!isValidName && (
                              <p className="text-fs-11 text-red-100">
                                El campo está vacio
                              </p>
                            )}
                          </div>

                          {/* INPUT EMAIL */}
                          <div className="w-full">
                            <label className="text-fs-12 m-m text-gry-100">
                              Gmail
                            </label>

                            <div className="relative border border-gry-50 h-[43px]">
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/mail/mail-b.svg`}
                                width={15}
                                height={15}
                                alt="icon-tel-crm"
                                className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                              />

                              {userSelected?.email ? (
                                <input
                                  type="email"
                                  className="border border-gry-50 py-2 my-auto pl-[40px] text-fs-12 text-gry-100 focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                                  placeholder="Correo electrónico"
                                  value={userSelected?.email}
                                  disabled={true}
                                />
                              ) : (
                                <select
                                  value={domain}
                                  onChange={(e) => setDomain(e.target.value)}
                                  className="border border-gry-50 py-2 pl-[40px] focus:outline-none w-full h-full bg-transparent text-gry-100 text-fs-12"
                                >
                                  <option value="@staywuw.com">Stay Wuw</option>
                                  <option value="@royalvacationsmexico.com">
                                    Royal Vacations
                                  </option>
                                </select>
                              )}
                            </div>
                          </div>

                          {/* INPUT TEL */}
                          <div>
                            <label className="text-fs-12 m-m text-gry-100">
                              Teléfono <span className="text-red-100">*</span>
                            </label>

                            <div className="relative w-full h-[43px]">
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/tel/tel-b.svg`}
                                width={15}
                                height={15}
                                alt="icon-tel-crm"
                                className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                              />

                              <input
                                maxLength={10}
                                type="tel"
                                className={`py-2 pl-[40px] focus:outline-none w-full h-full bg-transparent text-gry-100 text-fs-12 border-2 ${
                                  isValidPhone
                                    ? "border-gry-50"
                                    : "border-red-100"
                                }`}
                                placeholder="Teléfono"
                                value={phone}
                                onChange={(e) =>
                                  setPhone(handleNumbers(e.target.value))
                                }
                              />
                            </div>

                            {!isValidPhone && (
                              <p className="text-fs-11 text-red-100">
                                Tiene que ser 10 dígitos
                              </p>
                            )}
                          </div>

                          {/* INPUT OBSERVATIONS */}
                          <div className="relative w-full h-max">
                            <label className="text-fs-12 m-m text-gry-100">
                              Observaciones
                            </label>

                            <p className="absolute text-fs-12 text-gry-70 mb-2 left-2 bottom-0">{`${
                              observerUser ? observerUser.length : 0
                            }/160`}</p>
                            <textarea
                              maxLength={160}
                              type="text"
                              placeholder="Observaciones"
                              className="border-2 pl-[15px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 h-[100px]"
                              value={observerUser}
                              onChange={(e) => setObserverUser(e.target.value)}
                            />
                          </div>

                          {!userSelected && (
                            <div className="w-full">
                              <label className="text-fs-12 m-m text-gry-100">
                                Contraseña{" "}
                                <span className="text-red-100">*</span>
                              </label>

                              <div className="relative border border-gry-50 h-[43px]">
                                <img
                                  src={`${
                                    import.meta.env.VITE_URL_IMAGES
                                  }assets/icons/general/padlock.svg`}
                                  width={15}
                                  height={15}
                                  alt="icon-tel-crm"
                                  className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                                />

                                <input
                                  type={isPassword ? "password" : "text"}
                                  placeholder="Contraseña"
                                  className="border pl-[40px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />

                                <img
                                  onClick={() => setIsPassword(!isPassword)}
                                  src={`${
                                    import.meta.env.VITE_URL_IMAGES
                                  }assets/icons/general/${
                                    isPassword ? "eye-close.svg" : "eye.svg"
                                  }`}
                                  alt="eye"
                                  width={20}
                                  height={20}
                                  className="cursor-pointer absolute right-[12px] top-0 bottom-0 my-auto"
                                />
                              </div>
                            </div>
                          )}

                          <div>
                            <label className="text-fs-12 m-m text-gry-100">
                              Salario <span className="text-red-100">*</span>
                            </label>
                            <div className="relative w-full h-[43px]">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-[18px]">
                                <span className="text-gry-100 text-fs-14">
                                  $
                                </span>
                              </div>

                              <input
                                type="text"
                                onKeyDown={handleKeyDown}
                                value={salary}
                                onChange={handleRefundChange}
                                maxLength={99000}
                                placeholder="Salario"
                                className={`border-2 pl-[15px] pr-[15px] text-gry-100 text-fs-12 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 text-right ${
                                  isValidSalary
                                    ? "border-gry-50 "
                                    : "border-red-100"
                                }`}
                              />
                            </div>
                          </div>
                          {!isValidSalary && (
                            <p className="text-fs-11 text-red-100">
                              El salario es requerido
                            </p>
                          )}
                        </div>

                        {/* SECCION 2 */}
                        <div className="w-1/2 flex flex-col gap-2">
                          {/* INPUT NSS */}
                          <div className="w-full">
                            <label className="text-fs-12 m-m text-gry-100">
                              Numero de seguro social
                            </label>

                            <div className="relative border border-gry-50 h-[43px]">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-[18px]">
                                <span className="text-gry-100 text-fs-14">
                                  NSS
                                </span>
                              </div>

                              <input
                                maxLength={11}
                                type="text"
                                className="border pl-[44px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                                value={nssNumber}
                                onChange={(e) =>
                                  setNssNumber(handleNumbers(e.target.value))
                                }
                              />
                            </div>
                          </div>

                          {/* INPUT BANK ACCOUNT */}
                          <div className="w-full">
                            <label className="text-fs-12 m-m text-gry-100">
                              Cuenta bancaria
                            </label>

                            <div className="relative border border-gry-50 h-[43px]">
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/payment/card.svg`}
                                width={19}
                                height={19}
                                alt="icon-tel-crm"
                                className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                              />

                              <input
                                type="text"
                                placeholder="Cuenta bancaria"
                                maxLength={19}
                                value={numberBak}
                                className="border pl-[40px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                                onChange={(e) =>
                                  setNumberBank(handleNumbers(e.target.value))
                                }
                              />
                            </div>
                          </div>

                          {/* INPUT CARD NUMBER */}
                          <div className="w-full">
                            <label className="text-fs-12 m-m text-gry-100">
                              Número de tarjeta
                            </label>

                            <div className="relative border border-gry-50 h-[43px]">
                              <img
                                src={`${
                                  import.meta.env.VITE_URL_IMAGES
                                }assets/icons/payment/card.svg`}
                                width={19}
                                height={19}
                                alt="icon-tel-crm"
                                className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                              />

                              <input
                                type="text"
                                placeholder="Numero de tarjeta"
                                value={cardNumber}
                                maxLength={19}
                                onChange={(e) =>
                                  setCardNumber(handleNumbers(e.target.value))
                                }
                                className="border pl-[40px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                              />
                            </div>
                          </div>

                          {/* INPUT BANK */}
                          <div className="w-full ">
                            <label className="text-fs-12 m-m text-gry-100">
                              Tipo de banco
                            </label>

                            <div className="relative m-0 border border-gry-50 h-[43px]">
                              <AutoCompleteBank
                                input={bankName}
                                setInput={setBankName}
                              />
                            </div>
                          </div>

                          {/* INPUT TYPE CONTRACT */}
                          <div className="relative w-full ">
                            <label className="text-fs-12 m-m text-gry-100">
                              Tipo de contrato
                            </label>

                            <div className="border border-gry-50 h-[43px]">
                              <SelectContract
                                user={userSelected}
                                contractType={contractType}
                                setContractType={setContractType}
                              />
                            </div>
                          </div>

                          {/* INPUT START DATE */}
                          <div className="relative w-full text-fs-12 m-m text-gry-100">
                            <label>Fecha de inicio</label>

                            <input
                              type="text"
                              value={startContract}
                              className="border-2 pl-[15px] border-gry-0 py-2 my-auto h-[43px] focus:outline-none w-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 pr-4"
                              onChange={(e) => setStartContract(e.target.value)}
                              ref={inputRefStart}
                              placeholder={placeholder}
                              onClick={() => handleClick(1)}
                              onFocus={handleFocusStart}
                              onBlur={handleBlurStart}
                            />
                          </div>

                          {contractType?.value == "TEMPORAL" && (
                            // {/* INPUT END DATE */}
                            <div className="relative w-full text-fs-12 m-m text-gry-100">
                              <label>Fecha de finalizacion</label>

                              <input
                                type="text"
                                value={endContract}
                                className="border-2 pl-[15px] border-gry-0 py-2 my-auto h-[43px] focus:outline-none w-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12 pr-4"
                                onChange={(e) => setEndContract(e.target.value)}
                                ref={inputRefEnd}
                                placeholder={placeholderEnd}
                                onClick={() => handleClick(2)}
                                onFocus={handleFocusEnd}
                                onBlur={handleBlurEnd}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-x-8">
                      <div className="mt-3">
                        <span className="m-b text-fs-14 text-[#5F5F5F]">
                          Rol de usuario <span className="text-red-100">*</span>
                        </span>

                        <SelectRolUser
                          isValidRol={isValidRol}
                          roles={rolesLists}
                          selected={selected}
                          setSelected={setSelected}
                          userSelected={userSelected}
                        />
                      </div>

                      <div className="mt-3">
                        <span className="m-b text-fs-14 text-[#5F5F5F]">
                          Departamentos <span className="text-red-100">*</span>
                        </span>

                        <SelectorApartment
                          isValidApartment={isValidApartment}
                          apartmentsLists={apartmentsLists}
                          selectedApartment={selectedApartment}
                          setSelectedApartment={setSelectedApartment}
                          userSelected={userSelected}
                        />
                      </div>
                    </div>

                    {/* BTN´S ALERT */}
                    <div className="flex justify-center gap-3 mt-5">
                      <button
                        onClick={closeNewUser}
                        className="px-3 py-3 rounded-lg w-fit text-red-100 m-s-b text-fs-12 hover:bg-red-100 hover:text-white"
                      >
                        Cancelar
                      </button>

                      <button
                        onClick={handleSubmit}
                        className={`flex gap-1 px-3 py-3 rounded-lg w-fit text-fs-12 text-white m-s-b ${
                          isLoader ? "bg-bl-70" : "bg-bl-100 hover:bg-bl-110"
                        }`}
                        disabled={isLoader}
                      >
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/save-w.svg`}
                          alt="icon save white"
                          width={18}
                          height={18}
                        />
                        {isLoader ? "Guardando..." : "Guardar"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
