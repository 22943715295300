import { useState } from "react";

import ToolTipDays from "./ToolTipDays";
import ToolTipConfirm from "./ToolTipConfirm";
import ConfirmVacations from "./ConfirmVacations";
import CalendarVacations from "./CalendarVacations";
import { UseVacations } from "../context/VacationsContext";
import { useUser } from "../../../configs/login/UserContext";
import { RhConfirmVacation } from "../../RH/components/ModalInfo/RhConfirmVacation";
import { useNotification } from "../../../components/alerts/notifications/useNotification";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { DatesSkeleton } from "../Skeleton/DatesSkeleton";
import { EmployeesSkeleton } from "../Skeleton/EmployeesSkeleton";

export default function ListEmployees() {
  const {
    isButtonEnabled,
    filterData,
    setIsButtonEnabled,
    refreshUserList,
    daysPerMonth,
    isLoader,
  } = UseVacations();

  const [isToolConfirm, setIsToolConfirm] = useState(null);
  const [isToolDays, setIsToolDays] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { user } = useUser();
  const { notification, showNotification, hideNotification } =
    useNotification();

  const openVacationsDialog = (userId) => {
    setIsConfirmOpen(true);
    setIsButtonEnabled(userId);
  };

  const closeVacationsDialog = () => {
    setIsConfirmOpen(false);
  };
  const isRole =
    user.department_name === "Recursos Humanos" ||
    user.department_name === "Sistemas";

  const notificationResponse = (name, status) => {
    refreshUserList();
    if (status == 2) {
      showNotification(
        "success",
        `Las fechas se cancelaron con exito`,
        `${
          isRole
            ? `Las vacaciones de ${name} fueron canceladas`
            : "Se agregaron las fechas con éxito."
        }`,
        5000
      );
    } else {
      showNotification(
        "success",
        `${isRole ? "¡Vacaciones confirmadas!" : "Fechas Agregadas"}`,
        `${
          isRole
            ? `Las vacaciones de ${name} fueron confirmadas`
            : "Se agregaron las fechas con éxito."
        }`,
        5000
      );
    }
  };

  return (
    <>
      <div
        className={`overflow-y-auto scroll-page-gry overflow-x-hidden ${
          !isLoader && filterData.length > 2 ? "h-[70vh]" : "h-[20vh]"
        }`}
      >
        {/* LOADER */}
        {isLoader && (
          <div className="flex w-full">
            <div className="flex w-[15%] border-b border-r items-center justify-between">
              <EmployeesSkeleton />
            </div>

            <DatesSkeleton totalDates={daysPerMonth.length} />
          </div>
        )}
        {isRole
          ? // {/* empty table */}
            filterData.length == 0 &&
            !isLoader && (
              <div className="flex w-full">
                <div className="flex w-[15%] border-b border-r items-center justify-between">
                  <h4 className="text-[#0A1629] text-fs-16 m-s-b w-[250px] text-center">
                    No se encontraron resultados
                  </h4>
                </div>

                <DatesSkeleton totalDates={daysPerMonth.length} />
              </div>
            )
          : filterData.length == 0 &&
            !isLoader && (
              <div className="flex-col justify-center items-center m-b flex text-fs-16">
                <div className=" justify-center pt-8 gap-1">
                  Hola <span className="text-or-100"> {user.name} </span>,
                  actualmente no cuentas con días de vacaciones disponibles.
                </div>
                <div>Para más información, consulta con Recursos Humanos.</div>
              </div>
            )}

        {!isLoader &&
          filterData.length > 0 &&
          filterData.map((users, index) => (
            <div key={index} className="flex w-full">
              <div className="flex items-center w-[15%] border-b border-r justify-between">
                <div
                  className={`flex gap-1 h-[55px] items-center relative max-xl:flex-col ${
                    users?.vacations?.dates?.selected?.length > 0 ||
                    (isRole && users?.vacations?.dates?.pending?.length > 0)
                      ? "w-[60%]"
                      : "w-[100%]"
                  }`}
                >
                  {/* IMG */}
                  <div className={`rounded-full bg-gry-50  h-[24px] w-[25px]`}>
                    <img
                      src={users.url_image}
                      className="w-full h-full rounded-full object-cover"
                      alt="user"
                    />
                  </div>
                  {/* NAME */}

                  <h4
                    onMouseLeave={() => setIsToolDays(null)}
                    onMouseOver={() => setIsToolDays(index)}
                    className={`text-[#0A1629] text-fs-16 m-s-b truncate cursor-default w-full max-2xl:text-fs-12`}
                  >
                    {users.name}
                    {isToolDays == index && <ToolTipDays user={users} />}
                  </h4>
                </div>
                {/* ADMINS */}
                {isRole ? (
                  // {/* BTN CONFIRM VACATIONS */}
                  users?.vacations?.dates?.pending?.length > 0 ? (
                    <>
                      <button
                        onClick={() => openVacationsDialog(users.id)}
                        onMouseLeave={() => setIsToolConfirm(null)}
                        onMouseOver={() => setIsToolConfirm(index)}
                        // className="pr-2 relative w-[50%]"
                        className="bg-bl-100 rounded px-3 py-1 h-fit m-s-b text-fs-12 hover:bg-bl-110 text-white flex gap-1 items-center justify-center relative mr-1"
                      >
                        Aprobar
                        {isToolConfirm == index && (
                          <ToolTipConfirm
                            text="Confirmar o cancelar"
                            style="bg-bl-70 text-black"
                          />
                        )}
                      </button>

                      {/* MODAL CONFIRM */}
                      {isButtonEnabled == users.id && (
                        <RhConfirmVacation
                          isOpen={isConfirmOpen}
                          userVacation={users}
                          close={() => setIsConfirmOpen(false)}
                          notificationResponse={notificationResponse}
                        />
                      )}
                    </>
                  ) : (
                    users?.vacations?.dates?.selected?.length > 0 && (
                      <>
                        <button
                          onClick={() => openVacationsDialog(users.id)}
                          className="w-[40%] bg-grn-100 rounded px-3 py-1 h-fit text-white m-s-b hover:bg-grn-70 flex gap-1 items-center justify-center mr-1 text-fs-12 max-2xl:text-fs-10"
                        >
                          Confirmar{" "}
                        </button>
                        {isButtonEnabled == users.id && (
                          <ConfirmVacations
                            isOpen={isConfirmOpen}
                            close={closeVacationsDialog}
                            name={users.name}
                            vacations={users.vacations}
                            id={users.id}
                            img={users.url_image}
                            notificationResponse={notificationResponse}
                          />
                        )}
                      </>
                    )
                  )
                ) : (
                  // USERS
                  users?.vacations?.dates?.selected?.length > 0 && (
                    <>
                      <button
                        onClick={() => openVacationsDialog(users.id)}
                        className="bg-grn-100 rounded px-3 py-1 h-fit text-white m-s-b text-fs-12 hover:bg-grn-70 flex gap-1 items-center justify-center mr-1"
                      >
                        Confirmar
                      </button>

                      {/* MODAL CONFIRM */}
                      {isButtonEnabled == users.id && (
                        <ConfirmVacations
                          isOpen={isConfirmOpen}
                          close={closeVacationsDialog}
                          name={users.name}
                          vacations={users.vacations}
                          id={users.id}
                          img={users.url_image}
                          notificationResponse={notificationResponse}
                        />
                      )}
                    </>
                  )
                )}
              </div>
              <div className="w-[85%] pl-1">
                <CalendarVacations
                  Vacations={users.vacations}
                  id={users.id}
                  role={user.role_name}
                  users={users}
                />
              </div>
            </div>
          ))}

        {notification && notification.visible && (
          <NotificationType
            type={notification.type}
            title={notification.title}
            message={notification.message}
            duration={notification.duration}
            onClose={hideNotification}
            notificationResponse={notificationResponse}
          />
        )}
      </div>

      {/* FOOTER VACATIONS */}
      <div className="w-full flex">
        <div className="w-[15%]" />
        <div className="w-[85%] border-l pt-5 pl-6 flex flex-col border-t gap-2 max-2xl:gap-1">
          <span className="text-fs-14 m-s-b text-[#7D8592]">Vacaciones</span>
          <div className="flex justify-between pr-5">
            <div className="flex gap-5 max-xl:flex-col">
              <div className="flex gap-10 max-2xl:gap-5">
                <StatusItem color="bg-[#15C0E6]" label="Aprobadas" />
                <StatusItem color="!bg-[#66CC9B]" label="Pagadas" />
                <StatusItem
                  color="bg-[#15C0E61E] border border-[#15C0E6]"
                  label="Pendientes"
                />
              </div>
              <div className="flex gap-10 max-2xl:gap-5">
                <StatusItem
                  color="bg-[#C775391E] border border-[#EB741E]"
                  label="Seleccionados"
                />
                <StatusItem color="bg-[#e0dcdc]" label="Deshabilitados" />
                <StatusItem color="" label="Festivo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// STATUS ITEM
const StatusItem = ({ color, label }) => (
  <div className="flex items-center gap-3 max-2xl:gap-1 ">
    <div className={`rounded-full ${color} w-[14px] h-[14px]`} />
    <span className="text-fs-16 m-m text-[#0A1629] flex gap-1">
      {label == "Festivo" && (
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/crm/christmas.svg`}
          alt="icon holiday"
          width={20}
          height={20}
        />
      )}
      {label}
    </span>
  </div>
);
