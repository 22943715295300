import { useContext, useEffect, useState } from "react";

import CardTopActivities from "./CardTopActivities";
import { MarketingContext } from "../../../context/MarketingContext";

const tours = [
  {
    id: 1,
    codeName: "paddle-surf-en-el-islote-el-morro",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/paddle-surf-islote-morro.jpg",
    category: 0,
    duration: "1h 30m",
    hidden: false,
    hiddenEdit: false,
    es: {
      name: "Paddle surf en el islote El Morro",
      destination: "Acapulco",
      country: "mexico",
      price: 255.72,
    },
    en: {
      name: "Isla El Morro Paddleboarding",
      destination: "Acapulco",
      country: "mexico",
      price: 255.72,
    },
  },
  {
    id: 2,
    codeName: "paseo-en-barco-por-acapulco",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/paseo-barco-acapulco.jpg",
    category: 4.5,
    duration: "2h 30m",
    hidden: false,
    hiddenEdit: false,
    es: {
      name: "Paseo en barco por Acapulco",
      destination: "Acapulco",
      country: "mexico",
      price: 434.72,
    },
    en: {
      name: "Acapulco Bay Cruise",
      destination: "Acapulco",
      country: "mexico",
      price: 434.72,
    },
  },
  {
    id: 5,
    codeName: "snorkel-en-la-bahia-de-acapulco",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/snorkel-bahia-acapulco.jpg",
    category: 0,
    duration: "1h 30m",
    hidden: false,
    hiddenEdit: false,
    es: {
      name: "Snorkel en la bah\u00eda de Acapulco",
      destination: "Acapulco",
      country: "mexico",
      price: 358,
    },
    en: {
      name: "Acapulco Bay Snorkelling",
      destination: "Acapulco",
      country: "mexico",
      price: 358,
    },
  },
  {
    id: 6,
    codeName: "cena-y-espectaculo-de-clavados-en-acapulco",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/cena-espectaculo-clavados-acapulco.jpg",
    category: 3.5,
    duration: "3h",
    hidden: false,
    hiddenEdit: false,
    es: {
      name: "Cena y espect\u00e1culo de clavados en Acapulco",
      destination: "Acapulco",
      country: "mexico",
      price: 1704.76,
    },
    en: {
      name: "Acapulco Cliff Dive Show & Dinner",
      destination: "Acapulco",
      country: "mexico",
      price: 1704.76,
    },
  },
  {
    id: 12,
    codeName: "excursion-a-la-isla-de-la-roqueta",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/excursion-isla-roqueta.jpg",
    category: 2,
    duration: "4h 30m",
    hidden: false,
    hiddenEdit: false,
    es: {
      name: "Excursi\u00f3n a la isla de La Roqueta",
      destination: "Acapulco",
      country: "mexico",
      price: 1263.23,
    },
    en: {
      name: "La Roqueta Island Day Trip",
      destination: "Acapulco",
      country: "mexico",
      price: 1263.23,
    },
  },
  {
    id: 14,
    codeName: "tour-por-el-fuerte-de-san-diego-playas-caleta-y-caletilla",
    destinationCodeName: "acapulco",
    image:
      "https://cdn2.civitatis.com/mexico/acapulco/fuerte-san-diego-caleta-caletilla.jpg",
    category: 0,
    duration: "6h",
    hidden: false,
    hiddenEdit: false,
    es: {
      name: "Tour por el Fuerte de San Diego + Playas Caleta y Caletilla",
      destination: "Acapulco",
      country: "mexico",
      price: 834.35,
    },
    en: {
      name: "San Diego Fort Tour + Caleta and Caletilla Beaches",
      destination: "Acapulco",
      country: "mexico",
      price: 834.35,
    },
  },
  {
    id: 121,
    codeName: "paseo-en-catamaran-por-el-mar-de-cortes",
    destinationCodeName: "los-cabos",
    image:
      "https://cdn2.civitatis.com/mexico/los-cabos/paseo-catamaran-mar-cortes.jpg",
    category: 4.5,
    duration: "3h 30m",
    hidden: true,
    hiddenEdit: false,
    es: {
      name: "Paseo en catamar\u00e1n por el Mar de Cort\u00e9s",
      destination: "Los Cabos",
      country: "mexico",
      price: 1443.97,
    },
    en: {
      name: "Los Cabos Boat Ride",
      destination: "Los Cabos",
      country: "mexico",
      price: 1443.97,
    },
  },
  {
    id: 365,
    codeName: "paseo-a-caballo-por-tequila-visita-a-una-destileria-con-cata",
    destinationCodeName: "guadalajara",
    image:
      "https://cdn2.civitatis.com/mexico/guadalajara/paseo-caballo-tequila-visita-destileria-cata.jpg",
    category: 5,
    duration: "6h",
    hidden: true,
    hiddenEdit: false,
    es: {
      name: "Paseo a caballo por Tequila + Visita a una destiler\u00eda con cata",
      destination: "Guadalajara",
      country: "mexico",
      price: 2945.84,
    },
    en: {
      name: "Horseback Riding in Tequila & Distillery Visit with Tasting",
      destination: "Guadalajara",
      country: "mexico",
      price: 2945.84,
    },
  },
  {
    category: 4.5,
    codeName: "free-tour-por-merida",
    destinationCodeName: "merida-yucatan",
    duration: "2h",
    hidden: true,
    hiddenEdit: true,
    en: {
      name: "Mérida Free Tour",
      destination: "Mérida",
      country: "mexico",
      price: 0,
    },
    es: {
      name: "Free tour por Mérida",
      destination: "Merida - Yucatan",
      country: "mexico",
      price: 0,
    },
    id: 700,
    image: "https://cdn2.civitatis.com/mexico/merida/free-tour-merida.jpg",
  },
  {
    category: 1,
    codeName: "senderismo-por-la-barranca-tararecua",
    destinationCodeName: "creel",
    duration: "8h",
    hidden: true,
    hiddenEdit: true,
    en: {
      name: "Hiking Through the Tararecua Canyon",
      destination: "Creel",
      country: "mexico",
      price: 2365.37,
    },
    es: {
      name: "Senderismo por la Barranca Tararecua",
      destination: "Creel",
      country: "mexico",
      price: 2365.37,
    },
    id: 500,
    image:
      "https://cdn2.civitatis.com/mexico/creel/senderismo-barranca-tararecua.jpg",
  },
];

export default function TopActivities() {
  const { responsiveView, isOpenEdit } = useContext(MarketingContext);

  const [maxCard, setMaxCard] = useState(0);

  useEffect(() => {
    switch (responsiveView.value) {
      case "laptop":
        setMaxCard(!isOpenEdit ? 10 : 8);
        break;
      case "tablet":
        setMaxCard(6);
        break;
      case "mobile":
        setMaxCard(5);
        break;
    }
  }, [isOpenEdit, responsiveView]);
  return (
    <>
      <div className="mb-9">
        {/* TITLE TOP ACTIVITIES */}
        <h2 className="m-b text-black text-fs-26 mb-1">Top actividades</h2>

        <span className="m-m text-fs-16 text-gry-100">
          Descubre nuestras mejores actividades para momentos emocionantes y
          culturales
        </span>
      </div>

      <div
        data-aos="fade-up"
        className={`flex flex-wrap gap-[16px] ${
          responsiveView.value === "mobile"
            ? "justify-around"
            : "justify-between"
        }`}
      >
        {tours.length > 0 &&
          tours
            .slice(0, maxCard)
            .map((tour, index) => (
              <CardTopActivities tour={tour} key={index} />
            ))}
      </div>
    </>
  );
}
