import { TabActivity } from "./TabActivity";
import { useEffect, useState } from "react";
import { TourDetails } from "./TourDetails";
import { HotelDetails } from "./HotelDetails";
import { TransportDetails } from "./TransportDetails";
import { DivisionActivities } from "../../config/DivisionActivities";

export function ActivityDetails({ services }) {
  
  const [active, setActive] = useState(null);
  const [splitServices, setSplitServices] = useState(null);

  useEffect(() => {
    let response = DivisionActivities(services);
    setSplitServices(response);

    if (response.hotel.length > 0) {
      setActive("hotel");
    } else if (response.tour.length > 0) {
      setActive("tour");
    } else if(response.transport.length > 0) {
      setActive("transport");
    }
  }, [services]);

  // console.log(active);

  return (
    splitServices && (
      <div className="relative w-[40%]">
        <TabActivity
          active={active}
          setActive={setActive}
          splitServices={splitServices}
        />

        <div className="overflow-y-auto scroll-page-gry pr-2 h-[92%]">
          {active === "hotel" && <HotelDetails hotels={splitServices.hotel} />}

          {active === "tour" && <TourDetails tours={splitServices.tour} />}

          {active === "transport" && <TransportDetails transports={splitServices.transport}/>}
        </div>
      </div>
    )
  );
}
