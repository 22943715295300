/* eslint-disable react-hooks/exhaustive-deps */
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../others/BtnEdit";
import { SetConfigDropzone } from "../others/setConfigDropZone";
import { MarketingContext } from "../../context/MarketingContext";
import { useUser } from "../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../configs/Permissions/AlertPermissionsDeclined";

export function BannerHome() {
  const {
    responsiveView,
    totalFiles,
    routActual,
    jonImagesActualView,
    languageSelected,
  } = useContext(MarketingContext);

  // BANNERS HOTEL AND TOUR
  const [bannerDestination, setBannerDestination] = useState(null);
  const [bannerBlue, setBannerBlue] = useState(null);
  const [bannerTour, setBannerTour] = useState(null);

  useEffect(() => {
    if (jonImagesActualView[languageSelected]) {
      if (routActual === "hotel") {
        setBannerDestination(
          jonImagesActualView[languageSelected].bannerDestination[
            responsiveView.value
          ]
        );
        setBannerBlue(
          jonImagesActualView[languageSelected].bannerBlue[responsiveView.value]
        );
        setBannerTour(
          jonImagesActualView[languageSelected].bannerTour[responsiveView.value]
        );
      } else if (routActual === "tour") {
        setBannerDestination(
          jonImagesActualView[languageSelected].bannerDestination[
            responsiveView.value
          ]
        );
        setBannerBlue(
          jonImagesActualView[languageSelected].bannerBlue[responsiveView.value]
        );
        setBannerTour(
          jonImagesActualView[languageSelected].bannerText[responsiveView.value]
        );
      }
    }
  }, [totalFiles, responsiveView, routActual, languageSelected]);

  return (
    <>
      <div className={`${responsiveView.value !== "laptop" && "hidden"}`}>
        <BannerHomeD
          bannerDestination={bannerDestination}
          bannerBlue={bannerBlue}
          bannerTour={bannerTour}
        />
      </div>
      <div className={`${responsiveView.value == "laptop" && "hidden"}`}>
        <BannerHomeSwiper
          bannerDestination={bannerDestination}
          bannerBlue={bannerBlue}
          bannerTour={bannerTour}
        />
      </div>
    </>
  );
}

export function BannerHomeD({ bannerDestination, bannerBlue, bannerTour }) {
  const {
    responsiveView,
    setIsOpenEdit,
    isOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
  } = useContext(MarketingContext);

  const [selectedBanner, setSelectedBanner] = useState(null);
  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (jsonConfig) => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpenEdit(true);

      let configDropzone = SetConfigDropzone(routActual, jsonConfig);

      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  const isBannerDestination =
    selectedBanner === "bannerDestination" &&
    currentBanner === "bannerDestination";
  const isBannerBlue =
    selectedBanner === "bannerBlue" && currentBanner === "bannerBlue";
  const isBannerTour =
    selectedBanner === "bannerText" && currentBanner === "bannerText";

  return (
    <div
      className={`flex gap-[16px] mb-[40px] ${
        isOpenEdit ? "h-[318px]" : "h-[350px]"
      }`}
    >
      {responsiveView.value === "tablet" ||
        ("laptop" && (
          <div
            className={`flex bg-white justify-center items-center shadow-3xl relative ${
              responsiveView.value === "mobile" ? "w-full" : "w-1/2"
            }`}
          >
            <div
              onClick={() => handleClick("bannerDestination")}
              className={`w-full h-full flex justify-center !bg-white overflow-hidden rounded-lg hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                isBannerDestination && "border-4 border-or-100 rounded-xl"
              }`}
            >
              <img
                width={547}
                height={235}
                alt="Banner Exc Discounts"
                src={
                  bannerDestination?.urlImage
                    ? bannerDestination?.urlImage
                    : bannerDestination
                }
                className={`w-full rounded-lg object-cover object-center select-none group-hover:brightness-75 ${
                  isBannerDestination && "brightness-75"
                }`}
              />
              <BtnEdit />
            </div>
            <AlertPermissionsDeclined
              hasPermissions={hasPermissions}
              cleanedAlert={setHasPermissions}
            />
          </div>
        ))}

      {responsiveView.value === "mobile" ||
        ("tablet" && (
          <>
            <div
              className={`relative flex justify-center ${
                responsiveView.value === "tablet" ? "w-[50%]" : "w-3/12"
              }`}
            >
              <div
                onClick={() => handleClick("bannerBlue")}
                className={`relative w-full flex justify-center hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                  isBannerBlue && "border-4 border-or-100 rounded-xl"
                }`}
              >
                <img
                  width={266}
                  height={235}
                  alt="Banner Experimenta los mejores tours"
                  src={bannerBlue?.urlImage ? bannerBlue?.urlImage : bannerBlue}
                  className={`object-cover w-full rounded-lg select-none group-hover:brightness-75 ${
                    isBannerBlue && "brightness-75"
                  }`}
                />

                <div className={`absolute top-[39px] left-[33px]`}>
                  <h2
                    className={`m-b w-9/12 text-white mb-4 text-left text-fs-22 max-2xl:text-fs-18`}
                  >
                    Experimenta los mejores tours de todo México.
                  </h2>

                  <h4
                    className={`m-m w-9/12 text-[#BEE0EE] text-left text-fs-22 max-2xl:text-fs-18 max-xl:text-fs-16 max-lg:text-fsw-38 max-sm:text-fsw-64`}
                  >
                    El momento de disfrutar es ahora.
                  </h4>
                </div>
                <BtnEdit />
              </div>
            </div>

            <div
              className={`flex justify-center ${
                responsiveView.value === "tablet" ? "w-[50%]" : "w-3/12"
              }  relative`}
            >
              <div
                onClick={() =>
                  handleClick(
                    routActual === "hotel" ? "bannerTour" : "bannerText"
                  )
                }
                className={`w-full flex justify-center overflow-hidden rounded-lg hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                  isBannerTour && "border-4 border-or-100 rounded-xl"
                }`}
              >
                <img
                  width={266}
                  height={235}
                  src={bannerTour?.urlImage ? bannerTour?.urlImage : bannerTour}
                  alt="Banner tour mes de feb"
                  className={`w-full rounded-lg select-none object-cover group-hover:brightness-75 ${
                    isBannerTour && "brightness-75"
                  }`}
                />
                <BtnEdit />
              </div>
            </div>
          </>
        ))}
    </div>
  );
}

export function BannerHomeSwiper({
  bannerDestination,
  bannerBlue,
  bannerTour,
}) {
  const {
    responsiveView,
    setIsOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
  } = useContext(MarketingContext);

  const [selectedBanner, setSelectedBanner] = useState(null);
  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (jsonConfig) => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpenEdit(true);

      let configDropzone = SetConfigDropzone(routActual, jsonConfig);

      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  const isBannerDestination =
    selectedBanner === "bannerDestination" &&
    currentBanner === "bannerDestination";
  const isBannerBlue =
    selectedBanner === "bannerBlue" && currentBanner === "bannerBlue";
  const isBannerTour =
    selectedBanner === "bannerText" && currentBanner === "bannerText";

  return (
    <div className="relative my-11">
      <Swiper
        slidesPerView={3}
        spaceBetween={16}
        modules={[Autoplay]}
        // autoplay={{
        //   delay: 5000,
        // }}
        // loop={true}
        className="!static h-[22.4rem] rounded-lg !z-0"
        initialSlide={0}
        breakpoints={{
          0: {
            slidesPerView: 0.5,
          },
          350: {
            slidesPerView: 1,
          },
        }}
      >
        {/* ONE CARD TRANSPORT */}

        <SwiperSlide className="!flex !justify-center !bg-white gap-4">
          <div
            // onClick={handleClick}
            onClick={() => handleClick("bannerDestination")}
            className={`relative w-full flex justify-center mb-5 hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
              isBannerDestination && "border-4  border-or-100 rounded-xl"
            }`}
          >
            <img
              src={
                bannerDestination?.urlImage
                  ? bannerDestination?.urlImage
                  : bannerDestination
              }
              width={378}
              height={235}
              className={`w-full rounded-lg select-none object-cover object-center group-hover:brightness-75 ${
                isBannerDestination && "brightness-75"
              }`}
              alt="Banner Destino Tablet"
            />
            <BtnEdit />
          </div>
        </SwiperSlide>
        {/*END ONE CARD TRANSPORT */}

        {/*TWO CARD TRANSPORT */}
        <SwiperSlide className="!flex !justify-center !bg-white gap-4">
          <div
            onClick={() => handleClick("bannerBlue")}
            className={`relative w-full flex justify-center mb-5 hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
              isBannerBlue && "border-4  border-or-100 rounded-xl"
            }`}
          >
            <img
              src={bannerBlue?.urlImage ? bannerBlue?.urlImage : bannerBlue}
              width={266}
              height={235}
              className={`object-cover w-full rounded-lg select-none group-hover:brightness-75 ${
                isBannerBlue && "brightness-75"
              }`}
              alt="Banner Azul Tablet"
            />

            <div className={`absolute top-[39px] left-[33px]`}>
              <h2
                className={`m-b w-9/12 text-white mb-4 text-left text-fs-22 max-2xl:text-fs-18`}
              >
                Experimenta los mejores tours de todo México.
              </h2>

              <h4
                className={`m-m w-9/12 text-[#BEE0EE] text-left text-fs-22 max-2xl:text-fs-18 max-xl:text-fs-16 max-lg:text-fsw-38 max-sm:text-fsw-64`}
              >
                El momento de disfrutar es ahora.
              </h4>
            </div>

            <BtnEdit />
          </div>

          <div
            onClick={() =>
              handleClick(routActual === "hotel" ? "bannerTour" : "bannerText")
            }
            className={`relative w-full flex justify-center mb-5 hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer group ${
              responsiveView.value === "mobile" && "hidden"
            } ${isBannerTour && "border-4  border-or-100 rounded-xl"}`}
          >
            <img
              src={bannerTour?.urlImage ? bannerTour?.urlImage : bannerTour}
              width={266}
              height={235}
              className={`w-full rounded-lg select-none object-cover object-bottom group-hover:brightness-75 ${
                isBannerTour && "brightness-75"
              }`}
              alt="Banner tour Tablet"
            />
            <BtnEdit />
          </div>
        </SwiperSlide>
        {/*END TWO CARD TRANSPORT */}

        {/* THREE CARD TRANSPORT */}
        <SwiperSlide
          className={`${
            responsiveView.value === "tablet"
              ? " !hidden"
              : " flex justify-center !bg-white relative"
          }`}
        >
          <div
            onClick={() =>
              handleClick(routActual === "hotel" ? "bannerTour" : "bannerText")
            }
            className={`relative w-full flex justify-center mb-5 hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
              isBannerTour && "border-4 border-or-100 rounded-xl"
            }`}
          >
            <img
              src={bannerTour?.urlImage ? bannerTour?.urlImage : bannerTour}
              width={378}
              height={235}
              className={`w-full h-[21rem] rounded-lg object-cover object-center select-none group-hover:brightness-75 ${
                isBannerTour && "brightness-75"
              }`}
              alt="Banner Tour Mobile"
            />

            <BtnEdit />
          </div>
        </SwiperSlide>
        {/*END THREE CARD TRANSPORT*/}
      </Swiper>
      {/* <BtnEdit /> */}
      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </div>
  );
}
