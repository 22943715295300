export function CheckBox({ provider, handleProvider, providersSelected }) {
  // console.log(providersSelected[provider].);

  const handleName = (name) => {
    switch (name) {
      case "hb":
        return "Hotel Beds";
      case "pt":
        return "Price Travel";
      case "rh":
        return "Ratehawk";
      case "ct":
        return "Civitatis";
    }
  };

  return (
    <div className="flex flex-wrap gap-x-4 items-center py-[7px]">
      {providersSelected[provider].map((prov, index) => (
        <div className="flex gap-x-2 items-center" key={index}>
          <input
            className="focus:outline-none"
            type="checkbox"
            value={prov.name}
            onChange={(event) =>
              handleProvider(provider, prov, event.target.checked)
            }
            checked={prov.status === "active"}
          />

          <p className={`m-0 m-m text-fs-14`}>{handleName(prov.name)}</p>
        </div>
      ))}
    </div>
  );
}
