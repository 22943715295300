export const UrlImage = (base64) => {
  const [header, base64Data] = base64.split(",");

  const byteString = atob(base64Data);
  const byteArray = new Uint8Array(byteString.length);

  for (let index = 0; index < byteString.length; index++) {
    byteArray[index] = byteString.charCodeAt(index);
  }

  const mimeType = header.match(/:(.*?);/)[1];

  let urBlob = new Blob([byteArray], { type: mimeType });

  return URL.createObjectURL(urBlob);
};
