export function PYCModal({ text, open, handleClose }) {
  return (
    open && (
      <div className="absolute min-w-[30px] h-auto rounded-lg shadow-3xl bg-white p-4 z-[2]">
        <div className="flex justify-end">
          <button className="focus:outline-none right-0 top-0">
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/close/close-70.svg`}
              width={16}
              height={16}
              alt="close modal PYC"
              onClick={handleClose}
            />
          </button>
        </div>
        <div className="relative w-full h-full">
          <p className="m-s-b text-fs-14 text-gry-100">{text}</p>
        </div>
      </div>
    )
  );
}
