import moment from "moment";
import { useState } from "react";

import ToolTipHoliday from "./ToolTipHoliday";
import { getHolidays } from "../config/HolidayJson";
import AlertsCrm from "../../../components/alerts/Alerts";
import { UseVacations } from "../context/VacationsContext";
import { RhConfirmVacation } from "../../RH/components/ModalInfo/RhConfirmVacation";
import { useNotification } from "../../../components/alerts/notifications/useNotification";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { useUser } from "../../../configs/login/UserContext";

export default function CalendarVacations(props) {
  const userId = props.id;
  const vacations = props.Vacations;
  const users = props.users;
  const { user } = useUser();
  const [typeAlert, setTypeAlert] = useState("");
  const [isToolTip, setIsToolTip] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { notification, showNotification, hideNotification } =
    useNotification();
  const [alertConfig, setAlertConfig] = useState({
    title: "",
    text: "",
    typeAlert: "",
  });

  const {
    daysPerMonth,
    currentYear,
    setIsButtonEnabled,
    dataVacations,
    setFilterData,
    filterData,
    isButtonEnabled,
    refreshUserList,
  } = UseVacations();

  // HOLIDAYS
  const holidays = getHolidays(currentYear);

  // DATE FORMAT
  const parseDates = (date) =>
    moment({
      year: date.year,
      month: date.numberMonth - 1,
      day: date.day,
    }).format("YYYY-MM-DD");

  // VALIDATE ALERT
  const ValidDateCalendar = (dateSelected) => {
    if (vacations.dates && vacations?.dates?.pending.length) {
      setOpenAlert(true);
      setTypeAlert("cfv");
      setAlertConfig({
        title: "¡vacaciones pendientes!",
        text: `${isRole ? "Este usuario tiene vacaciones pendientes de confirmar o cancelar" : " tienes vacaciones pendientes , comunicate con RH para cancelar o aceptar tus vacaciones"}`,
        typeAlert: "warning",
      });
      return false;
    }

    if (
      (vacations.dates?.selected &&
        vacations.available_days == vacations.dates?.selected.length &&
        !vacations.dates?.selected.includes(dateSelected)) ||
      vacations.available_days == 0
    ) {
      showNotification(
        "warning",
        `Vacaciones concluidas`,
        `El usuario ${users.name} ya no cuenta con días para vacacionar.`,
        7000
      );
      return false;
    }

    return true;
  };

  // CLICK CALENDAR
  const handleDay = (event, userId, employees) => {
    const id = userId;
    const selectedValue = JSON.parse(event.value);
    const formattedDate = parseDates(selectedValue);

    const isValid = ValidDateCalendar(formattedDate);

    if (!isValid) return;

    setIsButtonEnabled(userId);

    const removeDate = (dateArray, date) => {
      const dateIndex = dateArray.indexOf(date);
      if (dateIndex > -1) {
        dateArray.splice(dateIndex, 1);
      }
      return dateArray.length === 0;
    };

    const handleDateChange = (vacationDates, dateKey, date) => {
      if (!vacationDates[dateKey]) {
        vacationDates[dateKey] = [];
      }

      const dateExists = vacationDates[dateKey].indexOf(date) > -1;

      if (dateExists) {
        if (removeDate(vacationDates[dateKey], date)) {
          delete vacationDates[dateKey];
        }
      } else {
        vacationDates[dateKey].push(date); // ADD DATE
      }
    };

    employees = filterData.map((employee) => {
      if (employee.id === id) {
        let vacation = employee.vacations;

        if (vacation && vacation.dates) {
          const dateKey = "selected"; // DETERMINE WHETHER TO USE 'PENDING' OR 'SELECTED'
          handleDateChange(vacation.dates, dateKey, formattedDate);
        }
      } else {
        employee = {
          ...employee,
          vacations: {
            ...employee.vacations,
            dates: {
              approved: employee.vacations.dates.approved,
              pay: employee.vacations.dates.pay,
              pending: employee.vacations.dates.pending,
            },
          },
        };
      }

      return employee;
    });

    setFilterData(employees);
    return employees;
  };

  const isRole = user.department_name === "Recursos Humanos" || user.department_name === "Sistemas";

  const notificationResponse = (name, status) => {
    refreshUserList();
    if (status == 2) {
      showNotification(
        "success",
        `Las fechas se cancelaron con exito`,
        `${
          isRole
            ? `Las vacaciones de ${name} fueron canceladas`
            : "Se agregaron las fechas con éxito."
        }`,
        5000
      );
    } else {
      showNotification(
        "success",
        `${isRole ? "¡Vacaciones confirmadas!" : "Fechas Agregadas"}`,
        `${
          isRole
            ? `Las vacaciones de ${name} fueron confirmadas`
            : "Se agregaron las fechas con éxito."
        }`,
        5000
      );
    }
  };

  const ConfirmAlert = () => {
    if (typeAlert == "cfv") {
      setOpenAlert(false);
      setIsConfirmOpen(true);
      setIsButtonEnabled(userId);
    }
  };

  return (
    <>
      <div className="flex gap-1 w-full mt-1">
        {daysPerMonth.map((days, index) => {
          // IF IT IS A HOLIDAY
          const holidayFound = holidays.find(
            (holiday) =>
              holiday.day === days.day &&
              holiday.month === days.numberMonth &&
              holiday.year === days.year
          );
          // BLOCK IF GREATER THAN 8 IF HOLIDAY OR SUNDAY
          const disabled =
            // IF APPROVED AND PAY, DISABLED
            vacations?.dates?.approved?.includes(days.date) ||
            vacations?.dates?.pay?.includes(days.date) ||
            vacations?.dates?.pending?.includes(days.date) ||
            vacations.available_days === 0 ||
            // // CHECK THE LIMIT OF DAYS , ALLOW 'PENDING' AND 'SELECTED'
            // (
            //   (vacations?.dates?.pending ? vacations.dates.pending.length : 0) +
            //   (vacations?.dates?.selected
            //     ? vacations.dates.selected.length
            //     : 0) >=
            //   vacations.available_days
            //   &&
            //   // DO NOT DISABLE DATES 'PENDING' OR 'SELECTED'
            //   !(
            //     (vacations.dates.pending
            //       ? vacations.dates.pending.includes(days.date)
            //       : false) ||
            //     (vacations.dates.selected
            //       ? vacations.dates.selected.includes(days.date)
            //       : false)
            //   )
            // ) ||
            // IF IT IS SUNDAY
            days.nameDay === "domingo" ||
            // DISABLE IF DATE IS LESS THAN TODAY
            moment(days.date).isSameOrBefore(moment(), "day");

          // CLASS BUTTON CALENDAR
          const buttonClasses = `
        h-[50px] w-full rounded-[7px]      
        ${
          days.nameDay === "domingo" ||
          // holidayFound ||
          vacations.available_days === 0 ||
          moment(days.date).isSameOrBefore(moment(), "day")
            ? "bg-[#e0dcdc] cursor-not-allowed !border-transparent"
            : "bg-[#F4F9FD] cursor-pointer"
        }
        ${
          vacations?.dates?.approved?.includes(days.date)
            ? "!bg-[#15C0E6]" // APPROVED
            : vacations?.dates?.pending.includes(days.date)
            ? "!bg-[#15C0E61E] border !border-[#15C0E6]" // PENDING
            : ""
        }
        
        ${
          vacations?.dates?.selected?.includes(days.date) //SELECTED
            ? "!bg-[#C775391E] border !border-[#EB741E]"
            : ""
        }
          ${
            vacations?.dates?.pay?.includes(days.date) //PAY
              ? "!bg-[#66CC9B]"
              : ""
          }
      `;

          return (
            <button
              key={index}
              className={` ${buttonClasses} ${
                disabled ? "!cursor-not-allowed border border-transparent" : "cursor-pointer border border-transparent"
              } relative`}
              value={days ? JSON.stringify(days) : "{}"}
              disabled={disabled}
              onClick={(event) =>
                handleDay(event.currentTarget, userId, dataVacations)
              }
            >
              {/* {moment(days.date).isSameOrBefore(moment(), "day") &&
                !holidayFound &&
                !vacations?.dates?.approved?.includes(days.date) &&
                !vacations?.dates?.pay?.includes(days.date) && (
                  <div className="absolute w-[1px] h-full bg-red-100 inset-x-0 inset-y-0 m-auto rotate-[145deg]" />
                )} */}

              {vacations?.dates?.approved?.includes(days.date) && (
                <div className="flex justify-center">
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/crm/holiday-w.svg`}
                    alt="approved"
                    width={20}
                    height={20}
                  />
                </div>
              )}

              {/* validacion 2 */}
              {holidayFound &&
                !vacations?.dates?.pay?.includes(days.date) &&
                !vacations?.dates?.approved?.includes(days.date) && (
                  <div
                    className="relative z-10 flex items-center justify-center"
                    onMouseOver={() => setIsToolTip(true)}
                    onMouseLeave={() => setIsToolTip(false)}
                  >
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/crm/christmas.svg`}
                      alt="icon holiday"
                      width={20}
                      height={20}
                    />
                    {isToolTip && <ToolTipHoliday name={holidayFound.name} />}
                  </div>
                )}
            </button>
          );
        })}
      </div>

      {/* MODAL CONFIRM */}
      {isButtonEnabled == users.id && (
        <RhConfirmVacation
          isOpen={isConfirmOpen}
          userVacation={users}
          close={() => setIsConfirmOpen(false)}
          notificationResponse={notificationResponse}
        />
      )}

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
          notificationResponse={notificationResponse}
        />
      )}

      <AlertsCrm
        setConfirmation={isRole ? ConfirmAlert : () => setOpenAlert(false)}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title={alertConfig.title}
        text={alertConfig.text}
        typeAlert={alertConfig.typeAlert}
      />
    </>
  );
}
