import { useContext } from "react";

import CardTourHome from "./CardTourHome";
import { MarketingContext } from "../../../context/MarketingContext";

const activitiesExplore = [
  {
    codeName: "acapulco",
    country: "MX",
    description:
      "Acapulco, conocido por sus playas doradas y vibrante vida nocturna, ofrece paisajes naturales espectaculares y una rica historia cultural. Con su bahía icónica y emocionantes actividades acuáticas, es el destino perfecto para unas vacaciones inolvidables.",
    destinationName: "Acapulco",
    image: "https://cdn2.civitatis.com/mexico/acapulco/acapulco.jpg",
    key: 1,
    activities: [
      {
        activityName: "Isla El Morro Paddleboarding",
        codeName: "paddle-surf-en-el-islote-el-morro",
        destinationName: "Acapulco",
        duration: "1h 30m",
        image:
          "https://cdn2.civitatis.com/mexico/acapulco/paddle-surf-islote-morro.jpg",
        key: 1,
        price: 225,
        stars: 1,
        hidden: false,
      },
      {
        activityName: "Acapulco Bay Snorkelling",
        codeName: "snorkel-en-la-bahia-de-acapulco",
        destinationName: "Acapulco",
        duration: "1h 30m",
        image:
          "https://cdn2.civitatis.com/mexico/acapulco/snorkel-bahia-acapulco.jpg",
        key: 5,
        price: 315,
        stars: 1,
        hidden: false,
      },
      {
        activityName: "El Morro Islet Kayak + Hiking Tour",
        codeName: "tour-en-kayak-y-senderismo-por-el-islote-el-morro",
        destinationName: "Acapulco",
        duration: "2h",
        image:
          "https://cdn2.civitatis.com/mexico/acapulco/kayak-senderismo-islote-morro.jpg",
        key: 7,
        price: 315,
        stars: 5,
        hidden: true,
      },
      {
        activityName: "Tour por Mazatlán + Acuario",
        codeName: "tour-por-mazatlan-acuario",
        destinationName: "Mazatlan",
        duration: "5h",
        image:
          "https://cdn2.civitatis.com/mexico/mazatlan/tour-mazatlan-acuario.jpg",
        key: 570,
        price: 495,
        stars: 5,
        hidden: true,
      },
    ],
  },
];

export default function ExploreActivitiesHome() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div className="flex flex-col my-[180px] max-sm:my-[90px]">
      {/* TITLE EXPLORE ACTIVITIES HOME*/}
      <span className="m-b text-fs-28 mb-[4px]">
        Explora una variedad de actividades
      </span>

      {/* SUBTITLE EXPLORE ACTIVITIES HOME */}
      <span className="m-m text-fs-16 text-gry-100 mb-[32px]">
        Te recomendamos aquí los mejores tours de México.
      </span>

      <div
        className={`w-full flex ${
          responsiveView.value === "tablet"
            ? "flex-col h-auto gap-[12px]"
            : "h-[717px] gap-[2vw]"
        } ${responsiveView.value === "mobile" && "flex-col h-auto gap-[12px]"}`}
      >
        {/* SWIPER EXPLORE ACTIVITIES HOME */}
        <div
          className={`relative  overflow-hidden rounded-lg shadow-3xl ${
            responsiveView.value === "tablet" ? "w-full h-[500px]" : "w-[50%]"
          } ${responsiveView.value === "mobile" && "!w-full h-[500px]"} `}
        >
          <img
            src={activitiesExplore[0].image}
            alt="activity home"
            width={547}
            height={717}
            className="h-[73%] w-full select-none object-cover max-lg:h-[61%]"
          />
          {/* SWIPER IMG */}

          <div className="h-[314px] absolute bottom-0 w-full z-0">
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/img/home/curve-yellow.png`}
              alt="curve yellow"
              className="w-full h-full select-none"
            />
          </div>

          <div className="w-[190px] h-[190px] rounded-full border-[32px] border-yw-100 absolute top-[-114px] right-[-31px] z-[0]" />

          <div
            className={`absolute bottom-0 flex flex-col gap-[8px] z-0 max-xl:pb-[10px] max-lg:pb-[49px] ${
              responsiveView.value === "mobile"
                ? "pl-[47.5px] pr-[59.5px] pb-[20px]"
                : "pb-[49px] pl-[32.5px] pr-[17.5px] pt-[30px]"
            }`}
          >
            <h3 className="text-fs-28 m-s-b">
              {activitiesExplore[0].destinationName}
            </h3>
            <p
              className={`m-m m-0 ${
                responsiveView.value === "mobile" ? "text-fs-12" : "text-fs-14"
              }`}
            >
              {activitiesExplore[0].description}
            </p>
            <button className="cursor-default m-b text-fs-16 flex items-center w-fit px-[24px] py-[10px] bg-white rounded-full gap-[8px]">
              Saber más
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/arrows/arrow-right.svg`}
                width={16}
                height={12}
                alt="icon-arrow"
                className="w-[16px] h-[12px]"
              />
            </button>
          </div>
        </div>

        {/*CARDS TOUR EXPLORE ACTIVITIES HOME */}
        <div
          className={`grid ${
            responsiveView.value === "tablet"
              ? "w-full gap-[12px]"
              : "w-[55%] gap-[16px] gap-x-[30px]"
          } ${
            responsiveView.value === "mobile"
              ? "w-full gap-[12px] grid-cols-1"
              : "grid-cols-2"
          }`}
        >
          {activitiesExplore[0] &&
            activitiesExplore[0].activities
              .slice(0, 4)
              .map((activity, index) => (
                <CardTourHome activity={activity} key={index} />
              ))}
        </div>
      </div>
    </div>
  );
}
