import { useState } from "react";

import { DropzoneUser } from "./DropzoneUser";
import { useUser } from "../../../../configs/login/UserContext";

export function UserImage() {
  const{ user, imageUser} = useUser();
  
  const [isHover, setIsHover] = useState(false);
  return (
    <>
      <img
        src={imageUser ? imageUser : user.url_image}
        alt="img user"
        className="w-full h-full object-cover rounded-lg"
      />
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={`cursor-pointer absolute w-full h-full inset-y-0 inset-x-0 `}
      >
        {isHover && (
          <>
            <DropzoneUser />
          </>
        )}
      </div>
    </>
  );
}
