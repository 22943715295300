export function ButtonPayment({
  payment,
  paymentSelected,
  setTypePayment,
}) {
  const handleName = (name) => {
    switch (name) {
      case "op":
        return "Open Pay";
      case "cp":
        return "Clip";
      case "ca":
        return "Conecta";
    }
  };

  return (
    <button
      onClick={() => setTypePayment(payment)}
      className={`focus:outline-none border-o rounded-full py-[7px] px-[18px] ${
        payment.name == paymentSelected?.name
          ? "bg-bl-100"
          : "bg-white shadow-3xl"
      }`}
    >
      <p
        className={`m-0 text-center text-fs-14 m-m ${
          payment.name == paymentSelected?.name ? "text-white" : " text-black"
        }`}
      >
        {handleName(payment.name)}
      </p>
    </button>
  );
}
