/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";

import { FilterApartment } from "./FilterApartment";
import { UseVacations } from "../context/VacationsContext";
import { useUser } from "../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";

export default function SearchCalendar() {
  const { user } = useUser();

  const [selected, setSelected] = useState("");
  const { input, setInput } = UseVacations();
  const [hasPermissionsDepartmentList, setHasPermissionsDepartmentList] =
    useState(false);

  const isRole =
    user.department_name === "Recursos Humanos" ||
    user.department_name === "Sistemas";

  const handleLetters = (input) =>
    input.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, "");

  const handleChange = (event) => {
    const newValue = handleLetters(event.target.value);
    if (newValue.includes("@")) {
      return;
    }
    setInput(newValue);
  };

  useEffect(() => {
    setHasPermissionsDepartmentList(
      HasPermissionsUser(user.permissions, "list_departments")
    );
  }, []);

  return (
    <div className="mb-1">
      <div className="flex justify-between items-center px-2 pb-1 w-full">
        {isRole && (
          <Combobox value={selected} onChange={setSelected}>
            <div className="relative mt-1 w-full">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg m-b">
                <Combobox.Input
                  className="w-full border-none py-3 text-fs-16 text-sm leading-5 text-[#0A1629] placeholder:text-[#0A1629] focus:outline-none placeholder:text-fs-16 max-2xl:placeholder:text-fs-12"
                  placeholder="Empleados"
                  autoComplete="off"
                  name={`search-${Math.random().toString(36).substr(2, 9)}`}
                  onChange={handleChange}
                  value={input}
                />

                <Combobox.Button className="absolute inset-y-0 right-[-1px] flex items-center bg-[#F4F9FD] rounded-[14px] p-3 max-2xl:p-2">
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/search/search-black.svg`}
                    alt="icon-eye-close"
                    width={19}
                    height={19}
                  />
                </Combobox.Button>
              </div>
            </div>
          </Combobox>
        )}
      </div>
      
      {hasPermissionsDepartmentList && <FilterApartment />}
    </div>
  );
}
