import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";
import { TotalStars } from "../../../../../components/stars/TotalStars";

export default function CardTourHome({ activity }) {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div
      className={`shadow-3xl rounded-lg colum-one cursor-default ${
        responsiveView.value === "mobile" && activity.hidden ? "hidden" : ""
      }`}
    >
      <div className="w-full h-[241px] overflow-hidden rounded-t-lg">
        <img
          className={`w-full h-full rounded-t-lg object-cover select-none`}
          src={activity.image}
          alt="card"
        />
      </div>

      <div className="w-full rounded-b-lg pb-3 pt-2 px-4 bg-white flex flex-col h-[109px]">
        <div className="m-s-b pt-1 text-fs-14 text-start truncate mb-[4px]">
          {activity.activityName}
        </div>

        <div className="mb-[4px]">
          <TotalStars
            name="read-only"
            stars={activity.stars}
            width={"10px"}
            height={"10px"}
          />
        </div>

        <div className="flex w-full">
          <div className="flex gap-1 w-1/2">
            <img
              className="w-[11px] h-[14px]"
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/location/location-bl.svg`}
              alt="location icon"
              width={11}
              height={14}
            />

            <span className="text-bl-100 m-s-b text-fs-12 truncate">
              {activity.destinationName}
            </span>
          </div>

          <div className="flex justify-end w-1/2 items-center">
            <div className="flex flex-col">
              <span className="m-m text-gry-100 text-fs-12 text-start">
                desde
              </span>

              <span className="m-b text-or-100 text-fs-12">
                MXN $
                <span className="m-b text-fs-16">
                  {Math.floor(activity.price)
                    .toLocaleString("es-MX", { currency: "MXN" })
                    .replace(".00", "")}
                  .<sup>{(activity.price % 1).toFixed(2).slice(2)}</sup>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
