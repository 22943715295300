import "./globals.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import DashboardLayout from "./DashboardLayout";
import AuthGuard from "./configs/login/AuthGuard";
import ResetPassword from "./configs/login/ResetPassword";
import { UserProvider } from "./configs/login/UserContext";
import ForgotPassword from "./configs/login/ForgotPassword";
import { LoginLayout } from "./sections/Login/Layout/LoginLayout";
import { NotificationProvider } from "./context/NotificationContext";

const App = () => {
  return (
    <UserProvider>
      <NotificationProvider>
        <Router>
          <Routes>
            {/* ROUTES OUT */}
            <Route path="/login" element={<LoginLayout />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* ROUTEN IN LOGIN  */}
            <Route
              path="*"
              element={
                <AuthGuard>
                  <DashboardLayout />
                </AuthGuard>
              }
            />
          </Routes>
        </Router>
      </NotificationProvider>
    </UserProvider>
  );
};

export default App;
