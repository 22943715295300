import { DropzoneReservation } from "./DropzoneReservation";
const Options = ["Credencial", "Pasaporte", "Acta de nacimiento"];

export function AddFiles({ isLoader, imageIsEmpty, addFiles, setAddFiles }) {
  const handleNewFiles = () => {
    if (!isLoader) {
      setAddFiles((files) => [
        ...files,
        { document_name: "Credencial", file: "", type: "", viewFile: "" },
      ]);
    }
  };

  const removeFile = (item) => {
    if (!isLoader) {
      setAddFiles((files) => files.filter((_, index) => item !== index));
    }
  };

  const handelTypeOption = (e, index) => {
    setAddFiles((files) =>
      files.map((file, item) => {
        if (item === index) {
          return { ...file, document_name: e.target.value };
        }
        return file;
      })
    );
  };

  return (
    <>
      <div className="flex gap-10 items-center">
        <span className="text-fs-14 m-b text-gry-100">Nuevos documentos</span>

        <div
          className={`rounded-lg bg-bl-100 text-center flex items-center gap-x-3 px-4 py-2 ${
            isLoader ? "cursor-default" : "cursor-pointer"
          }`}
          onClick={handleNewFiles}
        >
          <img
            src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/add/add-w.svg`}
            alt="add new files"
            width={12}
            height={12}
          />

          <h3 className="text-white m-b text-fs-12"> Nuevo documento</h3>
        
        </div>
      </div>

      <div className="scroll-page-gry pr-3 flex flex-col items-center gap-y-2  overflow-y-auto max-h-[20rem] min-h-[8rem] rounded-lg mt-6 mb-6 py-4">
        {addFiles.length > 0 ? (
          addFiles.map((file, index) => (
            <div
              key={index}
              className={`relative mb-3 gap-[30px] w-full flex items-center border-dashed border-[3px] p-4 rounded ${
                addFiles.length - 1 !== index && "border border-gry-50"
              }`}
            >
              <div className="relative flex w-[300px]">
                <label
                  htmlFor="location"
                  className="m-m text-gry-100 text-fs-10 absolute top-[12px] left-[12px] "
                >
                  Tipo de documento
                </label>

                <select
                  className="pt-[1.2rem] mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6 text-fs-14 bg-white"
                  onChange={() => handelTypeOption(event, index)}
                >
                  {Options.map((option, item) => (
                    <option
                      key={item}
                      className="m-0 p-2 text-fs-12 m-m text-black"
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <DropzoneReservation
                imageIsEmpty={imageIsEmpty}
                file={file}
                position={index}
                setAddFiles={setAddFiles}
              />

              {/* REMOVE FILE */}
              <button
                className={`absolute cursor-pointer flex items-center justify-center top-2 right-2 ${
                  isLoader ? "cursor-default" : "cursor-pointer"
                }`}
                onClick={() => removeFile(index)}
              >
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/close/close-r.svg`}
                  alt="remove file"
                  width={10}
                  height={10}
                />
              </button>
            </div>
          ))
        ) : (
          <h2 className="text-center items-center flex justify-center text-fs-13 m-s-b text-gry-100 my-auto shadow-3xl w-[98%] max-h-[20rem] min-h-[9rem] rounded-lg">
            No hay archivos
          </h2>
        )}
      </div>
    </>
  );
}
