/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../../others/BtnEdit";
import { SetConfigDropzone } from "../../others/setConfigDropZone";
import { MarketingContext } from "../../../context/MarketingContext";
import { useUser } from "../../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../../configs/Permissions/AlertPermissionsDeclined";

export function BannerOffersNow() {
  return (
    <div className="flex flex-col">
      <span className="m-b text-fs-28 ">Ofertas de ahora</span>

      <span className="m-m text-fs-16 text-gry-100 mb-[22px]">
        Promociones y ofertas válidas durante tiempo limitado.
      </span>

      <OffersNow />
    </div>
  );
}

export function OffersNow() {
  const {
    responsiveView,
    setIsOpenEdit,
    isOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
    jonImagesActualView,
    totalFiles,
    languageSelected,
  } = useContext(MarketingContext);
  const [offersNowBlue, setOffersNowBlue] = useState(null);
  const [offersNowYellow, setOffersNowYellow] = useState(null);

  const [selectedBanner, setSelectedBanner] = useState(null);

  useEffect(() => {
    if (jonImagesActualView[languageSelected]) {
      setOffersNowBlue(
        jonImagesActualView[languageSelected].bannerBlue[responsiveView.value]
      );
      setOffersNowYellow(
        jonImagesActualView[languageSelected].bannerYellow[responsiveView.value]
      );
    }
  }, [totalFiles, responsiveView, routActual]);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (jsonConfig) => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpenEdit(true);

      let configDropzone = SetConfigDropzone(routActual, jsonConfig);
      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  const isBannerBlue =
    selectedBanner === "bannerBlue" && currentBanner === "bannerBlue";
  const isBannerYellow =
    selectedBanner === "bannerYellow" && currentBanner === "bannerYellow";

  return (
    <>
      <div
        className={` w-full gap-[2vw] justify-between ${
          isOpenEdit ? "h-[19rem]" : "h-[350px]"
        } ${responsiveView.value === "laptop" ? "flex" : "hidden"}`}
      >
        <div
          onClick={() => handleClick("bannerBlue")}
          className={`w-[50%] relative flex justify-center cursor-pointer rounded-xl group hover:border-4 hover:border-or-100 ${
            isBannerBlue && "border-4 border-or-100 "
          }`}
        >
          <img
            src={
              offersNowBlue?.urlImage ? offersNowBlue?.urlImage : offersNowBlue
            }
            alt="banner-offers-blue"
            className={`object-cover w-full rounded-lg select-none group-hover:brightness-75 ${
              isBannerBlue && "brightness-75"
            }`}
          />
          <BtnEdit />
        </div>

        <div
          onClick={() => handleClick("bannerYellow")}
          className={`w-[50%] relative flex justify-center cursor-pointer rounded-xl group hover:border-4 hover:border-or-100 ${
            isBannerYellow && "border-4 border-or-100 "
          }`}
        >
          <img
            src={
              offersNowYellow?.urlImage
                ? offersNowYellow?.urlImage
                : offersNowYellow
            }
            alt="banner-offers-yellow"
            className={`object-cover w-full rounded-lg select-none group-hover:brightness-75 ${
              isBannerYellow && "brightness-75"
            }`}
          />
          <BtnEdit />
        </div>
      </div>

      {/* SWIPER MOBILE BANNER OFFERS NOW */}
      <div
        className={`relative justify-center cursor-pointer rounded-xl ${
          responsiveView.value === "tablet" ? "h-[768px]" : "h-[412px]"
        } ${responsiveView.value === "laptop" ? "hidden" : "flex "}`}
      >
        <Swiper
          slidesPerView={1}
          className="h-full rounded-lg !z-0 !static"
          initialSlide={0}
          loop={true}
        >
          {/* BANNER OFFERS NOW 1 */}
          <SwiperSlide className="!flex !justify-center !bg-white">
            <div
              onClick={() => handleClick("bannerBlue")}
              className={`relative w-full flex justify-center !mb-5 hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                isBannerBlue && "border-4 border-or-100 rounded-xl"
              }`}
            >
              <img
                src={
                  offersNowBlue?.urlImage
                    ? offersNowBlue?.urlImage
                    : offersNowBlue
                }
                alt="banner-offers-blue-mobile"
                className={`h-full w-full rounded-lg select-none object-cover group-hover:brightness-75 ${
                  isBannerBlue && "brightness-75"
                }`}
              />
              <BtnEdit />
            </div>
          </SwiperSlide>

          {/* BANNER OFFERS NOW 2 */}
          <SwiperSlide className="!flex !justify-center !bg-white">
            <div
              onClick={() => handleClick("bannerYellow")}
              className={`relative w-full flex justify-center !mb-5 hover:border-4 hover:border-or-100 hover:rounded-xl cursor-pointer group ${
                isBannerYellow && "border-4 border-or-100 rounded-xl"
              }`}
            >
              <img
                src={
                  offersNowYellow?.urlImage
                    ? offersNowYellow?.urlImage
                    : offersNowYellow
                }
                alt="banner-offers-yellow-m"
                className={`h-full w-full rounded-lg select-none object-cover group-hover:brightness-75 ${
                  isBannerYellow && "brightness-75"
                }`}
              />
              <BtnEdit />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* END SWIPER MOBILE BANNER OFFERS NOW */}

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
