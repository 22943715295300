import { useState } from "react";
import { UpdatePermissionsBayUser } from "../../api/api";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";

export function ButtonSavePermissions({
  setPermissionsAssigned,
  permissionsAssigned,
  setRemovePermissions,
  removePermissions,
  userSelected,
  permissionsByUser,
}) {
  const [isLoader, setIsLoader] = useState(false);
  const { notification, showNotification, hideNotification } =
    useNotification();

  const handlePermissionsByUser = async () => {
    setIsLoader(true);
    let permissions = {
      userId: userSelected,
      Added: permissionsAssigned,
      Revoqued: removePermissions,
    };
    try {
      await UpdatePermissionsBayUser(permissions);
      showNotification(
        "success",
        "Actualización de permisos",
        "Los permisos fueron asignados correctamente",
        5000
      );
      setIsLoader(false);
      setPermissionsAssigned([]);
      setRemovePermissions([]);
      permissionsByUser();
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      showNotification(
        "error",
        "Actualización de permisos",
        "Ocurrió un problema en la actualización, inténtelo más tarde",
        5000
      );
      throw error;
    }
  };

  const validPermissions =
    permissionsAssigned.length == 0 && removePermissions.length == 0;

  const shouldDisableButton = validPermissions || isLoader;

  return (
    <>
      <button
        disabled={shouldDisableButton}
        onClick={handlePermissionsByUser}
        className={`w-max rounded-full border-0 focus:outline-none px-4 py-3 flex gap-x-3 items-center ${
          shouldDisableButton ? "bg-bl-70" : "bg-bl-100"
        }`}
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/save-w.svg`}
          alt="save"
          width={18}
          height={18}
        />

        <p className="text-white m-0 m-s-b text-fs-14">
          {isLoader ? "Guardando..." : "Guardar"}
        </p>
      </button>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
