/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { MarketingContext } from "../../context/MarketingContext";

export function StepsImagesValidation() {
  const [validStep, setValidStep] = useState({
    laptop: "1",
    tablet: "1",
    mobile: "1",
  });

  const {
    totalFiles,
    configDropzone,
    responsiveView,
    languageSelected,
    jonImagesActualView,
  } = useContext(MarketingContext);

  useEffect(() => {
    if (jonImagesActualView[languageSelected]) {
      validationFiles(
        jonImagesActualView[languageSelected][configDropzone.nameJson]
      );
    }
  }, [
    jonImagesActualView,
    languageSelected,
    configDropzone,
    totalFiles,
    responsiveView,
  ]);

  const validationFiles = (viewsResponse) => {
    Object.keys(validStep).map((views) => {
      if (Array.isArray(viewsResponse[views])) {
        // console.log("es arreglo", viewsResponse[views]);
        if (
          viewsResponse[views].some(
            (element) => typeof element === "object" && element !== null
          )
        ) {
          setValidStep((step) => {
            return { ...step, [views]: "3" };
          });
        } else if (
          responsiveView.value === views &&
          viewsResponse[views].some(
            (element) => typeof element !== "object" || element === null
          )
        ) {
          setValidStep((step) => {
            return { ...step, [views]: "2" };
          });
        } else if (
          viewsResponse[views].some(
            (element) => typeof element === "string" && element !== null
          )
        ) {
          setValidStep((step) => {
            return { ...step, [views]: "1" };
          });
        }
      } else {
        // console.log(viewsResponse[views]);
        if (
          viewsResponse[views] !== null &&
          typeof viewsResponse[views] === "object"
        ) {
          setValidStep((step) => {
            return { ...step, [views]: "3" };
          });
        } else if (
          (responsiveView.value === views &&
            typeof viewsResponse[views] !== "object") ||
          viewsResponse[views] == null
        ) {
          setValidStep((step) => {
            return { ...step, [views]: "2" };
          });
        } else if (
          typeof viewsResponse[views] === "string" &&
          viewsResponse[views] !== null
        ) {
          setValidStep((step) => {
            return { ...step, [views]: "1" };
          });
        }
      }
    });
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex gap-x-3">
        <SuccessStep step={validStep.laptop} />

        <ViewProcess view={"Laptop"} step={validStep.laptop} />
      </div>

      <div className="flex gap-x-3">
        <SuccessStep step={validStep.tablet} />

        <ViewProcess view={"Tablet"} step={validStep.tablet} />
      </div>

      <div className="flex gap-x-3">
        <SuccessStep step={validStep.mobile} line={false} />

        <ViewProcess view={"Mobile"} step={validStep.mobile} />
      </div>
    </div>
  );
}

const SuccessStep = ({ step, line = true }) => {
  return (
    <div className="flex flex-col gap-y-3">
      <div
        className={`rounded-full flex items-center justify-center w-[27px] h-[27px] 
        ${step === "3" && "bg-bl-100"}
        ${step === "2" && "border border-bl-100"}
        ${step === "1" && "bg-bl-50"}`}
      >
        {step === "3" && (
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/done/done-w.svg`}
            alt="success icon"
            width={12}
            height={12}
          />
        )}

        {step === "2" && (
          <div className="w-[15px] h-[15px] bg-bl-100 mx-auto my-auto rounded-full" />
        )}
      </div>

      {line && (
        <div
          className={`flex justify-center relative ${
            (step === "1" || step === "2") && "opacity-[0.2] grayscale-[1]"
          }`}
        >
          <div className={`w-[2.1px] h-[70px] bg-bl-100`} />

          <img
            className="absolute right-0 left-0 bottom-0 mx-auto"
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/arrows/down-bl.svg`}
            alt="row bottom"
            width={16}
            height={16}
          />
        </div>
      )}
    </div>
  );
};

const ViewProcess = ({ view, step }) => {
  return (
    <div>
      <p className="m-0 pb-1 m-m text-fs-12">{view}</p>
      <p className="m-0 pb-3 m-b text-fs-14">Images</p>
      <p
        className={`m-0 m-b text-fs-14 ${step === "3" && "text-grn-100"} ${
          step === "2" && "text-bl-100"
        } ${step === "1" && "text-gry-70"}`}
      >
        {step === "3" && "Completado"} {step === "2" && "En progreso"}{" "}
        {step === "1" && "Pendiente"}
      </p>
    </div>
  );
};
