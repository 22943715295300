import { useContext } from "react";

import CardHotelHome from "./CardHotelHome";
import { MarketingContext } from "../../../context/MarketingContext";

export default function EnjoyStayHome() {
  const { responsiveView } = useContext(MarketingContext);
  
  const hotelsEnjoy = [
    {
      code: 42883,
      name: "Park Royal Beach Cancun",
      codeName: "park-royal-beach-cancun",
      image:
        "https://cdn.worldota.net/t/x500/content/85/6c/856ce692b51b8c305884d9c23dbc682796affde3.JPEG",
      destinationCodeName: "cancun",
      price: 2341.8,
      stars: 4,
      address: "Blvd Kukulkan Km. 12.5, Zona Hotelera",
      es: {
        destination: "Cancun",
        country: "mexico",
      },
      en: {
        destination: "Cancun",
        country: "mexico",
      },
      hidden:false
    },
    {
      code: 42886,
      name: "Sandos Caracol Eco Resort All Inclusive",
      codeName: "sandos-caracol-eco-resort-all-inclusive",
      image:
        "https://cdn.worldota.net/t/x500/content/ec/ce/eccefa29e25b07b207bf740170970bd559f2d5a8.jpeg",
      destinationCodeName: "riviera-maya-playa-del-carmen",
      price: 2255.6,
      stars: 4,
      address: "Carretera Cancun, Chetumal, Km. 295",
      es: {
        destination: "Playa del Carmen",
        country: "mexico",
      },
      en: {
        destination: "Playa del Carmen",
        country: "mexico",
      },
      hidden:true
    },
  ];

  return (
    <div
      className={`flex w-full rounded-lg ${
        responsiveView.value === "laptop" ? "h-[448px]" : "h-auto flex-col"
      }`}
    >
      {/* TEXT AND BTN SEE OFFERS */}
      <div
        className={`relative ${
          responsiveView.value === "laptop"
            ? "w-[40%]"
            : "w-full h-[330px] rounded-t-lg bg-bl-100"
        }`}
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/banners/home/Frame-1693.jpg`}
          alt="fondo azul con palmeras"
          className={`select-none h-full w-full ${
            responsiveView.value === "laptop"
              ? "rounded-l-lg"
              : "rounded-t-lg rounded-l-0"
          }`}
        />

        <div
          className={`absolute bottom-0 flex flex-col pl-[49px] ${
            responsiveView.value === "laptop"
              ? "pb-[111px] w-[80%] gap-[40px]"
              : "pb-[70px] pr-[49px] w-full gap-[20px]"
          } ${responsiveView.value === "mobile" && " !pb-[50px]"}`}
        >
          <h2
            className={`text-white m-s-b leading-[1.13] ${
              responsiveView.value === "laptop" ? "text-fs-38" : "text-fs-40"
            } ${responsiveView.value === "mobile" && " !text-fs-34"}`}
          >
            Disfruta de tu estancia en hoteles exclusivos
          </h2>

          <button className="px-[16px] py-[14px] bg-or-100 text-white text-fs-14 m-s-b rounded-full w-fit mt-[20px] cursor-default">
            Ver Hoteles
          </button>
        </div>
      </div>

      {/* SWIPER CARDS HOTEL */}
      <div
        className={` relative  ${
          responsiveView.value === "laptop"
            ? "w-[60%]"
            : "w-full h-[448px] rounded-b-lg bg-bl-100"
        }`}
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/banners/home/Pool_view_4.tmedium.jpg`}
          alt="alberca con palmeras alrededor"
          className={`h-full w-full pt-[1px] select-none brightness-75  ${
            responsiveView.value === "laptop" ? "rounded-r-lg" : "rounded-b-lg"
          }`}
        />

        <div
          className={`absolute top-0 w-full h-full pt-[54.2px] ${
            responsiveView.value === "mobile"
              ? " px-[40px]"
              : " px-[5rem]"
          } ${responsiveView.value === "tablet" && " !pl-[76px] !pr-[67px]"}`}
        >
          <div className="relative flex justify-between gap-3">
            {hotelsEnjoy.map((hotel, index) => (
              <div
                className={`!rounded-lg shadow-3xl w-fit ${
                  responsiveView.value === "mobile" && hotel.hidden
                    ? "hidden"
                    : ""
                }`}
                key={index}
              >
                <CardHotelHome hotel={hotel} />
              </div>
            ))}
            {/* ARROWS */}
            <div className="absolute gap-2 top-40 left-[-1.5rem] rounded-full bg-white h-10 w-10 flex justify-center items-center">
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/arrows/left-100.svg`}
                alt="icon arrow left"
                width={8}
                height={8}
              />
            </div>
            <div className="absolute gap-2 top-40 right-[-1.5rem] rounded-full bg-white h-10 w-10 flex justify-center items-center">
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/arrows/right-100.svg`}
                alt="icon arrow right"
                width={8}
                height={8}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
