/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { AddFiles } from "./AddFiles";
import { NewComments } from "./NewComments";
import { UseReservations } from "../../context/ReservationsContext";
import { useUser } from "../../../../configs/login/UserContext";
import {
  SaveFilesReservationDetails,
  SaveNewComment,
} from "../../api/Reservations";

export function DialogComments({ isOpen, setIsOpen }) {
  const [comments, setComments] = useState("");
  const [addFiles, setAddFiles] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [imageIsEmpty, setImageIsEmpty] = useState(false);
  const [isEmptyComment, setIsEmptyComment] = useState(false);
  const [isFailed, setIsFailed] = useState({
    isFailDocuments: false,
    isFailComments: false,
  });

  const { detailsReservation, setDetailsReservations } = UseReservations();

  const { user, token } = useUser();

  const handleComment = async (event) => {
    event.preventDefault();

    if (comments.trim() === "") {
      setIsEmptyComment(true);
      return;
    }

    setIsLoader(true);

    if (addFiles.length > 0) {
      let filesResponse = await responseFiles();
      if (filesResponse == "ok") {
        await responseComments();
      }
    } else {
      await responseComments();
    }

    setIsLoader(false);
  };

  const responseFiles = async () => {
    try {
      if (addFiles.length > 0 && addFiles.some((file) => file.file === "")) {
        setImageIsEmpty(true);
        setIsLoader(false);
        return;
      } else if (
        addFiles.length > 0 &&
        addFiles.some((file) => file.file !== "")
      ) {
        // ADD NEW FILES
        let filesResponse = await SaveFilesReservationDetails(
          token,
          detailsReservation.bookingReference,
          addFiles
        );

        if (filesResponse.data.code === "ok") {
          setDetailsReservations((details) => {
            return {
              ...details,
              documents: [
                ...details.documents,
                ...filesResponse.data.documents,
              ],
            };
          });
        }

        setIsLoader(false);
        setImageIsEmpty(false);
        setIsFailed((fails) => {
          return { ...fails, isFailDocuments: false };
        });

        return "ok";
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      setIsFailed((fails) => {
        return { ...fails, isFailDocuments: true };
      });
      throw error;
    }
  };

  const responseComments = async () => {
    try {
      let commentResponse = await SaveNewComment(
        detailsReservation.bookingReference,
        user.id,
        comments
      );

      if (commentResponse.code === "ok") {
        setDetailsReservations((details) => {
          return {
            ...details,
            comments: [
              ...details.comments,
              {
                user_name: user?.name,
                comment: comments,
                created_at: new Date(),
              },
            ],
          };
        });

        setIsFinish(true);
        setIsLoader(false);
        setIsFailed((fails) => {
          return { ...fails, isFailComments: false };
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      setIsFailed((fails) => {
        return { ...fails, isFailComments: true };
      });
      throw error;
    }
  };

  useEffect(() => {
    if (isFinish) {
      setComments("");
      setAddFiles([]);
      setIsOpen(false);
      setIsFinish(false);
      setImageIsEmpty(false);
      setIsEmptyComment(false);
    }
  }, [isFinish]);

  //   VALIDATION ALERTS
  useEffect(() => {
    if (isFailed.isFailComments) {
      alert(
        "Ocurrió un problema al agregar un comentario, inténtelo más tarde."
      );
    }

    if (isFailed.isFailDocuments) {
      alert(
        "Ocurrió un problema en la subida de documento, por favor inténtalo más tarde."
      );
    }
  }, [isFailed]);

  return (
    isOpen && (
      <div className="fixed flex items-center justify-center left-0 right-0 top-0 bottom-0 w-[100vw] h-[100vh] backdrop-brightness-50 z-[1]">
        <div className="bg-white rounded-lg p-5 relative w-[35%]">
          {/* CLOSE MODAL */}
          <button
            onClick={() => setIsOpen(false)}
            className="focus:outline-none absolute right-0 top-[12px] mr-3"
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/close/close-70.svg`}
              alt="close modal"
              width={13}
              height={13}
            />
          </button>

          <h1 className="text-center text-fs-18 text-gry-100 m-b py-2">
            Subir documentos
          </h1>

          <hr className="border-[1.5px] mt-2 mb-4" />

          <form onSubmit={handleComment}>
            <AddFiles
              isLoader={isLoader}
              imageIsEmpty={imageIsEmpty}
              addFiles={addFiles}
              setAddFiles={setAddFiles}
            />

            <NewComments
              isLoader={isLoader}
              comments={comments}
              setComments={setComments}
              isEmptyComment={isEmptyComment}
              setIsEmptyComment={setIsEmptyComment}
            />

            <div className="flex justify-center items-center gap-x-12">
              {/* CLOSE MODAL */}
              <a
                onClick={() => setIsOpen(false)}
                className="focus:outline-none text-red-100 text-fs-12 m-b cursor-pointer p-4 rounded-lg hover:text-white hover:bg-red-100"
              >
                Cancelar
              </a>

              <button
                disabled={isLoader}
                type="submit"
                className="gap-2 focus:outline-none rounded-lg bg-bl-100 hover:bg-bl-110 flex items-center p-4 text-white text-fs-12 m-b"
              >
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/general/save-w.svg`}
                  alt="save comments"
                  width={18}
                  height={18}
                />
                {isLoader ? "Guardando..." : "Guardar"}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}
