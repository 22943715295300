/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { logoutApi } from "./api/api";
import { ValidationUser } from "./sections/RH/api/api";
import MenuVar from "./components/nabvar/MenuVar";
import { GetRouterMkt } from "./routes/GetRouterMkt";
import { useUser } from "./configs/login/UserContext";
import { PhatRoutings } from "./routes/ConfigsRouting";
import { NotificationContext } from "./context/NotificationContext";
import Unauthorized from "./components/unauthorized/Unauthorized";

const DashboardLayout = () => {
  const routes = PhatRoutings();
  const { user, setUser, token, setToken } = useUser();
  const userRole = user?.module_ids || []; // Use optional chaining with a default empty string

  const { isOpen } = useContext(NotificationContext);
  const isMkt = GetRouterMkt();
  const location = useLocation();

  useEffect(() => {
    const getUserLogged = async () => {
      if (user?.id) {
        try {
          const response = await ValidationUser(user.id);

          // VALIDATION MODULES AND UPDATE LOCAL STORAGE
          validationModules(response.data?.UserInfo?.module_ids);

          // VALIDATION PERMISSIONS AND UPDATE LOCAL STORAGE
          validationPermission(response.data?.UserInfo?.permissions);

          if (response.data.UserInfo.status === "INACTIVO") {
            handleLogout();
          }
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
    };

    const intervalId = setInterval(() => {
      getUserLogged();
    }, 20000);

    getUserLogged();

    return () => clearInterval(intervalId);
  }, [user?.id]); // Ensure `user.id` is part of the dependency array

  const orderValue = (arrayValue) => {
    return arrayValue.sort((a, b) => a - b);
  };

  const oderPermission = (permissions) => {
    return permissions.sort((a, b) => a.id - b.id);
  };

  const validationModules = (modules) => {
    let moduleUser = JSON.stringify(orderValue(user.module_ids));
    let actualModule = JSON.stringify(orderValue(modules));
    if (moduleUser !== actualModule) {
      let newInfoUSer = { ...user, module_ids: modules };
      setUser(newInfoUSer);
    }
  };

  const validationPermission = (permissions) => {
    let permissionUser = JSON.stringify(oderPermission(user.permissions));
    let permission = JSON.stringify(oderPermission(permissions));
    if (permissionUser !== permission) {
      let newInfoUSer = { ...user, permissions: permissions };
      setUser(newInfoUSer);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutApi(token);
      setUser(null);
      setToken(null);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <div className="flex justify-between">
      {/* Render MenuVar only if the path does not include '/mkt/' */}
      {!isMkt && !location.pathname.includes("/mkt/") && (
        <div className="relative">
          <MenuVar />
        </div>
      )}

      <div
        className={`${
          isOpen ? (isMkt ? "w-full" : "w-[82%]") : "w-full"
        } p-2.5 flex-grow`}
      >
        <Routes>
          {routes.map((route, index) => {
            const isAllowedModules = route.allowedModules.some((role) =>
              userRole.includes(role)
            );
            if (isAllowedModules) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<Unauthorized />} // Or redirect to another route
                />
              );
            }
          })}
        </Routes>
      </div>
    </div>
  );
};

export default DashboardLayout;
