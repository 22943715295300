import { useState } from "react";

import { InputMarkup } from "./InputMarkup";
import { SetMarkup } from "../../api/ConfigurationApi";
import AlertsCrm from "../../../../components/alerts/Alerts";
import { ContainerConfig } from "../../config/ContainerConfig";
import { ButtonSave } from "./ButtonSave";

export function ContainerMarkup({ showNotification, isOpen }) {
  const [inputMarkup, setInputMarkup] = useState("");
  const [loader, setLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const saveMarkup = async (isConfirm) => {
    if (isConfirm) {
      setOpenAlert(false);
      setLoader(true);
      try {
        await SetMarkup(inputMarkup);
        setLoader(false);
        showNotification(
          "success",
          "Markup actualizado",
          "El Markup se actualizó correctamente",
          5000
        );
      } catch (error) {
        console.log(error);
        setLoader(false);
        showNotification("error", "El Markup no se pudo actualizar", "", 5000);
        throw error;
      }
    }
  };

  return (
    <>
      <ContainerConfig isOpen={isOpen}>
        {isOpen && (
          <div className="flex flex-col gap-y-2.5">
            <InputMarkup
              inputMarkup={inputMarkup}
              setInputMarkup={setInputMarkup}
              isValid={isValid}
              setIsValid={setIsValid}
            />

            <ButtonSave
              setOpenAlert={setOpenAlert}
              inputMarkup={inputMarkup}
              loader={loader}
              isValid={isValid}
            />
          </div>
        )}
      </ContainerConfig>

      <AlertsCrm
        setConfirmation={saveMarkup}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title="Markup"
        text="¿Deseas cambiar el valor del Morkup?"
        typeAlert={"warning"}
      />
    </>
  );
}
