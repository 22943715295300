import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";
import { TotalStars } from "../../../../../components/stars/TotalStars";

export default function CardTopActivities({ tour }) {
  const { responsiveView } = useContext(MarketingContext);

  return (
    <div
      className={`!w-fit !rounded-lg shadow-3xl colum-two ${
        responsiveView.value === "tablet" && tour.hidden ? " hidden" : "flex"
      }`}
    >
      <div
        className={`  h-full cursor-pointer shadow-md shadow-gry-30 rounded-xl relative max-md:min-w-[266px] max-md:max-w-[280px] ${
          responsiveView.value === "laptop"
            ? `!max-w-[286px]`
            : " min-w-[229px] max-w-[245px] "
        }  ${responsiveView.value === "mobile" ? "!max-w-fit" : ""}`}
      >
        <div className="w-full h-[216px] overflow-hidden rounded-t-lg">
          <img
            className={`w-full h-full object-cover select-none`}
            src={tour.image}
            alt="card"
          />

          <p className="absolute m-b text-fs-10 text-gry-100 top-[2%] right-[2%] py-[14px] px-[10px] rounded-lg bg-[#EBEBEB] bg-opacity-70">
            <span>Duración </span>
            {tour.duration}
          </p>
        </div>

        <div className="w-full rounded-b-lg pb-3 pt-2 px-4 bg-white flex flex-col">
          <div
            className={`m-s-b pt-1 text-fs-14 text-start truncate mb-[4px] ${
              responsiveView.value === "mobile" && "w-[250px]"
            }`}
          >
            {tour.es.name}
          </div>

          <TotalStars
            className="my-1"
            name="read-only"
            stars={tour.category}
            readOnly
            width={"14px"}
            height={"14px"}
          />

          <div className="flex gap-1 mb-[26px] mt-[6px]">
            <img
              className="w-auto h-auto"
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/location/location-bl.svg`}
              alt="icon-location"
              width={11}
              height={14}
            />
            <span className="text-bl-100 m-s-b text-fs-12">
              {tour.es.destination}
            </span>
          </div>

          <div className="flex justify-between border-t border-[#ebebeb] pt-[11px] items-center">
            <div className="flex flex-col text-fs-12">
              <span className="m-m text-black text-fs-12 text-start">
                desde
              </span>
              <span className="m-s-b text-or-100">
                MXN{" "}
                <span className="m-b text-fs-16">
                  ${Math.floor(tour.es.price)}
                </span>
                .
                <sup className="m-b">
                  {(tour.es.price % 1).toFixed(2).slice(2)}
                </sup>
              </span>
            </div>

            <button
              className={`m-b  text-fs-12 min-h-8 rounded-3xl border-2  px-4 py-2 text-nowrap text-black border-bl-100 
              }`}
            >
              Ver detalles
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
//   if (language === "es") {
//     if (value.es) {
//       return {
//         name: value.es.name,
//         destination: value.es.destination,
//         price: value.es.price,
//       };
//     } else {
//       return {
//         name: value.en.name,
//         destination: value.en.destination,
//         price: value.en.price,
//       };
//     }
//   } else if (language === "en") {
//     if (value.en) {
//       return {
//         name: value.en.name,
//         destination: value.en.destination,
//         price: value.en.price,
//       };
//     } else {
//       return {
//         name: value.es.name,
//         destination: value.es.destination,
//         price: value.es.price,
//       };
//     }
//   }
// };
