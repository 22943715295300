export const displayLoginError = (code, showNotification) => {
    switch (code) {
      case 'unf':
        showNotification('error', 'Error de Autenticación', 'No se ha encontrado el usuario especificado. Verifique sus credenciales e inténtelo de nuevo.', 5000);
        break;
      case 'pwdNot':
        showNotification('error', 'Error de Autenticación', 'La contraseña ingresada es incorrecta. Por favor, inténtelo de nuevo.', 5000);
        break;
      case 'credErr':
        showNotification('error', 'Error de Autenticación', 'Ha ocurrido un problema al verificar sus credenciales. Inténtelo de nuevo más tarde.', 5000);
        break;
      case 'usrInact':
        showNotification('error', 'Cuenta Inactiva', 'Su cuenta está actualmente inactiva. Por favor, contacte al soporte para obtener ayuda.', 5000);
        break;
      case 'usrLogin':
        showNotification('warning', 'Sesión en otro dispositivo', 'Su cuenta está actualmente iniciada en otro dispositivo. Cierre sesión en otros dispositivos o contacte al soporte.', 5000);
        break;
      case 'error':
        showNotification('error', 'Error', 'Ha ocurrido un error inesperado. Inténtelo de nuevo o contacte al soporte si el problema persiste.', 7000);
        break;
      case 'ok':
        showNotification('success', 'Operación Exitosa', 'Su solicitud ha sido procesada con éxito.', 5000);
        break;
      case "usrInactive":
        showNotification('warning', 'Acceso restringido', 'Por el momento el acceso fue restringido, comunícate con RH', 5000);
        break;
      default:
        showNotification('error', 'Error', 'Ha ocurrido un error inesperado. Inténtelo de nuevo o contacte al soporte si el problema persiste.', 7000);
        break;
    }
  };

  export const displayUserCreation = (code, showNotification) => {
    switch (code) {
      case 'emailReg':
        showNotification('error', 'Correo en Uso', 'El correo electrónico ya está registrado. Por favor, utiliza uno diferente o intenta recuperar tu cuenta.', 5000);
        break;
      case 'pwdWeak':
        showNotification('error', 'Contraseña Insegura', 'La contraseña no es lo suficientemente fuerte. Debe tener al menos 8 caracteres, incluir un número y un carácter especial.', 5000);
        break;
      case 'valErr':
        showNotification('error', 'Datos Incorrectos', 'Algunos campos contienen información no válida. Revisa los datos e intenta nuevamente.', 5000);
        break;
      case 'error':
        showNotification('error', 'Algo salió mal', 'Ocurrió un inconveniente mientras procesábamos tu solicitud. Por favor, inténtalo de nuevo más tarde.', 7000);
        break;
      case 'ok':
        showNotification('success', 'Usuario Creado', 'El usuario se ha creado correctamente.', 5000);
        break;
      default:
        showNotification('error', 'Error Inesperado', 'Ocurrió un problema inesperado. Por favor, intenta nuevamente o contacta al soporte si el problema persiste.', 7000);
        break;
    }
  };
  
  
  