/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDropzone } from "react-dropzone";
import { useCallback, useContext, useEffect, useState } from "react";

import AlertsCrm from "../alerts/Alerts";
import { base64Data, configName } from "./ChangeFormatImage";
import { RemoveFileOnJson, SetFilesJson } from "./ConfigImagesJson";
import { MarketingContext } from "../../sections/Marketing/context/MarketingContext";

export function DropzoneFile() {
  const [files, setFiles] = useState([]);

  const {
    routActual,
    setTotalFiles,
    configDropzone,
    responsiveView,
    languageSelected,
    jonImagesActualView,
  } = useContext(MarketingContext);

  let isImageValidation = import.meta.env.VITE_IMAGE_VALIDATE;
  const [openAlert, setOpenAlert] = useState(false);
  const [configAlert, setConfigAlert] = useState({
    typeAlert: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    let imageJson = jonImagesActualView;

    if (configDropzone && imageJson[languageSelected]) {
      let images = [];

      let jsonImages =
        imageJson[languageSelected][configDropzone.nameJson][
          responsiveView.value
        ];

      // VALIDATION INFO TYPE
      if (Array.isArray(jsonImages)) {
        images = [...jsonImages];
      } else if (
        typeof jsonImages === "string" ||
        (typeof jsonImages === "object" && jsonImages)
      ) {
        images = [jsonImages];
      }
      setFiles(images);
    }
  }, [
    configDropzone,
    routActual,
    responsiveView,
    jonImagesActualView,
    languageSelected,
  ]);

  useEffect(() => {
    setTotalFiles(files.length);
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const totalFiles = files.length + acceptedFiles.length;

      if (totalFiles > configDropzone.maxImage) {
        setOpenAlert(true);
        setConfigAlert({
          typeAlert: "warning",
          title: "Límite Máximo de Imágenes Alcanzado",
          text: `No puedes subir más imágenes. El límite permitido es de ${configDropzone.maxImage} Por favor, elimina alguna existente si necesitas agregar una nueva`,
        });
        return;
      }

      acceptedFiles.slice(0, configDropzone.maxImage).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.onload = () => {
            if (isImageValidation !== true) {
              // VALIDATION WIDTH AND HEIGHT
              if (
                image.width > configDropzone[responsiveView.value].maxWidth ||
                image.width < configDropzone[responsiveView.value].minWidth ||
                image.height > configDropzone[responsiveView.value].maxHeight ||
                image.height < configDropzone[responsiveView.value].minHeight
              ) {
                setOpenAlert(true);
                setConfigAlert({
                  typeAlert: "warning",
                  title: "Dimensiones de la Imagen Incorrectas",
                  text: `Por favor, asegúrate de que la imagen cumpla con las medidas requeridas: ${
                    configDropzone[responsiveView.value].width
                  } x ${
                    configDropzone[responsiveView.value].height
                  } píxeles antes de subirla.`,
                });
                return;
                // return alert(
                //   `las imagenes deben tener las siguente dimencion ${
                //     configDropzone[responsiveView.value].maxWidth
                //   }`
                // );
              }
            }
            // console.log(file);

            let name = configName(file.name);

            base64Data(URL.createObjectURL(file)).then((imageData) => {
              const fileData = {
                name: name,
                imageType: file.type,
                imageData: imageData,
                urlImage: URL.createObjectURL(file),
              };

              // REPLACE IMAGE ON JSON
              let replaceImage = SetFilesJson(
                configDropzone,
                responsiveView.value,
                fileData,
                jonImagesActualView,
                languageSelected
              );

              if (Array.isArray(replaceImage)) {
                replaceImage = [...replaceImage];
              } else {
                replaceImage = [replaceImage];
              }
              setFiles(replaceImage);
            });

            return;
          };
          image.src = event.target.result;
        };
        if (file.type.startsWith("image/")) {
          reader.readAsDataURL(file);
        } else {
          alert("Solo se puede subir archivos de tipo imagen");
          return;
        }
      });
    },
    [files, configDropzone[responsiveView.value]]
  );

  const removeFile = (file) => {
    let remove = RemoveFileOnJson(
      configDropzone,
      responsiveView.value,
      file,
      jonImagesActualView,
      languageSelected
    );
    if (!remove) {
      remove = [];
    }
    setFiles(remove);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="border-dashed border-2 border-gry-70 rounded-lg">
      {files.length === 0 ? (
        <div className="cursor-pointer p-5 relative" {...getRootProps()}>
          <input {...getInputProps()} />
          <p className="text-center m-b text-gry-100 text-fs-14">
            Arrastra archivos aquí, o haz clic para seleccionar archivos
          </p>
        </div>
      ) : (
        <div className="flex p-2 items-center justify-between gap-x-2 ">
          <div className="flex flex-wrap p-2 gap-3 w-full items-center justify-center overflow-y-auto min-h-[8rem] max-h-[11rem] scroll-page-gry">
            {files.map((file, index) => (
              <div key={index} className="relative h-max">
                <button
                  className="focus:outline-none z-[3] bg-[#d9d9d9] w-[20px] h-[20px] rounded-full flex justify-center items-center absolute top-[-4px] right-[-6px]"
                  onClick={() => removeFile(file)}
                >
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/close/close-70.svg`}
                    alt="icon close gry"
                    className="w-[9px] h-[9px]"
                    width={15}
                    height={15}
                  />
                </button>

                {file?.file?.type.startsWith("image/") || file ? (
                  <img
                    src={file.urlImage ? file.urlImage : file}
                    alt="preview"
                    width="100"
                    className={`rounded-lg w-[100px] h-[80px]`}
                  />
                ) : (
                  <p>{file?.file.name}</p>
                )}
              </div>
            ))}
          </div>

          <div
            className="focus:outline-none p-5 cursor-pointer rounded-lg bg-[#ededed] text-gry-100 text-fs-10 m-m flex flex-col justify-center items-center"
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              disabled={configDropzone.maxImage >= files.length}
            />

            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/general/add-image.svg`}
              alt="icon close gry"
              className="w-[18px] h-[18px]"
              width={18}
              height={18}
            />
          </div>
        </div>
      )}
      <AlertsCrm
        setConfirmation={() => setOpenAlert(false)}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title={configAlert.title}
        text={configAlert.text}
        typeAlert={configAlert.typeAlert}
      />
    </div>
  );
}
