/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { createContext, useContext, useState, useEffect } from "react";

import { GetRoleList } from "../../Users/api/api";
import { useUser } from "../../../configs/login/UserContext";
import { GetApartmentList, GetVacationsList } from "../api/api";
import { ImageModification } from "../../../components/imageGet/ImageGet";
import { FilterApartment, FilterInput } from "../config/FiltersVacations";

const VacationsContext = createContext();

export const UseVacations = () => useContext(VacationsContext);

const VacationsProviderContext = ({ children }) => {
  const { user } = useUser();
  const [filterData, setFilterData] = useState([]);
  const [dataVacations, setDataVacations] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState();
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [isLoader, setIsLoader] = useState(false);
  const [input, setInput] = useState("");
  const [apartmentFilter, setApartmentFilter] = useState("all");
  const [apartmentList, setApartmentList] = useState([]);

  const [daysPerMonth, setDaysPerMonth] = useState([
    { year: moment().year(), day: 1, nameDay: "lunes", nameMonth: "enero" },
  ]);
  const [rolesLists, setRolesLists] = useState([]);

  const getRoleLists = async () => {
    try {
      const roles = await GetRoleList();
      setRolesLists(roles.roleList);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getApartmentList = async () => {
    try {
      const response = await GetApartmentList();
      setApartmentList(response?.roleList || []);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const refreshUserList = async () => {
    setIsLoader(true);
    try {
      const data = await GetVacationsList(user?.department_name, user.id);
      const response = await ImageModification(data.employees);
      setDataVacations(response);
      setIsLoader(false);
    } catch (error) {
      console.error("Error fetching vacations data:", error);

      setIsLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (dataVacations) {
        let filter = dataVacations;

        if (input.length > 3) {
          filter = await FilterInput(input);
        } else {
          filter = dataVacations;
        }

        if (apartmentFilter !== "all") {
          filter = FilterApartment(apartmentFilter, filter);
        }

        setFilterData(filter);
      }
    };
    fetchData();
  }, [dataVacations, input, apartmentFilter]);

  useEffect(() => {
    refreshUserList();
    // getRoleLists();
  }, []);

  return (
    <VacationsContext.Provider
      value={{
        currentYear,
        setCurrentYear,
        currentMonth,
        setCurrentMonth,
        daysPerMonth,
        setDaysPerMonth,
        isButtonEnabled,
        setIsButtonEnabled,
        dataVacations,
        setDataVacations,
        filterData,
        setFilterData,
        refreshUserList,
        rolesLists,
        setRolesLists,
        getRoleLists,
        getApartmentList,
        isLoader,
        input,
        setInput,
        apartmentList,
        apartmentFilter,
        setApartmentFilter,
      }}
    >
      {children}
    </VacationsContext.Provider>
  );
};

export { VacationsContext, VacationsProviderContext };
