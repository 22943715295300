import { useContext, useEffect, useState } from "react";

import { transportData } from "../../../configs/Json";
import { MarketingContext } from "../../../context/MarketingContext";

export function RecommendedTransport() {
  const [maxLength, setMaxLength] = useState(0);
  const { responsiveView } = useContext(MarketingContext);

  useEffect(() => {
    switch (responsiveView.value) {
      case "laptop":
        setMaxLength(5);
        break;
      case "tablet":
        setMaxLength(3);
        break;
      case "mobile":
        setMaxLength(2);
        break;
    }
  }, [responsiveView]);

  return (
    <>
      <div className="relative">
        {/* TITLE RECOMMENDED TRANSPORT */}
        <h2 className="mb-[32px] text-fs-24 m-b">Opciones recomendadas</h2>

        {/* CARD TRANSPORT */}
        <div
          className={`grid gap-x-3.5 grid-rows-1 ${
            responsiveView.value === "laptop" && `grid-cols-5`
          } ${responsiveView.value === "tablet" && "grid-cols-3"} ${
            responsiveView.value === "mobile" && "grid-cols-2"
          }`}
        >
          {transportData.slice(0, maxLength).map((transport, index) => (
            <div key={index}>
              <div className="flex flex-col gap-2 p-[16px] border-2 border-[#EBEBEB] rounded-lg bg-white h-[22rem]">
                <CatalogueRoutesCard transport={transport} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export function CatalogueRoutesCard({ transport }) {
  return (
    <>
      {/* IMG TRANSPORT */}
      <div className="w-full flex justify-center items-center">
        <img
          src={transport.img}
          width={216}
          height={164}
          alt={transport.name}
          className="select-none aspect-[32/15]"
        />
      </div>

      {/* NAME TRANSPORT */}
      <div className="flex flex-col gap-[16px]">
        <span className="text-fs-16 m-b mt-[16px]">{transport.name}</span>

        {/* FEATURES */}
        <div className="flex flex-col gap-[8px]">
          <span className="text-gry-100 text-fs-12 m-m">Características</span>

          <div className="flex flex-wrap gap-2">
            <div className="flex gap-2">
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/adult/adult-b.svg`}
                width={12.8}
                height={13.4}
                alt="icon adult"
                className="aspect-square"
              />
              <span className="m-m text-fs-12">
                {transport.capacity} Personas
              </span>
            </div>

            {/* suitcases */}
            <div className="flex gap-2">
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/baggage/baggage-b.svg`}
                width={12.1}
                height={14.1}
                alt="icon baggage"
                className="aspect-square"
              />
              <span className="m-m text-fs-12">
                {transport.facilities.largeSuitcase} Maletas
              </span>
            </div>
          </div>
          <div className="flex gap-2">
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/baggage/baggage-b.svg`}
              width={12.1}
              height={14.1}
              alt="icon baggage"
              className="aspect-square"
            />
            <span className="m-m text-fs-12">
              {transport.facilities.handSuitcase} Equipaje de mano
            </span>
          </div>
        </div>

        {/* MODALITIES */}
        <div className="flex flex-col gap-[8px]">
          <span className="text-gry-100 text-fs-12 m-m">Modalidades</span>

          <div className="flex gap-2 mb-[15.5px]">
            {transport.type === "private" ? (
              <div className="bg-gry-50 text-gry-100 rounded-full px-[8px] py-[4px] m-s-b text-fs-12">
                Privado
              </div>
            ) : (
              <div className="bg-gry-50 text-gry-100 rounded-full px-[8px] py-[4px] m-s-b text-fs-12">
                Compartido
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
