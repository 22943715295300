/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../../others/BtnEdit";
import { SetConfigDropzone } from "../../others/setConfigDropZone";
import { MarketingContext } from "../../../context/MarketingContext";
import { useUser } from "../../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../../configs/Permissions/AlertPermissionsDeclined";

export default function BannerFooterTour() {
  const {
    routActual,
    totalFiles,
    setIsOpenEdit,
    currentBanner,
    responsiveView,
    setCurrentBanner,
    languageSelected,
    setConfigDropzone,
    jonImagesActualView,
  } = useContext(MarketingContext);

  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerLocation, setBannerLocation] = useState(null);

  // SET IMAGE ACTUAL FROM JSON
  useEffect(() => {
    if (jonImagesActualView) {
      setBannerLocation(
        jonImagesActualView[languageSelected].bannerLocation[
          responsiveView.value
        ]
      );
    }
  }, [totalFiles, responsiveView, routActual, languageSelected]);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  // SET COMPONENT SELECTED
  const handleClick = (jsonConfig) => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpenEdit(true);

      let configDropzone = SetConfigDropzone(routActual, jsonConfig);

      setConfigDropzone(configDropzone);
      setSelectedBanner(jsonConfig);
      setCurrentBanner(jsonConfig);
    }
  };

  const isBannerDestinationTour =
    selectedBanner === "bannerLocation" && currentBanner === "bannerLocation";

  return (
    <div
      onClick={() => handleClick("bannerLocation")}
      className={`relative justify-center flex w-full ${
        responsiveView.value === "mobile" && " h-[442px] my-[30px] "
      }${responsiveView.value === "tablet" && " h-[349px] my-[120px] "}${
        responsiveView.value === "laptop" && " h-[535px] my-[120px] "
      }`}
    >
      <img
        src={
          bannerLocation?.urlImage ? bannerLocation?.urlImage : bannerLocation
        }
        width={1272}
        height={469}
        alt="banner-footer-tour"
        className={`w-full h-auto select-none object-cover hover:brightness-75 cursor-pointer rounded-2xl hover:border-4 hover:border-or-100 ${
          isBannerDestinationTour && "brightness-75 border-4 border-or-100"
        }`}
      />
      <BtnEdit />

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </div>
  );
}
