export function InputMarkup({
  inputMarkup,
  setInputMarkup,
  isValid,
  setIsValid,
}) {
  const handleNumbers = (numbers) => {
    return numbers.replace(/\D/g, "");
  };

  const handleMarkup = (value) => {
    let markup = parseInt(value);

    if (markup < 6 || markup > 20) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    setInputMarkup(value);
  };

  return (
    <div
      className={`relative flex items-center border rounded-md my-2.5 ${
        isValid ? "border-gry-70" : "border-red-100"
      }`}
    >
      <img
        className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
        src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/money/money-b.svg`}
        width={15}
        height={15}
      />

      <input
        value={inputMarkup}
        onChange={(event) => handleMarkup(handleNumbers(event.target.value))}
        maxLength={20}
        type="text"
        placeholder="Markup"
        className="m-m py-3 pl-[40px] focus:outline-none w-full h-full bg-transparent text-gry-100 text-fs-12 text-right"
      />
      <p className="ml-4 text-fs-14 m-b mr-[18px]">$</p>

      {!isValid && (
        <p className="text-red-100 absolute bottom-[-22px] right-0">
          El markup tiene que ser mayor a 6 y menor a 21
        </p>
      )}
    </div>
  );
}
