import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { responsiveViews } from "../../configs/Json";
import { MarketingContext } from "../../context/MarketingContext";

export function ResponsiveViews({ view }) {
  const { responsiveView, setResponsiveView, languageSelected } = useContext(MarketingContext);
  const navigation = useNavigate();

  const handleVersionsView = (views) => {
    let configView = { value: views.value, width: views.width };
    setResponsiveView(configView);
    navigation(`/mkt/${configView.value}/${view}/${languageSelected}`);
  };

  return (
    <div className={`flex gap-x-2 items-center `}>
      {responsiveViews.map((res, index) => (
        <button
          key={index}
          onClick={() => handleVersionsView(res)}
          className={`focus:outline-none w-[48px] h-[34px] flex items-center justify-center ${
            responsiveView.value === res.value ? "bg-or-100" : "bg-gry-50"
          } rounded-md`}
        >
          <img
            src={
              res.urlIcon +
              `${responsiveView.value === res.value ? res.iconW : res.icon}`
            }
            alt={res.icon}
            width={res.w}
            height={res.h}
          />
        </button>
      ))}
    </div>
  );
}
