import { useState } from "react";
import EditUser from "./EditUser/EditUser";

export default function BtnEditUser({ dataUserFilter }) {
  const [openAddUser, setOpenAddUser] = useState(false);
  return (
    <>
      <button>
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/pencil-100.svg`}
          alt="edit-pencil"
          width={13}
          height={15}
          onClick={() => setOpenAddUser(!openAddUser)}
        />
      </button>

      {openAddUser && (
        <EditUser
          users={dataUserFilter}
          closeNewUser={() => setOpenAddUser(false)}
        />
      )}
    </>
  );
}
