/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useMemo } from "react";

import Profile from "./Profile";
import Settings from "./Settings";
import { Tooltip } from "../Tooltip/Tooltip";
import NavigationMenu from "./NavigationMenu";
import { useUser } from "../../configs/login/UserContext";
import LogOut from "../../sections/Login/components/LogOut";
import { navigationMenu, settingsMenu } from "./OptionsMenu";
import { NotificationContext } from "../../context/NotificationContext";
import EditUser from "../../sections/Users/components/EditUser/EditUser";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MenuVar() {
  const { user } = useUser();

  const userRole = user?.module_ids || [];

  const navigate = useNavigate();
  const routerActual = NavigationMenu();
  const [openEditUser, setOpenEditUser] = useState(false);
  // const [settings, setSettings] = useState(settingsMenu);
  const { isOpen, setIsOpen } = useContext(NotificationContext);

  const filteredNavigationMenu = useMemo(
    () =>
      navigationMenu.filter((item) =>
        item.roles.some((role) => userRole.includes(role))
      ),
    [userRole]
  );

  const filterNavigationSetting = useMemo(
    () =>
      settingsMenu.filter((item) =>
        item.roles.some((role) => userRole.includes(role))
      ),
    [userRole]
  );
  
  const updatedNavigation = useMemo(
    () =>
      filteredNavigationMenu.map((nav) => ({
        ...nav,
        current: nav.href === routerActual,
      })),
    [filteredNavigationMenu, routerActual]
  );

  const updatedNavigationSetting = useMemo(
    () =>
      filterNavigationSetting.map((nav) => ({
        ...nav,
        current: nav.href === routerActual,
      })),
    [filterNavigationSetting, routerActual]
  );

  function open() {
    setIsOpen(!isOpen);
  }

  const handleRoute = (ref) => {
    navigate(`/${ref}`);
  };

  return (
    <>
      {/* DESKTOP*/}
      <div
        className={`sticky transition-all duration-500 top-0 w-max max-lg:hidden h-[90vh] lg:inset-y-0 lg:flex lg:flex-col m-4 shadow-3xl rounded-lg ${
          isOpen ? "lg:w-72" : "lg:w-28"
        }`}
      >
        <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white p-6 rounded-lg ">
          {/* LOGO */}
          <div className="flex h-16 shrink-0 items-center justify-between relative">
            <img
              alt="staywuw logo"
              src={`${import.meta.env.VITE_URL_IMAGES}assets${
                isOpen
                  ? "/royal/principal-logo.svg"
                  : "/icons/general/infotipo-staywuw.svg"
              }`}
              className={` transition-all duration-500 object-contain ${
                isOpen ? "h-[43px] w-[150px]" : "h-[43px] w-[43px]"
              }`}
            />
            <button
              onClick={open}
              className="focus:outline-none h-[24px] w-[24px] rounded-full shadow-3xl flex justify-center items-center absolute top-[14px] right-[-14px]"
            >
              <img
                alt="arrow icon"
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/crm/navbarCRM/right-black.svg`}
                className={`${isOpen ? "rotate-180 transform" : ""}`}
              />
            </button>
          </div>

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              {/* MENU */}
              <li className="min-h-80">
                <span className="m-m text-gry-100 text-fs-12 ml-[5px]">
                  Menú
                </span>
                <ul role="list" className="-mx-2 space-y-1">
                  {updatedNavigation.map((item) => (
                    <li
                      key={item.name}
                      className={`h-[52px] flex ${!isOpen && "justify-center"}`}
                    >
                      <Tooltip
                        text={<React.Fragment>{item.name}</React.Fragment>}
                      >
                        <div
                          onClick={() => handleRoute(item.href)}
                          className={`${classNames(
                            item.current
                              ? "relative bg-gry-50 text-bl-100 after:border-[1.8px] after:border-bl-100 after:absolute after:left-[-1px] after:h-[30px] after:rounded-lg"
                              : "text-gry-100 hover:bg-gry-50 hover:text-bl-100",
                            "group flex gap-x-3 rounded-md p-2 m-s-b text-fs-14 leading-6 h-full items-center"
                          )} ${isOpen ? "" : "justify-center"}`}
                        >
                          <img
                            src={`${classNames(
                              item.current ? item.iconA : item.icon
                            )}`}
                            alt={item.alt}
                            className="w-[20px] h-[20px]"
                          />

                          <div className={`${isOpen ? "" : "hidden"}`}>
                            {item.name}
                          </div>
                        </div>
                      </Tooltip>
                    </li>
                  ))}
                </ul>
              </li>

              {/* SETTINGS */}
              <li>
                <hr />
                <Settings
                  settings={updatedNavigationSetting}
                  isOpen={isOpen}
                  handleRoute={handleRoute}
                />
              </li>

              {/* PROFILE */}
              <li className="-mx-6 h-full items-end flex flex-col justify-end">
                <Profile
                  user={user}
                  isOpen={isOpen}
                  setOpenEditUser={setOpenEditUser}
                />
                <LogOut isOpen={isOpen} />
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <EditUser
        users={user}
        isOpen={openEditUser}
        setIsOpen={setOpenEditUser}
      />
    </>
  );
}
