
export default function TabInfoRH({active, setActive}) {
  
  return (
    <div className={`sticky top-0 flex min-w-[379px]`}>
      <p
        className={`${
          active === "Info"
            ? "text-bl-100 border-bl-100 m-s-b"
            : "text-gry-100 border-gry-50 m-m"
        }  m-0 text-fs-14 border-b py-2 px-4 cursor-pointer`}
        onClick={() => setActive("Info")}
      >
        Informacion
      </p>

      <p
        className={`${
          active === "Doc"
            ? "text-bl-100 border-bl-100 m-s-b"
            : "text-gry-100 border-gry-50 m-m"
        }  m-0 text-fs-14 border-b py-2 px-4 cursor-pointer`}
        onClick={() => setActive("Doc")}
      >
        Documentos
      </p>
  </div>
  )
}
