/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

import { RolesSkeleton } from "../../config/Skeleton";
import { UsersContext } from "./../../context/UsersContext";

export function FilterByRol() {
  // const [rolesList, setRolesList] = useState([]);

  console.log("entra");
  

  const { setRolesSelected, rolesSelected, getRoleLists, rolesLists } = useContext(UsersContext);

  useEffect(() => {
    getRoleLists()
  }, []);

  const handleCheckBox = (id) => {
    setRolesSelected((roles) => {
      if (roles.some((rol) => rol === id)) {
        return roles.filter((service) => service !== id);
      } else {
        return [...roles, id];
      }
    });
  };

  return (
    <div className="flex flex-col items-start gap-y-3">
      <p className="text-fs-14 m-b">Roles</p>

      <div className="grid grid-cols-2 gap-x-2 gap-y-2 h-max">
        {rolesLists.length > 0 ? (
          rolesLists.map((role, index) => (
            <button
              key={index}
              className={`select-none py-2 px-4 focus:outline-none rounded-full border ${
                rolesSelected.some((value) => value === role.id)
                  ? "border-bl-100 bg-bl-100 text-white"
                  : "border-gry-70"
              }`}
              onClick={() => handleCheckBox(role.id)}
            >
              <p className="m-0 m-s-b text-fs-12 text-center">
                {role.description}
              </p>
            </button>
          ))
        ) : (
          <RolesSkeleton />
        )}
      </div>
    </div>
  );
}
