/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

import TopActivities from "../others/TopActivities";
import { BannerHome } from "../../HotelView/BannerHome";
import { GetImagesByView } from "../../../api/MarketingApi";
import { PopularState } from "../../HotelView/others/PopularState";
import BannerFooterTour from "../bannerFooterTour/bannerFooterTour";
import { MarketingContext } from "../../../context/MarketingContext";
import { BannerCallHotelT } from "../../HotelView/others/BannerCallHotelT";
import BannerSearch from "../../../../../components/bannerHomes/BannerSearch";
import { ContainerViewsHome } from "../../../../../components/containers/ContainerViewsHome";

export function TourLayout() {
  const { responsiveView, jonImagesActualView, setJonImagesActualView, routActual, languageSelected } =
    useContext(MarketingContext);


  useEffect(() => {
    if (routActual) {
      // VALID IF LENGTH ARY IS EMPTY FROM GET IMAGE
      if (jonImagesActualView[languageSelected] == null ) {
        GetImagesByView(routActual, languageSelected).then((result) => {
          if (result) {
            setJonImagesActualView((images) => {
              return { ...images, [languageSelected]: result };
            });
          }
        });
      }
    }
  }, [routActual, languageSelected]);

  return (
    jonImagesActualView[languageSelected] && (
      <div
        className={`mx-auto ${
          responsiveView.width !== "all" && responsiveView.width
        }`}
      >
        <BannerSearch />

        <ContainerViewsHome>
          <></>
          <BannerHome />

          <BannerCallHotelT />
          <PopularState />
          <TopActivities />
          <BannerFooterTour />
        </ContainerViewsHome>
      </div>
    )
  );
}
