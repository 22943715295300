/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { UseVacations } from "../context/VacationsContext";

export function FilterApartment() {
  const {
    apartmentList,
    getApartmentList,
    apartmentFilter,
    setApartmentFilter,
  } = UseVacations();

  useEffect(() => {
    const getApartment = async () => {
      try {
        getApartmentList();
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    getApartment();
  }, []);

  const handleApartment = (apartment) => {

    setApartmentFilter(apartment);
  };

  return (
    <div className="flex gap-1 pr-2">
      {/* SELECT */}
      <div className={`flex border w-fit py-1 px-2 rounded border-gry-50`}>
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/filter-black.svg`}
          alt="filter table"
          width={13}
          height={13}
        />

        <select
          value={apartmentFilter}
          onChange={(event) => handleApartment(event.target.value)}
          className="focus:outline-none px-3 py-1 rounded-xl text-fs-14 text-gry-100 m-m scroll-page-gry w-full"
        >
          <option value="all">Todos</option>

          {apartmentList &&
            apartmentList.map((apartment, index) => (
              <option value={apartment.department_name} key={index}>
                {apartment.department_name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}
