/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { base64Data } from "../../../../components/dropzone/ChangeFormatImage";

export function DropzoneFiles({ docs, setRhDocs }) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.slice(0, 1).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          base64Data(URL.createObjectURL(file)).then((imageData) => {
            let urlImage = URL.createObjectURL(file);
            addNewFile(imageData, urlImage);
          });
          return;
        };
        image.src = event.target.result;
      };
      if (file.type.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        base64Data(URL.createObjectURL(file)).then((pdfData) => {
          let urlPdf = URL.createObjectURL(file);
          addNewFile(pdfData, urlPdf);
        });
        return;
      }
    });
  });

  const addNewFile = (urlFile, url) => {
    setRhDocs((files) => {
      return files.map((file) => {
        if (file.type === docs.type) {
          return { ...file, document: urlFile, url: url, isEdit: true };
        }
        return file;
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div
      {...getRootProps()}
      className="w-full h-full flex items-center backdrop-brightness-50 rounded-2xl"
    >
      <input type="text" {...getInputProps()} placeholder="file" />
      <p className="mx-auto my-auto m-s-b text-white text-fs-12">
        Cambiar archivo
      </p>
    </div>
  );
}
