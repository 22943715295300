import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { updateBookingStatusApi } from "../../api/Reservations";

export default function ReconfirmationDialog({
  isOpen,
  onClose,
  referenceNumber,
  isUpdate
}) {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const updateBookingStatus = async () => {
    setLoading(true);
    try {
      const bookingStatus = await updateBookingStatusApi(referenceNumber);
      let message = "";

      switch (bookingStatus) {
        case "Confirmado":
          setStatus("Confirmado");
          message = (
            <div className="text-fs-18">
              La reserva <span className="m-b">{referenceNumber}</span> cambió a estatus{" "}
              <span className="text-grn-100 m-b">
                confirmado
              </span>
              .
            </div>
          );
          break;
        case "Pendiente":
          setStatus("Pendiente");
          message = (
            <div className="text-fs-18">
              La reserva <span className="m-b">{referenceNumber}</span> está en estatus{" "}
              <span className="text-or-100 m-b">
                pendiente
              </span>
              . Por favor, intenta nuevamente o contacta al equipo.
            </div>
          );
          break;
        case "Cancelado":
          setStatus("Cancelado");
          message = (
            <div className="text-fs-18">
              La reserva <span className="m-b">{referenceNumber}</span>  ha sido{" "}
              <span className="text-red-100 m-b">
                cancelada
              </span>
              . Por favor, contacta al equipo.
            </div>
          );
          break;
        default:
          message = "Hubo un problema. Por favor, contacta al equipo.";
          break;
      }

      setStatusMessage(message);
    } catch (error) {
      setStatusMessage(error.message);
    } finally {
      setLoading(false);
      isUpdate();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[500px] h-[400px] transform rounded-lg overflow-hidden bg-white text-left shadow-xl transition-all">
                {/* Header */}
                <div className="w-full bg-bl-100 p-7 text-center">
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/royal/principal-logo-blank.svg`}
                    alt="logo staywuw white"
                    className="w-[120px] mx-auto"
                  />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                  {!status ? (
                    <Dialog.Title
                      as="h3"
                      className="text-lg fs-18 text-gry-100"
                    >
                      ¿Confirmas la actualización de la reservación{" "}
                      <span className="fs-20 m-b">{referenceNumber}</span>?
                    </Dialog.Title>
                  ) : status === "Cancelado" ? (
                    <Dialog.Title as="h3" className="text-lg fs-24 m-b">
                      ¡Reservación Cancelada Exitosamente!
                    </Dialog.Title>
                  ) : status === "Pendiente" ? (
                    <Dialog.Title as="h3" className="text-lg fs-24 m-b">
                      Actualización en Proceso: Estado Pendiente
                    </Dialog.Title>
                  ) : status === "Confirmado" ? (
                    <Dialog.Title as="h3" className="text-lg fs-24 m-b">
                      ¡Reservación Confirmada Exitosamente!
                    </Dialog.Title>
                  ) : null}

                  <div className="mt-4">
                    {loading ? <p className="animate-pulse">Cargando...</p> : <div>{statusMessage}</div>}
                  </div>
                </div>

                {/* Footer */}
                <div className="p-6 mt-[3rem] flex justify-center space-x-4 border-t">
                  {status === "Pendiente" && (
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                      onClick={updateBookingStatus}
                    >
                      Intentar nuevamente
                    </button>
                  )}
                  {!status ? (
                    <>
                      <button
                        className="bg-bl-100 hover:bg-bl-70 text-white font-bold py-2 px-4 rounded"
                        onClick={updateBookingStatus}
                      >
                        Actualizar
                      </button>
                      <button
                        className="bg-red-100 hover:bg-red-70 text-white font-bold py-2 px-4 rounded"
                        onClick={onClose}
                      >
                        Cerrar
                      </button>
                    </>
                  ) : (
                    <button
                      className="bg-red-100 hover:bg-red-70 text-white font-bold py-2 px-4 rounded"
                      onClick={onClose}
                    >
                      Cerrar
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
