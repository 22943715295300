import { UseRHContext } from "../../context/RHContext";

export default function FiltersRH() {
  const { setIsOpenFilter, isOpenFilter } = UseRHContext();

  return (
    <div className="flex items-center gap-x-4">
      {/* FILTER TABLE */}
      <button
        className="focus:outline-none px-4 flex items-center gap-x-2"
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/filter-black.svg`}
          alt="filter table"
          width={13}
          height={13}
        />
        <p className="m-0 text-fs-12 m-m text-gry-100">Filtar</p>
      </button>
    </div>
  );
}
