// import { RemoveFiles } from "../../api/Reservations";
// import { useUser } from "../../../../configs/login/UserContext";
// import { UseReservations } from "../../context/ReservationsContext";
import { TruncateLetters } from "../../../../configs/others/TruncateLetters";

export function FilesCards({ file }) {
  // const { setDetailsReservations } = UseReservations();
  // const { token } = useUser();

  const fileImage = (typeFile) => {
    let urlImage = `${import.meta.env.VITE_URL_IMAGES}assets/icons/typeFile/`;

    let type = typeFile.split("/")[1] ? typeFile.split("/")[1] : typeFile;

    switch (type) {
      case "pdf":
        urlImage = `${urlImage}pdf.svg`;
        break;
      case "jpeg":
      case "jpg":
      case "png":
        urlImage = `${urlImage}photo.svg`;
        break;
      default:
        urlImage = `${urlImage}photo.svg`;
        break;
    }
    return urlImage;
  };

  // const removeFile = (file) => {
  //   RemoveFiles(token, file.document_id)
  //     .then((result) => {
  //       if (result.code === "ok") {
  //         setDetailsReservations((reservation) => {
  //           return {
  //             ...reservation,
  //             documents: reservation.documents.filter(
  //               (document) => document !== file
  //             ),
  //           };
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div className="rounded-[4px] py-2 px-4 bg-gry-30 relative flex items-center gap-x-3 w-full">
      <img src={fileImage(file.type)} alt={file.type} width={17} height={17} />

      <p className="m-0 text-fs-14 m-m">
        {file.document_name.length > 25
          ? `${TruncateLetters(file.document_name, 25)}...`
          : file.document_name}
      </p>
      {/* ICON EYE */}
      <a className="no-underline cursor-pointer" href={file.path} target="_blank">
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/eye.svg`}
          width={15}
          height={15}
          alt="icon eye"
          className="absolute right-6 top-3 cursor-pointer"
        />
      </a>
      {/* ICON CLOSE */}
      {/* <button onClick={() => removeFile(file)} className="focus:outline-none ">
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/close/close-r.svg`}
          width={8}
          height={8}
          alt="remove document"
          className="absolute right-2 top-[15px] cursor-pointer"
        />
      </button> */}
    </div>
  );
}
