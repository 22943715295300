/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { MarketingContext } from "../context/MarketingContext";
import { FooterView } from "../../../components/footer/FooterView";
import { TourLayout } from "../components/TourView/Layout/TourLayout";
import { HomeLayout } from "../components/HomeView/Layout/HomeLayout";
import { HotelLayout } from "../components/HotelView/Layout/HotelLayout";
import { TransportLayout } from "../components/TransportView/Layout/TransportLayout";

export default function ViewSelected() {
  const { isOpenEdit, setRoutActual } =
    useContext(MarketingContext);

  const { view } = useParams();

  useEffect(() => {
    if (view) {
      
      setRoutActual(view);
    }
  }, [view]);

  return (
    <div
      id="sectionToCapture"
      className={`bg-white ${isOpenEdit ? "w-[80%]" : "w-[90%]"}`}
    >
      {view === "home" && <HomeLayout />}
      {view === "hotel" && <HotelLayout />}
      {view === "tour" && <TourLayout />}
      {view === "transport" && <TransportLayout />}
      <FooterView />
    </div>
  );
}
