import { PrincipalView } from "../components/LayoutPrincipal/PrincipalView";

export function MarketingViews() {

  return (
    <div className="flex justify-between gap-x-4 relative">
      <div className="w-full">
        <PrincipalView />
      </div>
    </div>
  );
}
