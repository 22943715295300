import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function TransportBanner() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <section
      className={`flex bg-white ${
        responsiveView.value == "tablet" &&
        "items-center flex-col md:mt-[131px] md:mb-32 "
      } ${responsiveView.value == "laptop" && "flex-row"} h-max my-16  ${
        responsiveView.value == "mobile" && "items-center flex-col"
      }  shadow-3xl rounded-lg`}
    >
      <div
        data-aos="fade-right"
        className={`flex justify-center px-8 py-14 ${
          responsiveView.value == "tablet" && "px-16 py-[88px]"
        } ${
          responsiveView.value == "laptop" && "pr-0 w-4/5"
        } w-full items-start flex-col gap-y-5`}
      >
        <h3 className="m-b text-fs-32">Transporte a tu disposición</h3>

        <p className="m-m text-fs-14">
          Reserva ahora y disfruta de tarifas competitivas, seguridad y
          puntualidad en cada recorrido. Tu comodidad es nuestra prioridad.
          Únete a nosotros y haz que tus viajes sean memorables desde el primer
          momento.
        </p>

        <button className="rounded-full py-[20px] px-[41px] bg-bl-100 text-white w-max m-s-b text-fs-12">
          Reservar transporte
        </button>
      </div>

      <div
        data-aos="fade-left"
        className={`relative w-4/5 flex justify-center mb-14 ${
          responsiveView.value == "laptop" && "mb-0 justify-end items-end"
        } items-center `}
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/banners/vehicle/tiggo-7-pro.webp`}
          alt={`tiggo car`}
          className={`absolute z-[1] left-0 bottom-[75px] md:mx-auto right-0  mx-auto select-none ${
            responsiveView.value == "mobile" && "!left-0"
          }`}
          width={452}
          height={226}
        />

        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/general/Iso-tipo-staywuw.jpg`}
          alt={`tiggo card`}
          className={`object-cover h-full ${
            responsiveView.value == "laptop" && "mr-0"
          } ${responsiveView.value == "tablet" && "mr-[44px]"} ${
            responsiveView.value == "mobile" && "mr-0"
          }  select-none`}
          width="auto"
          height="100%"
        />
      </div>
    </section>
  );
}
