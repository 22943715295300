/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useUser } from "../../../../configs/login/UserContext";

export function ProgressPassWord({ password }) {
  const {progress, setProgress} = useUser()

  const [bgColor, setBgColor] = useState("");
  const [hasNumber, setHasNumber] = useState({ value: false, total: 27 });
  const [isUpperCase, setIsUpperCase] = useState({ value: false, total: 27 });
  const [minCharacter, setMinCharacter] = useState({ value: false, total: 27 });
  const [isSpecialCharacter, setIsSpecialCharacter] = useState({
    value: false,
    total: 19,
  });

  useEffect(() => {
    if (progress >= 0 && progress <= 30) {
      setBgColor("bg-red-100");
    }
    if (progress > 30 && progress <= 70) {
      setBgColor("bg-yw-100");
    }
    if (progress > 70) {
      setBgColor("bg-grn-100");
    }
  }, [progress]);

  useEffect(() => {
    setMinCharacter((values) => {
      return {
        ...values,
        value: password.length >= 8,
      };
    });

    // VALID IF HAS A UPPERCASE LETTER
    setIsUpperCase((values) => {
      return {
        ...values,
        value: /[A-Z]/.test(password),
      };
    });

    //   VALID IF HAS NUMBER
    setHasNumber((values) => {
      return { ...values, value: /\d/.test(password) };
    });

    //   VALID IF HAS A SPECIAL CHARACTER
    setIsSpecialCharacter((values) => {
      return { ...values, value: /[!@#$%^&*(),.?":{}|<>]/g.test(password) };
    });
  }, [password]);

  useEffect(() => {
    const calculateProgress = () => {
      let totalProgress = 0;

      if (hasNumber.value) totalProgress += hasNumber.total;
      if (isUpperCase.value) totalProgress += isUpperCase.total;
      if (minCharacter.value) totalProgress += minCharacter.total;
      if (isSpecialCharacter.value) totalProgress += isSpecialCharacter.total;

      totalProgress = Math.min(totalProgress, 100);

      setProgress(totalProgress);
    };

    calculateProgress();
  }, [hasNumber, isUpperCase, minCharacter, isSpecialCharacter]);

  return (
    <>
      <div className={`h-[4px] w-full shadow-3xl relative bg-gry-70`}>
        <div
          className={`absolute h-full ${bgColor}`}
          style={{ width: `${progress}%` }}
        />
      </div>

      <div className="flex justify-start w-full">
        <ul className="list-disc list-inside text-fs-12 m-m ">
          <li className={`${isUpperCase.value && "line-through"}`}>
            Minimo una letra mayuscula{" "}
          </li>
          <li className={`${hasNumber.value && "line-through"}`}>
            Minimo un numero{" "}
          </li>
          <li className={`${minCharacter.value && "line-through"}`}>
            Minimo 8 caracteres{" "}
          </li>
          <li className={`${isSpecialCharacter.value && "line-through"}`}>
            Caracte especial (*+)
          </li>
        </ul>
      </div>
    </>
  );
}
