import { useContext, useEffect, useState } from "react";

import { MarketingContext } from "../../sections/Marketing/context/MarketingContext";

export function FooterView() {
  const { responsiveView } = useContext(MarketingContext);
  const [actualImage, setActualImage] = useState("");

  useEffect(() => {
    switch (responsiveView.value) {
      case "laptop":
      case "tablet":
        setActualImage(
          `${import.meta.env.VITE_URL_IMAGES}assets/img/crm/footer.jpg`
        );
        break;
      case "mobile":
        setActualImage(
          `${import.meta.env.VITE_URL_IMAGES}assets/img/crm/footer-m.jpg`
        );
        break;
    }
  }, [responsiveView]);

  return (
    <img
      className={`pt-2 mx-auto select-none ${
        responsiveView.width !== "all" ? responsiveView.width : "w-full"
      } `}
      src={actualImage}
      alt="footer"
    />
  );
}
