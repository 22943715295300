import { useContext } from "react";

import { UsersContext } from "../../context/UsersContext";

const statusValues = [
  { label: "Activo", value: "ACTIVO" },
  { label: "Inactivo", value: "INACTIVO" },
];
export function FilterByStatus() {
  const { setFilterStatus, filterStatus } = useContext(UsersContext);

  return (
    <div className="flex flex-col items-start gap-y-3 max-w-[12rem]">
      <p className="text-fs-14 m-b text-black">Estatus</p>
      <div className="flex gap-3">
        {statusValues.map((status, index) => (
          <button
            onClick={() => setFilterStatus(status.value)}
            key={index}
            className={`flex gap-x-2 items-center select-none border py-2 px-4 rounded-full ${
              filterStatus === status.value
                ? "border-bl-100 bg-bl-100 text-white"
                : "border-gry-70"
            }`}
          >
            <p className="m-0 text-fs-12 m-s-b select-none">{status.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
