/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../../others/BtnEdit";
import { MarketingContext } from "../../../context/MarketingContext";
import { SetConfigDropzone } from "../../../../Marketing/components/others/setConfigDropZone";
import { useUser } from "../../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../../configs/Permissions/AlertPermissionsDeclined";

// IMG BANNER DESKTOP
export default function BannerHomeOffers() {
  const {
    responsiveView,
    setIsOpenEdit,
    setConfigDropzone,
    routActual,
    setCurrentBanner,
    currentBanner,
    totalFiles,
    jonImagesActualView,
    languageSelected,
  } = useContext(MarketingContext);

  const [selectedBanner, setSelectedBanner] = useState(null);

  // CAROUSEL IMAGE
  const [offerBaggage, setOfferBaggage] = useState(null);

  // SET ACTUAL BANNERS
  useEffect(() => {
    if (jonImagesActualView[languageSelected]) {
      setOfferBaggage(
        jonImagesActualView[languageSelected].offerBaggage[responsiveView.value]
      );
    }
  }, [totalFiles, responsiveView, routActual, languageSelected, jonImagesActualView]);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = (statusBanner) => {
     // VALID PERMISSIONS
     const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {

      setIsOpenEdit(true);
      let json = statusBanner == 1 ? "offertsCarrousel" : "offerBaggage";
      let configDropzone = SetConfigDropzone(routActual, json);
      setConfigDropzone(configDropzone);
      setSelectedBanner(json);
      setCurrentBanner(json);
    }


  };

  const [isOfferCarrousel, setIsOfferCarrousel] = useState(false);
  const [isOfferBaggage, setIsOfferBaggage] = useState(false);
  
  useEffect(() => {
    if (
      selectedBanner === "offertsCarrousel" &&
      currentBanner === "offertsCarrousel"
    ) {
      setIsOfferCarrousel(true);
    } else {
      setIsOfferCarrousel(false);
    }
    if (selectedBanner === "offerBaggage" && currentBanner === "offerBaggage") {
      setIsOfferBaggage(true);
    } else {
      setIsOfferBaggage(false);
    }
  });

  return (
    <div
      className={`relative w-full flex  justify-center  max-xl:h-[347px] max-lg:h-[262px] ${
        responsiveView.value === "tablet"
          ? "h-[262px]"
          : "h-[434px]"
        
      } ${
        responsiveView.value === "mobile"
          ? "flex-col h-[900px] gap-6"
          : "gap-[12px]"
      }`}
    >
      {/* BANNER OFFERS */}
      <div
        className={`z-0 ${
          responsiveView.value === "mobile" ? "w-full h-[440px]" : "w-[70%] h-full"
        }`}
      >
        <div
          onClick={() => handleClick(1)}
          className={`h-full w-full relative flex justify-center hover:border-4 hover:border-or-100 cursor-pointer rounded-xl group ${
            isOfferCarrousel && " border-4 border-or-100"
          }`}
        >
          {/* TEST */}
          {jonImagesActualView[languageSelected].offertsCarrousel[
            responsiveView.value
          ] ? (
            <Swiper
              slidesPerView={1}
              className={`h-full rounded-lg !z-0 !w-full ${
                responsiveView.value === "mobile"
                  ? " h-[440px]"
                  : " h-full "
              }`}
              id={`${
                responsiveView.value === "tablet"
                  ? "swiper-banner-home-offers-t"
                  : "swiper-banner-home-offers-l"
              }`}
              initialSlide={0}
              navigation
              modules={[Navigation]}
            >
              {jonImagesActualView[languageSelected].offertsCarrousel[
                responsiveView.value
              ].map((offertsCarrousel, index) => (
                <SwiperSlide key={index} className="!rounded-lg relative">
                  <img
                    src={
                      offertsCarrousel?.urlImage
                        ? offertsCarrousel?.urlImage
                        : offertsCarrousel
                    }
                    alt="banner-offers -D"
                    className={`w-full h-full select-none object-cover rounded-lg group-hover:brightness-75 ${
                      isOfferCarrousel && " brightness-75"
                    }`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="animate-[skeletonLoading_1s_linear_infinite_alternate] w-full h-full rounded-lg" />
          )}

          <BtnEdit />
        </div>
      </div>

      <div
        onClick={() => handleClick(2)}
        className={`max-md:w-full flex justify-center hover:border-4 hover:border-or-100 rounded-xl cursor-pointer group ${
          responsiveView.value === "mobile" ? "w-full h-[412px]" : "w-[30%]"
        } ${isOfferBaggage && "border-4 border-or-100"}`}
      >
        <img
          src={offerBaggage?.urlImage ? offerBaggage?.urlImage : offerBaggage}
          className={`h-full w-full rounded-lg select-none object-cover group-hover:brightness-75 ${
            isOfferBaggage && "brightness-75"
          }`}
          alt="Banner ofertas de viaje Home"
        />
        {/* BTN EDIT */}
        <BtnEdit />
      </div>

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </div>
  );
}
