export function SearchReviewFilter(reservations, inputValue) {
  // console.log(reservations);

  // Normaliza el valor de entrada
  const normalizedInputValue = inputValue
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

  let filterData = reservations.filter((item) =>
    Object.values(item).some((reservation) => {
      if (typeof reservation === "string") {
        // Normaliza y compara si es una cadena
        return reservation
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedInputValue);
      } else if (Array.isArray(reservation)) {
        // Maneja arreglos, incluyendo el arreglo de "services"
        return reservation.some((subValue) => {
          if (typeof subValue === "string") {
            // Normaliza y compara si es una cadena dentro del arreglo
            return subValue
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(normalizedInputValue);
          } else if (typeof subValue === "object" && subValue !== null) {
            // Si subValue es un objeto (como los elementos en "services"), busca dentro de sus valores
            return Object.values(subValue).some((innerValue) => {
              if (typeof innerValue === "string") {
                return innerValue
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(normalizedInputValue);
              } else {
                return innerValue
                  .toString()
                  .toLowerCase()
                  .includes(normalizedInputValue);
              }
            });
          } else {
            // Convierte a cadena otros tipos de datos antes de comparar
            return subValue
              .toString()
              .toLowerCase()
              .includes(normalizedInputValue);
          }
        });
      } else {
        // Convierte a cadena y compara si no es un arreglo ni una cadena
        return reservation
          .toString()
          .toLowerCase()
          .includes(normalizedInputValue);
      }
    })
  );
  return filterData;
}

export function FilterByActivity(reservations, activity) {
  // console.log(activity);
  // console.log(reservations);
  
  
  if (!activity) return reservations;
  
  return reservations.filter((reservation) =>
    reservation.services.some((service) => service.type === activity)
  );
}

export function FilterByStatus(
  reservations,
  setTotalConfirmed,
  setTotalPending,
  setTotalDeclined,
  setPendingDeclined
) {
  setTotalConfirmed(FilterStatus(reservations, "Confirmado"));
  setTotalPending(FilterStatus(reservations, "Pendiente"));
  setTotalDeclined(FilterStatus(reservations, "Cancelado"));
  setPendingDeclined(FilterStatus(reservations, "Proceso de cancelación"))
}

export const FilterStatus = (reservations, status) => {
  //   console.log(reservations);
  return reservations.filter((reservation) => reservation.status === status)
    .length;
};

export function FilterTabStatus(reservations, status) {
  let dataReservation = reservations.filter(
    (reservation) => reservation.status == status
  );
  return dataReservation;
}

export function FilterByService(reservations, services) {
  const filterReservation = reservations.filter((reservation) =>
    reservation.services.some((service) => services.includes(service.type))
  );

  return filterReservation;
}

export function FilterRangeDate(reservations, datesFilter) {
  const firstDay = AdjustedDate(new Date(datesFilter.firstDay));

  const lastDate = AdjustedDate(new Date(datesFilter.lastDate));

  const filterReservation = reservations.filter((reservation) => {
    const currentDate = new Date(reservation.reservationDate);

    return currentDate >= firstDay && currentDate <= lastDate;
  });

  return filterReservation;
}

export function FilterByOrder(reservations, filterByOrder) {
  return reservations.sort((a, b) => {
    if (filterByOrder === 1) {
      return new Date(b.reservationDate) - new Date(a.reservationDate); // Descendente
    }
    if (filterByOrder === 2) {
      return new Date(a.reservationDate) - new Date(b.reservationDate); // Ascendente
    }
    return 0;
  });
}


const AdjustedDate = (date) => {
  const adjustedDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60000
  );
  return adjustedDate;
};
