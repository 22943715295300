/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export function SelectorApartment({
  isValidApartment,
  apartmentsLists,
  selectedApartment,
  setSelectedApartment,
  userSelected = null,
}) {
  useEffect(() => {
    if (userSelected) {
      const rolFilter = apartmentsLists.find(
        (rol) => rol.id === userSelected.department_id
      );
      setSelectedApartment(rolFilter.id);
    } else {
      setSelectedApartment("");
    }
  }, [userSelected]);

  // FUNCTION THAT HANDLES THE CHANGE OF THE SELECT
  const handleSelectChange = (event) => {
    setSelectedApartment(event.target.value);
  };

  return (
    <>
      {/* SELECT */}
      <div
        className={`flex border w-fit py-1 px-2 rounded ${
          isValidApartment ? "border-gry-50" : "border-red-100"
        }`}
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/type-user.svg`}
          alt="icon type user"
          width={19}
          height={19}
        />

        <select
          value={selectedApartment}
          onChange={handleSelectChange}
          className="focus:outline-none px-3 py-2 rounded-xl text-fs-14  text-gry-100 m-m scroll-page-gry"
        >
          <option value="" disabled>
            Selecciona un departamento
          </option>
          {apartmentsLists.map((apartment, index) => (
            <option value={apartment.id} key={index}>
              {apartment.department_name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
