import { useContext } from "react";
import { ImagesBanners } from "./ImagesBanners";
import { LanguageSelector } from "../others/LanguageSelector";
import { MarketingContext } from "../../context/MarketingContext";
import { ResponsiveViews } from "../ResponsiveViews/ResponsiveViews";
import { StepsImagesValidation } from "./StepsImagesValidation";

export default function ModalEditImage() {
  const {
    isOpenEdit,
    setIsOpenEdit,
    setConfigDropzone,
    configDropzone,
    routActual,
    setCurrentBanner,
  } = useContext(MarketingContext);

  const handleClick = () => {
    setIsOpenEdit(false);
    setConfigDropzone(null);
    setCurrentBanner(null);
  };

  const capitalizeFirstLetter = (sentence) => {
    return sentence
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    configDropzone && (
      <div className={`${isOpenEdit ? "w-[21%] mr-3" : ""}`}>
        <div
          className={`sticky top-[73px] left-5 bg-gry-20 rounded-lg p-5 flex flex-col gap-4 ${
            !isOpenEdit && "hidden"
          }`}
        >
          <div className="flex justify-end">
            <ResponsiveViews view={routActual} />
          </div>

          <div className="flex items-center gap-3 justify-between">
            <span className="m-m">
              {capitalizeFirstLetter(routActual)}
              <b className="m-b"> {`/ ${configDropzone.nameRoute}`}</b>
            </span>
            <button onClick={handleClick}>
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/arrows/left-100.svg`}
                alt="arrow-icon-left"
                className="w-[7.4px] h-[12px]"
              />
            </button>
          </div>
          <LanguageSelector />

          {/* ADD IMAGES */}
          <ImagesBanners />

          {/* STEPS VALIDATIONS */}
          <StepsImagesValidation />
        </div>
      </div>
    )
  );
}
