/* eslint-disable no-useless-escape */
export async function base64Data(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    // convertir el blob en base 64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const configName = (name) => {
  let newFilename = name.substring(0, name.lastIndexOf("."));
  newFilename = newFilename.replace(/[ %\.\\?*":<>|#]/g, "_");
  return newFilename;
};

export const isPdfBase64 = (url) => {
  return url.startsWith("data:application/pdf;base64,");
};

export const isBase64=(url)=>{
  return url.startsWith("data:");
}
