import moment from "moment";
import { PYCModal } from "./PYC";
import { useState } from "react";

export function TourDetails({ tours }) {
  const [openPYC, setOpenPYC] = useState(false);

  const leftStyle = (isBold) =>
    `${isBold ? "m-b" : "m-m"} m-0 w-[199px] text-fs-14 text-gry-100`;

  const providerTypes = (provider) => {
    let nameProvider = "";

    switch (provider) {
      case "ct":
        nameProvider = "Civitatis";
    }
    return nameProvider;
  };

  return (
    <div className="flex flex-col gap-y-3 mt-2">
      {tours &&
        tours.map((tour, index) => (
          <div
            key={index}
            className="bg-gry-30 flex flex-col gap-y-2 p-4 rounded-lg"
          >
            <h3 className="m-b text-fs-14 text-black">{tour.activity_name}</h3>

            <h3 className="m-b text-fs-14 text-gry-100">Datos generales</h3>

            <div className="flex items-center gap-x-5 py-[5px]">
              <p className={`${leftStyle(false)}`}>No. de reserva</p>
              <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
              <span className="m-b text-bl-100">StayWuw:</span>
                {tour.provider_booking_id}
              </p>
            </div>

            <div className="flex items-center gap-x-5 py-[5px]">
              <p className={`${leftStyle(false)}`}>Proveedor</p>
              <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                <span className="m-b text-bl-100">
                  {providerTypes(tour.provider)}
                </span>
              </p>
            </div>

            <div className="flex items-center gap-x-5 py-[5px]">
              <p className={`${leftStyle(false)}`}>Dia de la reserva</p>
              <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                {moment(tour.date).format("DD/MM/YYYY")}
              </p>
            </div>

            <div className="flex items-center gap-x-5 py-[5px] relative">
              <p className={`${leftStyle(true)}`}>Políticas de cancelación</p>
              <p
                className={`m-0 text-fs-14 m-m flex gap-x-2 items-center text-bl-100 underline decoration-bl-100 cursor-pointer`}
                onClick={() => setOpenPYC(true)}
              >
                Rembolsable
              </p>

              <PYCModal
                text={
                  "Le informamos que su reserva en nuestro hotel es cancelable hasta una semana antes de la fecha de llegada. En caso de que desee cancelar su reserva dentro de este período, se le cobrará un 20% del total de la estancia como cargo por cancelación."
                }
                open={openPYC}
                handleClose={() => setOpenPYC(false)}
              />
            </div>

            <div className="flex items-center gap-x-5 py-[5px]">
              <p className={`${leftStyle(true)}`}>Total de personas</p>
              <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                {tour.totalPax}
              </p>
            </div>

            <div className="flex items-center gap-x-5 py-[5px]">
              <p className={`${leftStyle(true)}`}>Total</p>

              <p className="m-0 text-fs-14 m-m items-center text-gry-100">
                $
                {Math.floor(tour.amount)
                  .toLocaleString("es-MX", { currency: "MXN" })
                  .replace(".00", "")}
                .
                <sup className="sup-price-cart">
                  {(tour.amount % 1).toFixed(2).slice(2)}{" "}
                </sup>{" "}
                MXN
              </p>
            </div>
          </div>
        ))}
    </div>
  );
}
