import { ReservationProvider } from "../context/ReservationsContext";
import { Notifications } from "../../../components/Notifications/Notification";
import { ReservationFilterTable } from "../components/ReservationFilterTable";

export function ReservationsLayout() {
  return (
    <ReservationProvider>
      <div className="flex justify-between mb-[44px] w-full">
        <h1 className="text-fs-24 m-b text-black">Reservaciones</h1>

        <Notifications />
      </div>

      <ReservationFilterTable />
    </ReservationProvider>
  );
}
