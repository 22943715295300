/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";

import { AllPermissionsUser } from "./AllPermissionsUser";
import { UsersContext } from "../../context/UsersContext";
import { ButtonSavePermissions } from "./ButtonSavePermissions";

export function ModalPermissions({ openFilter, setOpenFilter, userSelected }) {
  const [allPermissions, setAllPermissions] = useState(null);
  const [permissionsAssignedDefault, setPermissionsAssignedDefault] =
    useState(null);
  const { getAllPermissions } = useContext(UsersContext);
  const [permissionsAssigned, setPermissionsAssigned] = useState([]);
  const [removePermissions, setRemovePermissions] = useState([]);

  const permissionsByUser = async () => {
    let response = await getAllPermissions(userSelected);
    setAllPermissions(response);
    setPermissionsAssignedDefault(response?.userPermissions);
  };

  useEffect(() => {
    if (openFilter && userSelected) {
      // CLEAN PERMISSIONS
      setPermissionsAssigned([]);
      setRemovePermissions([]);

      permissionsByUser();
    }
  }, [openFilter, userSelected]);

  return (
    <Transition show={openFilter} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[2]"
        onClose={() => setOpenFilter(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="bg-white rounded-lg p-7 relative w-max">
              <h3 className="text-fs-18 m-b mb-6 mt-2">Permisos de usuarios</h3>
              <button
                onClick={() => setOpenFilter(false)}
                className="focus:outline-none absolute right-1 top-[15px] mr-3"
              >
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/close/close-70.svg`}
                  alt="close modal"
                  width={13}
                  height={13}
                />
              </button>

              <div className="flex gap-6">
                <div className="flex gap-y-4 flex-col">
                  {allPermissions && permissionsAssignedDefault && (
                    <AllPermissionsUser
                      allPermissions={allPermissions}
                      setPermissionsAssigned={setPermissionsAssigned}
                      permissionsAssignedDefault={permissionsAssignedDefault}
                      setPermissionsAssignedDefault={
                        setPermissionsAssignedDefault
                      }
                      setRemovePermissions={setRemovePermissions}
                    />
                  )}

                  <ButtonSavePermissions
                    setPermissionsAssigned={setPermissionsAssigned}
                    permissionsAssigned={permissionsAssigned}
                    setRemovePermissions={setRemovePermissions}
                    removePermissions={removePermissions}
                    userSelected={userSelected}
                    permissionsByUser={permissionsByUser}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
