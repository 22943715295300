import { createContext, useState } from "react";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <NotificationContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
