import { useContext } from "react";

import { StepCard } from "./StepCard";
import { MarketingContext } from "../../../context/MarketingContext";

export default function CarrouselSteps({ activeStep, stepData }) {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div
      className={`mt-6 mx-auto w-[100%] ${
        responsiveView.value === "laptop" ? "hidden" : "block"
      }`}
    >
      <div
        className={`flex flex-wrap gap-x-1 m-b text-fs-28 justify-center w-full`}
      >
        <p className="m-0 text-wrap">Diseña el</p>
        <p className="m-0 text-bl-100 text-wrap">itinerario perfecto</p>
        <p className="m-0 text-wrap">para tu viaje</p>
      </div>

      <div className="mt-6 mx-auto w-[60%]">
        <StepCard
          activeStep={activeStep}
          number={stepData[0].number}
          title={stepData[0].title}
          description={stepData[0].description}
          stepType={stepData[0].stepType}
        />
      </div>
    </div>
  );
}
