import { useEffect, useState } from "react";

import SwipperLogin from "./SwipperLogin";
import { loginApi } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../configs/login/UserContext";
import { displayLoginError } from "../../../utils/error/errorMsg";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../components/alerts/notifications/useNotification";
import {
  IsImageURLValid,
  ValidImage,
} from "../../../components/imageGet/ImageGet";

export default function LoginT() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { user, token, setUser, setToken } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const { notification, showNotification, hideNotification } =
    useNotification();

  useEffect(() => {
    if (user && token) {
      navigate("/users", { replace: true });
    }
  }, [user, token, navigate]);

  useEffect(() => {
    const savedData = localStorage.getItem("authData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (parsedData.rememberMe) {
        setEmail(parsedData.email);
        setPassword(atob(parsedData.password));
        setRememberMe(parsedData.rememberMe);
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    setIsLogin(true);
    event.preventDefault();
    try {
      const data = await loginApi(email, password);

      if (data.success) {
        let hasImage = await IsImageURLValid(data.user.url_image);
        let userInfo = ValidImage(hasImage, data.user);

        setUser(userInfo);
        setToken(data.token);

        const authData = {
          email,
          password: btoa(password),
          rememberMe,
        };

        if (rememberMe) {
          localStorage.setItem("authData", JSON.stringify(authData));
        } else {
          localStorage.removeItem("authData");
        }

        displayLoginError("succes", showNotification);
        navigate("/dashboard");
      } else {
        setIsLogin(false);
        displayLoginError(data.code, showNotification);
      }
    } catch (error) {
      setIsLogin(false);
      displayLoginError("error", showNotification);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  function openPass() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="flex h-screen max-md:flex-col-reverse max-lg:gap-3">
      {/* SWIPER */}
      <div className="w-[40%] bg-gry-50 h-full rounded-r-[40px] max-md:w-full">
        <SwipperLogin />
      </div>

      {/* LOGIN */}
      <div className="w-[60%] flex max-md:w-full">
        <div className="justify-center ml-[150px] flex flex-col gap-5 w-[483px] max-lg:ml-0 max-md:mt-8">
          <img
            alt="logo stay-wuw"
            className="w-[150px] h-[43px]"
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/royal/principal-logo.svg`}
          />

          <h4 className="m-b text-fs-24">Bienvenido al sistema de control</h4>

          {/* EMAIL */}
          <input
            type="email"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="appearance-none outline-none border-2 border-gry-30 rounded-lg h-[45px] pl-5"
          />

          {/* PASSWORD */}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="appearance-none outline-none border-2 border-gry-30 rounded-lg h-[45px] pl-5 w-full pr-8"
            />

            <button
              type="button"
              className="absolute right-3 top-3"
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/general/eye.svg`}
                  alt="icon-eye-close"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/general/eye-close.svg`}
                  alt="icon-eye-close"
                  width={24}
                  height={24}
                />
              )}
            </button>
          </div>

          {/* REMEMBER ME */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={handleRememberMe}
              className="mr-2"
            />
            <label htmlFor="rememberMe" className="text-fs-12 m-b">
              Recordar contraseña
            </label>
          </div>

          {/* BTN  LOG IN*/}
          <button
            className="bg-bl-100 text-white m-b text-fs-12 rounded-lg py-[16px]"
            onClick={handleSubmit}
            disabled={isLogin}
          >
            {isLogin ? "Iniciando sesión..." : "Iniciar sesión"}
          </button>

          {/* YOU FORGOT THE PASSWORD */}
          <a className="w-max" href="/forgot-password">
            <span onClick={openPass} className="text-fs-12 m-b cursor-pointer">
              ¿Olvidaste tu contraseña?
            </span>
          </a>

          {notification && notification.visible && (
            <NotificationType
              type={notification.type}
              title={notification.title}
              message={notification.message}
              duration={notification.duration}
              onClose={hideNotification}
            />
          )}
        </div>
      </div>
    </div>
  );
}
