import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import TabInfoRH from "./TabInfoRH";
import DocDetails from "./DocDetails";
import InfoDetails from "./InfoDetails";
import { SaveDocument } from "../../api/api";
import AddNewEmployee from "../AddNewEmployee";
import { UseRHContext } from "../../context/RHContext";
import { useUser } from "../../../../configs/login/UserContext";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";
import AlertPermissionsDeclined from "../../../../configs/Permissions/AlertPermissionsDeclined";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";
// import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";

export default function ViewInformationRH({ isOpen, closeInfo }) {
  const [active, setActive] = useState("Info");
  const { notification, showNotification, hideNotification } =
    useNotification();
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const { rhDocs, userSelected, resetDocs } = UseRHContext();
  const [isUpdateImage, setIsUpdateImage] = useState(false);

  useEffect(() => {
    setIsUpdateImage(() => rhDocs.some((doc) => doc.isEdit === true));
  }, [rhDocs]);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const openAddUserDialog = () => {
    const hasPermission =
      user.department_name == "Recursos Humanos" && user.role_name == "Admin";
    // const hasPermission = HasPermissionsUser(
    //   user.permissions,
    //   "update_user"
    // );

    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsAddUserOpen(true);
    }
  };

  const closeAddUserDialog = () => {
    setIsAddUserOpen(false);
  };

  // UPDATE FILES
  const updateFiles = async () => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "save_user_document"
    );

    setHasPermissions(hasPermission);
    if (hasPermission) {
      let fileSelected = [];
      rhDocs.map((docs) => {
        if (docs.document && docs.isEdit) {
          fileSelected = [
            ...fileSelected,
            { type: docs.type, document: docs.document },
          ];
        }
      });
      if (fileSelected.length) {
        try {
          const data = { user_id: userSelected.id, documents: fileSelected };
          const responser = await SaveDocument(data);
          if (responser.data.code === "ok") {
            showNotification(
              "success",
              "La subida de archivos fue exitosa.",
              "Los archivos se subieron correctamente",
              5000
            );
            resetDocs(false);
          }
        } catch (error) {
          console.log(error);
          showNotification(
            "error",
            "Algo falló.",
            "Ocurrió un problema en la subida de los archivos, por favor inténtelo más tarde.",
            5000
          );
          throw error;
        }
      }
    }
  };

  const handleTab = (tabView) => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "view_user_document"
    );

    // const hasPermission =
    //   user.department_name == "Recursos Humanos" && user.role_name == "Admin";
    setHasPermissions(hasPermission);
    if (hasPermission && tabView === "Doc") {
      setActive(tabView);
    } else if (tabView !== "Doc") {
      setActive(tabView);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeInfo}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[65%] h-[80vh] rounded-lg transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* BTN CLOSE */}
                  <button
                    className="absolute right-3 top-3 w-max h-max focus:outline-none"
                    onClick={closeInfo}
                  >
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-70.svg`}
                      width={16}
                      height={16}
                      alt="close modal"
                    />
                  </button>
                  <h3 className="text-fs-14 m-b text-gry-100 my-4">
                    {userSelected?.user_name}
                  </h3>
                  {/* TAB AND BTN EDIT INFO */}
                  <div className="flex justify-between">
                    {/* TAB */}
                    <TabInfoRH active={active} setActive={handleTab} />

                    {/* BTN EDIT INFO */}
                    {active === "Info" && (
                      <button
                        onClick={openAddUserDialog}
                        className="bg-bl-100 rounded-lg px-4 py-3 flex gap-2 hover:bg-bl-110"
                      >
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/crm/pencil-w.svg`}
                          width={16}
                          height={16}
                          alt="icon edit white"
                        />
                        <span className="text-fs-12 text-white m-s-b">
                          Editar informacion
                        </span>
                      </button>
                    )}

                    {/* BTN EDIT DOCS */}
                    {active === "Doc" && (
                      <button
                        onClick={updateFiles}
                        className={`rounded-lg px-4 py-3 flex gap-2 ${
                          isUpdateImage
                            ? "bg-grn-100 hover:bg-grn-110"
                            : "bg-grn-70"
                        }`}
                        disabled={!isUpdateImage}
                      >
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/crm/pencil-w.svg`}
                          width={16}
                          height={16}
                          alt="icon edit white"
                        />
                        <span className="text-fs-12 text-white m-s-b">
                          Guardar documentos
                          {/* {isUpdateImage ? 'Editar documentos' : 'Editar documentos'} */}
                        </span>
                      </button>
                    )}
                  </div>

                  {active === "Info" && <InfoDetails />}
                  {active === "Doc" && <DocDetails />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* MODALS EDITS */}
      <AddNewEmployee
        isOpen={isAddUserOpen}
        closeNewUser={closeAddUserDialog}
      />

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
