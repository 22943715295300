import { useState } from "react";

import { TitleConfig } from "../../config/TitleDescription";
import { ContainerProviderPayment } from "./ContainerProviderPayment";
import NotificationType from "../../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../../components/alerts/notifications/useNotification";

export function ProviderPayment() {
  const [isOpen, setIsOpen] = useState(false);
  const { notification, showNotification, hideNotification } =
    useNotification();

  return (
    <>
      <div className="flex flex-col items-start gap-y-3">
        <button
          className="flex gap-x-2 focus:outline-none items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/arrows/arrow-drop-down.svg`}
            alt="arrow"
            className={
              !isOpen && "rotate-[270deg] w-[10px] h-[5px] object-contain"
            }
            width={10}
            height={5}
          />

          <TitleConfig isOpen={isOpen} text={"Proveedores de pago"} />
        </button>

        <ContainerProviderPayment
          showNotification={showNotification}
          isOpen={isOpen}
        />
      </div>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
