import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

const popularState = [
  {
    code: 49,
    codeName: "ciudad-de-mexico",
    country: "mexico",
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/49/cdmx-royal-vacations-mexico.webp",
    name: "Ciudad de Mexico",
    price: "8918.47",
    hidden: false,
    hiddenP: false,
  },
  {
    code: 48,
    codeName: "mazatlan",
    country: "mexico",
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/48/mazatlan-royal-vacations-mexico.webp",
    name: "Mazatlan",
    price: "7474.33",
    hidden: false,
    hiddenP: true,
  },
  {
    code: 52,
    codeName: "monterrey",
    country: "mexico",
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/52/monterrey-royal-vacations-mexico.webp",
    name: "Monterrey",
    price: "8636.1",
    hidden: false,
    hiddenP: true,
  },
  {
    code: 18,
    codeName: "cancun",
    country: "mexico",
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/18/cancun-royal-vacations-mexico.webp",
    name: "Cancun",
    price: "1474.81",
    hidden: true,
    hiddenP: true,
  },
  {
    code: 7,
    codeName: "los-cabos",
    country: "mexico",
    imageUrl:
      "https://staywuw.com/api2/assets/images/destinations/7/los-cabos-royal-vacations-mexico.webp",
    name: "Los Cabos",
    price: "419.4",
    hidden: true,
    hiddenP: true,
  },
];
export default function PopularDestinationsHome() {
  const { responsiveView } = useContext(MarketingContext);

  return (
    <div className="flex flex-col gap-[36px] my-[180px] relative max-sm:my-[90px]">
      <h2 className="text-fs-28 m-b ">Destinos populares</h2>
      <div className="flex w-full gap-3 justify-between">
        {popularState &&
          popularState.map((popular, index) => (
            <div
              key={index}
              className={`h-[280px] relative overflow-hidden rounded-lg ${
                responsiveView.value === "tablet" && popular.hidden
                  ? "hidden"
                  : ""
              } ${
                responsiveView.value === "mobile" && popular.hiddenP
                  ? "hidden"
                  : ""
              }`}
            >
              {/* IMAGE */}

              <img
                src={popular.imageUrl}
                alt="banner-offers"
                className={`brightness-[.65] h-full rounded-lg select-none object-cover  ${
                  responsiveView.value === "mobile" ? "w-[412px]" : "w-[284px]"
                }`}
              />

              <div className="flex flex-col pl-[16px] pb-[16px] absolute bottom-0 gap-[4px]">
                {/* TITLE */}
                <span className="m-b text-white text-fs-14">
                  {popular.name}
                </span>

                {/* PRICE */}
                <span className="flex items-center gap-1 m-m text-white text-fs-12">
                  Hoteles desde
                  <span className="text-fs-14">
                    MXN $
                    {Math.floor(popular.price)
                      .toLocaleString("es-MX", { currency: "MXN" })
                      .replace(".00", "")}
                    .<sup>{(popular.price % 1).toFixed(2).slice(2)}</sup>
                  </span>
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
