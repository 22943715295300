import "moment/locale/es";
import moment from "moment";
import { useState } from "react";

import { PYCModal } from "./PYC";
import ModalCancellation from "./ModalCancellation";
import { UseReservations } from "../../context/ReservationsContext";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";
import { useUser } from "../../../../configs/login/UserContext";
import AlertPermissionsDeclined from "../../../../configs/Permissions/AlertPermissionsDeclined";

export function HotelDetails({ hotels }) {
  return (
    <div className="flex flex-col gap-y-2 mt-2 h-auto">
      {hotels.map((hotel, index) => (
        <CardHotelDetails key={index} hotel={hotel} />
      ))}
    </div>
  );
}

export function CardHotelDetails({ hotel }) {
  const { detailsReservation } = UseReservations();
  const { user } = useUser();
  const leftStyle = (isBold) =>
    `${isBold ? "m-b" : "m-m"} m-0 w-[179px] text-fs-14 text-gry-100`;

  const [isOpen, setIsOpen] = useState(false);
  const [openPYC, setOpenPYC] = useState(false);
  const [cancelationOpen, setCancelationOpen] = useState(null);

  const providerHotel = (provider) => {
    let nameProvider = "";

    switch (provider) {
      case "hb":
        nameProvider = "Hotel beds";
        break;
      case "pt":
        nameProvider = "Price travel";
        break;
      default:
        nameProvider = provider;
        break;
    }

    return nameProvider;
  };

  const eatingPlan = (plan) => {
    let nameEating = "";

    switch (plan) {
      case "AI":
        nameEating = "Todo incluido";
        break;
      case "OR":
        nameEating = "Solo habitación";
        break;
      case "CD":
        nameEating = "Desayuno continental";
        break;
      case "SD":
        nameEating = "Desayuno especial";
        break;
      case "LI":
        nameEating = "Almuerzo incluido";
        break;
      case "DI":
        nameEating = "Cena incluida";
        break;
      default:
        nameEating = plan;
        break;
    }

    return nameEating;
  };

  const handleOpenCancelation = (index) => {
    setCancelationOpen(index);
    setOpenPYC(true);
  };

  const handleCloseCancelation = () => {
    setCancelationOpen(null);
    setOpenPYC(false);
  };
  const handleTextCancelation = (cancellation) => {
    if (cancellation.cancellationCode == "NOR") {
      return `Le informamos que su reserva en nuestro hotel es cancelable hasta el día ${moment(
        cancellation.policy.from
      ).format(
        "DD/MMMM/YYYY"
      )}. En caso de que desee cancelar su reserva dentro de este período, se le rembolsará un ${
        cancellation.policy.percentage
      }% del monto total.`;
    } else if (cancellation.cancellationCode == "NRF") {
      return `Le informamos que su reserva en nuestro hotel no es cancelable.`;
    }
  };

  const [hasPermissions, setHasPermissions] = useState(true);

  const handlePermissionsCancelReservation = () => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "request_booking_cancellation"
    );
    
    setHasPermissions(hasPermission);

    if (hasPermission) {
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className="bg-gry-30 flex flex-col gap-y-2 p-4 rounded-lg">
        <div className="flex justify-between">
          <h3 className="m-b text-fs-14 text-black">{hotel.hotel_name}</h3>

          {/* BTN REQUEST CANCELLATION */}
          {detailsReservation?.status === "Proceso de cancelación" ? (
            <div className="text-fs-12 m-s-b text-red-100 flex gap-2 rounded-lg items-center border-2 border-transparent bg-white px-2 py-1">
              Reservacion en proceso de cancelación
            </div>
          ) : detailsReservation?.status === "Cancelado" ? (
            <div className="text-fs-12 m-s-b text-red-100 flex gap-2 rounded-lg items-center border-2 border-transparent bg-white px-2 py-1">
              Reservacion cancelada
            </div>
          ) : (
            <button
              onClick={handlePermissionsCancelReservation}
              className="text-fs-12 m-s-b text-red-100 flex gap-2 rounded-lg items-center border-2 border-transparent hover:border-red-100 hover:bg-white px-2 py-1"
            >
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/calendar/calendar-canc-red.svg`}
                alt="icon cancelation"
              />
              Solicitar cancelacion
            </button>
          )}
        </div>

        <h3 className="m-b text-fs-14 text-gry-100">Datos generales</h3>

        {hotel?.provider == "hb" && (
          <div className="flex items-center gap-x-5 py-[5px]">
            <p className={`${leftStyle(false)}`}>No. de reserva</p>
            <div>
              {" "}
              <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                <span className="m-b text-bl-100">StayWuw:</span>
                {hotel?.provider_booking_id}
              </p>
            </div>
          </div>
        )}

        <div className="flex items-center gap-x-5 py-[5px]">
          <p className={`${leftStyle(false)}`}>Proveedor</p>
          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
            <span className="m-b text-bl-100">
              {providerHotel(hotel?.provider)}
            </span>
          </p>
        </div>

        <div className="flex items-center gap-x-5 py-[5px]">
          <p className={`${leftStyle(false)}`}>Dirección</p>
          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
            {hotel.address}
          </p>
        </div>

        <div className="flex items-center gap-x-5 py-[5px]">
          <p className={`${leftStyle(false)}`}>Check In</p>
          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
            {moment(hotel.check_in).format("DD/MM/YYYY")}
          </p>
        </div>

        <div className="flex items-center gap-x-5 py-[5px]">
          <p className={`${leftStyle(false)}`}>Check Out</p>
          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
            {moment(hotel.checkOut).format("DD/MM/YYYY")}
          </p>
        </div>

        <div className="flex items-center gap-x-5 py-[5px]">
          <p className={`${leftStyle(true)}`}>Habitaciones</p>
          <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
            {hotel.rooms.length}
          </p>
        </div>

        <div className="flex flex-col">
          {hotel.rooms.map((room, item) => (
            <div className="" key={item}>
              <p className={`${leftStyle(true)}`}>Habitación {item + 1}</p>

              {hotel?.provider == "pt" && (
                <div className="ml-5 flex items-center gap-x-5 py-[5px]">
                  <p className={`${leftStyle(false)}`}>
                    Referencia del proveedor
                  </p>

                  <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-bl-100">
                    #{room?.provider_reference}
                  </p>
                </div>
              )}

              <div className="ml-5 flex items-center gap-x-5 py-[5px]">
                <p className={`${leftStyle(false)}`}>Titular</p>
                <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                  {room.holder}
                </p>
              </div>

              <div className="ml-5 flex items-center gap-x-5 py-[5px]">
                <p className={`${leftStyle(false)}`}>{room.room_name}</p>
                <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                  {room.paxes}
                </p>
              </div>

              <div className="ml-5 flex items-center gap-x-5 py-[5px]">
                <p className={`${leftStyle(false)}`}>Plan alimenticio</p>
                <p className="m-0 text-fs-14 m-m flex gap-x-2 items-center text-gry-100">
                  {eatingPlan(room.eating_plan)}
                </p>
              </div>

              <div className="flex items-center gap-x-5 py-[5px] relative">
                <p className={`${leftStyle(true)}`}>Políticas de cancelación</p>

                <p
                  className={`m-0 text-fs-14 m-m flex gap-x-2 items-center underline cursor-pointer ${
                    room?.cancellation_policies?.cancellationCode == "NOR"
                      ? "text-bl-100 decoration-bl-100"
                      : "text-red-100 decoration-red-100"
                  }`}
                  onClick={() => handleOpenCancelation(item)}
                >
                  {room?.cancellation_policies?.cancellationCode == "NOR"
                    ? "Rembolsable"
                    : "No rembolsable"}
                </p>

                {cancelationOpen == item && (
                  <PYCModal
                    text={handleTextCancelation(room?.cancellation_policies)}
                    open={openPYC}
                    handleClose={() => handleCloseCancelation()}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center gap-x-5 py-[5px]">
          <p className={`${leftStyle(true)}`}>Total</p>

          <p className="m-0 text-fs-14 m-m items-center text-gry-100">
            $
            {Math.floor(hotel.totalAmount)
              .toLocaleString("es-MX", { currency: "MXN" })
              .replace(".00", "")}
            .
            <sup className="sup-price-cart">
              {(hotel.totalAmount % 1).toFixed(2).slice(2)}{" "}
            </sup>{" "}
            (impuestos incluidos)
          </p>
        </div>

        <ModalCancellation
          hotel={hotel}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
