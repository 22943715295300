// import { Listbox, Transition } from "@headlessui/react";
import { useContext } from "react";
import { MarketingContext } from "../../context/MarketingContext";

const country = [
  {
    name: "ENG",
    value: "en",
    img: `${import.meta.env.VITE_URL_IMAGES}assets/icons/leng/en.svg`,
  },
  {
    name: "ESP",
    value: "es",
    img: `${import.meta.env.VITE_URL_IMAGES}assets/icons/leng/es.svg`,
  },
];

export function LanguageSelector() {
  const { setLanguageSelected, languageSelected } =
    useContext(MarketingContext);

  const handleChange = (newSelected) => {
    setLanguageSelected(newSelected.target.value);
  };
 

  return (
    <div className="relative w-max cursor-pointer">
      <img
        src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/leng/${
          languageSelected === "es" ? "es" : "en"
        }.svg`}
        alt="icons lang"
        className="icon-spanish absolute left-2 top-2 w-6 h-6 pointer-events-none"
      />
      <select
        onChange={() => handleChange(event)}
        className="appearance-none block w-full bg-[#ededed] py-2 pl-10 pr-6 rounded-lg focus:outline-none cursor-pointer"
      >
        {country.map((lang, index) => (
          <option key={index} value={lang.value}>
            {lang.name}
          </option>
        ))}
      </select>

      <img
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }/assets/icons/arrows/arrow-drop-down.svg`}
        alt="icons lang"
        className="icon-spanish absolute right-2 top-3.5 w-3 h-3 pointer-events-none"
      />

      {/* LP TAILWIND */}
      {/* <div className="w-max block m-s-b  bg-[#ededed] py-2 px-1 rounded-lg focus:outline-none">
        <Listbox tabIndex="-1" value={languageSelected} onChange={handleChange}>
          <div className="relative">
            <Listbox.Button
              onChange={handleChange}
              className="relative flex gap-1 w-full cursor-default rounded-lg bg-transparent pl-3 pr-7 text-left focus:outline-none items-center"
            >
              {languageSelected === "es" ? (
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/leng/es.svg`}
                  alt="spanish"
                  className="icon-spanish"
                />
              ) : (
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/leng/en.svg`}
                  alt="spanish"
                  className="icon-spanish"
                />
              )}
              {languageSelected === "es" ? (
                <span className="block truncate">ESP</span>
              ) : (
                <span className="block truncate">ENG</span>
              )}
              <span className="pointer-events-none absolute right-0 flex items-center justify-center pr-2">
                <img
                  src={`${
                    import.meta.env.VITE_URL_IMAGES
                  }assets/icons/arrows/arrow-drop-down.svg`}
                  alt="icon arrow down"
                  width={10}
                  height={10}
                  className="rotate-180 transform"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="ml-[10px] absolute mt-1 w-[90%] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {country.map((countries, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-1 ${
                        active ? "bg-bl-100 text-white" : "text-gray-900"
                      }`
                    }
                    value={countries}
                  >
                    <div className="pl-1 flex gap-1">
                      <img
                        src={countries.img}
                        alt={`icon ${countries.name} `}
                        width={25}
                        height={17}
                        className="aspect-[25/17]"
                      />
                      <span className={`block truncate m-b pl-2`}>
                        {countries.name}
                      </span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div> */}
      {/* END LP */}
    </div>
  );
}
