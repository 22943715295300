export function InputEmail({ inputEmail, setInputEmail, setIsValidGmail }) {
  const handleValidGmail = (gmail) => {
    if (gmail.includes("@")) {
      setIsValidGmail(true);
    } else {
      setIsValidGmail(false);
    }

    setInputEmail(gmail);
  };
  return (
    <div className="relative border border-gry-70 rounded-md my-2.5">
      <img
        className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
        src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/mail/mail-b.svg`}
        width={15}
        height={15}
      />

      <input
        value={inputEmail}
        onChange={(event) => handleValidGmail(event.target.value)}
        type="text"
        placeholder="Correo electronico"
        className="m-m py-3 pl-[40px] focus:outline-none w-full h-full bg-transparent text-gry-100 text-fs-12"
      />
    </div>
  );
}
