import { useContext, useState } from "react";

import AddNewUser from "./AddNewUser";
import FiltersUsers from "./FiltersUsers";
import { UsersContext } from "../context/UsersContext";
import { FilterComponent } from "./Filters/FilterComponent";

export default function SearchUsers() {
  const { inputFilter, setInputFilter,refreshUserList } = useContext(UsersContext);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const handleInput = (event) => {
    setInputFilter(event.target.value);
  };

  // const openAddUserDialog = () => {
  //   setIsAddUserOpen(true);
  // };

  const closeAddUserDialog = () => {
    setIsAddUserOpen(false);
  };

  return (
    <>
    <div
      className={`flex gap-2 justify-between pb-12 `}
    >
      {/* SEARCH USERS */}
      <div className="flex gap-4 items-center">

      <div className="relative bg-gry-30 rounded-lg h-[43px] min-w-full lg:min-w-[456px]">
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/search/search-black-crm.svg`}
          width={15}
          height={15}
          alt="search-black-crm"
          className="absolute top-0 bottom-0 ml-[18px] my-auto"
        />
        <input
          type="text"
          className="my-auto pl-[40px] absolute left-0 top-0 right-0 bottom-0 focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
          placeholder="Buscar"
          value={inputFilter}
          onChange={handleInput}
        />
      </div>

      <button
          className="focus:outline-none w-[20px] h-[20px]"
          onClick={refreshUserList}
        >
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/crm/refresh.svg`}
            alt="refresh users"
          />
        </button>
      </div>
      


      <div className="flex justify-center gap-x-5">
        <FiltersUsers setIsOpenFilter={setIsOpenFilter}/>

        {/* ADD USERS BTN */}
        {/* <button
          onClick={openAddUserDialog}
          className="bg-bl-100 flex gap-3 rounded-lg justify-center items-center px-3"
        >
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/icons/general/add-user-w.svg`}
            alt="icon add user white"
          />
          <span className="text-white text-fs-12 m-b text-nowrap">
            Agregar usuario
          </span>
        </button> */}
      </div>

      <AddNewUser isOpen={isAddUserOpen} closeNewUser={closeAddUserDialog} />
    </div>

    <FilterComponent isOpenFilter={isOpenFilter} setIsOpenFilter={setIsOpenFilter} />
    </>
  );
}
