import { useState } from "react";
import { DialogComments } from "./DialogComments";
import { useUser } from "../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../configs/Permissions/AlertPermissionsDeclined";

export function AddDocument({ isCanceled }) {
  const [isOpen, setIsOpen] = useState(false);
  const {user}= useUser();

  const [hasPermissions,setHasPermissions]=useState(true);
  
  const handelPermissions=()=>{
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "save_documents"
    );
    setHasPermissions(hasPermission);
    if(hasPermission){
      setIsOpen(true);
    }
  }

  return (
    <>
      <button
        onClick={handelPermissions}
        disabled={isCanceled}
        className={`w-full focus:outline-none rounded-full flex items-center gap-x-2 px-4 py-2 justify-center ${
          !isCanceled ? "hover:bg-bl-110 bg-bl-100" : 'bg-bl-70'
        }`}
      >

        <p className="text-white m-b text-fs-12">Agregar Documento</p>
      </button>

      <DialogComments isOpen={isOpen} setIsOpen={setIsOpen} />

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
