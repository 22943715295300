import { useEffect, useState } from "react";

import { NewComments } from "./NewComments";
import { SaveNewComment } from "../../api/Reservations";
import { useUser } from "../../../../configs/login/UserContext";
import { UseReservations } from "../../context/ReservationsContext";
import AlertPermissionsDeclined from "../../../../configs/Permissions/AlertPermissionsDeclined";
import { HasPermissionsUser } from "../../../../configs/Permissions/PermissionsUser";

export function AddComment({ isCanceled }) {
  const { user } = useUser();
  const [isFinish, setIsFinish] = useState(false);
  const [isFailed, setIsFailed] = useState({
    isFailDocuments: false,
    isFailComments: false,
  });
  const { detailsReservation, handleModalReservation } = UseReservations();

  // COMMENTS
  const [isLoader, setIsLoader] = useState(false);
  const [comments, setComments] = useState("");
  const [isEmptyComment, setIsEmptyComment] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleComment = async (event) => {
    event.preventDefault();

    const hasPermission = HasPermissionsUser(
      user.permissions,
      "add_comment"
    );

    setHasPermissions(hasPermission);

    if (hasPermission) {

      if (comments.trim() === "") {
        setIsEmptyComment(true);
        return;
      }

      setIsLoader(true);

      await responseComments();

      setIsLoader(false);
    }
  };

  const responseComments = async () => {
    try {
      let commentResponse = await SaveNewComment(
        detailsReservation.bookingReference,
        user.id,
        comments
      );

      if (commentResponse.code === "ok") {
        const referenceNumber = detailsReservation.bookingReference;
        handleModalReservation(referenceNumber);

        setIsFinish(true);
        setIsLoader(false);
        setIsFailed((fails) => {
          return { ...fails, isFailComments: false };
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      setIsFailed((fails) => {
        return { ...fails, isFailComments: true };
      });
      throw error;
    }
  };

  useEffect(() => {
    if (isFinish) {
      setComments("");
      setIsFinish(false);
      setIsEmptyComment(false);
    }
  }, [isFinish]);

  useEffect(() => {
    if (isFailed.isFailComments) {
      alert(
        "Ocurrió un problema al agregar un comentario, inténtelo más tarde."
      );
    }
  }, [isFailed]);

  return (
    <>
      <div className="h-full flex flex-col w-full">
        <form onSubmit={handleComment}>
          <NewComments
            isLoader={isLoader}
            comments={comments}
            setComments={setComments}
            isEmptyComment={isEmptyComment}
            setIsEmptyComment={setIsEmptyComment}
          />
          <div className="flex justify-center items-center gap-x-12">
            <button
              disabled={isLoader || isCanceled}
              type="submit"
              className={`focus:outline-none w-full rounded-full flex items-center p-3 justify-center text-white text-fs-12 m-b ${
                !isCanceled ? "hover:bg-bl-110 bg-bl-100" : "bg-bl-70"
              }`}
            >
              {isLoader ? "Agregando comentario..." : "Agregar comentario"}
            </button>
          </div>
        </form>
      </div>
      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
