export function SelectStatus({ handleStatus, typeStatus, idUser, status }) {
  return (
    <select
      value={status}
      className={`py-2 px-2 m-m focus:outline-none rounded-md ${
        status === "ACTIVO"
          ? "bg-grn-10 text-grn-100"
          : "bg-red-50 text-red-100"
      }`}
      onChange={() => handleStatus(idUser, event)}
    >
      {typeStatus.map((status, index) => (
        <option
          className={`${
            status.value === "ACTIVO"
              ? "bg-grn-10 text-grn-100"
              : "bg-red-50 text-red-100"
          }`}
          value={status.value}
          key={index}
        >
          {status.label}
        </option>
      ))}
    </select>
  );
}

export function StatusView({ status }) {
  return (
    <div
      className={`py-2 px-2 m-m focus:outline-none rounded-md ${
        status === "ACTIVO"
          ? "bg-grn-10 text-grn-100"
          : "bg-red-50 text-red-100"
      }`}
    >
      <div
        className={`cursor-default ${
          status === "ACTIVO"
            ? "bg-grn-10 text-grn-100"
            : "bg-red-50 text-red-100"
        }`}
      >
        {status}
      </div>
    </div>
  );
}
