export function ButtonLogOut({
  handleLogout,
  inputEmail,
  loader,
  isValidGmail,
}) {
  return (
    <button
      onClick={handleLogout}
      className={` w-max focus:outline-none px-[18px] py-[7px] rounded-lg border-0 flex items-center gap-x-[10px] ${
        inputEmail.length > 3 && isValidGmail ? "bg-grn-70" : "bg-grn-50"
      }`}
      disabled={inputEmail.length <= 3 || !isValidGmail}
    >
      <p className="text-white text-fs-16 m-0 text-center">
        {loader ? "Log out..." : "Log out"}
      </p>
    </button>
  );
}
