import TableUsers from "../components/TableUsers";
import SearchUsers from "../components/SearchUsers";
import { UsersProviderContext } from "../context/UsersContext";
import { Notifications } from "../../../components/Notifications/Notification";

export default function UsersLayout() {
  return (
    <UsersProviderContext>
      <div className="flex justify-between mb-[44px] w-full">
        <h1 className="text-fs-24 m-b text-black">Usuarios</h1>
        <Notifications />
      </div>

      <SearchUsers />

      <TableUsers />
    </UsersProviderContext>
  );
}
