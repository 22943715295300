import { UseRHContext } from "../../context/RHContext";

export function FilterByRoleRH() {
  const { rolesLists, rolesSelected, setRolesSelected } = UseRHContext();

  const handelValueRol = (value) => {
    let valueSelected = value;

    setRolesSelected((selected) => {
      if (selected.includes(valueSelected)) {
        return selected.filter((values) => values !== valueSelected);
      } else {
        return [...selected, valueSelected];
      }
    });
  };

  return (
    <div className="flex flex-col gap-y-3">
      <h2 className="text-fs-14 m-b">Roles</h2>
      <div className="grid grid-cols-2 gap-x-2 gap-y-2 h-max">
        {rolesLists.map((rol, index) => (
          <button
            key={index}
            onClick={() => handelValueRol(rol.id)}
            className={`border p-2 rounded-full select-none ${
              rolesSelected.some((values) => values === rol.id)
                ? "border-bl-100 bg-bl-100 text-white"
                : "border-gry-70"
            }`}
          >
            <span className="m-0 m-s-b text-fs-12 ml-2 select-none">{rol.description}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
