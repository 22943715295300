/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MarketingContext = createContext();

const ProviderMarketingContext = ({ children }) => {
  const location = useLocation();
  const navigation = useNavigate();
  const [responsiveView, setResponsiveView] = useState({
    value: "laptop",
    width: "all",
  });
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [routActual, setRoutActual] = useState(null);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [configDropzone, setConfigDropzone] = useState(null);
  const [languageSelected, setLanguageSelected] = useState("en");

  // JSON HOME
  const [jsonImageHome, setJsonImageHome] = useState({es:null, en:null});
  // console.log(jsonImageHome);
  

  // JSON HOTEL
  const [jsonImageHotel, setJsonImageHotel] = useState(null);

  // JSON TOUR
  const [jsonImageTour, setJsonImageTour] = useState(null);

  // JSON TRANSPORT
  const [jsonImageTransport, setJsonImageTransport] = useState(null);

  const [totalFiles, setTotalFiles] = useState(0);

  const [jonImagesActualView, setJonImagesActualView] = useState({es:null, en:null});

  useEffect(() => {
    const pathSegments = location.pathname.split("/");

    if (languageSelected && pathSegments[1] !== "marketing") {
      pathSegments[pathSegments.length - 1] = languageSelected;
      const newPath = pathSegments.join("/");

      navigation(newPath);
    }
  }, [languageSelected]);

  // useEffect(() => {
  //   if (routActual) {
  //     if (
  //       jsonImageHome ||
  //       jsonImageHotel ||
  //       jsonImageTour ||
  //       jsonImageTransport
  //     ) {
  //       switch (routActual) {
  //         case "home":
  //           setJonImagesActualView(jsonImageHome);
  //           break;
  //         case "hotel":
  //           setJonImagesActualView(jsonImageHotel);
  //           break;
  //         case "tour":
  //           setJonImagesActualView(jsonImageTour);
  //           break;
  //         case "transport":
  //           setJonImagesActualView(jsonImageTransport);
  //           break;
  //       }
  //     }
  //   }
  // }, [
  //   routActual,
  //   jsonImageHome,
  //   jsonImageHotel,
  //   jsonImageTour,
  //   jsonImageTransport,
  // ]);

  return (
    <MarketingContext.Provider
      value={{
        responsiveView,
        setResponsiveView,
        isOpenEdit,
        setIsOpenEdit,
        configDropzone,
        setConfigDropzone,
        routActual,
        setRoutActual,
        jsonImageHome,
        setJsonImageHome,
        jsonImageHotel,
        setJsonImageHotel,
        jsonImageTour,
        setJsonImageTour,
        jsonImageTransport,
        setJsonImageTransport,
        totalFiles,
        setTotalFiles,
        currentBanner,
        setCurrentBanner,
        jonImagesActualView,
        languageSelected,
        setLanguageSelected,
        setJonImagesActualView
      }}
    >
      {children}
    </MarketingContext.Provider>
  );
};

export { MarketingContext, ProviderMarketingContext };
