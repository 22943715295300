import "swiper/css";
import "swiper/css/navigation";

import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";

import BtnEdit from "../../others/BtnEdit";
import { SetConfigDropzone } from "../../others/setConfigDropZone";
import { MarketingContext } from "../../../context/MarketingContext";
import { useUser } from "../../../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../../../configs/Permissions/AlertPermissionsDeclined";

export default function ChainsHome() {
  const [hotels, setHotels] = useState([]);

  const [selectedBanner, setSelectedBanner] = useState(null);
  // const [maxHotels, setMaxHotels] = useState(0);
  const [imgChains, setImgChains] = useState(6);

  const {
    totalFiles,
    routActual,
    setIsOpenEdit,
    currentBanner,
    jonImagesActualView,
    responsiveView,
    setCurrentBanner,
    setConfigDropzone,
    languageSelected,
  } = useContext(MarketingContext);

  useEffect(() => {
    switch (responsiveView.value) {
      case "laptop":
        // setMaxHotels(10);
        setImgChains(6);
        break;
      case "tablet":
        // setMaxHotels(10);
        setImgChains(4);
        break;
      case "mobile":
        // setMaxHotels(10);
        setImgChains(2);
        break;
    }
  }, [responsiveView]);

  useEffect(() => {
    if (jonImagesActualView[languageSelected]) {
      setHotels(
        jonImagesActualView[languageSelected].hotelChains[responsiveView.value]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFiles, responsiveView, routActual]);

  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = () => {

        // VALID PERMISSIONS
        const hasPermission = HasPermissionsUser(
          user.permissions,
          "upload_images"
        );
    
        setHasPermissions(hasPermission);
    
        if (hasPermission) {

          setIsOpenEdit(true);
      
          let configDropzone = SetConfigDropzone(routActual, "hotelChains");
      
          setConfigDropzone(configDropzone);
          setSelectedBanner("hotelChains");
          setCurrentBanner("hotelChains");
        }
  };

  const isHotelChains =
    selectedBanner === "hotelChains" && currentBanner === "hotelChains";

  return (
    <div className=" mb-[56px] w-full ">
      <h2 className="m-b text-fs-28 mb-[36px]">
        Los mejores hoteles para tu estancia
      </h2>

      <div
        onClick={handleClick}
        className={`bg-white relative h-[135px] flex gap-20 items-center hover:border-4  hover:border-or-100 hover:rounded-xl cursor-pointer hover:brightness-75 justify-center ${
          isHotelChains && "brightness-75 border-4 border-or-100 rounded-xl"
        }`}
      >
        <Swiper
          slidesPerView={imgChains}
          spaceBetween={24}
          className="h-full rounded-lg !z-0 !w-full"
          initialSlide={0}
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {hotels.map((hotel, index) => (
            <SwiperSlide
              className="!rounded-lg !flex !items-center h-full"
              key={index}
            >
              <img src={hotel?.urlImage ? hotel?.urlImage : hotel} alt="Hotel Chains" className="select-none object-cover" />
            </SwiperSlide>
          ))}
        </Swiper>

        <BtnEdit />
      </div>

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </div>
  );
}
