import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function BannerSafelyTransport() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div
      className={`bg-gry-30 rounded-lg flex gap-[16px] ${
        responsiveView.value !== "laptop" && "flex-col"
      } my-32`}
    >
      <img
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/banners/transport/client-happy-transport.jpg`}
        alt="banner transporte persona sonriendo"
        className={`w-1/2 rounded-l-lg  select-none object-cover ${
          responsiveView.value !== "laptop" &&
          "rounded-t-lg rounded-bl-none w-full h-[22rem]"
        }`}
      />

      <div
        className={`flex flex-col gap-4 items-start justify-center ${
          responsiveView.value !== "laptop"
            ? "py-[24px] pl-[16px] pr-[36px]"
            : "pl-[56px] py-[80px] pr-[25px]"
        }`}
      >
        <h3
          className={` ${
            responsiveView.value !== "laptop"
              ? "text-fs-26 w-full"
              : "text-fs-36 w-[75%]"
          } m-b`}
        >
          Viaja con seguridad y tranquilidad
        </h3>

        <span className="m-m text-fs-16">
          Nuestros conductores profesionales te garantizan un viaje sin
          contratiempos, brindándote la confianza que necesitas en cada
          trayecto.
        </span>

        <div className="px-[16px] py-[16px] rounded-full bg-yw-100 text-fs-14 m-s-b">
          Reservar un traslado
        </div>
      </div>
    </div>
  );
}
