/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useState, useContext, useEffect } from "react";

// import EditUser from "./EditUser";
import BtnEditUser from "./BtnEditUser";
import SelectStatusUser from "./SelectStatusUser";
import { UsersContext } from "../context/UsersContext";
import Pagination from "../../../components/pagination/Pagination";
import { EmptyTable } from "../../../components/table/EmptyTable";
import { BodySkeleton } from "../../../components/skeletons/BodySkeleton";
import { ModalPermissions } from "./Permissions/ModalPermissions";
import { useUser } from "../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../configs/Permissions/AlertPermissionsDeclined";

export default function TableUsers() {
  const styleTh = "text-fs-14 m-b text-gry-100 ";
  const styleTd = "m-0 text-fs-12 m-m flex justify-center";

  const { dataUserFilter, loaderUsers } = useContext(UsersContext);
  
  const { user } = useUser();

  const  [userSelected, setUserSelected]  = useState(null);

  // PAGINATION
  const blogPerPage = 7;
  const [totalPages, setTotalPages] = useState(1);
  const [currentTab, setCurrentTab] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [openPermissions, setOpenPermissions] = useState(false);
  const indexOfLastTab = currentPage * blogPerPage;
  const indexOfFirstTab = indexOfLastTab - blogPerPage;

  useEffect(() => {
    if (dataUserFilter && dataUserFilter.length > 0) {
      const sortedUsers = [...dataUserFilter].sort((a, b) => b.id - a.id);

      setCurrentTab(sortedUsers.slice(indexOfFirstTab, indexOfLastTab));

      const calculatedPages = Math.ceil(sortedUsers.length / blogPerPage);
      setTotalPages(Math.max(calculatedPages, 1));
    } else {
      setCurrentTab([]);
      setTotalPages(1);
    }
  }, [dataUserFilter, currentPage, indexOfFirstTab, indexOfLastTab]);

  useEffect(() => {
    if (currentTab.length === 0 && currentPage > 1) {
      setCurrentPage(1);
    }
  }, [currentTab]);

  const [hasPermissions, setHasPermissions] = useState(true);

  const handelPermission = (id) => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "get_user_permissions"
    );

    setHasPermissions(hasPermission);
    if (hasPermission) {
      setOpenPermissions(!openPermissions);
      setUserSelected(id);
    }
  };

  useEffect(() => {
    if (!openPermissions) {
      setUserSelected(null);
    }
  }, [openPermissions]);

  return (
    <>
      <div>
        <div className="bg-gry-30 rounded-2xl rounded-bl-2xl p-5 w-full ">
          <table className="w-full mt-1">
            <thead className="mb-4 ">
              <tr>
                <th className={`${styleTh} w-[152px]`}>Cuenta</th>
                <th className={`${styleTh}`}>Telefono</th>
                <th className={`${styleTh} `}>Rol</th>
                <th className={`${styleTh}`}>Estatus</th>
                <th className={`${styleTh}`}>Fecha de ingreso</th>
                <th className={`${styleTh}`}>No.Folio interno</th>
                <th className={`${styleTh}`}>Permisos</th>
              </tr>
            </thead>

            {!loaderUsers && dataUserFilter && dataUserFilter.length > 0 && (
              <tbody>
                <tr className="h-[25px] bg-gry-30"></tr>
                {currentTab.map((Users, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr className="bg-white rounded-lg h-[56px] ">
                        <td className="rounded-l-xl">
                          {/* IMG PROFILE */}
                          <div className="flex items-center gap-x-2 ml-2 w-fit">
                            <div className="h-11 w-11 rounded-lg bg-gry-50 relative">
                              <img
                                src={Users.url_image}
                                width={133}
                                height={117}
                                alt={Users.name}
                                className="object-cover w-full h-full rounded-lg"
                              />
                              <div
                                className={`w-2 h-2 rounded-full absolute top-0 right-0 ${
                                  Users.status === "ACTIVO" && Users.online
                                    ? "bg-grn-100"
                                    : "bg-gry-70"
                                }`}
                              />
                            </div>

                            <div className={`flex flex-col w-[250px]`}>
                              <span
                                aria-hidden="true"
                                className={`text-fs-14 m-b`}
                              >
                                {Users.name}
                              </span>
                              <span className={`truncate m-m text-fs-10`}>
                                {Users.email}
                              </span>
                            </div>
                          </div>
                        </td>

                        {/* TEL */}
                        <td>
                          <p
                            className={`${styleTd} flex justify-center m-m text-fs-12`}
                          >
                            {Users.phone}
                          </p>
                        </td>

                        {/* ROL */}
                        <td>
                          <div className="m-m text-fs-12 flex gap-x-2 items-center justify-center">
                            {Users.user_role_description}
                          </div>
                        </td>

                        {/* STATUS */}
                        <td>
                          <SelectStatusUser
                            status={Users.status}
                            id={Users.id}
                            name={Users.name}
                          />
                        </td>

                        {/* DATE */}
                        <td className=" ">
                          <div className="m-m text-fs-12 flex items-center gap-x-4 justify-center">
                            {Users.created_at &&
                              moment(Users.created_at).format("DD/MM/YYYY")}
                          </div>
                        </td>

                        {/* No.FOLIO */}
                        <td className="">
                          {/* EDIT */}
                          <div className="flex items-center gap-x-4 justify-center m-m text-fs-12">
                            {Users.edit && (
                              <>
                                {/* <button className={`${styleTd}`}>
                                <img
                                  src={`${
                                    import.meta.env.VITE_URL_IMAGES
                                  }assets/icons/general/pencil-100.svg`}
                                  alt="edit-pencil"
                                  width={13}
                                  height={15}
                                  onClick={() => setOpenAddUser(!openAddUser)}
                                />
                              </button> */}
                                <BtnEditUser dataUserFilter={Users} />
                              </>
                            )}
                            {/* <button className={`${styleTd}`}>
                            <img
                              src={`${
                                import.meta.env.VITE_URL_IMAGES
                              }assets/icons/delete/delete-gry-100.svg`}
                              width={13}
                              height={15}
                              alt="delete"
                            />
                          </button> */}
                            {Users.user_name}
                          </div>
                        </td>

                        <td className="rounded-r-xl ">
                          <div className="m-m text-fs-12 flex items-center gap-x-4 justify-center">
                            <img
                              src={`${
                                import.meta.env.VITE_URL_IMAGES
                              }assets/icons/crm/pencil-edit.svg`}
                              alt="edit-pencil"
                              width={13}
                              height={15}
                              className="cursor-pointer"
                              onClick={() => handelPermission(Users.id)}
                            />
                          </div>
                        </td>
                      </tr>

                      {dataUserFilter.length - 1 !== index && (
                        <tr className="h-[12px] bg-transparent" />
                      )}
                    </>
                  </React.Fragment>
                ))}
                {/* {openAddUser && (
                <EditUser
                  users={dataUserFilter}
                  closeNewUser={() => setOpenAddUser(false)}
                />
              )} */}
              </tbody>
            )}
          </table>

          <ModalPermissions
            openFilter={openPermissions}
            setOpenFilter={setOpenPermissions}
            userSelected={userSelected}
          />

          {/* {openAddUser && <EditUser users={dataUserFilter} closeNewUser={() => setOpenAddUser(false)} />} */}

          {!loaderUsers && dataUserFilter.length === 0 && <EmptyTable />}

          {loaderUsers && <BodySkeleton />}
        </div>
        <div className="w-full flex justify-end mt-2">
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              pageChange={currentPage}
              onChange={setCurrentPage}
            />
          )}
        </div>
      </div>

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
