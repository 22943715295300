import { Fragment, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";

export default function AssignVacations({ isOpen, close }) {
  const [enabled, setEnabled] = useState(false);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[486px] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-100.svg`}
                      width={16}
                      height={16}
                      alt="icon-close"
                      className="mr-2 cursor-pointer absolute right-4"
                      onClick={close}
                    />

                    <div className="flex flex-col gap-3 mt-5">
                      <div className="flex gap-3">
                        <div className="w-[120px] h-[120px] bg-gry-50 rounded-lg">
                          {/* IMG PROFILE */}
                        </div>
                        <div className="flex flex-col gap-1">
                          <h3 className="text-fs-20 text-gry-100 m-b">
                            Angel Rafael Bastian Tinoco*
                          </h3>
                          <div className="bg-grn-10 text-grn-100 px-2 py-1 w-fit text-fs-12 m-s-b rounded">
                            Activo
                          </div>
                        </div>
                      </div>
                      <hr className="border-[1.5px] my-2" />

                      {/* INPUT START DATE */}
                      <div className="relative w-full text-fs-12 m-m text-gry-100">
                        <label className="m-b text-fs-14 text-gry-100">
                          Fecha inicial
                        </label>
                        <div className="flex px-[16px] py-[12.8px] border border-[#ebebeb] items-center gap-2 relative mt-2">
                          <img
                            className="w-[14px] h-[16px]"
                            src={`${
                              import.meta.env.VITE_URL_IMAGES
                            }assets/icons/calendar/calendar-b.svg`}
                            alt="icon calendar"
                          />
                          <input
                            className="text-fs-12 focus:outline-none w-full cursor-pointer placeholder:text-gry-70 placeholder:m-m"
                            placeholder="Seleccione fecha inicial"
                          />
                        </div>
                      </div>

                      {/* INPUT END DATE */}
                      <div className="relative w-full text-fs-12 m-m text-gry-100">
                        <label className="m-b text-fs-14 text-gry-100">
                          Fecha finalizacion
                        </label>
                        <div className="flex px-[16px] py-[12.8px] border border-[#ebebeb] items-center gap-2 relative mt-2">
                          <img
                            className="w-[14px] h-[16px]"
                            src={`${
                              import.meta.env.VITE_URL_IMAGES
                            }assets/icons/calendar/calendar-b.svg`}
                            alt="icon calendar"
                          />
                          <input
                            className="text-fs-12 focus:outline-none w-full cursor-pointer placeholder:text-gry-70 placeholder:m-m"
                            placeholder="Seleccione fecha finalizacion"
                          />
                        </div>
                      </div>

                      <hr className="border-[1.5px] my-2" />
                      {/* SEND EMAIL */}
                      <div className="flex flex-col gap-2">
                        <h3 className="m-b text-fs-14 text-gry-100">
                          Mandar correo de confirmacion
                        </h3>

                        <Switch
                          checked={enabled}
                          onChange={setEnabled}
                          className={`${
                            enabled ? "bg-bl-100" : "bg-gry-70"
                          } relative inline-flex h-[25px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent duration-200 ease-in-out `}
                        >
                          <span
                            aria-hidden="true"
                            className={`${
                              enabled ? "translate-x-9" : "translate-x-0"
                            } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white transition duration-200 ease-in-out`}
                          />
                        </Switch>
                      </div>

                      {/* PASSWORD */}
                      <div className="flex flex-col">
                        <span className="m-b text-fs-14 text-gry-100 mb-0">
                          Contraseña
                        </span>
                        <span className="text-fs-10 m-m text-gry-100">
                          *Para autorizar vacaciones se debera confirmar la
                          contraseña*
                        </span>
                        
                        <input
                          type="password"
                          placeholder="Contraseña"
                          className="appearance-none outline-none border-2 border-gry-30 rounded h-[45px] pl-5 w-full pr-8 mt-5"
                        />
                      </div>
                    </div>

                    {/* BTN´S ALERT */}
                    <div className="flex gap-3 mt-7">
                      <button
                        // onClick={handleSubmit}
                        className="flex gap-1 px-3 py-3 rounded-lg bg-bl-100 w-fit text-fs-12 text-white m-s-b hover:bg-bl-110"
                      >
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/save-w.svg`}
                          alt="icon save white"
                          width={18}
                          height={18}
                        />
                        Guardar
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
