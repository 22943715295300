import RHListing from "../components/RHListing";
import TabTablaRH from "../components/TabTablaRH";
import { RHProviderContext } from "../context/RHContext";
import { Notifications } from "../../../components/Notifications/Notification";
import { VacationsProviderContext } from "../../Vacations/context/VacationsContext";

export default function RHLayout() {
  return (
    <RHProviderContext>
      <VacationsProviderContext>
        <div className="flex justify-between mb-[44px] w-full">
          <h1 className="text-fs-24 m-b text-black">Modulo de RH</h1>

          <Notifications />
        </div>

        <RHListing />

        <TabTablaRH />
      </VacationsProviderContext>
    </RHProviderContext>
  );
}
