import axiosWithInterceptor from "../../../configs/Instance/axiosConfig";

export async function GetProviderPayment() {
  try {
    // const response = await axiosWithInterceptor.get();
    const response = [
      {
        name: "op",
        status: "inactive",
      },
      {
        name: "cp",
        status: "active",
      },
      {
        name: "ca",
        status: "inactive",
      },
    ];
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function SaveProviderPayment(provider) {
  try {
    console.log(provider);

    // const saveProvider = await axiosWithInterceptor.post("", {
    //   topActive: provider,
    // });
    const saveProvider = { code: "ok" };
    return saveProvider;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetProvidersStayWuw() {
  try {
    // const response = await axiosWithInterceptor.gep(`${import.meta.env.VITE_API_URL_STAYWUW}/logout-by-email`);
    const response = {
      hotel: [
        {
          name: "hb",
          status: "active",
        },
        {
          name: "pt",
          status: "active",
        },
        {
          name: "rh",
          status: "inactive",
        },
      ],

      activity: [
        {
          name: "ct",
          status: "active",
        },
      ],

      transport: [
        {
          name: "ct",
          status: "active",
        },
      ],
    };

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function SaveProvidersStayWuw(providers) {
  // console.log(providers);

  try {
    // let response = await axiosWithInterceptor.post(``, providers);
    let response = { code: "ok" };
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function LogOutUserEmail(email) {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/logout-by-email`,
      email
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetMarkup() {
  try {
    // const response = await axiosWithInterceptor.get();
    const response = 3;
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function SetMarkup(markup) {
  try {
    // const response =await axiosWithInterceptor.post(``, markup);
    const response = { code: "ok" };
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
