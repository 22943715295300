/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { base64Data } from "../../../../components/dropzone/ChangeFormatImage";

export function DropzoneReservation({
  imageIsEmpty,
  file,
  position,
  setAddFiles,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Manejo para archivos de imagen
          if (file.type.startsWith("image/")) {
            const image = new Image();
            image.onload = () => {
              base64Data(URL.createObjectURL(file)).then((imageData) => {
                let imageType = file.type;
                let urlImage = URL.createObjectURL(file);

                setAddFiles((files) =>
                  files.map((actualFile, index) => {
                    if (index === position) {
                      return {
                        ...actualFile,
                        file: imageData,
                        type: imageType,
                        viewFile: urlImage,
                      };
                    }
                    return actualFile;
                  })
                );
              });
            };
            image.src = event.target.result;
          }
          // Manejo para archivos PDF
          else if (file.type === "application/pdf") {
            base64Data(URL.createObjectURL(file)).then((pdfData) => {
              let pdfType = file.type;
              let urlPDF = URL.createObjectURL(file);

              setAddFiles((files) =>
                files.map((actualFile, index) => {
                  if (index === position) {
                    return {
                      ...actualFile,
                      file: pdfData,
                      type: pdfType,
                      viewFile: urlPDF,
                    };
                  }
                  return actualFile;
                })
              );
            });
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [position]
  );

  const removeFile = (file) => {
    setAddFiles((files) =>
      files.map((actualFile) => {
        if (actualFile === file) {
          return (actualFile = {
            ...actualFile,
            file: "",
            type: "",
            viewFile: "",
          });
        }
        return actualFile;
      })
    );
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className="relative w-full">
      <div
        className={`w-[80%] flex justify-center border-dashed border-2 rounded-lg ${
          imageIsEmpty && file.file === "" ? "border-red-100" : "border-gry-70"
        }`}
      >
        {file.file === "" ? (
          <div
            className="focus:outline-none m-2.5 w-full h-[88px] cursor-pointer rounded-2xl  text-gry-100 text-fs-10 m-m flex flex-col justify-center items-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/general/add-image.svg`}
              alt="icon close gry"
              className="w-[18px] h-[18px]"
              width={18}
              height={18}
            />
            <span>Agregar archivo</span>
          </div>
        ) : (
          <div className="flex p-2.5 items-center justify-between flex-col gap-y-2">
            <div className="flex flex-wrap gap-3 w-full items-center justify-center">
              <div className="relative h-max">
                <div
                  className="cursor-pointer z-[3] bg-[#d9d9d9] w-[20px] h-[20px] rounded-full flex justify-center items-center absolute top-[-4px] right-[-6px]"
                  onClick={() => removeFile(file)}
                >
                  <img
                    src={`${
                      import.meta.env.VITE_URL_IMAGES
                    }assets/icons/close/close-70.svg`}
                    alt="icon close gry"
                    className="w-[9px] h-[9px]"
                    width={15}
                    height={15}
                  />
                </div>

                {file ? (
                  <img
                    src={FileImage(file)}
                    alt="preview"
                    width="100"
                    className={`rounded-lg w-full h-[88px] object-contain  backdrop-brightness-[.94]`}
                  />
                ) : (
                  <p>{file.name}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {imageIsEmpty && file.file === "" && (
        <span className="m-s-b text-red text-fs-12 text-red-100">
          Campo vacio
        </span>
      )}
    </div>
  );
}

export const FileImage = (file) => {
  let urlImage = "";

  let type = file.type.split("/")[1];

  if (type === "pdf") {
    urlImage = `${
      import.meta.env.VITE_URL_IMAGES
    }assets/icons/typeFile/pdf.svg`;
  } else {
    urlImage = file.viewFile;
  }

  return urlImage;
};
