/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export function SelectContract({ user, contractType, setContractType }) {
  const contractTypes = [
    { value: "TEMPORAL", label: "Temporal" },
    { value: "INDEFINIDO", label: "Indefinido" },
  ];

  useEffect(() => {
    if (user && user?.contract_type) {
      
      setContractType(() =>
        contractTypes.find((contract) => contract.value === user.contract_type)
      );
    } else {
      setContractType(contractTypes[0]);
    }
  }, [user]);

  const handleContract = (contract) => {
    let response = contract;
    setContractType(() =>
      contractTypes.find((contract) => contract.value === response)
    );
  };

  return (
    <div className="relative ">
      <img
        src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/crm/contract.svg`}
        width={19}
        height={19}
        alt="icon-tel-crm"
        className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
      />

      <div className="w-full pl-[40px] h-[43px] border text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12">
        {contractType && (
          <select
            className="focus:outline-none w-full h-full "
            value={contractType.value}
            onChange={(e) => handleContract(e.target.value)}
          >
            {contractTypes.map((contract, index) => (
              <option key={index} value={contract.value} className="text-fs-12">
                {contract.label}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
}
