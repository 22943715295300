/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { AutoCompleteInput } from "../../api/api";

export function AutoCompleteBank({ input, setInput }) {
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const handleLetters = (input) => {
    return input.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, "");
  };

  const handleChange = (event) => {
    setInput(handleLetters(event.target.value));
    setIsOpen(true);
  };

  useEffect(() => {
    if (input && input.length > 0) {
      handleSearch();
    }
  }, [input, isOpen]);

  const handleSearch = async () => {
    try {
      const response = await AutoCompleteInput(input);
      setOptions(response.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSelect = (item) => {
    if (item) {
      setSelectedItem(item);
      setInput(item.bank_name);
      setIsOpen(false);
    }
  };

  const ref = useRef(null);

  const handleClickOutSide = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  return (
    <>
      <img
        src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/payment/banco.svg`}
        width={19}
        height={19}
        alt="icon-tel-crm"
        className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
      />
      <Combobox value={selectedItem} onChange={handleSelect}>
        <Combobox.Input
          className="border pl-[40px] text-gry-100 text-fs-12 border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
          onChange={handleChange}
          displayValue={(item) => (item ? item.bank_name : "")}
          placeholder="Banco"
          autoComplete="off"
          type="text"
          value={input}
        />
        <Transition
          ref={ref}
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="scroll-page-blue p-0 absolute z-[2] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, index) => (
              <Combobox.Option key={index} value={option}>
                {({ active }) => (
                  <div
                    className={`p-2 cursor-pointer ${
                      active ? "bg-gry-30" : "text-gray-900"
                    }`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.bank_name}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </>
  );
}
