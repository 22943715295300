/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import { useContext, useEffect, useState } from "react";

import { useUser } from "../../configs/login/UserContext";
import { MarketingContext } from "../../sections/Marketing/context/MarketingContext";
import { SetConfigDropzone } from "../../sections/Marketing/components/others/setConfigDropZone";
import { HasPermissionsUser } from "../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../configs/Permissions/AlertPermissionsDeclined";

export default function BannerSearch() {
  const {
    responsiveView,
    setIsOpenEdit,
    setConfigDropzone,
    languageSelected,
    routActual,
    totalFiles,
    setCurrentBanner,
    currentBanner,
    jonImagesActualView,
  } = useContext(MarketingContext);

  const [imageBanner, setImageBanner] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const { user } = useUser();
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleClick = () => {
    // VALID PERMISSIONS
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "upload_images"
    );

    setHasPermissions(hasPermission);

    let typeBanner = "";
    switch (routActual) {
      case "home":
        typeBanner = "bannerHome";
        break;
      case "hotel":
        typeBanner = "bannerHotel";
        break;
      case "tour":
        typeBanner = "bannerTour";
        break;
      case "transport":
        typeBanner = "bannerTransportHome";
        break;
    }

    if (hasPermission) {
      setIsOpenEdit(true);

      let configDropzone = SetConfigDropzone(routActual, typeBanner);

      setConfigDropzone(configDropzone);

      setSelectedBanner("principalBanner");
      setCurrentBanner("principalBanner");
    }
  };

  useEffect(() => {
    switch (routActual) {
      case "home":
        setImageBanner(
          jonImagesActualView[languageSelected].bannerHome[responsiveView.value]
        );
        break;
      case "hotel":
        setImageBanner(
          jonImagesActualView[languageSelected].bannerHotel[
            responsiveView.value
          ]
        );
        break;
      case "tour":
        setImageBanner(
          jonImagesActualView[languageSelected].bannerTour[responsiveView.value]
        );
        break;
      case "transport":
        setImageBanner(
          jonImagesActualView[languageSelected].bannerTransportHome[
            responsiveView.value
          ]
        );
        break;
    }
  }, [
    totalFiles,
    responsiveView,
    routActual,
    languageSelected,
    jonImagesActualView,
  ]);

  const isPrincipalBanner =
    selectedBanner === "principalBanner" && currentBanner === "principalBanner";

  return (
    <div
      className={`rounded-t-lg  ${
        responsiveView.value === "mobile" ? "mb-[220px]" : "mb-[85px]"
      }`}
    >
      {/* NAVBAR */}
      <img
        src={
          `${import.meta.env.VITE_URL_IMAGES}assets/img/crm/` +
          `${responsiveView.value === "mobile" ? "navbar-m.jpg" : "navbar.jpg"}`
        }
        alt="navbar staywuw"
        className={`w-full rounded-t-lg select-none ${
          responsiveView.value === "laptop" && ""
        } ${responsiveView.value === "tablet" && "object-contain"} ${
          responsiveView.value === "mobile" && "object-cover"
        }`}
      />
      <div className="w-full relative flex justify-center">
        {/* BANNER */}
        <div
          className={`relative flex items-center justify-center ${
            responsiveView.value == "mobile" && "h-[442px]"
          } ${responsiveView.value == "tablet" && "h-72"}  ${
            responsiveView.value == "laptop" && "h-[480px]"
          } w-full`}
        >
          {/* test */}
          {imageBanner ? (
            <Swiper
              onClick={handleClick}
              className="h-full w-full !z-0"
              slidesPerView={1}
              id={`${
                responsiveView.value == "tablet"
                  ? "swiper-banner-home-m"
                  : "swiper-banner-home"
              }`}
              navigation
              modules={[Navigation, Autoplay]}
              loop={true}
              cssMode={true}
              autoplay={{
                delay: 8000,
              }}
            >
              {imageBanner.map((imageBanner, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={
                      imageBanner?.urlImage
                        ? imageBanner?.urlImage
                        : imageBanner
                    }
                    alt="banner principal"
                    width="100%"
                    height="100%"
                    className={`object-cover w-full h-full select-none hover:brightness-75 cursor-pointer border-4 border-transparent hover:border-or-100 ${
                      isPrincipalBanner && "!brightness-75 !border-or-100"
                    }`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="animate-[skeletonLoading_1s_linear_infinite_alternate] w-full h-full" />
          )}

          {routActual === "tour" && (
            <h2
              className={` text-center absolute text-white m-b ${
                responsiveView.value === "laptop" && "text-fs-60"
              } ${responsiveView.value === "tablet" && "text-fs-40"} ${
                responsiveView.value === "mobile" && "text-fs-20"
              }`}
            >
              El mejor viaje se disfruta en familia
            </h2>
          )}

          {/* SEARCH */}
          <img
            src={
              `${import.meta.env.VITE_URL_IMAGES}assets/img/crm/` +
              `${
                responsiveView.value == "mobile" ? "search-m.png" : "search.png"
              }`
            }
            className={`absolute select-none ${
              responsiveView.value == "mobile"
                ? "top-[290px] w-[395px]"
                : "bottom-[-68px]"
            }`}
            alt="search desktop"
          />
          {/* EDIT */}
          <button
            onClick={handleClick}
            className="bg-or-100 rounded-lg py-3 px-5 absolute top-[-20px] hover:bg-or-110 justify-center"
          >
            <img
              src={`${
                import.meta.env.VITE_URL_IMAGES
              }assets/icons/crm/pencil-w.svg`}
              width={16}
              height={16}
            />
          </button>
        </div>
      </div>
      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </div>
  );
}
