import { logoutApi } from "../../../api/api";
import { useUser } from "../../../configs/login/UserContext";

export default function LogOut({ isOpen }) {
  const { setUser, token, setToken } = useUser();

  const handleLogout = async () => {
    try {
      await logoutApi(token);

      setUser(null);
      setToken(null);

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <div
      className={`w-full flex px-6 py-4 ${
        isOpen ? "justify-between" : "justify-center"
      }`}
      onClick={handleLogout}
    >
      <span
        className={`cursor-pointer ${
          isOpen ? "m-s-b text-fs-12 text-red-100" : "hidden"
        }`}
      >
        Cerrar sesión
      </span>

      <img
        alt="icon sing off"
        className="cursor-pointer"
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/crm/navbarCRM/logout-red.svg`}
      />
    </div>
  );
}
