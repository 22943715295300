import { useContext } from "react";

import { StepsCards } from "./StepsCards";
import { PaymentBannerMobile } from "./PaymentBannerMobile";
import { CarrouselPaymentStep } from "./CarrouselPaymentStep";
import { MarketingContext } from "../../../context/MarketingContext";
import { NotificationContext } from "../../../../../context/NotificationContext";

export function BannerStepsItinerary() {
  const { responsiveView } = useContext(MarketingContext);
  const { isOpen } = useContext(NotificationContext);
  return (
    <div className="bg-gry-50">
      <div
        className={`flex max-md:justify-center p-5 ${
          responsiveView.value === "laptop"
            ? `${isOpen ? "gap-x-[10px]" : "gap-x-[50px]"}`
            : "flex-col-reverse"
        }`}
      >
        {/* LEFT CARD */}
        <div
          className={` justify-end h-max my-auto ${
            responsiveView.value === "laptop"
              ? "flex flex-row w-1/2 mt-12"
              : "w-full"
          }`}
        >
          <PaymentBannerMobile />

          <CarrouselPaymentStep />
        </div>

        {/* RIGHT CARD */}
        <div
          className={`mt-5 ${
            responsiveView.value === "laptop" ? "mt-10 mb-10 w-1/2" : ""
          }`}
        >
          <div className="w-full">
            <StepsCards />
          </div>
        </div>
      </div>
    </div>
  );
}
