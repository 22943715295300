/* eslint-disable react-hooks/exhaustive-deps */
import "flatpickr/dist/flatpickr.min.css";

import moment from "moment";
import flatpickr from "flatpickr";
import { useEffect, useRef } from "react";

export function FirstDateFilter({ handelDate, starDate, isFirstDate = true }) {
  const calendarRef = useRef(null);
  useEffect(() => {
    flatpickr(calendarRef.current, {
      mode: "single",
      dateFormat: "d/m/Y",
      locale: "es",
      inline: false,
      disableMobile: true,
      onChange: handelDate,
    });
  }, [starDate]);

  return (
    <input
      className="m-0 m-s-b text-fs-12 text-gry-100 placeholder:m-s-b placeholder:text-fs-12 placeholder:text-gry-100 focus:outline-none w-full cursor-pointer"
      type="text"
      ref={calendarRef}
      readOnly
      value={starDate ? moment(starDate).format("DD/MM/YYYY") : ""}
      placeholder={`Fecha ${isFirstDate ? "1" : "2"}`}
    />
  );
}
