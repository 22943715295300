import { useContext, useEffect, useState } from "react";

import { BestHotelCart } from "./BestHotelCart";
import { shuffleHotelTypes } from "../../../configs/Json";
import { MarketingContext } from "../../../context/MarketingContext";

export function LodgingsView() {
  const { responsiveView, isOpenEdit } = useContext(MarketingContext);

  const [maxCard, setMaxCard] = useState(0);

  useEffect(() => {
    switch (responsiveView.value) {
      case "laptop":
        setMaxCard(isOpenEdit ? 8 : 10);
        break;
      case "tablet":
        setMaxCard(2);
        break;
      case "mobile":
        setMaxCard(1);
        break;
    }
  }, [isOpenEdit, responsiveView]);

  return (
    <>
      <div
        className={`grid ${
          responsiveView.value === "laptop"
            ? isOpenEdit
              ? "max-2xl:grid-cols-4 grid-cols-4"
              : "2xl:grid-cols-5 md:grid-cols-3"
            : ""
        } ${responsiveView.value === "tablet" && "grid-cols-2"} ${
          responsiveView.value === "mobile" && "grid-cols-1"
        } gap-x-[16px] gap-y-[24px] `}
      >
        {Object.values(shuffleHotelTypes)
          .slice(0, maxCard)
          .map((hotel, index) => (
            <div key={index} className="!rounded-lg colum-two">
              {/*  */}
              <div
                className={`h-full min-w-[266px] max-w-[280px] shadow-md shadow-gry-30 rounded-xl ${
                  responsiveView.value === "mobile" && "mx-auto"
                }`}
              >
                <BestHotelCart hotel={hotel} />
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
