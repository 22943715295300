import { createContext, useState, useEffect, useContext } from "react";

import {
  FilterActiveStatus,
  FilterRolesStatus,
  FiltersTotals,
  FiltersUsersInput,
  FilterUsersByRole,
  FilterUsersVacations,
} from "../../Users/config/FiltersUsers";
import { GetApartment, GetRoleList } from "../../Users/api/api";
import { UrlImage } from "../../../configs/others/UrlImage";
import { FetchRHList, FindUser, FindUserDocs } from "../api/api";

const RHContext = createContext();

export const UseRHContext = () => useContext(RHContext);

const RHProviderContext = ({ children }) => {
  const [dataUsers, setDataUsers] = useState([]);
  const [inputFilter, setInputFilter] = useState("");
  const [dataRHFilter, setDataRHFilter] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [louderFinDocs, setLouderFinDocs] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [statusSelected, setStatusSelected] = useState("");
  const [rolesSelected, setRolesSelected] = useState([]);
  const [allTotal, setAllTotal] = useState(0);
  const [temporaryTotal, setTemporaryTotal] = useState(0);
  const [vacationsTotal, setVacationsTotal] = useState(0);
  const [indefiniteTotal, setIndefiniteTotal] = useState(0);
  const [withoutContract, setWithoutContract] = useState(0);
  const [addNewUser, setAddNewUser] = useState({
    isUser: false,
    message: null,
    title: null,
  });
  const [rhDocs, setRhDocs] = useState([
    {
      name: "INE",
      type: "INE",
      document: null,
      url: null,
      isEdit: false,
    },
    { name: "CURP", type: "CURP", document: null, url: null, isEdit: false },
    {
      name: "ACTA DE NACIMIENTO",
      type: "ACTA",
      document: null,
      url: null,
      isEdit: false,
    },
    {
      name: "COMPROBANTE DE DOMICILIO",
      type: "COMPROBANTEDOM",
      document: null,
      url: null,
      isEdit: false,
    },
    { name: "NSS", type: "NSS", document: null, url: null, isEdit: false },
    {
      name: "HOJA DE RETENCION",
      type: "INFONAVIT",
      document: null,
      url: null,
      isEdit: false,
    },
    {
      name: "CONTRATO",
      type: "CONTRATO",
      document: null,
      url: null,
      isEdit: false,
    },
    {
      name: "VACACIONES",
      type: "VACACIONES",
      document: null,
      url: null,
      isEdit: false,
    },
    {
      name: "PASAPORTE",
      type: "PASAPORTE",
      document: null,
      url: null,
      isEdit: false,
    },
  ]);

  const [rolesLists, setRolesLists] = useState([]);
  const [apartmentsLists, setApartmentsLists] = useState([]);

  const getRoleLists = async () => {
    
    try {
      const roles = await GetRoleList();
      setRolesLists(roles.roleList);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getApartmentLists = async () => {
    try {
      const apartments = await GetApartment();
      setApartmentsLists(apartments.roleList);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const refreshUserList = async () => {
    try {
      setIsLoaderTable(true);
      const data = await FetchRHList();
      setDataUsers(data.userInfo);
      setIsLoaderTable(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const refreshDocumentUser = async (id) => {
    setLouderFinDocs(true);
    try {
      const result = await FindUserDocs(id);
      const updatedDocs = rhDocs.map((doc) => {
        const matchingDoc = result.data.documents.find(
          (item) => item.type === doc.type
        );
        if (matchingDoc) {
          return {
            ...doc,
            document: matchingDoc.file,
            document_id: matchingDoc.document_id,
            updated_at: matchingDoc.updated_at,
            url: UrlImage(matchingDoc.file),
          };
        }
        return doc;
      });

      setRhDocs(updatedDocs);
      setLouderFinDocs(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        console.log("error", error.response.data);
      }
      setLouderFinDocs(false);
      throw error;
    }
  };

  const resetDocs = (isClose) => {
    setRhDocs((docs) => {
      return docs.map((doc) => {
        if (isClose) {
          return {
            name: doc.name,
            type: doc.type,
            document: null,
            url: null,
            isEdit: false,
          };
        } else {
          return { ...doc, isEdit: false };
        }
      });
    });
  };

  const findUserSelected = async (id) => {
    try {
      const response = await FindUser(id);
      if (response.data.code === "ok") {
        setUserSelected(response.data.UserInfo);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    refreshUserList();
    // getApartmentLists();
  }, []);

  useEffect(() => {
    if (dataUsers) {
      let dataFilter = [...dataUsers];

      if (inputFilter !== "") {
        dataFilter = FiltersUsersInput(dataFilter, inputFilter);
      } else {
        if (
          filterStatus &&
          filterStatus !== "ALL" &&
          filterStatus !== "VACATIONS"
        ) {
          dataFilter = FilterRolesStatus(dataFilter, filterStatus);
        } else if (filterStatus === "VACATIONS") {
          dataFilter = FilterUsersVacations(dataFilter);
        }
      }

      if (statusSelected) {
        dataFilter = FilterActiveStatus(dataFilter, statusSelected);
      }

      if (rolesSelected.length > 0) {
        dataFilter = FilterUsersByRole(dataFilter, rolesSelected);
      }

      FiltersTotals(
        dataUsers,
        setAllTotal,
        setTemporaryTotal,
        setVacationsTotal,
        setIndefiniteTotal,
        setWithoutContract
      );

      setDataRHFilter(dataFilter);
    }
  }, [inputFilter, dataUsers, filterStatus, statusSelected, rolesSelected]);

  return (
    <RHContext.Provider
      value={{
        rhDocs,
        setRhDocs,
        resetDocs,
        rolesLists,
        addNewUser,
        inputFilter,
        dataRHFilter,
        filterStatus,
        isOpenFilter,
        userSelected,
        setAddNewUser,
        rolesSelected,
        isLoaderTable,
        louderFinDocs,
        statusSelected,
        setInputFilter,
        apartmentsLists,
        setIsOpenFilter,
        setUserSelected,
        setFilterStatus,
        setDataRHFilter,
        refreshUserList,
        setRolesSelected,
        findUserSelected,
        setStatusSelected,
        refreshDocumentUser,
        getRoleLists,
        getApartmentLists,
        allTotal,
        temporaryTotal,
        vacationsTotal,
        indefiniteTotal,
        withoutContract,
      }}
    >
      {children}
    </RHContext.Provider>
  );
};

export { RHContext, RHProviderContext };
