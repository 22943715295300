import { ClientInfo } from "./ClientInfo";
import { ActivityDetails } from "./ActivityDetails";
import { DocumentDetails } from "./DocumentDetails";
import { UseReservations } from "../../context/ReservationsContext";
import { useEffect, useState } from "react";

export function DetailsReservation({ isOpen, openModal }) {
  const { detailsReservation } = UseReservations();
  const [isCanceled, setIsCanceled] = useState(false);

  useEffect(() => {
    if (detailsReservation && detailsReservation.status === "Cancelado") {
      setIsCanceled(true);
    } else {
      setIsCanceled(false);
    }
  }, [detailsReservation]);

  return (
    isOpen &&
    detailsReservation && (
      <div className="fixed left-0 top-0 w-[100vw] h-[100vh] backdrop-brightness-50 z-[1]">
        <div className="relative flex items-center w-full h-full">
          <div className="sticky top-0 mx-auto my-auto w-[90%] rounded-lg bg-white px-5 pb-5 pt-12 h-[93%]">
            <button
              className="absolute right-3 top-3 w-max h-max focus:outline-none"
              onClick={openModal}
            >
              <img
                src={`${
                  import.meta.env.VITE_URL_IMAGES
                }assets/icons/close/close-70.svg`}
                width={16}
                height={16}
                alt="close modal"
                className=""
              />
            </button>

            <div className="flex gap-x-5 h-full">
              <ClientInfo
                holder={detailsReservation.holder}
                payment={detailsReservation.payment}
                status={detailsReservation.status}
              />

              <ActivityDetails services={detailsReservation.services} />

              <DocumentDetails
                documents={detailsReservation.documents}
                comments={detailsReservation.comments}
                isCanceled={isCanceled}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
}
