import { useState } from "react";
import { ProgressPassWord } from "./ProgressPassword";
import { useUser } from "../../../../configs/login/UserContext";

export function UpdatePassword({
  cancelUpdate,
  password,
  setPassword,
  confirmedPassword,
  setConfirmedPassword,
}) {

  const { samePassword } = useUser();

  const [isPassword, setIsPassword] = useState("password");
  const [isPasswordConf, setIsPasswordConf] = useState("password");

  return (
    <div className="flex flex-col gap-y-4 items-end">
      <p
        onClick={() => {
          cancelUpdate(false);
        }}
        className="px-2 py-2 cursor-pointer text-end shadow-3xl rounded-lg w-max text-white m-m text-fs-12 bg-red-100"
      >
        Cancelar
      </p>

      <div className="flex gap-x-4 justify-between mb-4">
        <div className="relative shadow-3xl rounded-md px-4 py-2">
          <input
            className=" focus:outline-none placeholder:text-fs-12"
            type={isPassword ? "password" : "text"}
            value={password}
            autoComplete="new-password"
            onChange={() => setPassword(event.target.value.trim())}
            placeholder="Contraseña"
          />

          <img
            onClick={() => setIsPassword(!isPassword)}
            className="cursor-pointer absolute right-[12px] top-0 bottom-0 my-auto"
            src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/general/${
              isPassword ? "eye-close.svg" : "eye.svg"
            }`}
            alt="see password"
            width={20}
            height={20}
          />
        </div>

        <div
          className={`relative shadow-3xl rounded-md px-4 py-2 ${
            !samePassword &&
            confirmedPassword.length >= 0 &&
            "border border-red-100"
          }`}
        >
          <input
            className=" focus:outline-none placeholder:text-fs-12"
            type={isPasswordConf ? "password" : "text"}
            value={confirmedPassword}
            autoComplete="new-password"
            onChange={() => setConfirmedPassword(event.target.value.trim())}
            placeholder="Confirmar contraseña"
          />

          <img
            onClick={() => setIsPasswordConf(!isPasswordConf)}
            className="cursor-pointer absolute right-[12px] top-0 bottom-0 my-auto"
            src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/general/${
              isPasswordConf ? "eye-close.svg" : "eye.svg"
            }`}
            alt="see password"
            width={20}
            height={20}
          />
          {!samePassword && confirmedPassword.length >= 0 && (
            <p className="absolute bottom-[-18px] left-0 text-red-100 text-fs-11 m-m">
              Las contraseñas no coinciden
            </p>
          )}
        </div>
      </div>

      <p className="text-start text-black text-fs-12 m-m w-full flex justify-start">
        Debes cumplir minimo con tres puntos
      </p>

      {/* PROGRESS */}
      <ProgressPassWord password={password} />
    </div>
  );
}
