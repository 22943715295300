export function AllPermissionsUser({
  allPermissions,
  setPermissionsAssigned,
  permissionsAssignedDefault,
  setPermissionsAssignedDefault,
  setRemovePermissions,
}) {
  const handlePermissions = (permissions, checked) => {
    handleResponse(permissions);

    handlePermissionsCancel(permissions, checked);

    handlePermissionsAdded(permissions, checked)
    // setPermissionsAssigned((vale) => {
    //   if (vale.some((permissionId) => permissionId == permissions.id)) {
    //     return vale.filter((permissionsId) => permissionsId !== permissions.id);
    //   } else {
    //     if (checked && !vale.includes(permissions.id)) {
    //       return [...vale, permissions.id];
    //     }
    //     if (!checked && !vale.includes(permissions.id)) {
    //       return [...vale];
    //     }
    //   }
    // });
  };

  const handleResponse = (permission) => {
    let newResponse = permissionsAssignedDefault;
    if (newResponse.some((permissions) => permissions.id == permission.id)) {
      newResponse = newResponse.filter(
        (permissions) => permissions.id !== permission.id
      );
    } else {
      newResponse = [...newResponse, permission];
    }

    setPermissionsAssignedDefault(newResponse);
  };

  const handlePermissionsCancel = (permission, checked) => {
    const isIncluded = allPermissions.userPermissions.some(
      (permissions) => permissions.id == permission.id
    );

    if (!checked && isIncluded) {
      setRemovePermissions((permissions) => [...permissions, permission.id]);
    }
    if (checked && isIncluded) {
      setRemovePermissions((permissions) =>
        permissions.filter(
          (allPermissions) => allPermissions !== permission.id
        )
      );
    }
  };

  const handlePermissionsAdded =(permission, checked)=>{
    const isIncluded = allPermissions.userPermissions.some(
      (permissions) => permissions.id == permission.id
    );

    if(checked && !isIncluded){
      setPermissionsAssigned((actualPermissions)=>[...actualPermissions, permission.id])
    }
    
    if(!checked && !isIncluded){
      setPermissionsAssigned((actualPermissions)=>actualPermissions.filter((permissions)=> permissions != permission.id))
    }
  }

  return (
    <div className="grid grid-cols-3 gap-4">
      {allPermissions?.availablePermissions.map((option, index) => (
        <div key={index} className="">
          <label>
            <input
              type="checkbox"
              id="cbox1"
              checked={permissionsAssignedDefault.some(
                (permissions) => permissions.id == option.id
              )}
              onClick={(event) =>
                handlePermissions(option, event.target.checked)
              }
            />{" "}
            {option.description}
          </label>
        </div>
      ))}
    </div>
  );
}
