import { useEffect, useState } from "react";

import AssignVacations from "./AssignVacations";
import { UseRHContext } from "../../context/RHContext";
import { AgeCalculation } from "../../configs/AgeCalculation";
import { formatCurrency } from "../../configs/SalaryConfig";

export default function InfoDetails() {
  const styleS = "text-fs-12 m-m text-gry-100";
  const styleT = "text-fs-14 m-b text-gry-100";

  const { userSelected, rolesLists } = UseRHContext();

  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [ageCalculation, setAgeCalculation] = useState(null);

  // const openVacationsDialog = () => {
  //   setIsAssignOpen(true);
  // };

  const closeVacationsDialog = () => {
    setIsAssignOpen(false);
  };

  useEffect(() => {
    if (userSelected && userSelected?.start_contract) {
      setAgeCalculation(AgeCalculation(userSelected?.start_contract));
    }
  }, [userSelected]);

  console.log(userSelected);
  
  return (
    <div className="mt-7 mb-32 flex flex-col gap-4">
      {/* SECTION 1 */}
      <div className="flex gap-16">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            {/* NAME */}
            <h3 className={`${styleT}`}>Nombre</h3>
            <span className={`${styleS}`}>{userSelected?.name}</span>
          </div>

          <div className="flex flex-col gap-1">
            {/* EMAIL */}
            <h3 className={`${styleT}`}>Correo electrónico</h3>
            <span className={`${styleS}`}>{userSelected?.email}</span>
          </div>

          <div className="flex flex-col gap-1">
            {/* TEL */}
            <h3 className={`${styleT}`}>Teléfono</h3>
            <span className={`${styleS}`}>{userSelected?.phone}</span>
          </div>

          <div className="flex flex-col gap-1">
            {/* TEL */}
            <h3 className={`${styleT}`}>Salario</h3>
            <span className={`${styleS}`}>
              ${" "}
              {userSelected?.salary
                ? formatCurrency(userSelected.salary)
                : "00.00"}
            </span>
          </div>
        </div>

        {/* SECTION 2 */}
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            {/* NSS */}
            <h3 className={`${styleT}`}>NSS</h3>

            <span className={`${styleS}`}>
              {userSelected?.nss ? userSelected?.nss : "-"}{" "}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            {/* BANK ACCOUNT */}
            <h3 className={`${styleT}`}>Cuenta bancaria</h3>

            <span className={`${styleS}`}>
              {userSelected?.clabe ? userSelected?.clabe : "-"}{" "}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            {/* CARD NUMBER */}
            <h3 className={`${styleT}`}>Numero de tarjeta</h3>

            <span className={`${styleS}`}>
              {userSelected?.card_number ? userSelected?.card_number : "-"}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            {/* BANK */}
            <h3 className={`${styleT}`}>Banco</h3>

            <span className={`${styleS}`}>
              {userSelected?.bank_name ? userSelected?.bank_name : "-"}
            </span>
          </div>
        </div>

        {/* SECTION 3 */}
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            {/* DATE OF CONTRACT */}
            <h3 className={`${styleT}`}>Fecha de contratacion</h3>

            <span className={`${styleS}`}>
              {userSelected?.start_contract
                ? userSelected?.start_contract
                : "-"}
            </span>
          </div>

          {userSelected?.contract_type == "TEMPORAL" && (
            <div className="flex flex-col gap-1">
              {/* DATE OF COMPLETION */}
              <h3 className={`${styleT}`}>Fecha de finalizacion</h3>

              <span className={`${styleS}`}>
                {userSelected?.end_contract ? userSelected?.end_contract : "-"}
              </span>
            </div>
          )}

          <div className="flex flex-col gap-1">
            {/* Type de Usuario */}
            <h3 className={`${styleT}`}>Tipo de Usuario</h3>

            <span className={`${styleS}`}>
              {rolesLists.length > 0 &&
                rolesLists.find((rol) => rol.id == userSelected?.role_id)
                  ?.description}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            {/* DEPARTMENT */}
            <h3 className={`${styleT}`}>Departamento</h3>

            <span className={`${styleS}`}>
            {userSelected?.DepartmentDescription ? userSelected?.DepartmentDescription : "-"}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            {/* TYPE OF CONTRACT */}
            <h3 className={`${styleT}`}>Tipo de contrato</h3>
            <span
              className={`${
                !userSelected?.contract_type && "text-red-100"
              } ${styleS}`}
            >
              {userSelected?.contract_type
                ? userSelected?.contract_type
                : "Sin contrato"}
            </span>
          </div>
        </div>

        {/* SECTION 4 */}
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            {/* SENIORITY */}
            <h3 className={`${styleT}`}>Antigüedad</h3>

            <span className={`${styleS}`}>
              {ageCalculation &&
                `${
                  ageCalculation.year > 0
                    ? ageCalculation.year +
                      ` ${ageCalculation.year > 1 ? "años, " : "año,"}`
                    : ""
                } ${
                  ageCalculation.month > 0
                    ? ageCalculation.month +
                      ` ${ageCalculation.month > 1 ? "meses, " : "mes,"} y`
                    : ""
                } ${
                  ageCalculation.dates > 0 &&
                  `${ageCalculation.dates} ${
                    ageCalculation.dates > 1 ? "dias" : "dia"
                  }`
                }`}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            {/* VACATION REQUESTED */}
            <h3 className={`${styleT}`}>Vacaciones solicitadas</h3>
            <span className={`${styleS}`}>
              {userSelected?.vacations ? userSelected?.vacations : "-"}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            {/* VACATIONS AVAILABLE */}
            <h3 className={`${styleT}`}>Vacaciones disponibles</h3>

            <span className={`${styleS}`}>
              {userSelected?.available_days
                ? userSelected?.available_days
                : "-"}
            </span>
          </div>
        </div>
      </div>

      {/* BTN VACATIONS */}
      {/* <button
        onClick={openVacationsDialog}
        className="bg-bl-100 rounded-lg px-4 py-3 flex gap-2 hover:bg-bl-110 w-fit mt-4 mb-20"
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/vacations-w.svg`}
          width={16}
          height={16}
          alt="icon vacations"
          className=""
        />
        <span className="text-fs-12 text-white m-s-b">
          Asignarle vacaciones
        </span>
      </button> */}

      <AssignVacations isOpen={isAssignOpen} close={closeVacationsDialog} />
    </div>
  );
}
