// import { activityDetailsTest } from "../../config/filesJson";

export function TabActivity({ active, setActive, splitServices }) {
  return (
    splitServices && (
      <div className={`sticky top-0 flex min-w-[379px]`}>
        {splitServices.hotel.length > 0 && (
          <p
            className={`${
              active === Object.keys(splitServices)[0]
                ? "text-bl-100 border-bl-100 m-s-b"
                : "text-gry-100 border-gry-50 m-m"
            }  m-0 text-fs-14 border-b py-2 px-4 cursor-pointer`}
            onClick={() => setActive("hotel")}
          >
            Hotel
          </p>
        )}

        {splitServices.tour.length > 0 && (
          <p
            className={`${
              active === Object.keys(splitServices)[1]
                ? "text-bl-100 border-bl-100 m-s-b"
                : "text-gry-100 border-gry-50 m-m"
            }  m-0 text-fs-14 border-b py-2 px-4 cursor-pointer`}
            onClick={() => setActive("tour")}
          >
            Tour
          </p>
        )}

        {splitServices.transport.length > 0 && (
          <p
            className={`${
              active === Object.keys(splitServices)[2]
                ? "text-bl-100 border-bl-100 m-s-b"
                : "text-gry-100 border-gry-50 m-m"
            }  m-0 text-fs-14 border-b py-2 px-4 cursor-pointer`}
            onClick={() => setActive("transport")}
          >
            Transporte
          </p>
        )}
      </div>
    )
  );
}
