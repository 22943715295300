import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function CarrouselPaymentStep() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div
      className={`relative w-[299px]  select-none ${
        responsiveView.value === "laptop"
          ? "rotate-3 h-[444px]"
          : "mx-auto h-[461px]"
      }`}
    >
      {/* IMAGE MOCKUP PHONE */}
      <img
        className={`mx-auto absolute left-0 top-0 w-full ${
          responsiveView.value === "laptop" ? " h-full" : "h-[97%]"
        }`}
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/img/home/payment-step/mockup-phone.png`}
        alt="Foreground Phone"
      />

      <div className="h-full w-[64%] mx-auto relative">
        {/* SWIPER SLIDE ITINERARY DESIGN */}
        <div
          className={`${
            responsiveView.value === "laptop" ? "mt-4" : "mt-4 pt-4"
          }`}
        >
          <img
            className="rounded-[30px] object-contain h-full"
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/img/home/payment-step/step-1.png`}
            alt="Background Phone"
          />
        </div>
      </div>
    </div>
  );
}
