import { useContext } from "react";

import { MarketingContext } from "../../../context/MarketingContext";

export function PaymentBannerMobile() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <div
      className={`relative w-[180px] right-[-25px] ${
        responsiveView.value === "laptop" ? "block" : "hidden"
      }`}
    >
      <img
        className="absolute object-contain left-[50px] top-0 bottom-0 z-0 my-auto -rotate-6 w-full h-[370px] select-none"
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/img/home/payment-step/phone-banner-payment.png`}
        alt="phone-banner-payment"
      />

      <img
        className="z-0 absolute bottom-0 right-0 select-none"
        src={`${
          import.meta.env.VITE_URL_IMAGES
        }assets/img/home/payment-step/payment-banner.png`}
        alt="payment-banner"
      />
    </div>
  );
}
