import { useContext } from "react";

import { LodgingsView } from "./LodgingsView";
import { listOptions } from "../../../configs/Json";
import { MarketingContext } from "../../../context/MarketingContext";

export function ShuffleHotel() {
  const { responsiveView } = useContext(MarketingContext);
  return (
    <>
      <h2 className="m-b text-fs-24 mb-8 ">Hospedajes al mejor precio</h2>
      <div
        className={`flex custom-scrollbar overflow-y-hidden gap-10 bg-gry-50 py-[8px] px-[12px] rounded-3xl mb-8 ${
          responsiveView.value === "laptop" ? "w-max max-2xl:w-auto" : "w-full"
        } h-max`}
      >
        {listOptions.map((option, index) => (
          <div
            key={index}
            className={`${
              option.name === "Playa"
                ? "bg-or-100 rounded-3xl text-white"
                : "text-gry-100"
            } flex gap-2 py-2 px-4 justify-center items-center h-max`}
          >
            {option.name === "Playa" ? (
              <img
                src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/${
                  option.icon
                }`}
                alt={option.name}
                width={16}
                height={16}
                className="w-[16px] h-[16px]"
              />
            ) : (
              <img
                src={`${import.meta.env.VITE_URL_IMAGES}assets/icons/${
                  option.iconActive
                }`}
                alt={option.name}
                width={16}
                height={16}
                className="w-[16px] h-[16px]"
              />
            )}

            <span className={`text-nowrap text-fs-12 m-m`}>{option.name}</span>
          </div>
        ))}
      </div>
      <LodgingsView />
    </>
  );
}
