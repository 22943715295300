import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { CreateUser } from "../api/api";
import SelectRolUser from "./SelectRolUser";
import { UsersContext } from "../context/UsersContext";
import { displayUserCreation } from "../../../utils/error/errorMsg";
import NotificationType from "../../../components/alerts/notifications/NotificationType";
import { useNotification } from "../../../components/alerts/notifications/useNotification";

export default function AddNewUser({ isOpen, closeNewUser }) {
  const [rol, setRol] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("active");
  const [domain, setDomain] = useState("@staywuw.com");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { notification, showNotification, hideNotification } =
    useNotification();
  const { refreshUserList, rolesLists} = useContext(UsersContext);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setName("");
      setPhone("");
      setPassword("");
      setPasswordConfirmation("");
      setStatus("active");
      setRol(1);
      setDomain("@staywuw.com");
      setErrors({});
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    let formErrors = {};

    // Validaciones
    if (!name) formErrors.name = "El nombre es requerido";
    if (!phone) formErrors.phone = "El teléfono es requerido";
    if (!password) {
      formErrors.password = "La contraseña es requerida";
    }
    if (!passwordConfirmation) {
      formErrors.passwordConfirmation =
        "La confirmación de la contraseña es requerida";
    } else if (password !== passwordConfirmation) {
      formErrors.passwordConfirmation = "Las contraseñas no coinciden";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const userData = {
      name,
      password,
      password_confirmation: passwordConfirmation,
      domain,
      phone,
      status,
      rol,
    };

    try {
      const result = await CreateUser(userData);

      if (result) {
        displayUserCreation(result.code, showNotification);
        closeNewUser();
        refreshUserList();
      } else {
        displayUserCreation(result.code, showNotification);
      }
    } catch (error) {
      displayUserCreation("error", showNotification);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeNewUser}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[486px] transform rounded-lg overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <img
                      src={`${
                        import.meta.env.VITE_URL_IMAGES
                      }assets/icons/close/close-100.svg`}
                      width={16}
                      height={16}
                      alt="icon-close"
                      className="mr-2 cursor-pointer absolute right-4"
                      onClick={closeNewUser}
                    />

                    <h2 className="m-b text-fs-20 text-[#5F5F5F] text-center">
                      Crear nuevo usuario
                    </h2>

                    {/* FORM */}
                    <div className="flex flex-col gap-3 mt-5">
                      <span className="m-b text-fs-14 text-[#5F5F5F]">
                        Datos empresariales
                      </span>
                      {/* INPUT NAME */}
                      <div className="relative w-full border border-gry-50 h-[43px]">
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/adult/adult-b.svg`}
                          width={15}
                          height={15}
                          alt="icon-adult-crm"
                          className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                        />
                        <input
                          type="text"
                          placeholder="Nombre Completo"
                          className="border pl-[40px] border-gry-50 py-2 my-auto focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && (
                          <p className="text-red-500 text-xs">{errors.name}</p>
                        )}
                      </div>

                      {/* INPUT TEL */}
                      <div className="relative w-full border border-gry-50 h-[43px]">
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/tel/tel-b.svg`}
                          width={15}
                          height={15}
                          alt="icon-tel-crm"
                          className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                        />
                        <input
                          type="tel"
                          className="border border-gry-50 py-2 my-auto pl-[40px] focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                          placeholder="Teléfono"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        {errors.phone && (
                          <p className="text-red-500 text-xs">{errors.phone}</p>
                        )}
                      </div>

                      {/* SELECT DOMAIN */}
                      <div className="relative w-full border border-gry-50 h-[43px]">
                        <select
                          value={domain}
                          onChange={(e) => setDomain(e.target.value)}
                          className="border border-gry-50 py-2 pl-[40px] focus:outline-none w-full h-full bg-transparent text-[#5F5F5F]"
                        >
                          <option value="@staywuw.com">StayWUW</option>
                          <option value="@royalvacationsmexico.com">
                            Royal Vacations
                          </option>
                        </select>
                      </div>

                      {/* INPUT PASSWORD */}
                      <div className="relative w-full border border-gry-50 h-[43px]">
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/padlock.svg`}
                          width={18}
                          height={18}
                          alt="icon-padlock-crm"
                          className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                        />
                        <input
                          type="password"
                          placeholder="Contraseña"
                          className="border border-gry-50 py-2 my-auto pl-[40px] focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && (
                          <p className="text-red-500 text-xs">
                            {errors.password}
                          </p>
                        )}
                      </div>

                      {/* INPUT PASSWORD CONFIRMATION */}
                      <div className="relative w-full border border-gry-50 h-[43px]">
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/padlock-confirm.svg`}
                          width={18}
                          height={18}
                          alt="icon-padlock-confirm-crm"
                          className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                        />
                        <input
                          type="password"
                          placeholder="Confirmar Contraseña"
                          className="border border-gry-50 py-2 my-auto pl-[40px] focus:outline-none w-full h-full bg-transparent placeholder:text-gry-100 placeholder:m-m placeholder:text-fs-12"
                          value={passwordConfirmation}
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                        />
                        {errors.passwordConfirmation && (
                          <p className="text-red-500 text-xs">
                            {errors.passwordConfirmation}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 mt-5">
                      <span className="m-b text-fs-14 text-[#5F5F5F]">
                        Administración
                      </span>

                      {/* SELECT STATUS */}
                      <div className="flex relative">
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/user-${
                            status === "active" ? "active" : "inactive"
                          }.svg`}
                          width={18}
                          height={18}
                          alt="icon-user-active-crm"
                          className="absolute top-[3px] bottom-0 ml-[18px] my-auto"
                        />
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="border border-gry-50 py-2 pl-[40px] focus:outline-none w-full h-full bg-transparent text-[#5F5F5F]"
                        >
                          <option value="active">Activo</option>
                          <option value="inactive">Inactivo</option>
                        </select>
                      </div>

                      {/* SELECT ADMIN */}
                      <SelectRolUser
                        roles={rolesLists}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </div>

                    {/* BTN´S ALERT */}
                    <div className="flex justify-center gap-3 mt-5">
                      <button
                        onClick={closeNewUser}
                        className="px-3 py-3 rounded-lg w-fit text-red-100 m-s-b text-fs-12 hover:bg-red-100 hover:text-white"
                      >
                        Cancelar
                      </button>

                      <button
                        onClick={handleSubmit}
                        className="flex gap-1 px-3 py-3 rounded-lg bg-bl-100 w-fit text-fs-12 text-white m-s-b hover:bg-bl-110"
                      >
                        <img
                          src={`${
                            import.meta.env.VITE_URL_IMAGES
                          }assets/icons/general/save-w.svg`}
                          alt="icon save white"
                          width={18}
                          height={18}
                        />
                        Guardar
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {notification && notification.visible && (
        <NotificationType
          type={notification.type}
          title={notification.title}
          message={notification.message}
          duration={notification.duration}
          onClose={hideNotification}
        />
      )}
    </>
  );
}
