// src/api.jsx
import axiosWithInterceptor from "../../../configs/Instance/axiosConfig";

export const FetchUserList = async () => {
  try {
    const response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/userList`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const CreateUser = async (userData) => {
  try {
    const response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/register`,
      userData
    );

    return response.data;
  } catch (error) {
    throw new Error("Error al crear el usuario");
  }
};

export async function GetRoleList() {
  try {
    let response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/RoleList`
    );
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetApartment() {
  try {
    let response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/DepartmentList`
    );
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function ChangeStatusUser(id, status) {
  try {
    let response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/change-status`,
      { userId: id, status: status }
    );
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function UpdateUser(info, userId) {
  try {
    let response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/updateUser/${userId}`,
      info
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetUserPermissions(userId) {
  try {
    let response = await axiosWithInterceptor.get(
      `${import.meta.env.VITE_API_URL_STAYWUW}/getUserPermissions/${userId}`
    );
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export async function UpdatePermissionsBayUser(permissions) {
  try {
    let response = await axiosWithInterceptor.post(
      `${import.meta.env.VITE_API_URL_STAYWUW}/addPermissions`, permissions
    );
    if (response) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}


