import { useEffect, useState } from "react";

import {
  GetProviderPayment,
  SaveProviderPayment,
} from "../../api/ConfigurationApi";
import { Skeleton } from "./Skeleton";
import { ButtonSave } from "./ButtonSave";
import { ButtonPayment } from "./ButtonPayment";
import AlertsCrm from "../../../../components/alerts/Alerts";
import { ContainerConfig } from "../../config/ContainerConfig";

export function ContainerProviderPayment({ showNotification, isOpen }) {
  const [loader, setLoader] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [typePayment, setTypePayment] = useState(null);
  const [providersPayment, setProvidersPayment] = useState([]);

  const getProvider = async () => {
    try {
      let response = await GetProviderPayment();
      setProvidersPayment(response);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // GET PROVIDERS
  useEffect(() => {
    if (isOpen) {
      getProvider();
    }
  }, [isOpen]);

  // SET PROVIDER ACTIVE
  useEffect(() => {
    if (providersPayment.length > 0) {
      setTypePayment(() =>
        providersPayment.find((provider) => provider.status == "active")
      );
    }
  }, [providersPayment]);

  useEffect(() => {
    if (providersPayment && typePayment) {
      const filterActive = providersPayment.find(
        (payment) => payment.status == "active"
      );

      if (filterActive.name == typePayment.name) {
        setIsChange(false);
      } else {
        setIsChange(true);
      }
    }
  }, [typePayment, providersPayment]);

  // SAVE PROVIDER ACTIVE
  const handleSavePayment = async (isUpdate) => {
    if (isUpdate) {
      setLoader(true);
      setOpenAlert(false);
      try {
        const saveProvider = await SaveProviderPayment(typePayment.name);
        if (saveProvider.code == "ok") {
          showNotification(
            "success",
            "El proveedor de pago se cambio con exito",
            "",
            5000
          );
        }
        setLoader(false);
      } catch (error) {
        console.log(error);
        showNotification(
          "error",
          "Ha fallado la actualización del proveedor de pago",
          "",
          5000
        );
        setLoader(false);
        throw error;
      }
    }
  };

  return (
    <>
      <ContainerConfig isOpen={isOpen}>
        {isOpen && (
          <div className="flex flex-col gap-y-2.5">
            <div className="flex py-2.5 justify-start flex-wrap gap-x-4">
              {providersPayment.length ? (
                providersPayment.map((payment, index) => (
                  <ButtonPayment
                    payment={payment}
                    paymentSelected={typePayment}
                    setTypePayment={setTypePayment}
                    key={index}
                  />
                ))
              ) : (
                <Skeleton />
              )}
            </div>

            <ButtonSave
              isChange={isChange}
              setOpenAlert={setOpenAlert}
              loader={loader}
            />
          </div>
        )}
      </ContainerConfig>

      <AlertsCrm
        setConfirmation={handleSavePayment}
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        twoOptions={true}
        title="¡Actualización de proveedores de pago!"
        text="¿Deseas actualizar los proveedores de pago?"
        typeAlert={"warning"}
      />
    </>
  );
}
