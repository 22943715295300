import { UseReservations } from "../../context/ReservationsContext";

const typesOrder = [
  { value: 1, label: "Ultimas reservas" },
  { value: 2, label: "Reservas antiguas" },
];
export function FilterByOrder() {
  const { setFilterByOrder, filterByOrder } = UseReservations();
  return (
    <div className="flex flex-col gap-y-3 ">
      <p className="text-fs-14 m-b text-black">Servicios</p>

      {typesOrder.map((order, index) => (
        <button
          onClick={() => setFilterByOrder(order.value)}
          key={index}
          className={`flex gap-x-2 items-center focus:outline-none select-none border py-2 px-4 rounded-full ${
            filterByOrder === order.value
              ? "border-bl-100 bg-bl-100 text-white"
              : "border-gry-70"
          }`}
        >
          <p className="m-0 text-fs-12 m-s-b select-none">{order.label}</p>
        </button>
      ))}
      {/* <select
        onChange={() => handleOrder(event)}
        className="focus:outline-none border rounded-sm px-[16px] py-[12.4px] h-[45px]"
      >
        {typesOrder.map((order, index) => (
          <option
            className="m-0 text-fs-12 m-s-b text-gry-100"
            key={index}
            value={order.value}
          >
            {order.label}
          </option>
        ))}
      </select> */}
    </div>
  );
}
