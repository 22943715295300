import { useParams } from "react-router-dom";

import ViewSelected from "./ViewSelected";
import { NavButtons } from "../components/others/NavButtons";
import ModalEditImage from "../components/Images/ModalEditImage";
import { ProviderMarketingContext } from "../context/MarketingContext";

export function LayoutViewSelected() {
  const { view } = useParams();

  return (
    <ProviderMarketingContext>
      <NavButtons view={view} />

      <div className="flex justify-center bg-gry-20">
        <ModalEditImage />

        <ViewSelected />
      </div>
    </ProviderMarketingContext>
  );
}
