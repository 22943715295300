import { Navigate } from "react-router-dom";

import { useUser } from "./UserContext";

const AuthGuard = ({ children }) => {
  const { user, loading } = useUser();

  // loading....

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="animate-pulse">
          <img
            src={`${
              import.meta.env.VITE_URL_IMAGES
            }assets/royal/principal-logo.svg`}
            alt="Company Logo"
            className="w-[300px] h-[200px]"
          />
        </div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthGuard;
