import React, { useContext } from "react";
import moment from "moment";

import { SeeDetails } from "./SeeDetails";
import { RemoveReservation } from "./RemoveReservation";
import { BodySkeleton } from "../../../components/skeletons/BodySkeleton";
import { TruncateLetters } from "../../../configs/others/TruncateLetters";
import {
  ReconfirmationBooking,
  ReconfirmationBookingBtn,
} from "./reconfirmationBooking/ReconfirmationBooking";
import { EmptyTable } from "../../../components/table/EmptyTable";
import { ReservationsContext } from "../context/ReservationsContext";

export function TableReservation({ dataReservationFilter }) {
  const dataReservationFilterPag = dataReservationFilter;

  const { loaderReservation } = useContext(ReservationsContext);
  // STYLE TABLE
  const styleTd = "m-0 text-fs-12 m-m flex justify-center";
  const styleTh = "text-fs-14 m-b text-gry-100 ";

  const typeImage = (activity) => {
    let url = "";
    switch (activity) {
      case "hotel":
        url = `${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/hotel/hotel-crm.svg`;
        break;
      case "tour":
        url = `${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/tour/tour-crm.svg`;
        break;
      case "transport":
        url = `${
          import.meta.env.VITE_URL_IMAGES
        }assets/icons/transport/transport-crm.svg`;
        break;
    }
    return url;
  };

  const statusReservation = (status, reservation) => {
    let style = "";
    switch (status) {
      case "Confirmado":
        style = "bg-grn-10 text-grn-100";
        break;
      case "Pendiente":
        style = "bg-or-20 text-or-100";
        return (
          <div>
            <ReconfirmationBookingBtn
              referenceNumber={reservation.bookingReference}
              status={reservation.status}
            />
          </div>
        );
      case "Cancelado":
        style = "bg-red-20 text-red-100";
        break;
    }

    return (
      <div
        className={`p-1 rounded-md m-b text-fs-12 w-max flex justify-center ${style}`}
      >
        {reservation.status}
      </div>
    );
  };

  return (
    <div className="bg-gry-30 rounded-r-2xl rounded-bl-2xl p-5 w-full overflow-x-auto">
      <table className="w-full mt-6">
        <thead className="mb-4">
          <tr>
            <th className={`${styleTh} w-[152px]`}>No.Folio interno</th>
            <th className={`${styleTh}`}>Nombre</th>
            <th className={`${styleTh}`}>Servicios</th>
            <th className={`${styleTh}`}>Fecha de reservación</th>
            <th className={`${styleTh}`}>Estatus</th>
            <th className={`${styleTh}`}>Agente asignado</th>
            <th className={`${styleTh}`}>Totales</th>
            <th className={`${styleTh}`}>Acciones</th>
          </tr>
        </thead>

        {!loaderReservation &&
          dataReservationFilterPag &&
          dataReservationFilterPag.length > 0 && (
            <tbody>
              <tr className="h-[25px] bg-gry-30"></tr>
              {dataReservationFilterPag.map((reservation, index) => (
                <React.Fragment key={index}>
                  <>
                    <tr className="bg-white rounded-lg h-[56px]">
                      <td className="rounded-l-xl">
                        <div className="flex items-center gap-x-2 justify-center">
                          <p
                            className={styleTd}
                          >{`${reservation.bookingReference}`}</p>
                        </div>
                      </td>

                      <td className="w-[130px]">
                        <p className={`${styleTd} !m-s-b`}>
                          {reservation.userName.length >= 28
                            ? `${TruncateLetters(reservation.userName, 28)}...`
                            : reservation.userName}
                        </p>
                      </td>

                      <td className="w-[110px]">
                        <div className="flex gap-x-2 items-center justify-center">
                          {reservation?.services &&
                            reservation.services.map((activity, item) => (
                              <div key={item}>
                                <img
                                  src={typeImage(activity.type)}
                                  alt={activity.type}
                                />
                              </div>
                            ))}
                        </div>
                      </td>

                      <td className="w-[120px]">
                        <p className={`${styleTd}`}>
                          {moment(reservation.reservationDate).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </td>

                      {/* STATUS */}
                      <td>
                        <div className="flex items-center justify-center">
                          {statusReservation(reservation.status, reservation)}
                        </div>
                      </td>

                      {/* EMPLEOYE */}
                      <td>
                        <p className={`${styleTd}`}>
                          {reservation.assignedAgent.length >= 28
                            ? `${TruncateLetters(
                                reservation.assignedAgent,
                                28
                              )}...`
                            : reservation.assignedAgent}
                        </p>
                      </td>

                      {/* TOTALS */}
                      <td>
                        <p className={`${styleTd}`}>
                          ${" "}
                          {Math.floor(reservation.totalAmount)
                            .toLocaleString("es-MX", { currency: "MXN" })
                            .replace(".00", "")}
                          .
                          <sup className="top-0">
                            {(reservation.totalAmount % 1).toFixed(2).slice(2)}{" "}
                          </sup>{" "}
                        </p>
                      </td>

                      {/* ACTIONS BTNS RESERVATION */}
                      <td className="rounded-r-xl w-[7rem]">
                        <div className="grid grid-cols-3 justify-items-center">
                          <SeeDetails
                            referenceNumber={reservation.bookingReference}
                          />

                          {reservation.status === "Proceso de cancelación" && (
                            <RemoveReservation reservationInfo={reservation} />
                          )}

                          {reservation.status === "Pendiente" && (
                            <ReconfirmationBooking
                              referenceNumber={reservation.bookingReference}
                            />
                          )}

                          {/* <EditReservationButton reservation={reservation} />

                        <RemoveReservation reservationInfo={reservation} /> */}
                        </div>
                      </td>
                    </tr>

                    {dataReservationFilterPag.length - 1 !== index && (
                      <tr className="h-[12px] bg-transparent" />
                    )}
                  </>
                </React.Fragment>
              ))}
            </tbody>
          )}
      </table>

      {!loaderReservation && dataReservationFilterPag.length === 0 && (
        <EmptyTable />
      )}

      {loaderReservation && <BodySkeleton />}
    </div>
  );
}
