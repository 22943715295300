import { useState } from "react";
import AddNewEmployee from "./AddNewEmployee";
import { useUser } from "../../../configs/login/UserContext";
import { HasPermissionsUser } from "../../../configs/Permissions/PermissionsUser";
import AlertPermissionsDeclined from "../../../configs/Permissions/AlertPermissionsDeclined";

export default function BtnAddRH() {
  const { user } = useUser();
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(true);

  const openAddUserDialog = () => {
    const hasPermission = HasPermissionsUser(
      user.permissions,
      "register_user"
    );

    setHasPermissions(hasPermission);
    if(hasPermission){
      setIsAddUserOpen(true);

    }
  };

  const closeAddUserDialog = () => {
    setIsAddUserOpen(false);
  };
  return (
    <>
      <button
        onClick={openAddUserDialog}
        className="bg-bl-100 flex gap-3 rounded-lg justify-center items-center px-3 h-[43px]"
      >
        <img
          src={`${
            import.meta.env.VITE_URL_IMAGES
          }assets/icons/general/add-user-w.svg`}
          alt="icon add user white"
        />
        <span className="text-white text-fs-12 m-b text-nowrap">
          Agregar empleado
        </span>
      </button>

      <AddNewEmployee isOpen={isAddUserOpen} closeNewUser={closeAddUserDialog}/>

      <AlertPermissionsDeclined
        hasPermissions={hasPermissions}
        cleanedAlert={setHasPermissions}
      />
    </>
  );
}
